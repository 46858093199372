import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setBanner } from '../../../redux/store/advertisement/baseInformation/baseInformationActions';

import UploadedFile from './UploadedFile/UploadedFile';
import InitialFile from './InitialFile/InitialFile';

const UploadFile = ({
  defaultFile,
  handleUploadFileChange,
  onChange,
  approved,
  status,
  content,
  name,
  errors,
}) => {
  const dispatch = useDispatch();

  const [upload, setUpload] = useState({
    mainState: 'initial',
    fileUploaded: 0,
    selectedFile: null,
  });

  useEffect(() => {
    if (defaultFile) {
      setUpload({
        mainState: 'uploaded',
        selectedFile: defaultFile,
        fileUploaded: 1,
      });
      dispatch(setBanner(defaultFile));
    }
  }, [dispatch, defaultFile]);

  const handleUploadClick = event => {
    event.preventDefault();
    event.stopPropagation();
    let [file] = event.target.files;
    file = file && URL.createObjectURL(file);

    setUpload({
      ...upload,
      mainState: 'uploaded',
      selectedFile: file,
      fileUploaded: 1,
    });

    onChange(event.target.files[0]);
    handleUploadFileChange(event.target.files[0]);
    dispatch(setBanner(event.target.files[0]));
  };

  const handleResetUpload = event => {
    setUpload({
      ...upload,
      mainState: 'initial',
      selectedFile: null,
      fileUploaded: 0,
    });
    onChange(null);
    handleUploadFileChange(null);
    dispatch(setBanner(null));
  };

  return (
    <div>
      <div>
        {upload.mainState === 'initial' && (
          <InitialFile
            approved={approved}
            status={status}
            defaultFile={content}
            handleUploadClick={handleUploadClick}
            errors={errors}
          />
        )}
        {upload.mainState === 'uploaded' && (
          <UploadedFile
            approved={approved}
            status={status}
            fileResetHandler={handleResetUpload}
            selectedFile={upload?.selectedFile}
            name={content?.name || name}
            handleUploadClick={handleUploadClick}
            errors={errors}
          />
        )}
      </div>
    </div>
  );
};

export default UploadFile;
