import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../redux/store/modal/modalActions';

import Rating from '../../common/Rating/Rating';
import { modalTypes } from '../../../../configs/modalTypes';
import paywallAvatar from '../../../../assets/images/bloggers/paywallAvatar.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';
import {getOfferDemos, changeOfferStatus, changeOfferStatusAndPay} from '../../../../api/offer-service/offer-service'
const BlogerCardAdvertisement = ({ bloggerInformation, isPaywalled, t, refresh, refreshState}) => {
  const { status, user, comments, demoFile, _id, userName,result_link } = bloggerInformation;
  const dispatch = useDispatch();
  const [isRefreshed, setIsRefreshed] = useState(refresh)
  useEffect(()=>{
    setIsRefreshed(refresh)
  },[refresh])

  const type = 'secondary';
  const isFavorite = false;
  const _onClickFinilize = (e) => {
    changeOfferStatusAndPay(_id, {status: "COMPLETED", comment:""}).then(()=>{
      refreshState(refresh*-1)
    })
  };
  
  const _onClickAccept = (e) => {
    changeOfferStatus(_id, {status: "ACTIVE", comment:""}).then(()=>{
      refreshState(refresh*-1)
    })
  };
  const _onClickDeciline = (e) => {
    changeOfferStatus(_id, {status: "REJECTED_BY_ADVERTISER", comment:""}).then(()=>{
      refreshState(refresh*-1)
    })
  };
  const _onClickShowPlacementLink = (titleT, textT) =>{
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.showResultLink,
        details: {
          title: titleT, text: textT, buttonText: "Close"}
      }),
    );
  }
  return (
    <div className="blogger-statistics-card">
      <div className="blogger-statistics-card-head ">
        <img
          crossOrigin="anonymous"
          src={isPaywalled || !user?.avatar ? paywallAvatar : user?.avatar}
          className="blogger-statistics-card-head__img"
          alt="blooger"
          onError={e => {
            e.target.onerror = null;
            e.target.src = paywallAvatar;
          }}
        />
        {userName && (
          <p className="blogger-statistics-card-head__profile">
            {userName}
          </p>
        )}
        <p className="blogger-statistics-card-head__category">
          {user?.accountSubject}
        </p>
        <div className="blogger-statistics-card-head__links">
          <div className="blogger-card-head__stars">
            <Rating rating={user?.accountQuality} />
          </div>
        </div>
      </div>
      <p className="blogger-card-head__profile">{userName}</p>


      {
        status === "PENDING" &&
        <p className="blogger-statistics-card-head__profile">Pending</p>
      }


      {
        status === "WAITING_FOR_DEMO" &&
        <p className="blogger-statistics-card-head__profile">Waiting for demo</p>
      }

      {status === "DEMO_LOADED" && <div className="blogger-statistics-card-price-ads">
          <button onClick={()=>{
            getOfferDemos(_id)
          }}>Download Demo</button>
        </div>
      }
      {status === "DEMO_LOADED" && 
        <button className="blogger-card-button" onClick={()=>{_onClickAccept()}}>Accept</button>
      }
      {status === "DEMO_LOADED" && 
        <button  className="blogger-card-button" onClick={()=>{_onClickDeciline()}} >Decline</button>
      }


      {
        status === "ACTIVE" &&
        <p className="blogger-statistics-card-head__profile">Approved</p>
      }

      {
        status === "COMPLETED" &&
        <p className="blogger-statistics-card-head__profile">Completed</p>
      }
      {status === "RESULT_LOADED" && result_link &&
        <button className="blogger-card-button" onClick={()=>{ navigator.clipboard.writeText(result_link).then(function() {
                        console.log('Async: Copying to clipboard was successful!');
                      }, function(err) {
                        console.error('Async: Could not copy text: ', err);
                      }); 
       }}>Copy link to check result</button>
      }
      {status === "RESULT_LOADED" && result_link &&
        <button  className="blogger-card-button" onClick={()=>{_onClickFinilize()}} >Send money</button>
      }
    </div>
  );
};

export default withTranslation()(BlogerCardAdvertisement);
