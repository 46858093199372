import types from './advetisementTypes';

const initialQueryParams = {
  offset: 0,
  limit: 10,
  statusParams: '&status=ALL',
  sortParams: '&sortBy=publicId',
  orderParams: '&orderBy=desc',
  dateFromParams: '',
  dateToParams: '',
};

const initialState = {
  advertisementItem: {
    offers: [],
    story: {
      comments: [],
      approved: '',
    },
  },
  advertisementStatistic: {
    items: [],
    count: 0,
  },
  params: initialQueryParams,
};

export const advertisementReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.GET_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        advertisementItem: payload,
      };
    case types.CLEAR_ADVERTISEMENT:
      return {
        ...state,
        advertisementItem: initialState.advertisementItem,
      };
    case types.GET_ADVERTISEMENT_STATISTIC_SUCCESS:
      return {
        ...state,
        advertisementStatistic: payload,
      };
    case types.SET_SORT_TYPE:
      return {
        ...state,
        params: {
          ...state.params,
          sortParams: payload,
          offset: 0,
        },
      };
    case types.SET_STATUS:
      return {
        ...state,
        params: {
          ...state.params,
          statusParams: payload,
          offset: 0,
        },
      };
    case types.SET_ORDER_TYPE:
      return {
        ...state,
        params: {
          ...state.params,
          orderParams: payload,
          offset: 0,
        },
      };
    case types.SET_DATE_FROM_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          dateFromParams: payload,
          offset: 0,
        },
      };
    case types.SET_DATE_TO_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          dateToParams: payload,
          offset: 0,
        },
      };
    case types.SET_OFFSET:
      return {
        ...state,
        params: {
          ...state.params,
          offset: payload,
        },
      };
    case types.CLEAR_ALL_PARAMS:
      return {
        ...state,
        params: initialQueryParams,
      };
    case types.CLEAR_DATE_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          offset: 0,
          dateFromParams: initialQueryParams.dateFromParams,
          dateToParams: initialQueryParams.dateToParams,
        },
      };
    case types.DELETE_ADVERTISEMENT_SUCCESS:
      return {
        ...state,
        advertisementItem: state.advertisementItem,
      };
    case types.REMOVE_OFFER_FROM_LIST:
      return {
        ...state,
        advertisementItem: {
          ...state.advertisementItem,
          offers: payload,
        },
      };
    default:
      return state;
  }
};

export default advertisementReducer;
