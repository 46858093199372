import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';

import { putUserPersonalData } from '../../../../../redux/store/session/sessionOperations';
import {
  buttonLoaderStatusSelector,
  sessionPersonalInformationSelector,
} from '../../../../../redux/selectors/selectors';

import FormContainer from '../../../Authentication/shared/Container/Container';

import { formingAnObjectForSendingForm } from '../../../../../helpers/isEmptyObject';
import { accauntPasswordSettings } from '../../../../../schemas/settingsForm';
import { isEmptyObject } from '../../../../../helpers/isEmptyObject';
import Password from '../../../common/Fields/Password/Password';
import Button from '../../../common/Button/Button';

import './styles.scss';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const EditPasswordSettingsForm = ({ t }) => {
  const personalInformation = useSelector(sessionPersonalInformationSelector);
  const loading = useSelector(buttonLoaderStatusSelector);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: personalInformation,
    resolver: yupResolver(accauntPasswordSettings),
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const handleFormSubmit = data => {
    const newData = formingAnObjectForSendingForm(data, dirtyFields);
    dispatch(putUserPersonalData(newData));
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="settings-form">
      <FormContainer name="Password">
        <div className="edit-settings__item">
          <div className="form-block-container">
            <Password
              errors={errors?.currentPassword}
              options={{
                labelText: t('authentication_set_password_label_current'),
                placeholderText: t(
                  'authentication_set_password_placeholder_current',
                ),
              }}
              inputChangeOptions={register('currentPassword')}
            />
          </div>
        </div>
        <div className="edit-settings__item">
          <div className="form-block-container">
            <Password
              errors={errors?.newPassword}
              options={{
                labelText: t('authentication_set_password_label_new'),
                placeholderText: t(
                  'authentication_set_password_placeholder_new',
                ),
              }}
              inputChangeOptions={register('newPassword')}
            />
          </div>
        </div>
        <div className="edit-settings__item">
          <div className="form-block-container">
            <Password
              errors={errors?.confirmNewPassword}
              options={{
                labelText: t('authentication_set_password_label_confirm'),
                placeholderText: t(
                  'authentication_set_password_placeholder_confirm',
                ),
              }}
              inputChangeOptions={register('confirmNewPassword')}
            />
          </div>
        </div>
        <div className="edit-settings__btn">
          <Button
            loadingStatus={loading}
            options={{
              text: t('authentication_set_password_button'),
              renderType: 'primary',
              type: 'submit',
              size: 'm',
              disabled: isEmptyObject(dirtyFields),
            }}
          />
        </div>
      </FormContainer>
    </form>
  );
};

export default withRouter(withTranslation()(EditPasswordSettingsForm));
