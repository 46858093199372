export const modalTypes = {
  editCommonCampaignLaunch: 'EDIT_COMMON_CAMPAIGN_LAUNCH',
  editStoriesCampaignLaunch: 'EDIT_STORIES_CAMPAIGN_LAUNCH',
  editCommonCampaign: 'EDIT_COMMON_CAMPAIGN',
  manualSearchBloggersFilter: 'MANUAL_SEARCH_BLOGGERS_FILTER',
  deleteCampaign: 'DELETE_CAMPAIGN',
  attachToCampaign: 'ATTACH_TO_CAMPAIGN',
  auth: 'AUTH',
  showResultLink:"showResultLink",
  showCustomContent: 'SHOW_CUSTOM_CONTENT',
  deleteAdvertisement: 'DELETE_ADVERTISEMENT',
  manualSearchOffersFilter: 'MANUAL_SEARCH_OFFERS_FILTER',
  offerComments: 'OFFER_COMMENTS',
  statisticsBlogger: 'STATISTICS_BLOGGER',
  quickRefillPopUp: 'QUICK_REFILL_POP_UP',
  editCurrentPassword: 'EDIT_CURRENT_PASSWORD',
  editCurrentEmail: 'EDIT_CURRENT_EMAIL',
  editEmailConfirmCode: 'EDIT_EMAIL_CONFIRM_CODE',
  showOfferText: "SHOW_OFFER_TEXT",
  insufficientFunds: 'INSUFFICIENT_FUNDS',
  instagramStatisticsApprove: 'INSTAGRAM_STATISTICS_APPROVE',
  addedNewInstagramAccount: 'ADDED_NEW_INSTAGRAM_ACCOUNT',
  showVerificationCode: 'SHOW_VERIFICATION_CODE',
  showVerificationCodeInstagram: 'SHOW_VERIFICATION_CODE_INSTAGRAM',
  supportWriteUs: 'SUPPORT_WRITE_US',
  supportOpenTicket: 'SUPPORT_OPEN_TICKET',
  getText: 'GET_TEXT',
  showOfferDemoDownload: "Download demo video",
  showAddCompletedLink: "Add link to completed work"
};
