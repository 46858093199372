import React from 'react';
import { NavLink } from 'react-router-dom';

import { navbar } from './config';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const Navbar = ({ referrals }) => {
  const [t, i18n] = useTranslation();

  return (
    <div className="header-top-navigation">
      {navbar.map(({ title, link, target, rel }, index) => (
        <span key={index} className="header-top-navigation-item">
          <NavLink
            className={
              referrals
                ? 'header-top-navigation-item-btn-dark'
                : 'header-top-navigation-item-btn'
            }
            to={link}
          >
            {t(title)}
          </NavLink>
        </span>
      ))}
    </div>
  );
};

export default Navbar;
