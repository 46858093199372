import types from './baseInformationTypes';

//creationStatus

export const chengeCreationStatus = (chengedData = {}) => ({
  type: types.ADD_BASE_ADVERTISEMENT_INFO_START,
  payload: chengedData,
});

export const addDataToAdvertisement = data => ({
  type: types.ADD_DATA_TO_ADVERTISEMENT,
  payload: data,
});

export const addBaseAdvertisementInfoStart = (status = false) => ({
  type: types.ADD_BASE_ADVERTISEMENT_INFO_START,
  payload: status,
});

export const addBaseAdvertisementInfoSuccess = data => ({
  type: types.ADD_BASE_ADVERTISEMENT_INFO_SUCCESS,
  payload: data,
});

export const addBaseAdvertisementInfoError = (status = false) => ({
  type: types.ADD_BASE_ADVERTISEMENT_INFO_ERROR,
  payload: status,
});

export const addBaseStoriesInfoStart = (status = false) => ({
  type: types.ADD_BASE_STORIES_INFO_START,
  payload: status,
});

export const addBaseStoriesInfoSuccess = (status = false) => ({
  type: types.ADD_BASE_STORIES_INFO_SUCCESS,
  payload: status,
});

export const addBaseStoriesInfoError = (status = false) => ({
  type: types.ADD_BASE_STORIES_INFO_ERROR,
  payload: status,
});

export const setStoriesList = (data = []) => ({
  type: types.SET_STORIES_LIST,
  payload: data,
});

// set
export const setBanner = data => ({
  type: types.SET_BANNER,
  payload: data,
});

// clear
export const clearInformationStories = () => ({
  type: types.CLEAR_INFORMATION_STORIES,
});

export const clearBaseInformation = () => ({
  type: types.CLEAR_BASE_INFORMATION,
});
