import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearAllParams } from '../../../redux/store/campaign/campaignActions';
import {
  fetchCampaign,
  editOffsetItem,
  editSortItem,
  editOrderItem,
  editStatusItem,
} from '../../../redux/store/campaign/campaignOperations';
import {
  campaignSelector,
  campaignOffsetSelector,
} from '../../../redux/selectors/selectors';

import CampaignsAdvertisementsItem from '../../component/CampaignsAdvertisementsItem/CampaignsAdvertisementsItem';
import SelectFilter from '../../component/HeaderListBlogger/selectFilter/selectFilter';
import EmptyComponent from '../../component/EmptyComponent/EmptyComponent';
import Pagination from '../../component/common/Pagination/Pagination';
import {
  statusItem,
  sortByItem,
  orderByList,
} from '../../../constants/CampaignList';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const CampaignsAdvertisements = ({ match }) => {
  const { advertisements } = useSelector(state => campaignSelector(state));
  const offset = useSelector(state => campaignOffsetSelector(state));
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const sizeItemOnPage = 6;

  const { id } = match.params;

  useEffect(() => {
    dispatch(fetchCampaign(id));

    return () => dispatch(clearAllParams());
  }, [dispatch, id]);

  const onChangeSort = ({ value }) => {
    dispatch(editSortItem(id, value));
  };

  const onChangeOrder = ({ value }) => {
    dispatch(editOrderItem(id, value));
  };

  const onChangeStatus = ({ value }) => {
    dispatch(editStatusItem(id, value));
  };

  return (
    <div className="campaigns">
      <div className="campaigns__select">
        <div className="campaigns__select-left">
          <SelectFilter
            options={statusItem}
            onChange={onChangeStatus}
            defaultValue={statusItem[0]}
            type={'status'}
          />
        </div>
        <div className="campaigns__select-right">
          <div className="campaigns__select-right-sort">
            <SelectFilter
              options={sortByItem}
              onChange={onChangeSort}
              defaultValue={sortByItem[3]}
              type={'sort'}
            />
          </div>
          <div className="campaigns__select-right-sort">
            <SelectFilter
              options={orderByList}
              onChange={onChangeOrder}
              defaultValue={orderByList[0]}
              type={'sortBy'}
            />
          </div>
        </div>
      </div>
      {!advertisements.count ? (
        <EmptyComponent
          title={t('my_campaigns_advertisements_empty_title')}
          text={t('my_campaigns_advertisements_empty_text')}
          button={t('my_campaigns_advertisements_empty_button')}
        />
      ) : (
        <div className="campaigns-advertisements">
          {advertisements.items.map(advertisement => (
            <CampaignsAdvertisementsItem
              key={advertisement._id}
              advertisement={advertisement}
            />
          ))}
        </div>
      )}
      <Pagination
        items={advertisements}
        elementType="page"
        sizeItemOnPage={sizeItemOnPage}
        getNextPageItems={e => {
          dispatch(editOffsetItem(id, e));
        }}
        isStartedPage={offset}
      />
    </div>
  );
};

export default CampaignsAdvertisements;
