import React from 'react';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';

import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';
import { notifyStatusSelector } from '../../../redux/selectors/selectors';

import book from '../../router/book';

import './styles.scss';

const Toastify = () => {
  const { title, type, content } = useSelector(notifyStatusSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const Notification = () => {
    return <h4>{title}</h4>;
  };

  const onHandleClick = () => {
    if (content?.notification_case === 'GO_TO_STATISTICS') {
      history.push(`${book.statistics}/${content?.idForRedirect}`);
    } else if (content?.notification_case === 'GO_TO_ACCOUNTS') {
      history.push(book.accountBlogger);
    } else if (content?.notification_case === 'GO_TO_FINANCE') {
      history.push(book.finance);
    } else if (content?.notification_case === 'GO_TO_OFFERS') {
      history.push(book.offers);
    }
  };

  const PushNotiifcation = () => {
    return (
      <div className="push-notification">
        <img
          className="push-notification__img"
          src={content?.imageURL}
          alt="notification"
        />
        <div className="push-notification__content">
          <h4>{title}</h4>
          <button
            onClick={onHandleClick}
            className="push-notification__btn custom-btn"
          >
            Open Page
          </button>
        </div>
      </div>
    );
  };

  if (type === 'error') {
    toast.error(<Notification />);
    dispatch(
      setNotifyStatus({ isVisible: false, type: '', title: '', content: '' }),
    );
  }

  if (type === 'custom') {
    toast(<PushNotiifcation />);
    dispatch(
      setNotifyStatus({ isVisible: false, type: '', title: '', content: '' }),
    );
  }

  if (type === 'success') {
    toast.success(<Notification />);
    dispatch(
      setNotifyStatus({ isVisible: false, type: '', title: '', content: '' }),
    );
  }

  return (
    <ToastContainer
      autoClose={5000}
      position="bottom-right"
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      limit={6}
      pauseOnHover
    />
  );
};

export default Toastify;
