import React, { useState, useEffect } from 'react';

import { improvisavionTypeSelectOptions } from '../../../../../../configs/selectOptions';
import Textarea from '../../../../common/Fields/Textarea/Textarea';
import Select from '../../../../common/Fields/Select/Select';
import UploadFile from '../../../../UploadFile/UploadFile';

import './../styles.scss';
import { withTranslation } from 'react-i18next';

const ContentSelectInstagram = ({
  contentType,
  descrription,
  story,
  errors,
  register,
  control,
  status,
  Controller,
  t,
}) => {
  const { file, filename, approved } = story;

  const [customContent, setCustomContent] = useState(null);

  useEffect(() => {
    setCustomContent(file);
  }, [file]);

  return (
    <>
      {contentType === 'IMPROVISATION' && (
        <>
          {/*<div className="form-block">
            <Select
              inputChangeOptions={register(`story.content`)}
              options={{
                labelText: t('advertisement_BaseInfo_Instagram_label'),
                approved: approved,
              }}
              listOfOptions={improvisavionTypeSelectOptions}
            />
            </div>*/}
          <div className="form-block">
            <Textarea
              errors={errors?.story?.instruction?.message}
              inputChangeOptions={register(`story.instruction`)}
              options={{
                labelText: t('advertisement_BaseInfo_Instruction_Instagram_label'),
                placeholderText: t(descrription),
                approved: approved,
              }}
            />
          </div>
        </>
      )}
      {contentType === 'CUSTOM' && (
        <div className="form-block">
          <Controller
            control={control}
            name="story.file"
            render={({ field: { onChange } }) => (
              <UploadFile
                errors={errors?.story?.file}
                name={filename}
                onChange={onChange}
                defaultFile={file}
                content={customContent}
                handleUploadFileChange={setCustomContent}
                approved={approved}
                status={status}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(ContentSelectInstagram);
