import types from './errorHandlerTypes';

const initialState = {
  history: {},
};

export const errorHandlerReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.SET_HISTORY:
      return { ...state, history: payload };

    default:
      return state;
  }
};

export default errorHandlerReducer;
