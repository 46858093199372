import React from 'react';

import Girl from '../../../../../../assets/images/landing/advantages/girl.svg';
import Output from '../../../../../../assets/images/landing/advantages/output.svg';
import Camera from '../../../../../../assets/images/landing/advantages/camera.svg';
import File from '../../../../../../assets/images/landing/advantages/file.svg';
import './styles.scss';

const Blogger = ({ t }) => {
  return (
    <div className="blogger">
      <div className="blogger__top">
        <div className="blogger__top-title">
          {t('bloggers_landing_for_bloggers_title')}
        </div>
        <div className="blogger__top-subtitle">
          {t('bloggers_landing_for_bloggers_subtitle')}
        </div>
      </div>
      <div className="blogger__center">
        <div className="blogger__center-left">
          <div className="blogger__center-left-item">
            <div className="blogger__center-left-item-photo">
              <img
                className="blogger__center-left-item-photo-img"
                src={Camera}
                alt="Camera"
              />
            </div>
            <div className="blogger__center-left-item-info">
              <div className="blogger__center-left-item-info-title">
                {t('bloggers_landing_for_bloggers_item_1_title')}
              </div>
              <div className="blogger__center-left-item-info-subtitle">
                {t('bloggers_landing_for_bloggers_item_1_subtitle')}
              </div>
            </div>
          </div>
          <div className="blogger__center-left-item">
            <div className="blogger__center-left-item-photo">
              <img
                className="blogger__center-left-item-photo-img"
                src={File}
                alt="File"
              />
            </div>
            <div className="blogger__center-left-item-info">
              <div className="blogger__center-left-item-info-title">
                {t('bloggers_landing_for_bloggers_item_2_title')}
              </div>
              <div className="blogger__center-left-item-info-subtitle">
                {t('bloggers_landing_for_bloggers_item_2_subtitle')}
              </div>
            </div>
          </div>
          <div className="blogger__center-left-item">
            <div className="blogger__center-left-item-photo">
              <img
                className="blogger__center-left-item-photo-img"
                src={Output}
                alt="Output"
              />
            </div>
            <div className="blogger__center-left-item-info">
              <div className="blogger__center-left-item-info-title">
                {t('bloggers_landing_for_bloggers_item_3_title')}
              </div>
              <div className="blogger__center-left-item-info-subtitle">
                {t('bloggers_landing_for_bloggers_item_3_subtitle')}
              </div>
            </div>
          </div>
        </div>
        <div className="blogger__center-right">
          <img className="blogger__center-right-img" src={Girl} alt="Girl" />
        </div>
      </div>
    </div>
  );
};

export default Blogger;
