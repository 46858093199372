import types from './choiceBloggersTypes';

const initialState = {
  sortingParams: { choiceType: null, filter: null, sort: null },
  selected: {
    bloggersList: [],
    count: 0,
    coverage: 0,
    price: 0,
    additionalTargeting: 0,
  },
  error: null,
  loading: false,
};

export const choiseBloggersReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.ADD_BLOGGER_TO_SELECTED_LIST:
    case types.SET_ACTUAL_BLOGGERS_PRICE:
    case types.REMOVE_BLOGGER_FROM_SELECTED_LIST: {
      return {
        ...state,
        selected: payload,
      };
    }
    case types.CLEAR_CHOISE_BLOGGERS_INFORMATION: {
      return initialState;
    }

    default:
      return state;
  }
};

export default choiseBloggersReducer;
