import React, { useState } from 'react';

import { questions } from './config';

import './styles.scss';

const Question = ({ t }) => {
  const [active, setActive] = useState(1);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(e => (e === index ? null : index));
  };

  return (
    <div className="question">
      <div className="question__title">
        {t('referrals_landing_question_title')}
      </div>
      {questions.map(({ title, subtitle }, index) => (
        <div
          className={
            active === index
              ? 'question__block-active'
              : 'question__block-inactive'
          }
          key={index}
        >
          <div
            onClick={e => eventHandler(e, index)}
            className={`question__container ${
              active === index ? 'question__active' : 'question__inactive'
            }`}
          >
            {t(title)}
            <div className="question__content">
              <div
                className={
                  active === index ? 'question__plus' : 'question__minus'
                }
              />
            </div>
          </div>
          <div
            className={
              active === index
                ? 'question__panel-plus'
                : 'question__panel-minus'
            }
          >
            {t(subtitle)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Question;
