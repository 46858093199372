import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { putNotificationSubscribe } from '../../api/notifications-service/notifications-service';
import { getCurrentUserInformation } from '../../api/session-service/session-service';

import { isSupportedNotification } from './isSupportedNotification';
import { firebaseConfig } from '../../configs/firebase';
import {
  sessionAdvertiserPersonalDataSuccess,
  setFcmToken,
} from '../../redux/store/session/sessionActions';

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const publicKey =
  'BL-QA0ZbFxh_S3sHU66dadP0m60y-M1k3ZW9k7BBP1rkQbxBVLifhfcPCDZ7-QDzDq-Mxtg9UGhy9YOIU2vK9gU';

export const getToken = async dispatch => {
  let fcmToken = '';

  const infoUser = JSON.parse(localStorage.getItem('accaunt'));
  const isAuth = localStorage.getItem('session');

  try {
    fcmToken = await messaging.getToken({ vapidKey: publicKey });
    dispatch(setFcmToken(fcmToken));
  } catch (error) {
    console.log(error);
  } finally {
    if (isSupportedNotification()) {
      if (infoUser?.notification === Notification.permission || !isAuth) return;
      putNotificationSubscribe({ fcmToken }).then(() => {
        getCurrentUserInformation().then(user => {
          dispatch(
            sessionAdvertiserPersonalDataSuccess({
              ...user,
              fcmToken: fcmToken,
              notification: isSupportedNotification()
                ? Notification.permission
                : '',
            }),
          );
          localStorage.setItem(
            'accaunt',
            JSON.stringify({
              ...user,
              notification: isSupportedNotification()
                ? Notification.permission
                : '',
            }),
          );
        });
      });
    }
  }

  return fcmToken;
};
