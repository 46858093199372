import React from 'react';

import './styles.scss';

const CheckboxCard = ({ handleSelectedCheckbox = {}, options = {}, t }) => {
  const { name, text, Icon } = options;
  return (
    <div className="checkbox-card-wrapper">
      <label htmlFor={name} className="checkbox-card__label">
        <input
          className="checkbox-card-item__input"
          type="radio"
          name="checkbox-card-item"
          id={name}
          onChange={({ target: { id } }) => {
            handleSelectedCheckbox(id);
          }}
        />
        <span className="icon" />

        <div className="checkbox-card-content">
          <Icon
            id="checkbox-card-content__icon"
            className="checkbox-card-content__icon"
          />
          <div className="checkbox-card-content__text">{t(text)}</div>
        </div>
      </label>
    </div>
  );
};

export default CheckboxCard;
