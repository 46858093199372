import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { putUserPersonalData } from '../../../redux/store/session/sessionOperations';
import {
  sessionPersonalInformationSelector,
  buttonLoaderStatusSelector,
} from '../../../redux/selectors/selectors';

import SettingsForm from '../../component/Forms/SettingsForm/SettingsForm';

import './styles.scss';

const Settings = () => {
  const personalInformation = useSelector(sessionPersonalInformationSelector);
  const loading = useSelector(buttonLoaderStatusSelector);
  const dispatch = useDispatch();

  return (
    <div className="settings-page">
      <SettingsForm
        personalInformation={personalInformation}
        putUserPersonalData={putUserPersonalData}
        dispatch={dispatch}
        loading={loading}
      />
    </div>
  );
};

export default Settings;
