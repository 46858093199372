import { useEffect } from 'react';

const useGoogleLogin = () => {
  useEffect(() => {
    window.gapi?.load('auth2', function () {
      window.gapi.auth2.init({
        client_id:
          '1005155315229-vktlrni2f135cqkb6fg63pqnu3el9eqi.apps.googleusercontent.com',
      });
    });
  }, []);

  const googleLogin = () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    return auth2.signIn();
  };

  const googleLogout = () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    return auth2.signOut();
  };

  return [googleLogin, googleLogout];
};

export default useGoogleLogin;
