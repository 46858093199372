import React from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';
import DownloadDesktop from '../../../Advertisers/component/Download/DownloadDesktop/DownloadDesktop';
import DownloadMobile from '../../../Advertisers/component/Download/DownloadMobile/DownloadMobile';
import BenefitsItem from './BenefitsItem/BenefitsItem';
import { bloggers } from './config';

import Break from '../../../../../../assets/images/landing/global/break.svg';
import Center from '../../../../../../assets/images/landing/advantages/break.svg';
import './styles.scss';

const Benefits = ({ openModal, setAuthenticationStep, t }) => {
  return (
    <>
      <div className="benefits-title">
        {t('bloggers_landing_benefits_title')}
      </div>
      <div className="benefits-subtitle">
        {t('bloggers_landing_benefits_subtitle')}
      </div>
      <img className="benefits-break" src={Break} alt="Break" />
      <div className="benefits__container">
        <div className="benefits">
          {bloggers &&
            bloggers.map((item, index) => {
              return <BenefitsItem key={index} item={item} t={t} />;
            })}
          <img className="benefits-break-second" src={Break} alt="Break" />
          <div className="benefits-content">
            <img className="benefits-content-break" src={Center} alt="Center" />
            <div className="benefits-content-title">
              {t('bloggers_landing_benefits_content_title')}
            </div>
            <div
              onClick={() =>
                handleRegistrationButtonClick(setAuthenticationStep, openModal)
              }
              className="benefits-content-button"
            >
              {t('bloggers_landing_benefits_button')}
            </div>
          </div>
        </div>
      </div>
      {/*<DownloadDesktop t={t} />*/}
      {/*}<DownloadMobile t={t} />*/}
    </>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(Benefits);
