import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { compareKeyBySelect } from '../../../helpers/compareKeyBySelect';
import { categoriesNews } from '../../../constants/newsList';
import book from '../../router/book';

import categoryImage from '../../../assets/images/landing/blog/category.svg';
import dateImage from '../../../assets/images/landing/blog/date.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const BlogItem = ({ news }) => {
  const { _id, banner, data, category, title, summary } = news;
  const [t] = useTranslation();

  return (
    <div className="news__body-item">
      <img className="news__body-item-img" src={banner} alt="banner" />
      <div className="news__body-item-content">
        <div className="news__body-item-content-header">
          <img src={dateImage} alt="date" />
          <span className="news__body-item-content-header-data">
            {dayjs(data).format('DD.MM.YYYY')}
          </span>
          <img src={categoryImage} alt="category" />
          <span className="news__body-item-content-header-category">
            {category && t(compareKeyBySelect(categoriesNews, category))}
          </span>
        </div>
        <div className="news__body-item-content-body">
          <h1 className="news__body-item-content-body-title">{title}</h1>
          <h2 className="news__body-item-content-body-subtitle">{summary}</h2>
        </div>
        <Link to={`${book.blog}/${_id}`}>
          <button className="news__body-item-content-button">
            {t('blog_item_read_more')}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BlogItem;
