import { combineReducers } from 'redux';
import { modalReducer } from './modal/modalReducer';
import { advertisementCombineReducer } from './advertisement/advertisementCombineReducer';
import { sessionReducer } from './session/sessionReducer';
import { campaignReducer } from './campaign/campaignReducer';
import { globalLoaderReducer } from './globalLoader/globalLoaderReducer';
import { instagramReducer } from './instagram/instagramReducer';
import { newsReducer } from './news/newsReducer';
import financeReducer from './finance/financeReducer';
import { notifyReducer } from './notify/notifyReducer';
import instagramAccountsBlogger from './accountBlogger/accountBloggerReducer';
import notificationReducer from './notification/notificationReducer';
import supportReducer from './support/supportReducer';
import errorHandlerReducer from './errorHandler/errorHandlerReducer';

export const rootReducer = combineReducers({
  modal: modalReducer,
  loader: globalLoaderReducer,
  advertisement: advertisementCombineReducer,
  sessionAdvertiser: sessionReducer,
  campaign: campaignReducer,
  instagram: instagramReducer,
  news: newsReducer,
  finance: financeReducer,
  notify: notifyReducer,
  instagramAccountsBlogger,
  userNotification: notificationReducer,
  support: supportReducer,
  errorHandler: errorHandlerReducer,
});

export default rootReducer;
