export const handleRedirect = (
  target,
  allRef,
  btnRef,
  history,
  action,
  secondBtnRef,
) => {
  if (
    allRef?.current &&
    !btnRef?.current?.contains(target) &&
    !secondBtnRef?.current?.contains(target)
  ) {
    return history.push(action);
  }
};
