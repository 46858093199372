const types = {
  GET_INSTAGRAM_APPROVED_ACCOUNTS_START:
    'instagram/GET_INSTAGRAM_APPROVED_ACCOUNTS_START',
  GET_INSTAGRAM_APPROVED_ACCOUNTS_SUCCESS:
    'instagram/GET_INSTAGRAM_APPROVED_ACCOUNTS_SUCCESS',
  GET_INSTAGRAM_APPROVED_ACCOUNTS_ERROR:
    'instagram/GET_INSTAGRAM_APPROVED_ACCOUNTS_ERROR',
  GET_INSTAGRAM_FAVORITE_ACCOUNTS_START:
    'instagram/GET_INSTAGRAM_FAVORITE_ACCOUNTS_START',
  GET_INSTAGRAM_FAVORITE_ACCOUNTS_ERROR:
    'instagram/GET_INSTAGRAM_FAVORITE_ACCOUNTS_ERROR',

  // put favorite status
  SET_FILTER_INFO: 'instagram/SET_FILTER_INFO',
  SET_LINK: 'instagram/SET_LINK',
  SET_SEARCH: 'instagram/SET_SEARCH',
  SET_TYPE_SORT_TYPE: 'instagram/SET_TYPE_SORT_TYPE',
  SET_OFFSET: 'instagram/SET_OFFSET',
  SET_OFFSET_COUNT: 'instagram/SET_OFFSET_COUNT',
  SET_FILTER_PARAMS: 'instagram/SET_FILTER_PARAMS',
  SET_SEARCH_VALUE: 'instagram/SET_SEARCH_VALUE',
  SET_DIRECTION_PARAMS: 'instagram/SET_DIRECTION_PARAMS',
  PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_START:
    'instagram/PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_START',
  PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_SUCCESS:
    'instagram/PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_SUCCESS',
  PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_ERROR:
    'instagram/PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_ERROR',
  SET_ACCOUNT_BLOGGER: 'instagram/SET_ACCOUNT_BLOGGER',
  CLEAR_ALL_PARAMS: 'instagram/CLEAR_ALL_PARAMS',
  GET_AUTO_SEARCH_INSTAGRAM_ACCOUNT: 'GET_AUTO_SEARCH_INSTAGRAM_ACCOUNT',
};

export default types;
