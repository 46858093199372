import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Input from '../../../common/Fields/Input/Input';
import Date from '../../../common/Fields/Date/Date';
import Select from '../../../common/Fields/Select/Select';
import Button from '../../../common/Button/Button';
import Range from '../../../common/Fields/Range/Range';
import Checkbox from '../../../common/Fields/Checkbox/CheckBox';
import FormContainer from '../../../common/FormContainer/FormContainer';

import { changeCreationAdvertisementStep } from '../../../../../redux/store/advertisement/creationStatus/creationStatusActions';
import { advertisementCreationSteps } from '../../../../../constants/advertisementCreationSteps';

import { listOfCheckbox } from './config';

import { schema } from '../../../../../schemas/adsSearchForm.Schema';

import '../../general.scss';
import './styles.scss';

const AdsSearchForm = ({ changeCreationAdvertisementStep }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = data => {};

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="ads-search-form__container"
    >
      <section className="form-section">
        <FormContainer button={''}>
          <div className="form-block-container">
            <div className="form-block form-block-part">
              <Select
                options={{
                  labelText: 'Actions you need to pay for',
                }}
                inputChangeOptions={register('pay')}
              />
            </div>
            <div className="form-block form-block-part">
              <Input
                options={{
                  labelText: 'Price per 1000 impressions',
                  placeholderText: 'Enter the price',
                }}
                inputChangeOptions={register('price')}
              />
            </div>
          </div>
          <div className="form-block-container">
            <div className="form-block form-block-part">
              <Select
                options={{
                  labelText: 'Geography',
                }}
                inputChangeOptions={register('geography')}
              />
            </div>
            <div className="form-block form-block-part">
              <Date
                options={{
                  labelText: 'Ad lifetime',
                  type: 'dual',
                }}
                inputChangeOptions={register('liftimeFirst')}
                inputChangeOptionsSecond={register('liftimeSecond')}
              />
            </div>
          </div>
        </FormContainer>
      </section>

      <section className="form-section">
        <FormContainer button={''}>
          <ul className="ads-search-form__checkbox-list">
            {listOfCheckbox.map((text, index) => (
              <li key={text + index} className="ads-search-form__checkbox-item">
                <Checkbox options={{ textLabel: text, number: index + 1 }} />
              </li>
            ))}
          </ul>
        </FormContainer>
      </section>

      <section className="form-section">
        <FormContainer button={''}>
          <div className="form-block-container">
            <div className="form-block form-block-part">
              <Select
                options={{
                  labelText: 'Audience gender',
                }}
                inputChangeOptions={register('gender')}
              />
            </div>
            <div className="form-block form-block-part">
              <Range
                options={{
                  labelText: 'Audience age',
                }}
              />
            </div>
          </div>
          <div className="form-block">
            <Select
              options={{
                labelText: 'Account type',
                descriptionText: 'Choose the type of account that suits you',
              }}
              inputChangeOptions={register('accauntType')}
            />
          </div>
        </FormContainer>
      </section>
      <div className="ads-search-form__buttons">
        <Button
          handleButtonClick={() => {
            changeCreationAdvertisementStep({
              activePage: advertisementCreationSteps.manual,
            });
          }}
          options={{ text: 'Return', renderType: 'secondary', size: 'm' }}
        />
        <Button
          options={{
            text: 'Proceed',
            renderType: 'primary',
            type: 'submit',
            size: 'm',
          }}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = {
  changeCreationAdvertisementStep,
};

export default connect(null, mapDispatchToProps)(withRouter(AdsSearchForm));
