import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { accaunt } from '../../../../../schemas/authFormsSchemas';

import Button from '../../../common/Button/Button';
import Input from '../../../common/Fields/Input/Input';
import Password from '../../../common/Fields/Password/Password';
import Checkbox from '../../../common/Fields/Checkbox/CheckBox';

import AuthFormContainer from '../../shared/Container/Container';
import PreviousStep from '../../shared/PreviousStep/PreviousStep';

import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';
import {
  sendPersonalData,
  changeCurentStep,
} from '../../../../../redux/store/session/sessionOperations';
import {
  sessionFormLoadingStatusSelector,
  sessionPersonalInformationSelector,
} from '../../../../../redux/selectors/selectors';

import './styles.scss';
import '../../general.scss';
import { useTranslation } from 'react-i18next';

const CreateAccauntForm = ({
  personalInformation: {
    email: activeEmail,
    fcmToken,
    role: activeRole,
    referralId,
  },
  sendPersonalData,
  changeCurentStep,
  setNotifyStatus,
  loadingStatus,
  history,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(accaunt),
  });

  const handleFormSubmit = ({
    lastName,
    firstName,
    createPassword,
    confirmPassword,
  }) => {
    const errorText = 'Password and confirmation password must be the same!';
    const isValidPasswords = createPassword === confirmPassword;
    if (!isValidPasswords)
      return setNotifyStatus({ title: errorText, type: 'error' });
    const formInformation = {
      email: activeEmail,
      role: activeRole,
      lastName,
      firstName,
      createPassword,
      confirmPassword,
    };
    fcmToken && (formInformation.fcmToken = fcmToken);
    sendPersonalData(formInformation, referralId, history).catch(error =>
      setNotifyStatus({ title: error, type: 'error' }),
    );
  };
  const [t] = useTranslation();

  return (
    <form
      className="auth-form auth-form-registration"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <AuthFormContainer name={t('authentication_account_form_title')}>
        <div className="auth-form-block">
          <Input
            errors={errors?.firstName}
            options={{
              placeholderText: t('authentication_account_form_first_name'),
              labelText: t(
                'authentication_account_form_first_name_placeholder',
              ),
            }}
            inputChangeOptions={register('firstName')}
          />
        </div>
        <div className="auth-form-block">
          <Input
            options={{
              placeholderText: t('authentication_account_form_last_name'),
              labelText: t('authentication_account_form_last_name_placeholder'),
            }}
            inputChangeOptions={register('lastName')}
          />
        </div>

        <div className="auth-form-block">
          <Input
            options={{
              placeholderText: activeEmail,
              labelText: t('authentication_account_form_email_label'),
              isDisabled: true,
            }}
            inputChangeOptions={register('email')}
          />
        </div>
        <div className="auth-form-block">
          <Password
            options={{
              placeholderText: t(
                'authentication_account_form_password_placeholder',
              ),
              labelText: t('authentication_account_form_password_name'),
            }}
            inputChangeOptions={register('createPassword')}
          />
        </div>
        <div className="auth-form-block">
          <Password
            options={{
              placeholderText: t(
                'authentication_account_form_confirm_password_placeholder',
              ),
              labelText: t('authentication_account_form_confirm_password_name'),
            }}
            inputChangeOptions={register('confirmPassword')}
          />
        </div>
        <div className="auth-form-block auth-form-registration-privacy">
          <div className="create-accaunt__terms">
            <Checkbox inputChangeOptions={register('terms')} />
            <p className="create-accaunt__terms-description">
              {t('authentication_account_form_i_agree')}{' '}
              <a
                href="https://frontend-dev.bloggerly.lampawork.com/agreement"
                target="_blank"
                rel="noreferrer"
                className="create-accaunt__terms-description-link"
              >
                {t('authentication_account_form_terms_conditions')}
              </a>{' '}
              {t('authentication_account_form_and')}{' '}
              <a
                href="https://frontend-dev.bloggerly.lampawork.com/policy"
                target="_blank"
                rel="noreferrer"
                className="create-accaunt__terms-description-link"
              >
                {t('authentication_account_form_privacy_policy')}
              </a>
            </p>
          </div>
        </div>
        <div className="auth-form-button-container">
          <Button
            loadingStatus={loadingStatus}
            options={{
              text: t('authentication_account_form_key'),
              renderType: 'primary',
              size: 'l',
              type: 'submit',
              disabled: !isValid,
            }}
          />
        </div>
      </AuthFormContainer>
      <PreviousStep changeCurentStep={changeCurentStep} />
    </form>
  );
};

const mapStateToProps = state => ({
  loadingStatus: sessionFormLoadingStatusSelector(state),
  personalInformation: sessionPersonalInformationSelector(state),
});

const mapDispatchToProps = {
  sendPersonalData,
  changeCurentStep,
  setNotifyStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreateAccauntForm));
