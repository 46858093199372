import React from 'react';

import './styles.scss';

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item: { title, subtitle, coverage, likes },
  index,
  onClick,
  t,
}) => {
  return (
    <div className="accordion__item">
      <button
        aria-expanded={ariaExpanded}
        aria-controls={`accordion${index + 1}_desc`}
        data-qa="accordion__item-button"
        className={`accordion__item-button ${fontWeightBold}`}
        onClick={onClick}
      >
        {t(title)}
      </button>

      <div>
        <div
          id={`accordion${index + 1}_desc`}
          data-qa="accordion__desc"
          className={`accordion__desc ${showDescription}`}
        >
          <span className="accordion__desc-subtitle">{t(subtitle)}</span>
          <div className="accordion__desc-bottom">
            <span className="accordion__desc-bottom-coverage">
              <span className="accordion__desc-bottom-coverage-title">
                {t('advertisers_landing_cases_coverage')}
              </span>
              {t(coverage)}
            </span>
            <span className="accordion__desc-bottom-likes">
              <span className="accordion__desc-bottom-coverage-title">
                {t('advertisers_landing_cases_likes')}
              </span>
              {t(likes)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
