import React from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';

import second from '../../../../../../assets/images/landing/banner/second.svg';
import first from '../../../../../../assets/images/landing/banner/first.svg';
import './styles.scss';

const Banner = ({ openModal, setAuthenticationStep, t }) => {
  return (
    <div className="banner">
      <img className="banner__first" src={first} alt="break" />
      <img className="banner__second" src={second} alt="break" />
      <div className="banner__title">{t('referrals_landing_banner_title')}</div>
      <div className="banner__subtitle">
        {t('referrals_landing_banner_subtitle')}
      </div>
      <span
        onClick={() =>
          handleRegistrationButtonClick(setAuthenticationStep, openModal)
        }
        className="banner__button"
      >
        {t('referrals_landing_banner_button')}
      </span>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(Banner);
