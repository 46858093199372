import book from '../views/router/book';

export const landingsBookList = [
  book.advertisers,
  book.referrals,
  book.agreement,
  book.bloggers,
  book.policy,
  book.blog,
];
