import React from 'react';

export const BloggerListIcon = ({ isActive }) => {
  const color = isActive ? '#FF1D70' : '#A1A5BF';
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 10C4.2 10 2 7.8 2 5C2 2.2 4.2 0 7 0C9.8 0 12 2.2 12 5C12 7.8 9.8 10 7 10ZM7 8C8.7 8 10 6.7 10 5C10 3.3 8.7 2 7 2C5.3 2 4 3.3 4 5C4 6.7 5.3 8 7 8ZM14 19C14 19.6 13.6 20 13 20C12.4 20 12 19.6 12 19V17C12 15.3 10.7 14 9 14H5C3.3 14 2 15.3 2 17V19C2 19.6 1.6 20 1 20C0.4 20 0 19.6 0 19V17C0 14.2 2.2 12 5 12H9C11.8 12 14 14.2 14 17V19ZM15 14C14.4 14 14 13.6 14 13C14 12.4 14.4 12 15 12C17.8 12 20 14.2 20 17V19C20 19.6 19.6 20 19 20C18.4 20 18 19.6 18 19V17C18 15.3 16.7 14 15 14ZM13 10C12.4 10 12 9.6 12 9C12 8.4 12.4 8 13 8C14.7 8 16 6.7 16 5C16 3.3 14.7 2 13 2C12.4 2 12 1.6 12 1C12 0.4 12.4 0 13 0C15.8 0 18 2.2 18 5C18 7.8 15.8 10 13 10Z"
        fill={color}
      />
    </svg>
  );
};
export const FinanceIcon = ({ isActive }) => {
  const color = isActive ? '#FF1D70' : '#A1A5BF';
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 20C3.9 20 0 17.9 0 15V5C0 2.1 3.9 0 9 0C14.1 0 18 2.1 18 5V15C18 17.9 14.1 20 9 20ZM2 13.2V15C2 16.4 5 18 9 18C13 18 16 16.4 16 15V13.2C14.4 14.3 11.9 15 9 15C6.1 15 3.6 14.3 2 13.2ZM2 10C2 11.4 5 13 9 13C13 13 16 11.4 16 10V8.2C14.4 9.3 11.9 10 9 10C6.1 10 3.6 9.3 2 8.2V10ZM2 5C2 6.4 5 8 9 8C13 8 16 6.4 16 5C16 3.6 13 2 9 2C5 2 2 3.6 2 5Z"
        fill={color}
      />
    </svg>
  );
};
export const MyCompanyIcon = ({ isActive }) => {
  const color = isActive ? '#FF1D70' : '#A1A5BF';
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.2 20.0041C6 20.0041 5 19.3041 4.6 18.2041L2.6 12.4041C1 11.5041 0 9.90405 0 8.00405C0 5.20405 2.2 3.00405 5 3.00405H6.8C10.4 3.00405 13.8 1.90405 15.2 0.304052C15.5 0.00405183 16 -0.0959482 16.4 0.104052C16.7 0.204052 17 0.604052 17 1.00405V4.10405C18.7 4.50405 20 6.10405 20 8.00405C20 9.90405 18.7 11.4041 17 11.9041V15.0041C17 15.4041 16.7 15.8041 16.4 15.9041C16 16.0041 15.6 15.9041 15.3 15.6041C14.3 14.5041 12.4 13.6041 10.1 13.2041V17.2041C10 18.8041 8.8 20.0041 7.2 20.0041ZM5 13.0041L6.6 17.5041C6.6 17.8041 6.9 18.0041 7.2 18.0041C7.7 18.0041 8 17.7041 8 17.2041V13.0041C7.6 13.0041 7.2 13.0041 6.8 13.0041H5ZM10 11.2041C11.9 11.5041 13.7 12.1041 15 12.9041V3.10405C13.7 3.90405 11.9 4.50405 10 4.80405V11.2041ZM3.8 10.8041C4.2 10.9041 4.6 11.0041 5 11.0041H6.8C7.2 11.0041 7.6 11.0041 8 11.0041V5.00405C7.6 5.00405 7.2 5.00405 6.8 5.00405H5C3.3 5.00405 2 6.30405 2 8.00405C2 9.20405 2.7 10.3041 3.8 10.8041ZM17 6.30405V9.80405C17.6 9.50405 18 8.80405 18 8.10405C18 7.40405 17.6 6.60405 17 6.30405Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="18"
          y1="0"
          x2="0"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const PaywallIcon = ({ isActive }) => {
  const color = isActive ? '#FF1D70' : '#A1A5BF';
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H6C6.6 12 7 11.6 7 11C7 10.4 6.6 10 6 10H5C4.4 10 4 10.4 4 11C4 11.6 4.4 12 5 12Z"
        fill={color}
      />
      <path
        d="M10 12H11C11.6 12 12 11.6 12 11C12 10.4 11.6 10 11 10H10C9.4 10 9 10.4 9 11C9 11.6 9.4 12 10 12Z"
        fill={color}
      />
      <path
        d="M16 0H4C1.8 0 0 1.8 0 4V12C0 14.2 1.8 16 4 16H16C18.2 16 20 14.2 20 12V4C20 1.8 18.2 0 16 0ZM18 12C18 13.1 17.1 14 16 14H4C2.9 14 2 13.1 2 12V7H18V12ZM18 5H2V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V5Z"
        fill={color}
      />
    </svg>
  );
};
export const StatisticsIcon = ({ isActive }) => {
  const color = isActive ? '#FF1D70' : '#A1A5BF';
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7 10.3C19.5 10.1 19.2 10 19 10H10V1C10 0.7 9.9 0.4 9.7 0.3C9.5 0.2 9.2 0 8.9 0C3.8 0.6 0 4.8 0 9.9C0 15.4 4.5 19.9 10 19.9C15.1 19.9 19.4 16.1 19.9 11C20 10.8 19.9 10.5 19.7 10.3ZM10 17.9C5.6 17.9 2 14.3 2 9.9C2 6.2 4.5 3 8 2.2V10.9C8 11.5 8.4 11.9 9 11.9H17.7C16.9 15.4 13.7 17.9 10 17.9Z"
        fill={color}
      />
      <path
        d="M13 7.9H18.5C18.8 7.9 19.1 7.7 19.3 7.5C19.5 7.3 19.5 6.9 19.4 6.6C18.4 3.7 16.2 1.5 13.3 0.5C13 0.4 12.7 0.4 12.4 0.6C12.2 0.8 12 1.1 12 1.5V7C12 7.5 12.4 7.9 13 7.9ZM14 3C15.2 3.7 16.2 4.7 16.9 5.9H14V3Z"
        fill={color}
      />
    </svg>
  );
};
export const SupportIcon = ({ isActive }) => {
  const color = isActive ? '#FF1D70' : '#A1A5BF';

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7L3.93023 3.93023M13 13L16.0698 16.0698M13 7L16.0698 3.93023M7 13L3.93023 16.0698M14.6 10C14.6 12.5405 12.5405 14.6 10 14.6C7.45949 14.6 5.4 12.5405 5.4 10C5.4 7.45949 7.45949 5.4 10 5.4C12.5405 5.4 14.6 7.45949 14.6 10ZM19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};
export const ReferralIcon = ({ isActive }) => {
  const color = isActive ? '#FF1D70' : '#A1A5BF';

  return (
    <svg
      version="1.1"
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2500 2500"
    >
      <g transform="translate(0.000000,1300.000000) scale(0.250000,-0.250000)">
        <path
          fill={color}
          d="M2815.4,4823.6c-553.8-127.6-1033.6-507.9-1288.8-1023.4c-148-303.7-188.9-482.3-186.3-842.2c2.6-472.1,119.9-816.7,403.2-1179.1c331.8-423.6,829.4-678.8,1398.5-714.6l224.6-15.3v150.6c0,257.8,0,255.2-239.9,273.1c-607.4,43.4-1117.8,441.5-1322,1036.2c-84.2,245-84.2,655.9,2.6,916.2c142.9,439,502.8,803.9,944.3,959.6c176.1,61.3,597.2,84.2,798.8,43.4c186.3-38.3,459.4-173.5,627.8-306.3c114.8-91.9,285.8-293.5,375.2-449.2c25.5-40.8,38.3-38.3,199.1,33.2c94.4,43.4,171,91.9,171,109.7c0,68.9-219.5,362.4-393,525.7c-222,209.3-444.1,344.5-717.1,433.9C3563.2,4856.8,3070.6,4879.7,2815.4,4823.6z"
        />
        <path
          fill={color}
          d="M5829.4,3606.2C4859.6,3504.2,4032.8,2771.7,3798,1807c-79.1-319-79.1-778.4-2.6-1092.3C4037.9-262.7,4859.6-985,5847.3-1084.5c832-81.7,1646.1,273.1,2133.5,936.6c454.3,615.1,587,1408.8,357.3,2123.3C7993.6,3047.3,6952.3,3723.6,5829.4,3606.2z M6554.2,3159.6c229.7-51,594.6-234.8,786-393C8024.2,2200,8236,1202.2,7843,398.3C7595.5-104.5,7090.2-510.3,6541.5-643c-262.9-63.8-684-58.7-941.7,12.8C4770.3-400.5,4198.6,296.2,4142.5,1146c-76.6,1140.8,893.2,2128.4,2028.9,2064.6C6291.3,3205.6,6462.3,3182.6,6554.2,3159.6z"
        />
        <path
          fill={color}
          d="M1582.8,1271.1C947.3,888.3,490.5,314.1,253.1-395.4C184.2-602.2,100-944.1,100-1020.7c0-23,63.8-43.4,183.8-61.3c199.1-30.6,224.6-25.5,224.6,43.4c0,99.5,127.6,546.1,214.4,742.6c173.5,403.2,497.7,814.1,837.1,1069.3l145.5,107.2l173.5-132.7C2095.7,582,2399.4,434,2662.3,367.6c283.3-71.4,900.9-66.4,977.5,10.2c7.7,7.7-5.1,94.4-30.6,194l-45.9,183.8L3433,735.1c-556.4-79.1-1036.1,71.5-1431.7,451.7c-114.8,112.3-214.4,201.6-219.5,199.1C1776.7,1385.9,1687.4,1332.3,1582.8,1271.1z"
        />
        <path
          fill={color}
          d="M1146.4-1268.2c-538.5-513-801.4-783.5-798.8-814.1c2.5-25.5,35.7-109.7,68.9-188.8l63.8-140.4l336.9-7.7l336.9-7.7l48.5-191.4c173.5-676.3,663.5-1283.7,1322-1641c283.3-153.1,913.6-375.2,1071.9-375.2c23,0,63.8,63.8,102.1,158.2c35.7,89.3,66.4,168.4,71.5,176.1c2.6,7.7-84.2,89.3-194,181.2c-426.2,357.3-666.1,803.9-758,1411.3c-20.4,140.4-38.3,262.9-38.3,273.1c0,10.2,150.6,23,336.9,25.5l334.3,7.6l71.5,173.5l71.5,171l-806.5,773.3c-441.5,423.7-811.6,773.3-821.8,775.8C1957.9-502.6,1587.9-847.1,1146.4-1268.2z M2460.7-1533.7l484.9-469.6l-285.8-2.6h-288.4v-176.1c0-635.5,168.4-1288.8,431.3-1671.7c45.9-63.8,74-117.4,66.4-117.4s-79.1,33.2-160.8,74c-334.3,168.4-689.1,505.3-885.6,839.6c-127.6,216.9-255.2,587-298.6,857.5l-30.6,193.9h-252.7c-137.8,0-247.6,10.2-242.4,23c10.2,28.1,946.8,921.3,964.7,921.3C1970.7-1061.5,2195.3-1273.3,2460.7-1533.7z"
        />
        <path
          fill={color}
          d="M4134.8-872.7c-255.2-153.1-477.2-313.9-630.4-451.7l-125-114.8l137.8-142.9l137.8-140.4l181.2,148c252.7,206.7,421.1,316.4,456.8,298.6c17.9-7.7,104.6-79.1,196.5-155.7c91.9-76.6,275.6-196.5,408.3-265.4c587-306.3,1253.1-377.7,1893.6-204.2c275.6,74,666.1,278.2,908.5,472.1l211.8,168.4l171-114.8c622.7-418.6,1056.6-1013.2,1265.8-1738c74-252.7,142.9-724.8,142.9-974.9v-163.3H6926.8H4362V-4443v-191.4h2753.7h2753.7l15.3,79.1c7.6,43.4,15.3,194,15.3,334.3c0,1513.4-661,2682.2-1921.7,3389.2l-148,81.7L7633.7-939c-316.5-308.8-630.4-487.4-1043.8-597.2c-257.8-66.4-768.2-61.3-1041.3,12.8c-393,104.6-717.1,296-1015.7,594.6c-94.4,94.4-181.2,173.5-191.4,173.5C4331.3-755.3,4239.5-808.9,4134.8-872.7z"
        />
      </g>
    </svg>
  );
};
