import { get } from '../apiService';

// get
export const getNewsList = (params = {}) => {
  const { limit, offset, search = 'LATEST_NEWS' } = params;

  const limitInfo = limit ? '?limit=' + limit : '?limit=6';
  const offsetInfo = offset ? '&offset=' + offset : '&offset=0';
  const searchInfo = search !== 'LATEST_NEWS' ? '&search=' + search : '';

  return get('/news/' + limitInfo + offsetInfo + searchInfo);
};

export const getNewsById = (id, params = '') => {
  return get('/news/' + id + params);
};

export const getOffersCount = () => {
  return get(`/offer/count`);
};
export const getProductionsCount = () => {
  return get(`/item/count`);
};
