import types from './modalTypes';

const initialState = {
  isOpen: false,
  renderType: null,
  details: null,
};

export const modalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.OPEN_MODAL:
      return payload;
    case types.CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
