import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Button from '../../../common/Button/Button';
import AuthFormContainer from '../../shared/Container/Container';
import PreviousStep from '../../shared/PreviousStep/PreviousStep';

import {
  sendConfirmationCode,
  resendConfirmationCode,
  changeCurentStep,
} from '../../../../../redux/store/session/sessionOperations';

import ResendCode from './ResendCode';
import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';
import { sessionFormLoadingStatusSelector } from '../../../../../redux/selectors/selectors';

import './styles.scss';
import '../../general.scss';

import ConfirmationCode from '../../../common/Fields/ConfirmationCode/ConfirmationCode';
import { useTranslation } from 'react-i18next';

const ConfirmationCodeForm = ({
  sendConfirmationCode,
  changeCurentStep,
  loadingStatus,
  type,
  history,
  setNotifyStatus,
}) => {
  const { handleSubmit } = useForm({
    defaultValues: {},
    mode: 'onChange',
  });

  const [verificationCode, setVerificationCode] = useState('');
  const [t] = useTranslation();

  const handleFormSubmit = () => {
    sendConfirmationCode(verificationCode, type, history).catch(error =>
      setNotifyStatus({ title: error, type: 'error' }),
    );
  };

  const handleChange = value => {
    setVerificationCode(value);
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <AuthFormContainer name={t('authentication_confirmation_verification')}>
        <div className="auth-form-block">
          <ConfirmationCode handleChange={handleChange} />
        </div>

        <div className="auth-form-button-container">
          <Button
            loadingStatus={loadingStatus}
            options={{
              text: t('confirmation_code'),
              renderType: 'primary',
              size: 'l',
              type: 'submit',
              disabled: verificationCode.length < 4,
            }}
          />
        </div>

        <ResendCode />
      </AuthFormContainer>

      <PreviousStep changeCurentStep={changeCurentStep} />
    </form>
  );
};

const mapStateToProps = state => ({
  loadingStatus: sessionFormLoadingStatusSelector(state),
});

const mapDispatchToProps = {
  sendConfirmationCode,
  resendConfirmationCode,
  changeCurentStep,
  setNotifyStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ConfirmationCodeForm));
