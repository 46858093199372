import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteCampaign } from '../../../redux/store/campaign/campaignOperations';

import FormContainer from '../common/FormContainer/FormContainer';

const DeleteCampaign = ({ details: { _id, title } }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteCampaign(_id));
  };

  return (
    <section className="form-section">
      <FormContainer button={''}>
        <div className="form-block">
          <div>
            Do you really want to delete campaign "
            <span className="campaign__left-top-container-confirm">
              {title}
            </span>
            " ?
          </div>
          <button
            onClick={handleDelete}
            className="campaign__left-top-container-btn-center custom-btn"
          >
            Yes, sure
          </button>
        </div>
      </FormContainer>
    </section>
  );
};

export default DeleteCampaign;
