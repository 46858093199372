import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Number from '../../../common/Fields/Number/Number';
import Select from '../../../common/Fields/Select/Select';
import Button from '../../../common/Button/Button';
import FormContainer from '../../../common/FormContainer/FormContainer';
import Range from '../../../common/Fields/Range/Range';

import { schema } from '../../../../../schemas/bloggersFilteringSchema';

import '../../general.scss';
import './styles.scss';
import { filterInfo } from '../../../../../redux/store/instagram/instagramOperations';
import { useDispatch, useSelector } from 'react-redux';
import { getOptionsFilterSelector } from '../../../../../redux/store/instagram/instagramSelector';
import { closeModal } from '../../../../../redux/store/modal/modalActions';
import { dataBloggerTypeInfo } from '../../../../../configs/typeBloger';
import { accountSubjectOptions } from '../../../../../configs/selectOptions';
import { withTranslation } from 'react-i18next';

const BloggersFilteringForm = ({ type, t }) => {
  const formData = useSelector(getOptionsFilterSelector);
  const {
    register,
    handleSubmit,
    reset,
    formState: {},
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    ageCategory: { min: 13, max: 65 },
    accountQualityGte: 1,
    accountQualityLte: 10,
  });
  const onSubmit = data => {
    dispatch(filterInfo({ ...data, ...value }, type));
    dispatch(closeModal());
  };
  const _onChangeAge = ({ min, max }) => {
    let buffer = value;
    buffer.ageCategory = { min, max };
    setValue(buffer);
  };
  const _onChangeQuality = ({ min, max }) => {
    let buffer = value;
    buffer.accountQualityGte = min;
    buffer.accountQualityLte = max;
    setValue(buffer);
  };

  const handleFormClear = () => reset({});
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <section className="form-section">
        <FormContainer button={''}>
          <section className="form-section">
            <div className="form-block-container">
              <div className="form-block form-block-part">
                <Select
                  inputChangeOptions={register('accountSubject')}
                  listOfOptions={accountSubjectOptions}
                  options={{
                    labelText: t('blogger_list_sort_account_subject'),
                  }}
                />
              </div>
              <div className="form-block form-block-part">
                <Number
                  inputChangeOptions={register('audienceReachGte')}
                  inputChangeOptionsLast={register('audienceReachLte')}
                  options={{
                    renderType: 'dual',
                    placeholderText: t('blogger_list_sort_from'),
                    placeholderTextLast: t('blogger_list_sort_to'),
                    labelText: t('blogger_list_sort_audience'),
                  }}
                />
              </div>
              {/*<div className="form-block form-block-part">*/}
              {/*  <Select*/}
              {/*    inputChangeOptions={register('countryName')}*/}
              {/*    listOfOptions={dataBloggerTypeInfo.countryName}*/}
              {/*    options={{*/}
              {/*      labelText: t('blogger_list_sort_geography'),*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <div className="form-block-container">
              <div className="form-block form-block-part">
                <Select
                  inputChangeOptions={register('audienceGender')}
                  listOfOptions={dataBloggerTypeInfo.audienceGender}
                  options={{
                    labelText: t('blogger_list_sort_audience_gender'),
                  }}
                />
              </div>
              <div className="form-block form-block-part">
                <Select
                  inputChangeOptions={register('accountGender')}
                  listOfOptions={dataBloggerTypeInfo.accountGender}
                  options={{
                    labelText: t('blogger_list_sort_bloggers_gender'),
                  }}
                />
              </div>
            </div>
            <div className="form-block-container">
              <div className="form-block form-block-part">
                <Range
                  onChange={_onChangeAge}
                  options={{
                    labelText: t('blogger_list_sort_audience_age'),
                    type: 'age',
                    maxSize: 65,
                    minSize: 13,
                    activeMax: formData?.ageCategory?.max,
                    activeMin: formData?.ageCategory?.min,
                  }}
                />
              </div>
              <div className="form-block form-block-part">
                <Select
                  listOfOptions={dataBloggerTypeInfo.accountType}
                  inputChangeOptions={register('accountType')}
                  options={{
                    labelText: t('blogger_list_sort_account_type'),
                  }}
                />
              </div>
            </div>
            <div className="form-block-container">
              <div className="form-block form-block-part">
                <Number
                  inputChangeOptions={register('subscribersCountGte')}
                  inputChangeOptionsLast={register('subscribersCountLte')}
                  options={{
                    renderType: 'dual',
                    placeholderText: t('blogger_list_sort_from'),
                    placeholderTextLast: t('blogger_list_sort_to'),
                    labelText: t('blogger_list_sort_subscriber'),
                  }}
                />
              </div>
              <div className="form-block form-block-part">
                <Number
                  inputChangeOptions={register('pricePerPostGte')}
                  inputChangeOptionsLast={register('pricePerPostLte')}
                  options={{
                    renderType: 'dual',
                    placeholderText: t('blogger_list_sort_from'),
                    placeholderTextLast: t('blogger_list_sort_to'),
                    labelText: t('blogger_list_sort_price'),
                  }}
                />
              </div>
            </div>
            <div className="form-block-container">
              <div className="form-block form-block-part">
                <Number
                  inputChangeOptions={register('storiesViewsGte')}
                  inputChangeOptionsLast={register('storiesViewsLte')}
                  options={{
                    renderType: 'dual',
                    placeholderText: t('blogger_list_sort_from'),
                    placeholderTextLast: t('blogger_list_sort_to'),
                    labelText: t('blogger_list_sort_views_stories'),
                  }}
                />
              </div>
              <div className="form-block form-block-part">
                <Range
                  onChange={_onChangeQuality}
                  options={{
                    labelText: t('blogger_list_sort_qualitys'),
                    type: 'accountQuality',
                    maxSize: 10,
                    minSize: 1,
                    activeMax: formData?.accountQualityLte,
                    activeMin: formData?.accountQualityGte,
                  }}
                />
              </div>
            </div>
            <div className="form-block-container">
              <div className="bloggers-filtering-form__buttons">
                <div className="bloggers-filtering-form__buttons-first">
                  <Button
                    handleButtonClick={handleFormClear}
                    options={{
                      text: t('blogger_list_sort_clear'),
                      renderType: 'secondary',
                      size: 's',
                    }}
                  />
                </div>
                <Button
                  options={{
                    text: t('blogger_list_sort_proceed'),
                    renderType: 'primary',
                    size: 's',
                    type: 'submit',
                  }}
                />
              </div>
            </div>
          </section>
        </FormContainer>
      </section>
    </form>
  );
};

export default withTranslation()(BloggersFilteringForm);
