import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { advertisementSelector } from '../../../redux/selectors/selectors';
import { deleteAdvertisement } from '../../../redux/store/advertisement/advertisement/AdvertisementOperations';
import { closeModal } from '../../../redux/store/modal/modalActions';

import FormContainer from '../common/FormContainer/FormContainer';
import { usePathname } from '../../../hooks/usePathname';
import book from '../../router/book';
import { withTranslation } from 'react-i18next';

const DeleteAdvertisement = ({ t }) => {
  const { _id: id, title } = useSelector(state => advertisementSelector(state));

  const pathname = usePathname();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = () => {
    dispatch(deleteAdvertisement(id));
    dispatch(closeModal());
    if (pathname.includes(book.myCompanies)) {
      history.push(book.myCompanies);
    } else {
      history.push(book.statistics);
    }
  };

  return (
    <section className="form-section">
      <FormContainer button={''}>
        <div className="form-block">
          <div>
            {t('my_campaigns_popup_delete_text')} "
            <span className="campaign__left-top-container-confirm">
              {title}
            </span>
            " ?
          </div>
          <button
            onClick={() => handleDelete()}
            className="campaign__left-top-container-btn-center custom-btn"
          >
            {t('my_campaigns_popup_delete_button')}
          </button>
        </div>
      </FormContainer>
    </section>
  );
};

export default withTranslation()(DeleteAdvertisement);
