import * as api from '../../../api/campaign-service/campaign-service';

import * as globalLoaderActions from '../globalLoader/globalLoaderActions';
import * as modalActions from '../modal/modalActions';
import * as campaignActions from './campaignActions';
import { setNotifyStatus } from '../notify/notifyActions';
import { allCampaignSelector } from '../../selectors/selectors';

import { createFormData } from '../../../helpers/formDataConvertor';

export const fetchCampaignList =
  (sizeItemOnPage, params = '') =>
  (dispatch, getState) => {
    const {
      campaign: {
        params: { sortParams = '', orderParams = '' },
      },
    } = getState();
    dispatch(campaignActions.getCampaignListStart());
    dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

    return api
      .getCampaignList(
        `?limit=${sizeItemOnPage}` + sortParams + orderParams + params,
      )
      .then(response => {
        dispatch(campaignActions.getCampaignListSuccess(response));
      })
      .catch(({ message }) => {
        dispatch(campaignActions.getCampaignListError(message));
      })
      .finally(() =>
        dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
      );
  };

export const fetchCampaign =
  (id, params = '') =>
  (dispatch, getState) => {
    const {
      campaign: {
        params: { sortItemParams = '', status = '', orderParams = '' },
      },
    } = getState();
    dispatch(campaignActions.getCampaignStart());
    dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

    return api
      .getCampaignById(
        id + '?limit=6' + sortItemParams + orderParams + status + params,
      )
      .then(response => {
        dispatch(campaignActions.getCampaignSuccess(response));
      })
      .catch(({ message }) => {
        dispatch(campaignActions.getCampaignError(message));
      })
      .finally(() =>
        dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
      );
  };

// set
export const editSortAll = (size, data) => dispatch => {
  dispatch(campaignActions.setSortType(`&sortBy=${data}`));
  dispatch(fetchCampaignList(size));
};

export const editOrderAll = (size, data) => dispatch => {
  dispatch(campaignActions.setOrderType(`&orderBy=${data}`));
  dispatch(fetchCampaignList(size));
};

export const editOffsetAll = (size, data) => dispatch => {
  dispatch(campaignActions.setOffset(`&offset=${data}`));
  const url = `&offset=${data}`;
  dispatch(fetchCampaignList(size, url));
};

export const editSortItem = (id, data) => dispatch => {
  dispatch(campaignActions.setSortItemType(`&sortBy=${data}`));
  dispatch(fetchCampaign(id));
};

export const editOrderItem = (id, data) => dispatch => {
  dispatch(campaignActions.setOrderType(`&orderBy=${data}`));
  dispatch(fetchCampaign(id));
};

export const editStatusItem = (id, data) => dispatch => {
  dispatch(campaignActions.setStatus(`&status=${data}`));
  dispatch(fetchCampaign(id));
};

export const editOffsetItem = (id, data) => dispatch => {
  dispatch(campaignActions.setOffset(`&offset=${data}`));
  const url = `&offset=${data}`;
  dispatch(fetchCampaign(id, url));
};

// post
export const postCampaign =
  (data = {}) =>
  dispatch => {
    dispatch(campaignActions.postCampaignStart());
    dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

    const infoDataFormat = createFormData(data);

    return api
      .postCampaignById(infoDataFormat)
      .then(response => {
        dispatch(campaignActions.postCampaignSuccess(response));
        dispatch(
          setNotifyStatus({
            title: 'Campaign was successfully created',
            type: 'success',
          }),
        );
      })
      .catch(({ message }) => {
        dispatch(campaignActions.postCampaignError(message));
      })
      .finally(() =>
        dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
      );
  };

// put
export const editCampaign = (id, data) => (dispatch, getState) => {
  dispatch(campaignActions.editCampaignStart());
  dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

  const { items } = allCampaignSelector(getState());

  const infoDataFormat = createFormData(data);

  return api
    .putCampaignById(id, infoDataFormat)
    .then(campaign => {
      const newCampaignList = items.map(item =>
        item._id === campaign._id
          ? { ...item, title: campaign.title, banner: campaign.banner }
          : item,
      );
      dispatch(campaignActions.editCampaignSuccess(newCampaignList));
      dispatch(
        setNotifyStatus({
          title: 'Campaign was successfully edited',
          type: 'success',
        }),
      );
    })
    .catch(({ message }) =>
      dispatch(campaignActions.editCampaignError(message)),
    )
    .finally(() =>
      dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
    );
};

// delete
export const deleteCampaign = id => (dispatch, getState) => {
  dispatch(campaignActions.deleteCampaignStart());
  dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

  const { items } = allCampaignSelector(getState());

  return api
    .deleteCampaignById(id)
    .then(() => {
      const newCampaigns = items.filter(({ _id }) => _id !== id);
      dispatch(campaignActions.clearAllParams());
      dispatch(campaignActions.deleteCampaignSuccess(newCampaigns));
      dispatch(fetchCampaignList(6));
      dispatch(modalActions.closeModal());
      dispatch(
        setNotifyStatus({
          title: 'Campaign was successfully deleted',
          type: 'success',
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(
        setNotifyStatus({
          title: message,
          type: 'error',
        }),
      );
      dispatch(modalActions.closeModal());
    })
    .finally(() =>
      dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
    );
};
