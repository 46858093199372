import * as instagramActions from './instagramActions';
import * as api from '../../../api/instagram-service/instagram-service';
import * as globalLoaderActions from '../globalLoader/globalLoaderActions';
import {
  saveAutoSearchInstagramAccount,
  setDirectionValue,
  setFilterInfo,
  setFilterParams,
  setOffset,
  setOffsetCount,
  setSearch,
  setSearchValue,
  setSortType,
} from './instagramActions';
import { store } from '../store';
import { instagramAprrovedAccountsSelector } from '../../selectors/selectors';
import { setNotifyStatus } from '../../store/notify/notifyActions';
import {
  getBloggerIngo,
  getInstagramAutoSearch,
} from '../../../api/instagram-service/instagram-service';
import { setErrorHandler } from '../errorHandler/errorHandlerOperations';

export const fetchInstagramApprovedAccounts = (
  params = '',
  type,
) => dispatch => {
  const {
    instagram: { sortParams = '', directionParams = '' },
  } = store.getState();

  dispatch(instagramActions.getInstagramApprovedAccountsStart());
  dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

  return api
    .getApprovedAccounts(
      'limit=6' + "&campaignType=youtube" + sortParams + directionParams + params,
      type,
    )
    .then(response => {
      console.log(response)
      dispatch(instagramActions.getInstagramApprovedAccountsSuccess(response));
    })
    .catch(({ message }) => {
      dispatch(instagramActions.getInstagramApprovedAccountsError(message));
    })
    .finally(() =>
      dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
    );
};
export const fetchInstagramApprovedAccounts2 = (
  params = '',
  type,
) => dispatch => {
  const {
    instagram: { sortParams = '', directionParams = '' },
  } = store.getState();

  dispatch(instagramActions.getInstagramApprovedAccountsStart());
  dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

  return api
    .getApprovedAccounts2(
      'limit=6' + "&campaignType=instagram"+ sortParams + directionParams + params,
      type,
    )
    .then(response => {
      console.log(response)
      dispatch(instagramActions.getInstagramApprovedAccountsSuccess(response));
    })
    .catch(({ message }) => {
      dispatch(instagramActions.getInstagramApprovedAccountsError(message));
    })
    .finally(() =>
      dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
    );
};
const generateAgeParams = (data, type = 'all') => {
  const ageInfo = [
    { value: '&ageCategory=13-17', min: 13, max: 17 },
    { value: '&ageCategory=18-24', min: 18, max: 24 },
    { value: '&ageCategory=25-34', min: 25, max: 34 },
    { value: '&ageCategory=35-44', min: 35, max: 44 },
    { value: '&ageCategory=45-54', min: 45, max: 54 },
    { value: '&ageCategory=55-64', min: 55, max: 64 },
    { value: '&ageCategory=65', min: 64, max: 65 },
  ];
  return ageInfo.reduce((accumulator, item) => {
    if (item.min >= data.ageCategory.min && item.max <= data.ageCategory.max) {
      return accumulator + item.value;
    }
    return accumulator;
  }, '');
};

export const filterInfo = (data, type = 'all') => dispatch => {
  const {
    instagram: { searchParams = '' },
  } = store.getState();
  const bufferData = { ...data };
  dispatch(setFilterInfo(data));
  const ageParams = generateAgeParams(data);
  delete bufferData['ageCategory'];
  const link = Object.keys(bufferData).reduce((params, item) => {
    if (bufferData[item]) {
      return params + `&${item}=${bufferData[item]}`;
    }
    return params;
  }, '');
  dispatch(setFilterParams(ageParams + link));
  const url = link + ageParams + searchParams;
  dispatch(fetchInstagramApprovedAccounts(url, type));
};

export const editOffset = (data, type = 'all') => dispatch => {
  const {
    instagram: { searchParams = '', filterParams = '' },
  } = store.getState();
  dispatch(setOffset(`&offset=${data}`));
  dispatch(setOffsetCount(data));
  const url = filterParams + `&offset=${data}` + searchParams;
  dispatch(fetchInstagramApprovedAccounts(url, type));
};
export const editSearch = (data, type = 'all') => dispatch => {
  dispatch(setSearchValue(data));
  const {
    instagram: { filterParams = '' },
  } = store.getState();
  const param = data ? `&userName=${data}` : '';
  dispatch(setSearch(param));
  const url = param + filterParams;
  dispatch(fetchInstagramApprovedAccounts(url, type));
};

export const editDirection = (data, type = 'all') => dispatch => {
  const {
    instagram: { filterParams = '', searchParams = '' },
  } = store.getState();
  const param = data ? `&direction=${data}` : '';
  dispatch(setDirectionValue(param));
  const url = filterParams + searchParams;
  dispatch(fetchInstagramApprovedAccounts(url, type));
};
export const editSort = (data, type = 'all') => dispatch => {
  const {
    instagram: { searchParams = '', filterParams = '' },
  } = store.getState();
  dispatch(setSortType(`&sort=${data}`));
  const url = filterParams + searchParams;
  dispatch(fetchInstagramApprovedAccounts(url, type));
};

export const editBloggerListIsFavorite = (data, type) => (
  dispatch,
  getState,
) => {
  const { items, count } = instagramAprrovedAccountsSelector(getState());
  if (type === 'featured') {
    const newBloggers = items.filter(item => data.accountId !== item._id);
    dispatch(
      instagramActions.putApprovedAccountsFavoriteStatusSuccess(newBloggers),
    );
    if (newBloggers.length <= 1 && count > 5) {
      dispatch(fetchInstagramApprovedAccounts('', 'featured'));
    }
  } else {
    const newBloggers = items.map(item =>
      data.accountId === item._id ? { ...item, isFavorite: data.status } : item,
    );
    dispatch(
      instagramActions.putApprovedAccountsFavoriteStatusSuccess(newBloggers),
    );
  }
};
export const editInstagramApprovedAccountsFavoriteStatus = data => dispatch => {
  dispatch(instagramActions.putApprovedAccountsFavoriteStatusStart());
  return api
    .putApprovedAccountsFavoriteStatus(data)
    .then(({ message }) => {
      dispatch(editBloggerListIsFavorite(data));
      dispatch(
        setNotifyStatus({
          title: message,
          type: 'success',
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(
        instagramActions.putApprovedAccountsFavoriteStatusError(message),
      );
    });
};
export const getBlogger = id => dispatch => {
  getBloggerIngo(id)
    .then(e => {})
    .catch(r => dispatch(setErrorHandler(r)));
};

export const getAutoSearchInstagramAccount = params => dispatch => {
  dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));
  getInstagramAutoSearch(params)
    .then(r => {
      dispatch(saveAutoSearchInstagramAccount(r));
    })
    .catch(({ message }) => {
      dispatch(instagramActions.getInstagramApprovedAccountsError(message));
    })
    .finally(() =>
      dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
    );
};
