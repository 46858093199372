import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  SelectedBloggersInformationSelector,
  changeCreationAdvertisementStepSelector,
} from '../.././../redux/selectors/selectors';
import { changeCreationAdvertisementStep } from '../.././../redux/store/advertisement/creationStatus/creationStatusActions';
import { sendChoiseBloggers } from '../.././../redux/store/advertisement/choiceBloggers/choiceBloggersOperations';
import { newAdvertisementDataSelector } from '../../../redux/selectors/selectors';

import { advertisementCreationSteps } from '../../../constants/advertisementCreationSteps';
import { numberWithSpaces } from '../../../helpers/numberWithSpaces';
import Button from '../common/Button/Button';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const SelectedBloggers = ({
  formInformation: { count, coverage, price, additionalTargeting },
  sendChoiseBloggers,
  changeCreationAdvertisementStep,
  advertisement: { _id, status },
  typeOfSearch,
  t,
}) => {
  const handleBackButton = () => {
    /*changeCreationAdvertisementStep({
      activePage: advertisementCreationSteps.base,
    });*/
  };

  return (
    <div className="selected-bloggers-container">
      <p className="selected-bloggers__title">
        {t('advertisement_Step2_Selected_bloggers')}
      </p>
      <div className="selected-bloggers__info">
        <div className="selected-bloggers__info-item">
          <p className="selected-bloggers__info-desc">
            {t('advertisement_Step2_Selected')}
          </p>
          <p className="selected-bloggers__info-value">
            {numberWithSpaces(count)}
          </p>
        </div>
        <div className="selected-bloggers__info-item">
          <p className="selected-bloggers__info-desc">
            {t('advertisement_Step2_Audience')}
          </p>
          <p className="selected-bloggers__info-value">
            {numberWithSpaces(coverage)}
          </p>
        </div>
        <div className="selected-bloggers__info-result">
          <p className="selected-bloggers__info-desc">
            {t('advertisement_Step2_Price')}
          </p>
          <p className="selected-bloggers__info-price">
            $ {numberWithSpaces(price)}
          </p>
        </div>
        {status === 'ACTIVE' && (
          <div className="selected-bloggers__info-item">
            <p className="selected-bloggers__info-desc">
              {t('advertisement_Step2_Additional')}
            </p>
            <p className="selected-bloggers__info-value">
              $ {numberWithSpaces(additionalTargeting || 0)}
            </p>
          </div>
        )}
      </div>
      <div className="selected-bloggers__button">
        <Button
          handleButtonClick={() => {
            sendChoiseBloggers(_id, typeOfSearch);
          }}
          options={{
            renderType: 'primary',
            text: t('advertisement_Step2_Сontinue'),
            size: 'l',
            disabled: !price,
          }}
        />

        {/*<button
          onClick={handleBackButton}
          className="selected-bloggers__button selected-bloggers__button-back"
        >
          {t('advertisement_Step2_Return')}
        </button>*/}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  formInformation: SelectedBloggersInformationSelector(state),
  advertisement: newAdvertisementDataSelector(state),
  typeOfSearch: changeCreationAdvertisementStepSelector(state),
});

const mapDispatchToProps = {
  sendChoiseBloggers,
  changeCreationAdvertisementStep,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(SelectedBloggers)));
