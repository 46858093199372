import types from './creationDataTypes';

const initialState = {};

export const creationDataReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.ADD_NEW_STEPS_INFORMATION:
    case types.EDIT_ADVERTISEMENT_SUCCESS:
      return payload;
    case types.CLEAR_ADVERTISEMENT_INGO_AFTER_POST:
      return initialState;
    default:
      return state;
  }
};

export default creationDataReducer;
