import { get } from '../apiService';

export const getReferralLink = () => {
  return get('/referral/link');
};
export const getReferralStatistics = () => {
  return get(`/referral/statistics`);
};
export const getListReferralTransactions = (limit = 10, offset = 0, params) => {
  return get(
    `/referral/transactions?limit=${limit}&offset=${offset}&sortBy=${
      params.sortBy
    }&orderBy=${params.orderBy ? 'asc' : 'desc'}`,
  );
};
