import React from 'react';
import { connect } from 'react-redux';

import { modalInformationSelector } from '../../../redux/selectors/selectors';
import { closeModal } from '../../../redux/store/modal/modalActions';

import CampaignLaunchFormEditCommon from '../Forms/Company/CampaignLaunchForm/CampaignLaunchFormEditCommon';
import BloggersFilteringForm from '../Forms/Company/BloggersFilteringForm/BloggersFilteringForm';
import OffersFilteringForm from '../Forms/OffersFilteringForm/OffersFilteringForm';
import AttachToCampaign from '../Forms/Company/BaseInfoForm/AttachToCampaign/AttachToCampaign';
import ShowContent from '../../component/UploadFile/ShowContent/ShowContent';
import DeleteAdvertisement from '../../component/AdvertisementItem/DeleteAdvertisement';
import EditCommonCampaign from '../../component/MyCampaignsItem/EditCommonCampaign';
import {
  EditEmailSettingsForm,
  EditPasswordSettingsForm,
  EditEmailConfirmCode,
} from '../../component/Forms/SettingsForm/SettingsFormModals.js';
import OfferComments from '../AdvertisementItem/OfferComments';
import DeleteCampaign from '../MyCampaignsItem/DeleteCampaign';
import { modalTypes } from '../../../configs/modalTypes';

import AuthentificationStepsContainer from '../../component/Authentication/AuthentificationStepsContainer';
import Modal from './Modal';
import StatisticsBloggerInfo from '../StatisticsBloggerInfo/StatisticsBloggerInfo';
import QuickRefillPopUp from '../quickRefillPopUp/QuickRefillPopUp';
import InsufficientFundsModal from '../SelectedBloggers/InsufficientFundsModal';
import InstagramStatisticsApprove from '../Forms/InstagramStatisticsApprove/InstagramStatisticsApprove';
import AddedNewInstagramAccount from '../../pages/accountBlogger/addedNewInstagramAccount/AddedNewInstagramAccount';
import ShowVerificationCode from '../../pages/accountBlogger/showVerificationCode/ShowVerificationCode';
import WriteUsModal from '../Support/WriteUs/WriteUsModal/WriteUsModal';
import AddTicketModal from '../Support/AddTicket/AddTicketModal/AddTicketModal';
import GetInfoTextInPopup from '../GetInfoTextInPopup/GetInfoTextInPopup';
import SendDemoForm from '../OfferCardNew/formt/SendDemoForm';
import SendResult from '../OfferCardNew/formt/SendResult';
import ShowVerificationCodeInstagram from '../../pages/accountBlogger/showVerificationCode/ShowVerificationCodeInstagram';
const RenderModal = ({
  modalInformation: { isOpen, renderType, details, type },
  closeModal,
}) => {
  const getModalContent = () => {
    switch (renderType) {
      case modalTypes.manualSearchOffersFilter:
        return <OffersFilteringForm type={type} />;
      case modalTypes.manualSearchBloggersFilter:
        return <BloggersFilteringForm type={type} />;
      case modalTypes.editCommonCampaignLaunch:
        return <CampaignLaunchFormEditCommon />;
      case modalTypes.editCommonCampaign:
        return <EditCommonCampaign details={details} />;
      case modalTypes.deleteAdvertisement:
        return <DeleteAdvertisement />;
      case modalTypes.attachToCampaign:
        return <AttachToCampaign />;
      case modalTypes.auth:
        return <AuthentificationStepsContainer type="signin" />;
      case modalTypes.showCustomContent:
        return <ShowContent details={details} />;
      case modalTypes.showOfferDemoDownload:
        return <SendDemoForm details={details} />;
      case modalTypes.showAddCompletedLink:
        return <SendResult details={details} />;
      case modalTypes.showResultLink:
        return <GetInfoTextInPopup details={details} />;
      case modalTypes.offerComments:
        return <OfferComments details={details} />;
      case modalTypes.deleteCampaign:
        return <DeleteCampaign details={details} />;
      case modalTypes.quickRefillPopUp:
        return <QuickRefillPopUp closeModal={closeModal} details={details} />;
      case modalTypes.statisticsBlogger:
        return (
          <StatisticsBloggerInfo details={details} closeModal={closeModal} />
        );
      case modalTypes.editCurrentPassword:
        return (
          <EditPasswordSettingsForm closeModal={closeModal} details={details} />
        );
      case modalTypes.editCurrentEmail:
        return <EditEmailSettingsForm closeModal={closeModal} />;
      case modalTypes.editEmailConfirmCode:
        return <EditEmailConfirmCode closeModal={closeModal} />;
      case modalTypes.insufficientFunds:
        return <InsufficientFundsModal closeModal={closeModal} />;
      case modalTypes.instagramStatisticsApprove:
        return (
          <InstagramStatisticsApprove
            closeModal={closeModal}
            details={details}
          />
        );
      case modalTypes.addedNewInstagramAccount:
        return <AddedNewInstagramAccount closeModal={closeModal} />;
      case modalTypes.supportWriteUs:
        return <WriteUsModal closeModal={closeModal} />;
      case modalTypes.supportOpenTicket:
        return <AddTicketModal closeModal={closeModal} details={details} />;
      case modalTypes.showVerificationCode:
        return (
          <ShowVerificationCode closeModal={closeModal} details={details} />
        );
      case modalTypes.showVerificationCodeInstagram:
          return (
            <ShowVerificationCodeInstagram closeModal={closeModal} details={details} />
          );
      case modalTypes.getText:
        return <GetInfoTextInPopup closeModal={closeModal} details={details} />;
      case modalTypes.showOfferText:
        return <GetInfoTextInPopup closeModal={closeModal} details={details} />;
      default:
        return <></>;
    }
  };

  return (
    <Modal
      onClose={() => {
        if (modalTypes.getText === renderType) {
          details.onClick();
        }
        closeModal();
      }}
      status={isOpen}
    >
      {getModalContent()}
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalInformation: modalInformationSelector(state),
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderModal);
