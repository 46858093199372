import React from 'react';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { changeCreationAdvertisementStep } from '../../../../../redux/store/advertisement/creationStatus/creationStatusActions';
import { postAdvertisement } from '../../../../../redux/store/advertisement/creationData/creationDataOperations';
import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';
import { openModal } from '../../../../../redux/store/modal/modalActions';
import {
  advertisementSelector,
  newAdvertisementDataSelector,
  SelectedBloggersInformationSelector,
} from '../../../../../redux/selectors/selectors';

import { advertisementCreationSteps } from '../../../../../constants/advertisementCreationSteps';
import CampaignLaunch from '../../../common/Fields/CampaignLaunch/CampaignLaunch';
import { compareKeyBySelect } from '../../../../../helpers/compareKeyBySelect';
import FormContainer from '../../../common/FormContainer/FormContainer';
import {
  minValue,
  maxValue,
  sumLinkClicks,
  sumProfileVisits,
} from '../../../../../helpers/expectedResults';
import { modalTypes } from '../../../../../configs/modalTypes';
import Button from '../../../common/Button/Button';
import {
  contentTypesChoiceOfBloggers,
  contentTypesSelectOptions,
} from '../../../../../configs/selectOptions';
import book from '../../../../router/book';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const CampaignLaunchForm = ({ history, t }) => {
  const {
    status,
    story: { profile, contentType },
  } = useSelector(advertisementSelector);
  const {
    _id,
    title,
    endDate,
    startDate,
    publishTimeTo,
    publishTimeFrom,
    typeOfAllocation,
  } = useSelector(newAdvertisementDataSelector);
  const { bloggersList, coverage } = useSelector(
    SelectedBloggersInformationSelector,
  );
  const dispatch = useDispatch();

  const handleStartAdvertisement = () => {
    if (!bloggersList.length) {
      dispatch(
        setNotifyStatus({
          title: 'You need to add at least 1 offer',
          type: 'error',
        }),
      );
    } else {
      dispatch(postAdvertisement(_id, history, book));
    }
  };

  const handleUpdateAdvertisement = () => {
    if (!bloggersList.length) {
      dispatch(
        setNotifyStatus({
          title: 'You need to add at least 1 offer',
          type: 'error',
        }),
      );
    } else {
      dispatch(
        setNotifyStatus({
          title: 'Your advertisement was successfully updated',
          type: 'success',
        }),
      );
      history.push(book.statistics);
    }
  };

  return (
    <div>
      <section className="form-section">
        <FormContainer
          options={{ head: true, title: 'Common parameters' }}
          button={
            <button
              disabled={status === 'ACTIVE'}
              className={
                status === 'ACTIVE'
                  ? 'compaign-launch__btn-disabled'
                  : 'compaign-launch__btn-edit custom-btn'
              }
              onClick={() =>
                dispatch(
                  openModal({
                    isOpen: true,
                    renderType: modalTypes.editCommonCampaignLaunch,
                  }),
                )
              }
            >
              Edit
            </button>
          }
        >
          <CampaignLaunch
            options={{
              title: title,
              startDate: dayjs(startDate).format('DD.MM.YYYY'),
              endDate: dayjs(endDate).format('DD.MM.YYYY'),
              startTime: dayjs(publishTimeFrom).format('hh:mm A'),
              endTime: dayjs(publishTimeTo).format('hh:mm A'),
              typeOf:
                typeOfAllocation &&
                t(
                  compareKeyBySelect(
                    contentTypesChoiceOfBloggers,
                    typeOfAllocation,
                  ),
                ),
            }}
          />
        </FormContainer>
      </section>
      <section className="form-section">
        <FormContainer
          button={''}
          options={{
            head: true,
            title: t('advertisement_Step3_second_advertising'),
          }}
        >
          <div className="compaign-launch__stories">
            <CampaignLaunch
              options={{
                content:
                  contentType &&
                  t(compareKeyBySelect(contentTypesSelectOptions, contentType)),
                mention: profile,
                type: 'tertiary',
              }}
            />
          </div>
        </FormContainer>
      </section>
      <section className="form-section">
        <FormContainer
          button={''}
          options={{ head: true, title: 'Expected results' }}
        >
          <div className="compaign-launch__section">
            <div className="compaign-launch__secondary-container">
              <div className="compaign-launch__secondary-container-item">
                <div className="compaign-launch__secondary-container-item-title">
                  {t('advertisement_Step3_third_coverage')}
                </div>
                <div className="compaign-launch__secondary-container-item-subtitle">
                  ~ {coverage ? coverage : 0}
                </div>
              </div>
            </div>
            <div className="compaign-launch__secondary-container">
              <div className="compaign-launch__secondary-container-item">
                <div className="compaign-launch__secondary-container-item-title">
                  {t('advertisement_Step3_third_taps')}
                </div>
                <div className="compaign-launch__secondary-container-item-subtitle">
                  ~ {coverage ? sumLinkClicks(coverage) : 0}
                </div>
              </div>
            </div>
            <div className="compaign-launch__secondary-container">
              <div className="compaign-launch__secondary-container-item">
                <div className="compaign-launch__secondary-container-item-title">
                  {t('advertisement_Step3_third_visits')}
                </div>
                <div className="compaign-launch__secondary-container-item-subtitle">
                  ~ {coverage ? sumProfileVisits(coverage) : 0}
                </div>
              </div>
            </div>
            <div className="compaign-launch__secondary-container">
              <div className="compaign-launch__secondary-container-item">
                <div className="compaign-launch__secondary-container-item-title">
                  {t('advertisement_Step3_third_confirmation')}
                </div>
                <div className="compaign-launch__secondary-container-item-subtitle">
                  {t('advertisement_Step3_third_from')}{' '}
                  {bloggersList.length ? '40' : '0'}%
                </div>
              </div>
            </div>
            <div className="compaign-launch__secondary-container">
              <div className="compaign-launch__secondary-container-item">
                <div className="compaign-launch__secondary-container-item-title">
                  {t('advertisement_Step3_third_Promotion')}
                </div>
                <div className="compaign-launch__secondary-container-item-subtitle">
                  Attracting of subscribers
                </div>
              </div>
            </div>
            <div className="compaign-launch__secondary-container">
              <div className="compaign-launch__secondary-container-item">
                <div className="compaign-launch__secondary-container-item-title">
                  {t('advertisement_Step3_third_Advertising')}
                </div>
                <div className="compaign-launch__secondary-container-item-subtitle">
                  from ${bloggersList.length ? `${minValue(bloggersList)}` : 0}{' '}
                  to ${bloggersList.length ? `${maxValue(bloggersList)}` : 0}
                </div>
              </div>
            </div>
          </div>
        </FormContainer>
      </section>
      <div className="choice-bloggers-form__buttons">
        <Button
          handleButtonClick={() =>
            dispatch(
              changeCreationAdvertisementStep({
                activePage: advertisementCreationSteps.budget,
                activeStep: 2,
              }),
            )
          }
          options={{
            text: t('advertisement_Step3_return'),
            renderType: 'secondary',
            size: 'm',
          }}
        />
        {status !== 'ACTIVE' ? (
          <Button
            handleButtonClick={handleStartAdvertisement}
            options={{
              text: t('advertisement_Step3_launch'),
              renderType: 'primary',
              size: 'm',
            }}
          />
        ) : (
          <Button
            handleButtonClick={handleUpdateAdvertisement}
            options={{
              text: t('advertisement_Step3_update'),
              renderType: 'primary',
              size: 'm',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(CampaignLaunchForm));
