import React from 'react';

import { join } from './config';

import girl from '../../../../../../assets/images/landing/join/girl.svg';
import './styles.scss';

const Join = ({ t }) => {
  return (
    <div className="join">
      <div className="join__title">Join us</div>
      <div className="join__subtitle">
        Everyone can become <br /> our partner
      </div>
      <div className="join__content">
        <div className="join__content-left">
          <img className="join__content-left-girl" src={girl} alt="girl" />
        </div>
        <div className="join__content-right">
          {join.map(({ img, alt, title, subtitle }, index) => (
            <div key={index} className="join__content-right-block">
              <img
                src={img}
                alt={alt}
                className="join__content-right-block-img"
              />
              <div className="join__content-right-block-title">{t(title)}</div>
              <div className="join__content-right-block-subtitle">
                {t(subtitle)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Join;
