import React from 'react';

import MobileItemCard from './MobileItemCard';

import './styles.scss';

const MobileAdvertisementStatisticItem = ({ items }) => {
  return (
    <div className="mobile-statistic">
      {items &&
        items.map(item => <MobileItemCard key={item._id} item={item} />)}
    </div>
  );
};

export default MobileAdvertisementStatisticItem;
