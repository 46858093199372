import { postUpdateToken } from '../../../api/session-service/session-service';
import * as api from '../../../api/session-service/session-service';

import * as globalLoaderActions from '../globalLoader/globalLoaderActions';
import { changeAuthenticationStep } from './sessionActions';
import { setNotifyStatus } from '../notify/notifyActions';
import * as sessionActions from './sessionActions';
import { closeModal } from '../modal/modalActions';
import {
  sessionPersonalInformationSelector,
  sessionAuthenticationStepSelector,
} from '../../selectors/selectors';

import { isSupportedNotification } from '../../../helpers/firebase/isSupportedNotification';
import { sessionAuthenticationSteps } from '../../../constants/sessionAuthenticationSteps';
import { clearTimer } from '../../../utilites/count';
import book from '../../../views/router/book';
import { generatorDeviceId } from '../../../utilites/globalUtilites';
import { setErrorHandler } from '../errorHandler/errorHandlerOperations';

export const changeCurentStep = () => (dispatch, getState) => {
  clearTimer();
  const currentStep = sessionAuthenticationStepSelector(getState());

  const entriesSessionAuthenticationSteps = Object.entries(
    sessionAuthenticationSteps,
  );

  entriesSessionAuthenticationSteps.forEach((item, index) => {
    if (item[1] === currentStep) {
      const currentStepAllData = entriesSessionAuthenticationSteps[index - 1];
      const resultKey = currentStepAllData[0];
      dispatch(
        sessionActions.changeAuthenticationStep(
          sessionAuthenticationSteps[resultKey],
        ),
      );
    }
  });
};

const saveAccountInLocalStorage = user => {
  localStorage.setItem(
    'accaunt',
    JSON.stringify({
      ...user,
      notification: isSupportedNotification() ? Notification.permission : '',
    }),
  );
};
export const getUserInfo = history => dispatch => {
  api
    .getCurrentUserInformation()
    .then(user => {
      localStorage.setItem('isAuth', JSON.stringify(true));
      dispatch(
        sessionActions.sessionAdvertiserPersonalDataSuccess({
          ...user,
          notification: isSupportedNotification()
            ? Notification.permission
            : '',
        }),
      );
      saveAccountInLocalStorage(user);
      dispatch(closeModal());
      history?.push(book.home);
    })
    .catch(({ message }) => {
      dispatch(sessionActions.sessionAdvertiserPersonalDataError(message));
      throw message;
    });
};
//operation for loginization
export const setAuthenticationStep = type => (dispatch, getState) => {
  const {
    sessionAdvertiser: { authenticationStep },
  } = getState();
  if (
    sessionAuthenticationSteps.email === type &&
    authenticationStep &&
    authenticationStep !== sessionAuthenticationSteps.login
  ) {
    dispatch(
      changeAuthenticationStep(getState().sessionAdvertiser.authenticationStep),
    );
  } else {
    dispatch(changeAuthenticationStep(type));
  }
};
export const sendLogin = (data, history) => dispatch => {
  dispatch(sessionActions.sessionLoginStart());
  const deviceId = generatorDeviceId();
  return api
    .postLoginUser({ ...data, deviceId })
    .then(session => {
      if (session.role === 'ADVERTISER' || session.role === 'BLOGGER') {
        dispatch(sessionActions.sessionLoginSuccess(data));
        localStorage.setItem(
          'session',
          JSON.stringify({ ...session, dateStart: new Date() }),
        );
        dispatch(
          setTimerUpdateToken(
            session.refreshToken,
            getEndTime(new Date(), session.expiration_date),
          ),
        );
        dispatch(getUserInfo(history));
      } else {
        dispatch(
          setNotifyStatus({ title: 'Wrong email or password', type: 'error' }),
        );
      }
    })
    .catch(({ message }) => {
      dispatch(sessionActions.sessionLoginError(message));
      throw message;
    });
};

export const forgotPasswordForm = (data, history) => dispatch => {
  dispatch(sessionActions.sessionLoginStart());

  return api
    .postForgotPasswordForm(data)
    .then(session => {
      dispatch(
        setAuthenticationStep(sessionAuthenticationSteps.resetPasswordSuccess),
      );
    })
    .catch(({ message }) => {
      dispatch(sessionActions.sessionLoginError(message));
      throw message;
    });
};
//operations for registration new user

export const sendEmail = data => dispatch => {
  dispatch(sessionActions.sessionPhoneNumberRequest());
  return api
    .postEmail(data.email)
    .then(() => {
      dispatch(sessionActions.sessionPhoneNumberSuccess(data));
      dispatch(
        sessionActions.changeAuthenticationStep(
          sessionAuthenticationSteps.code,
        ),
      );
    })
    .catch(({ message }) => {
      dispatch(sessionActions.sessionPhoneNumberError(message));
      throw message;
    });
};

export const sendConfirmationCode = code => (dispatch, getState) => {
  const { email } = sessionPersonalInformationSelector(getState());

  dispatch(sessionActions.sessionConfirmationCodeRequest());

  return api
    .postConfirmationData({ code, email })
    .then(response => {
      dispatch(sessionActions.sessionConfirmationCodeSuccess(response));
      dispatch(
        sessionActions.changeAuthenticationStep(
          sessionAuthenticationSteps.accaunt,
        ),
      );
    })
    .catch(({ message }) => {
      dispatch(sessionActions.sessionConfirmationCodeError(message));
      throw message;
    });
};

export const sendPersonalData = (data, referralId) => dispatch => {
  dispatch(sessionActions.sessionAdvertiserPersonalDataRequest());
  const deviceId = generatorDeviceId();
  return api
    .postPersonalInformation({ ...data, deviceId }, referralId)
    .then(response => {
      localStorage.setItem('isAuth', JSON.stringify(true));
      localStorage.setItem(
        'session',
        JSON.stringify({ ...response, dateStart: new Date() }),
      );

      api.getCurrentUserInformation().then(user => {
        dispatch(
          sessionActions.sessionAdvertiserPersonalDataSuccess({
            ...user,
            notification: isSupportedNotification()
              ? Notification.permission
              : '',
          }),
        );
        dispatch(
          setTimerUpdateToken(
            response.refreshToken,
            getEndTime(new Date(), user.expiration_date),
          ),
        );

        saveAccountInLocalStorage(user);
        dispatch(closeModal());
        dispatch(
          sessionActions.changeAuthenticationStep(
            sessionAuthenticationSteps.login,
          ),
        );
        // history.push(book.home);
      });
    })
    .catch(({ message }) => {
      dispatch(sessionActions.sessionAdvertiserPersonalDataError(message));
      throw message;
    });
};

export const resendConfirmationCode = page => (dispatch, getState) => {
  const { email, newEmail } = sessionPersonalInformationSelector(getState());

  dispatch(sessionActions.resendConfirmationCodeStart());

  if (page === 'settings') {
    return api
      .putUser({ email: newEmail })
      .then(() => {
        dispatch(
          sessionActions.resendConfirmationCodeSuccess({ email: newEmail }),
        );
      })
      .catch(({ message }) => {
        dispatch(sessionActions.resendConfirmationCodeError(message));
      });
  } else {
    return api
      .postEmail({ email })
      .then(() => {
        dispatch(sessionActions.resendConfirmationCodeSuccess({ email }));
      })
      .catch(({ message }) => {
        dispatch(sessionActions.resendConfirmationCodeError(message));
      });
  }
};

//facebook | google

export const sessionlogOut = history => (dispatch, getState) => {
  const { fcmToken } = sessionPersonalInformationSelector(getState());
  dispatch(sessionActions.sessionlogOut());
  api.logoutSocialAuth();
  clearLocalTimeout();
  localStorage.removeItem('session');
  localStorage.removeItem('accaunt');
  localStorage.removeItem('isAuth');
  dispatch(sessionActions.setFcmToken(fcmToken));
  history.replace(book.advertisers);
};

export const authLoginWithFacebook = (
  data,
  history,
  referralId,
) => dispatch => {
  return api
    .facebookAuth(data, referralId)
    .then(response => {
      if (response.role === 'ADVERTISER' || response.role === 'BLOGGER') {
        localStorage.setItem(
          'session',
          JSON.stringify({ ...response, dateStart: new Date() }),
        );
        dispatch(
          setTimerUpdateToken(
            response.refreshToken,
            getEndTime(new Date(), response.expiration_date),
          ),
        );
        dispatch(closeModal());
        dispatch(getUserInfo(history));
      } else {
        dispatch(
          setNotifyStatus({ title: 'Wrong email or password', type: 'error' }),
        );
      }
    })
    .catch(({ message }) => console.log(message));
};

export const authLoginWithGoogle = (data, history, referralId) => dispatch => {
  return api
    .googleAuth(data, referralId)
    .then(response => {
      if (response.role === 'ADVERTISER' || response.role === 'BLOGGER') {
        localStorage.setItem(
          'session',
          JSON.stringify({ ...response, dateStart: new Date() }),
        );
        dispatch(
          setTimerUpdateToken(
            response.refreshToken,
            getEndTime(new Date(), response.expiration_date),
          ),
        );
        dispatch(closeModal());
        dispatch(getUserInfo(history));
      } else {
        dispatch(
          setNotifyStatus({ title: 'Wrong email or password', type: 'error' }),
        );
      }
    })
    .catch(({ message }) => console.log(message));
};

const getEndTime = (dateStart, expiration_date) => {
  let dataEnd = new Date(dateStart).getTime();
  dataEnd -= 3 * 60 * 1000;
  dataEnd = Number.parseInt(dataEnd) + Number.parseInt(expiration_date);

  return dataEnd - new Date().getTime();
};
export const checkingStatusUser = () => dispatch => {
  let userInfo = localStorage.getItem('session');
  if (userInfo) {
    const buffer = JSON.parse(userInfo);
    dispatch(checkingStatusToken(buffer));
  } else {
  }
};
export const checkingStatusToken = data => dispatch => {
  if (data) {
    const { expiration_date, refreshToken, dateStart } = data;

    if (getEndTime(dateStart, expiration_date) > 0) {
      dispatch(
        setTimerUpdateToken(
          refreshToken,
          getEndTime(dateStart, expiration_date),
        ),
      );
    } else {
      dispatch(updateToken(refreshToken));
    }
  }
};
const clearLocalTimeout = () => {
  if (window.user_login) {
    clearTimeout(window.user_login);
    window.user_login = null;
  }
};
const setTimerUpdateToken = (refreshToken, time) => dispatch => {
  clearLocalTimeout();
  window.user_login = setTimeout(() => {
    dispatch(updateToken(refreshToken));
  }, time);
};
export const updateToken = refreshToken => (dispatch, getState) => {
  const { fcmToken } = sessionPersonalInformationSelector(getState());
  const deviceId = generatorDeviceId();

  postUpdateToken(refreshToken, fcmToken, deviceId)
    .then(r => {
      dispatch(
        setTimerUpdateToken(
          r.refreshToken,
          getEndTime(new Date(), r.expiration_date),
        ),
        api
          .getCurrentUserInformation()
          .then(user => {
            dispatch(
              sessionActions.sessionAdvertiserPersonalDataSuccess({
                ...user,
                notification: isSupportedNotification()
                  ? Notification.permission
                  : '',
              }),
            );
            saveAccountInLocalStorage(user);
            localStorage.setItem('isAuth', JSON.stringify(true));
          })
          .catch(({ message }) => {
            dispatch(
              sessionActions.sessionAdvertiserPersonalDataError(message),
            );
            throw message;
          }),
      );
      localStorage.setItem(
        'session',
        JSON.stringify({ ...r, dateStart: new Date() }),
      );
      window.location.reload();
    })
    .catch(r => {
      clearLocalTimeout();
      localStorage.removeItem('session');
      localStorage.removeItem('accaunt');
      localStorage.removeItem('isAuth');
    });
};

export const putUserPersonalData = data => (dispatch, getState) => {
  const personalInformation = sessionPersonalInformationSelector(getState());
  dispatch(sessionActions.putUserByIdStart());
  dispatch(globalLoaderActions.changeButtonLoaderStatus(true));

  return api
    .putUser(data)
    .then(user => {
      const updateUser = {
        ...user,
        budget: personalInformation.budget,
        notification: isSupportedNotification() ? Notification.permission : '',
      };
      if (personalInformation.fcmToken) {
        updateUser.fcmToken = personalInformation.fcmToken;
      }

      dispatch(sessionActions.putUserByIdSuccess(updateUser));
      dispatch(sessionActions.sessionAdvertiserPersonalDataSuccess(updateUser));
      saveAccountInLocalStorage(updateUser);
      if (!data.email) {
        dispatch(
          setNotifyStatus({
            title: 'Personal information was successfully edited',
            type: 'success',
          }),
        );
        dispatch(closeModal());
      }
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally(() =>
      dispatch(globalLoaderActions.changeButtonLoaderStatus(false)),
    );
};

export const putEmailByConfirmationCode = code => (dispatch, getState) => {
  const { newEmail } = sessionPersonalInformationSelector(getState());
  dispatch(sessionActions.sessionConfirmationCodeRequest());
  dispatch(globalLoaderActions.changeButtonLoaderStatus(true));

  return api
    .putUserConfirmationEmail({ code, email: newEmail })
    .then(user => {
      dispatch(sessionActions.sessionConfirmationCodeSuccess(user));
      dispatch(
        sessionActions.sessionAdvertiserPersonalDataSuccess({
          ...user,
          notification: isSupportedNotification()
            ? Notification.permission
            : '',
        }),
      );
      saveAccountInLocalStorage(user);
    })
    .catch(({ message }) => {
      dispatch(sessionActions.sessionConfirmationCodeError(message));
      throw message;
    })
    .finally(() =>
      dispatch(globalLoaderActions.changeButtonLoaderStatus(false)),
    );
};
