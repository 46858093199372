import {
  createInstagramAccount,
  deletedInstagramAccount,
  getListInstagramBlogger,
  createInstagramAccountNew,
  reVerificationAccountById,
} from '../../../api/account-blogger-service/account-blogger-service';
import { getInstagramList } from './accountBloggerActions';
import { openModal } from '../modal/modalActions';
import { modalTypes } from '../../../configs/modalTypes';
import { setErrorHandler } from '../errorHandler/errorHandlerOperations';
import {
  changeButtonLoaderStatus,
  changeGlobalLoaderStatus,
} from '../globalLoader/globalLoaderActions';

export const getAccountInstagramListBlogger = (limit, offset) => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getListInstagramBlogger(limit, offset)
    .then(items => {
      console.log(items)
      dispatch(getInstagramList(items));
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally(() => {
      dispatch(changeGlobalLoaderStatus(false));
    });
};
export const reVerification = id => dispatch => {
  reVerificationAccountById(id)
    .then(({ code }) => {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.showVerificationCode,
          details: { code },
        }),
      );
    })
    .catch(r => {
      dispatch(setErrorHandler(r));
    });
};
export const deleteInstagramAccount = id => dispatch => {
  deletedInstagramAccount(id)
    .then(r => {
      dispatch(getAccountInstagramListBlogger());
    })
    .catch(r => dispatch(setErrorHandler(r)));
};
export const instagramAccountCreate = (nikName,pricePerPost, accountType) => dispatch => {
  createInstagramAccountNew(nikName,pricePerPost, accountType)
    .then(({ code }) => {
      if (accountType === "Youtube"){
        dispatch(
          openModal({
            isOpen: true,
            renderType: modalTypes.showVerificationCode,
            details: { code },
          }),
        );
      }
      else{
        dispatch(
          openModal({
            isOpen: true,
            renderType: modalTypes.showVerificationCodeInstagram,
            details: { code },
          }),
        );
      }
      dispatch(getAccountInstagramListBlogger());
    })
    .catch(r => dispatch(setErrorHandler(r)));
};
