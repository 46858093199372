import React from 'react';

import './styles.scss';
import '../general.scss';
import { withTranslation } from 'react-i18next';

//types = 'primary' || 'secondary'

const CampaignLaunch = ({ options = {}, t }) => {
  const {
    primaryTitle = '',
    title = '',
    startDate = '',
    endDate = '',
    startTime,
    endTime,
    typeOf = '',
    content = '',
    mention = '',
    type = 'primary',
  } = options;

  return (
    <>
      {type === 'primary' && (
        <>
          <div className="compaign-launch__primary">
            <div className="compaign-launch__primary-left">
              {t('advertisement_Step3_first_title_item')}
            </div>
            <div className="compaign-launch__primary-right">{title}</div>
          </div>
          <div className="compaign-launch__primary">
            <div className="compaign-launch__primary-left">
              {t('advertisement_Step3_first_period')}
            </div>
            <div className="compaign-launch__primary-right">
              {t('advertisement_Step3_first_from')} {startDate}{' '}
              {t('advertisement_Step3_first_to')} {endDate}
            </div>
          </div>
          <div className="compaign-launch__primary">
            <div className="compaign-launch__primary-left">
              {t('advertisement_Step3_first_time')}
            </div>
            <div className="compaign-launch__primary-right">
              {t('advertisement_Step3_first_from')} {startTime}{' '}
              {t('advertisement_Step3_first_to')} {endTime}
            </div>
          </div>
          <div className="compaign-launch__primary">
            <div className="compaign-launch__primary-left">
              {t('advertisement_Step3_first_targeting')}
            </div>
            <div className="compaign-launch__primary-right">{typeOf}</div>
          </div>
        </>
      )}
      {type === 'secondary' && (
        <div className="compaign-launch__secondary-container">
          <div className="compaign-launch__secondary-container-item">
            <div className="compaign-launch__secondary-container-item-title">
              {primaryTitle}
            </div>
            <div className="compaign-launch__secondary-container-item-subtitle">
              {title}
            </div>
          </div>
        </div>
      )}
      {type === 'tertiary' && (
        <>
          <div className="compaign-launch__primary">
            <div className="compaign-launch__primary-left">
              {t('advertisement_Step3_second_content')}
            </div>
            <div className="compaign-launch__primary-right">{content}</div>
          </div>
          {mention && (
            <div className="compaign-launch__primary">
              <div className="compaign-launch__primary-left">
                {t('advertisement_Step3_first_mention')}
              </div>
              <div className="compaign-launch__primary-right-active">
                {mention}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(CampaignLaunch);
