import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import './styles.scss';
import Password from '../../component/common/Fields/Password/Password';
import Button from '../../component/common/Button/Button';
import { resetPassword } from '../../../schemas/authFormsSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { postResetPassword } from '../../../api/session-service/session-service';
import { useHistory } from 'react-router';
import { sessionAuthenticationSteps } from '../../../constants/sessionAuthenticationSteps';
import { setAuthenticationStep } from '../../../redux/store/session/sessionOperations';
import { openModal } from '../../../redux/store/modal/modalActions';
import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';

const ResetPasswordForms = ({ loadingStatus, id, setNotifyStatus }) => {
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(resetPassword),
  });
  const [tokenFailed, setTokenFailed] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleFormSubmit = ({ newPassword }) => {
    postResetPassword({ password: newPassword, token: id })
      .then(e => {
        history.push('/for-advertisers');
        dispatch(setAuthenticationStep(sessionAuthenticationSteps.login));
        dispatch(openModal({ renderType: 'AUTH', isOpen: true }));
      })
      .catch(({ message }) => {
        if ('invalid signature' === message || 'jwt malformed' === message) {
          setTokenFailed(true);
        }

        setNotifyStatus({ title: message, type: 'error' });
      });
  };
  const _handleRegistrationButtonClick = () => {
    history.push('/for-advertisers');

    dispatch(setAuthenticationStep(sessionAuthenticationSteps.resetPassword));
    dispatch(openModal({ renderType: 'AUTH', isOpen: true }));
  };
  return tokenFailed ? (
    <div className="error-token">
      <div className="error-token__text">
        Sorry but Link is not correct or more than 1 hour passed
      </div>
      <button
        className="error-token__key"
        onClick={_handleRegistrationButtonClick}
      >
        Repeat forgot password
      </button>
    </div>
  ) : (
    <form
      className="auth-form reset-password-form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className="auth-form-block">
        <Password
          options={{
            placeholderText: '*********',
            labelText: 'New password',
            type: 'password',
          }}
          inputChangeOptions={register('newPassword')}
        />
      </div>
      <div className="auth-form-block">
        <Password
          options={{
            placeholderText: '*********',
            labelText: 'Repeat new password',
            type: 'password',
          }}
          inputChangeOptions={register('repeatNewPassword')}
        />
      </div>

      <div className="auth-form-button-container">
        <Button
          loadingStatus={loadingStatus}
          options={{
            text: 'Send',
            renderType: 'primary',
            size: 'l',
            type: 'submit',
            disabled: !isValid,
          }}
        />
      </div>
    </form>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  setNotifyStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ResetPasswordForms));
