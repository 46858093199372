import { get, put } from '../apiService';

export const getApprovedAccounts = (params = '', type) => {
  return get(
    /*
    type === 'featured'
      ? '/advertiser/favorite?' + params
      : '/instagram/approved-accounts?' + params,
    */
      '/instagram/approved-accounts?' + params,
  );
};
export const getApprovedAccounts2 = (params = '', type) => {
  return get(
    /*
    type === 'featured'
      ? '/advertiser/favorite?' + params
      : '/instagram/approved-accounts?' + params,
    */
      '/instagram/approved-accounts?' + params,
  );
};

export const getApprovedAccountsFilter = () => {
  return get('/instagram/approved-accounts');
};

export const putApprovedAccountsFavoriteStatus = body => {
  return put('/advertiser/favorite', body);
};

export const getFavoriteBloggers = () => {
  return get('/advertiser/favorite');
};
export const getBloggerIngo = id => {
  return get(`/instagram/all-about-account/${id}`);
};
export const getInstagramAutoSearch = ({ limit, offset, advertisementId }) => {
  return get(
    `/instagram/approved-accounts-for-auto-search?limit=${limit}&offset=${offset}&advertisementId=${advertisementId}`,
  );
};
