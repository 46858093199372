import { useEffect, useState } from 'react';

const _extends =
  Object.assign ||
  function (target) {
    for (let i = 1; i < arguments.length; i++) {
      const source = arguments[i];
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

const useFacebookLogin = () => {
  const [isFacebookLoginReady, setIsFacebookLoginReady] = useState(false);

  const facebookLogin = {
    login: async () => {
      const { authResponse, status } = await new Promise(resolve =>
        window.FB.login(resolve, { scope: 'public_profile,email' }),
      );
      if (!authResponse) return { status };

      return new Promise(resolve =>
        window.FB.api(
          '/me',
          { locale: 'en_US', fields: 'name,email,picture' },
          me => {
            _extends(me, authResponse);
            resolve(me);
          },
        ),
      );
    },
  };

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '1227663231071718',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
      });
      setIsFacebookLoginReady(true);
    };

    ((d, s, id) => {
      if (d.getElementById(id)) {
        setIsFacebookLoginReady(true);
        return;
      }

      const fjs = d.getElementsByTagName(s)[0];
      const js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/en_US/sdk.js`;
      js.async = true;
      js.defer = true;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  return [facebookLogin, isFacebookLoginReady];
};

export default useFacebookLogin;
