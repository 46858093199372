import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeCreationAdvertisementStep } from '../../../../../redux/store/advertisement/creationStatus/creationStatusActions';

import { advertisementCreationSteps } from '../../../../../constants/advertisementCreationSteps';
import CheckboxCard from '../../../renderCards/CheckboxCard/CheckboxCard';
import Button from '../../../common/Button/Button';
import { listOfTypes } from './config';

import '../../general.scss';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const ChoiceBloggersForm = ({ changeCreationAdvertisementStep, t }) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState('');

  return (
    <div>
      <p className="choice-bloggers-form__description">
        {t('advertisement_Step2_title')}
      </p>
      <div className="choice-bloggers-form__list">
        {listOfTypes.map((item, index) => (
          <div key={index + item.number} className="choice-bloggers-form__item">
            <CheckboxCard
              handleSelectedCheckbox={setSelectedCheckbox}
              key={index}
              options={{ ...item }}
              t={t}
            />
          </div>
        ))}
      </div>
      <div className="choice-bloggers-form__buttons">
        <Button
          handleButtonClick={() =>
            changeCreationAdvertisementStep({
              activePage: advertisementCreationSteps.base,
              activeStep: 0,
              typeOperation: 'edit',
            })
          }
          options={{
            text: t('advertisement_Step2_button_return'),
            renderType: 'secondary',
            size: 'm',
          }}
        />
        <Button
          handleButtonClick={() => {
            if (selectedCheckbox) {
              changeCreationAdvertisementStep({
                activePage: advertisementCreationSteps[selectedCheckbox],
              });
            }
          }}
          options={{
            text: t('advertisement_Step2_button_Proceed'),
            renderType: 'primary',
            size: 'm',
            disabled: !selectedCheckbox,
          }}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  changeCreationAdvertisementStep,
};

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(withTranslation()(ChoiceBloggersForm)));
