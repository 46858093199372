import React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  SubHeader,
  Advantages,
  Work,
  Case,
  DownloadDesktop,
  DownloadMobile,
} from './component/index';
import { useTranslation } from 'react-i18next';

const Advertisers = () => {
  const [t] = useTranslation();

  return (
    <>
      <Helmet>
        <title>Order the advertisement from bloggers - Bloggerly</title>
        <meta
          name="description"
          content="Order the advertisement from bloggers quickly, efficiently and safely"
        />
      </Helmet>
      <SubHeader t={t} />
      <Advantages t={t} />
      <Work t={t} />
      <Case t={t} />
      {/*<DownloadDesktop t={t} />
      <DownloadMobile t={t} />*/}
    </>
  );
};

export default Advertisers;
