import React, { useEffect, useState } from 'react';
import DatePicker from '../../../component/common/DatePicker/DatePicker';
import Button from '../../../component/common/Button/Button';
import MobileFinanceItem from '../../../component/FinanceItem/MobileFinanceItem/MobileFinanceItem';
import FinanceItem from '../../../component/FinanceItem/FinanceItem';
import Pagination from '../../../component/common/Pagination/Pagination';
import {
  setAdvertiserBudget,
  setBloggerBudget,
  setTransactionHistoryAdvertiser,
  setTransactionHistoryBlogger,
} from '../../../../redux/store/finance/financeOperations';
import { useDispatch, useSelector } from 'react-redux';
import { getZeroDate } from '../../../../utilites/globalUtilites';

const TransactionHistoryTable = ({ userRole, t }) => {
  const sizeItemOnPage = 10;
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const dispatch = useDispatch();
  const [typeSort, setTypeSort] = useState('createDate');
  const [orderBy, setOrderBy] = useState('desc');
  const [goToFirstPage, setGoToFirstPage] = useState(false);
  const onChangeDateFrom = e => {
    setDateFrom(e);
  };
  const { historyAdvertiser, historyBlogger } = useSelector(
    state => state.finance,
  );

  useEffect(() => {
    if (userRole === 'BLOGGER') {
      dispatch(setTransactionHistoryBlogger());
      dispatch(setBloggerBudget());
    } else {
      dispatch(setAdvertiserBudget());
      dispatch(setTransactionHistoryAdvertiser());
    }
  }, [dispatch]);

  const onChangeDateTo = e => {
    setDateTo(e);
  };
  const editOffset = e => {
    if (userRole === 'BLOGGER') {
      dispatch(setTransactionHistoryBlogger(e));
    } else {
      dispatch(
        setTransactionHistoryAdvertiser(
          e,
          'createDate',
          'desc',
          dateTo,
          dateFrom,
        ),
      );
    }
  };
  const sortItemsDesktop = type => {
    setGoToFirstPage(e => !e);
    if (type === typeSort) {
      setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
    }
    if (userRole === 'BLOGGER') {
      dispatch(
        setTransactionHistoryBlogger(0, type, orderBy, dateTo, dateFrom),
      );
    } else {
      dispatch(
        setTransactionHistoryAdvertiser(0, type, orderBy, dateTo, dateFrom),
      );
    }
    setTypeSort(type);
  };
  const _sortItem = ({ sortType, directionValue }) => {
    setGoToFirstPage(e => !e);
    if (userRole === 'BLOGGER') {
      dispatch(
        setTransactionHistoryBlogger(
          0,
          sortType,
          directionValue,
          dateTo,
          dateFrom,
        ),
      );
    } else {
      dispatch(
        setTransactionHistoryAdvertiser(
          0,
          sortType,
          directionValue,
          dateTo,
          dateFrom,
        ),
      );
    }
  };
  const _filterOnData = () => {
    if (userRole === 'BLOGGER') {
      dispatch(
        setTransactionHistoryBlogger(0, 'createDate', 'desc', dateTo, dateFrom),
      );
    } else {
      dispatch(
        setTransactionHistoryAdvertiser(
          0,
          'createDate',
          'desc',
          dateTo,
          dateFrom,
        ),
      );
    }
  };
  return (
    <>
      <section className="finances-page__transactions-container">
        <h2 className="finances-page__transactions-title">
          {t('finance_form_transactions_history')}
        </h2>
        <div className="finances-page__transactions-filters-container">
          <p>{t('finance_from')}</p>
          <DatePicker
            selected={dateFrom}
            maxDate={dateTo ? dateTo : getZeroDate()}
            onChange={onChangeDateFrom}
          />
          <p>{t('finance_to')}</p>
          <DatePicker
            selected={dateTo}
            minDate={dateFrom}
            maxDate={getZeroDate()}
            onChange={onChangeDateTo}
          />
          <Button
            handleButtonClick={_filterOnData}
            options={{ text: t('finance_apply'), renderType: 'secondary' }}
          />
        </div>
        <div className="finances-page__transactions-container">
          <MobileFinanceItem
            onChengSort={_sortItem}
            items={
              userRole === 'BLOGGER'
                ? historyBlogger.body
                : historyAdvertiser.body
            }
          />
          <table className="finances-page__transactions-table">
            <thead>
              <tr>
                <th className="finance-item__th">
                  {t('finance_table_number')}
                </th>
                <th
                  className="finance-item__th"
                  onClick={() => {
                    sortItemsDesktop('type');
                  }}
                >
                  {t('finance_table_operation')}
                </th>
                <th
                  className="finance-item__th"
                  onClick={() => {
                    sortItemsDesktop('createDate');
                  }}
                >
                  {t('finance_table_date')}
                </th>
                <th className="finance-item__th">
                  {t('finance_table_status')}
                </th>
                <th
                  className="finance-item__th"
                  onClick={() => {
                    sortItemsDesktop('amount');
                  }}
                >
                  {t('finance_table_sum')}
                </th>
              </tr>
            </thead>
            {(userRole === 'BLOGGER'
              ? historyBlogger.body
              : historyAdvertiser.body
            ).map(item => (
              <FinanceItem key={item.number} item={item} t={t} />
            ))}
          </table>
        </div>
      </section>
      <Pagination
        items={userRole === 'BLOGGER' ? historyBlogger : historyAdvertiser}
        elementType="page"
        sizeItemOnPage={sizeItemOnPage}
        getNextPageItems={e => {
          editOffset(e);
        }}
        isStartedPage={10}
        initialPage={goToFirstPage ? 0 : null}
      />
    </>
  );
};
export default TransactionHistoryTable;
