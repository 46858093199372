import first from '../../../../../../../assets/images/landing/work/firstMobile.svg';
import second from '../../../../../../../assets/images/landing/work/secondMobile.svg';
import third from '../../../../../../../assets/images/landing/work/thirdMobile.svg';

export const config = [
  {
    title: 'advertisers_landing_how_works_step_1_title',
    subtitle: 'advertisers_landing_how_works_step_1_description',
    img: first,
  },
  {
    title: 'advertisers_landing_how_works_step_3_title',
    subtitle: 'advertisers_landing_how_works_step_3_description',
    img: second,
  },
  {
    title: 'advertisers_landing_how_works_step_2_title',
    subtitle: 'advertisers_landing_how_works_step_2_description',
    img: third,
  },
];
