import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';

import { postCampaign } from '../../../../../../redux/store/campaign/campaignOperations';
import { closeModal } from '../../../../../../redux/store/modal/modalActions';
import { campaignSelector } from '../../../../../../redux/selectors/selectors';

import { campaignFormSchema } from '../../../../../../schemas/campaignFormSchema';
import Input from '../../../../common/Fields/Input/Input';

import '../../../general.scss';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const CreateCampaign = ({ t }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(campaignFormSchema),
  });

  const dispatch = useDispatch();

  const campaign = useSelector(state => campaignSelector(state));

  useEffect(() => {
    if (campaign.title) setValue('title', campaign.title);
  }, []);

  const onSubmit = data => {
    const newData = {
      title: data.title,
    };

    dispatch(postCampaign(newData));
    dispatch(closeModal());
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <div className="attach-create">
        <div className="attach-create__item">
          <Input
            errors={errors?.title?.message}
            options={{
              labelText: t('company_create_company_labelText'),
              placeholderText: t('company_create_company_placeholder'),
            }}
            inputChangeOptions={register('title')}
          />
        </div>
        <button type="submit" className="attach-create__item-btn custom-btn">
          {t('company_create_company_save')}
        </button>
      </div>
    </form>
  );
};

export default withTranslation()(CreateCampaign);
