import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { closeModal } from '../../../../../../redux/store/modal/modalActions';
import { selectedCampaign } from '../../../../../../redux/store/campaign/campaignActions';
import {
  fetchCampaignList,
  editOffsetAll,
} from '../../../../../../redux/store/campaign/campaignOperations';
import {
  allCampaignSelector,
  campaignOffsetSelector,
} from '../../../../../../redux/selectors/selectors';

import Pagination from '../../../../common/Pagination/Pagination';
import EmptyComponent from '../../../../EmptyComponent/EmptyComponent';

import { ReactComponent as CampaignIcon } from '../../../../../../assets/images/dummy/campaign.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const PickCampaign = ({ t }) => {
  const allCampaigns = useSelector(state => allCampaignSelector(state));
  const offset = useSelector(state => campaignOffsetSelector(state));

  const dispatch = useDispatch();
  const sizeItemOnPage = 4;

  useEffect(() => {
    dispatch(fetchCampaignList(sizeItemOnPage));
  }, [dispatch]);

  return (
    <div className="attach-pick">
      {allCampaigns.count ? (
        allCampaigns.items.map(item => (
          <div key={item._id} className="attach-pick-item">
            <div>
              <CampaignIcon className="attach-pick-item__icon" />
            </div>
            <p className="attach-pick-item__title">{item.title}</p>
            <div className="attach-pick-item__date" />
            <button
              onClick={() =>
                dispatch(selectedCampaign(item)) && dispatch(closeModal())
              }
              className="attach-pick-item__btn custom-btn"
            >
              {t('company_pick')}
            </button>
          </div>
        ))
      ) : (
        <div className="attach-pick-item-empty">
          <EmptyComponent
            title={t('company_you_not_company')}
            text={t('company_create')}
          />
        </div>
      )}
      <Pagination
        items={allCampaigns}
        elementType="page"
        sizeItemOnPage={sizeItemOnPage}
        getNextPageItems={e => {
          dispatch(editOffsetAll(sizeItemOnPage, e));
        }}
        isStartedPage={offset}
      />
    </div>
  );
};

export default withTranslation()(PickCampaign);
