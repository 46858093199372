import React from 'react';

import './styles.scss';

const AddTicket = ({ details, closeModal }) => {
  const { number, title, data, status, ownMessage, moderatorComments } =
    details;

  return (
    <div className="open-ticket">
      <div className="open-ticket__top">
        <div className="open-ticket__top-left">
          <div className="open-ticket__top-left-title">
            #{number} - {title}
          </div>
          <div className="open-ticket__top-left-data">{data}</div>
        </div>
        <div
          className={`open-ticket__top-right ${
            status === 'Closed' && 'closed'
          }`}
        >
          {status}
        </div>
      </div>
      <div className="open-ticket__bottom">
        <div className="open-ticket__bottom-message">{ownMessage}</div>
        <div className="open-ticket__bottom-comment">
          {moderatorComments.map(({ id, image, name, data, message }) => (
            <div key={id} className="open-ticket__bottom-comment__item">
              <div className="open-ticket__bottom-comment__item-left">
                <img
                  className="open-ticket__bottom-comment__item-left-avatar"
                  src={image}
                  alt="avatar"
                />
              </div>
              <div className="open-ticket__bottom-comment__item-right">
                <div className="open-ticket__bottom-comment__item-right-name">
                  {name}
                </div>
                <div className="open-ticket__bottom-comment__item-right-data">
                  {data}
                </div>
                <div className="open-ticket__bottom-comment__item-right-message">
                  {message}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="open-ticket__bottom-buttons">
          <button
            onClick={closeModal}
            className="open-ticket__bottom-buttons-open"
          >
            Open
          </button>
          <button
            onClick={closeModal}
            className="open-ticket__bottom-buttons-reply"
          >
            Reply
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTicket;
