export const sessionTypes = {
  SESSION_LOGIN_START: 'session/SESSION_LOGIN_START',
  SESSION_LOGIN_SUCCESS: 'session/SESSION_LOGIN_SUCCESS',
  SESSION_LOGIN_ERROR: 'session/SESSION_LOGIN_ERROR',

  SESSION_PHONE_NUMBER_START: 'session/SESSION_PHONE_NUMBER_START',
  SESSION_PHONE_NUMBER_SUCCESS: 'session/SESSION_PHONE_NUMBER_SUCCESS',
  SESSION_PHONE_NUMBER_ERROR: 'session/SESSION_PHONE_NUMBER_ERROR',

  SESSION_CONFIRMATION_CODE_START: 'session/SESSION_CONFIRMATION_CODE_START',
  SESSION_CONFIRMATION_CODE_SUCCESS:
    'session/SESSION_CONFIRMATION_CODE_SUCCESS',
  SESSION_CONFIRMATION_CODE_ERROR: 'session/SESSION_CONFIRMATION_CODE_ERROR',

  SESSION_ABVERTISERS_PERSONAL_DATA_START:
    'session/SESSION_ABVERTISERS_PERSONAL_DATA_START',
  SESSION_ABVERTISERS_PERSONAL_DATA_SUCCESS:
    'session/SESSION_ABVERTISERS_PERSONAL_DATA_SUCCESS',
  SESSION_ABVERTISERS_PERSONAL_DATA_ERROR:
    'session/SESSION_ABVERTISERS_PERSONAL_DATA_ERROR',

  CHANGE_AUTHENTICATION_STEP: 'session/CHANGE_AUTHENTICATION_STEP',

  RESEND_CONFIRMATION_CODE_START: 'session/RESEND_CONFIRMATION_CODE_START',
  RESEND_CONFIRMATION_CODE_SUCCESS: 'session/RESEND_CONFIRMATION_CODE_SUCCESS',
  RESEND_CONFIRMATION_CODE_ERROR: 'session/RESEND_CONFIRMATION_CODE_ERROR',

  SESSION_LOGOUT: 'session/SESSION_LOGOUT',

  SESSION_SET_FCM_TOKEN: 'session/SESSION_SET_FCM_TOKEN',
  GET_INFORMATION_ABOUT_USER_START: 'session/GET_INFORMATION_ABOUT_USER_START',
  GET_INFORMATION_ABOUT_USER_SUCCESS:
    'session/GET_INFORMATION_ABOUT_USER_SUCCESS',
  GET_INFORMATION_ABOUT_USER_ERROR: 'session/GET_INFORMATION_ABOUT_USER_ERROR',

  PUT_USER_BY_ID_START: 'session/PUT_USER_BY_ID_START',
  PUT_USER_BY_ID_SUCCESS: 'session/PUT_USER_BY_ID_SUCCESS',
  PUT_USER_BY_ID_ERROR: 'session/PUT_USER_BY_ID_ERROR',

  CLEAR_NEW_EMAIL: 'session/CLEAR_NEW_EMAIL',
};
