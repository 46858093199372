const types = {
  // get
  GET_ADVERTISEMENT_START: 'advertisement/GET_ADVERTISEMENT_START',
  GET_ADVERTISEMENT_SUCCESS: 'advertisement/GET_ADVERTISEMENT_SUCCESS',
  GET_ADVERTISEMENT_ERROR: 'advertisement/GET_ADVERTISEMENT_ERROR',

  GET_ADVERTISEMENT_STATISTIC_START:
    'advertisement/GET_ADVERTISEMENT_STATISTIC_START',
  GET_ADVERTISEMENT_STATISTIC_SUCCESS:
    'advertisement/GET_ADVERTISEMENT_STATISTIC_SUCCESS',
  GET_ADVERTISEMENT_STATISTIC_ERROR:
    'advertisement/GET_ADVERTISEMENT_STATISTIC_ERROR',

  // delete
  DELETE_ADVERTISEMENT_START: 'advertisement/DELETE_ADVERTISEMENT_START',
  DELETE_ADVERTISEMENT_SUCCESS: 'advertisement/DELETE_ADVERTISEMENT_SUCCESS',
  DELETE_ADVERTISEMENT_ERROR: 'advertisement/DELETE_ADVERTISEMENT_ERROR',

  // remove offer
  REMOVE_OFFER_FROM_LIST: 'advertisement/REMOVE_OFFER_FROM_LIST',
  CLEAR_ADVERTISEMENT: 'advertisement/CLEAR_ADVERTISEMENT',

  // set
  SET_SORT_TYPE: 'advertisement/SET_SORT_TYPE',
  SET_ORDER_TYPE: 'advertisement/SET_ORDER_TYPE',
  SET_DATE_FROM_PARAMS: 'advertisement/SET_DATE_FROM_PARAMS',
  SET_DATE_TO_PARAMS: 'advertisement/SET_DATE_TO_PARAMS',
  SET_OFFSET: 'advertisement/SET_OFFSET',
  CLEAR_ALL_PARAMS: 'advertisement/CLEAR_ALL_PARAMS',
  CLEAR_DATE_PARAMS: 'advertisement/CLEAR_DATE_PARAMS',
  SET_STATUS: 'advertisement/SET_STATUS',

  // post
  POST_ADVERTISEMENT_COPY_START: 'advertisement/POST_ADVERTISEMENT_COPY_START',
  POST_ADVERTISEMENT_COPY_SUCCESS:
    'advertisement/POST_ADVERTISEMENT_COPY_SUCCESS',
  POST_ADVERTISEMENT_COPY_ERROR: 'advertisement/GET_ADVERTISEMENT_COPY_ERROR',
};

export default types;
