import { ReactComponent as AutoSearchIcon } from '../../../../../assets/images/vectors/search.svg';
import { ReactComponent as ManualSearch } from '../../../../../assets/images/vectors/edit.svg';
// import { ReactComponent as AdsIcon } from '../../../../../assets/images/vectors/ads.svg';
import { ReactComponent as FeaturedBloggersIcon } from '../../../../../assets/images/vectors/star.svg';

export const listOfTypes = [
  {
    number: 1,
    text: 'advertisement_Step2_autosearch',
    Icon: AutoSearchIcon,
    name: 'auto',
  },
  {
    number: 2,
    text: 'advertisement_Step2_manual',
    Icon: ManualSearch,
    name: 'manual',
  },
  // { number: 3, text: 'Ads', Icon: AdsIcon, name: 'ads' },
  {
    number: 3,
    text: 'advertisement_Step2_favorite',
    Icon: FeaturedBloggersIcon,
    name: 'featured',
  },
];
