import React from 'react';

import AdvantagesItem from './AdvantagesItem/AdvantagesItem';
import { referrals } from './config';

import Break from '../../../../../../assets/images/landing/global/break.svg';
import './styles.scss';

const Advantages = ({ t }) => {
  return (
    <div className="advantages-referrals__container">
      <div className="advantages-referrals-content">
        <div className="advantages-referrals-title">
          {t('referrals_landing_advantages_title')}
        </div>
        <div className="advantages-referrals">
          {referrals &&
            referrals.map((item, index) => {
              return <AdvantagesItem key={index} item={item} t={t} />;
            })}
          <img className="advantages-referrals-break" src={Break} alt="Break" />
        </div>
      </div>
    </div>
  );
};

export default Advantages;
