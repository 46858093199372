import { createSelector } from 'reselect';

// loaders
export const globalLoaderStatusSelector = state => state.loader.globalLoader;
export const buttonLoaderStatusSelector = state => state.loader.buttonLoader;
export const contentLoaderStatusSelector = state => state.loader.contentLoader;

//session

export const sessionFormLoadingStatusSelector = state =>
  state.sessionAdvertiser.loading;

export const sessionFormErrorSelector = state => state.sessionAdvertiser.error;

export const sessionAuthenticationStepSelector = state =>
  state.sessionAdvertiser.authenticationStep;

export const sessionPersonalInformationSelector = state =>
  state.sessionAdvertiser.personalInformation;

export const sessionIsAuthSelector = state =>
  state.sessionAdvertiser.authenticated;

// modal
export const modalInformationSelector = state => state.modal;

// notify
export const notifyStatusSelector = state => state.notify;

// instgram
export const instagramAprrovedAccountsSelector = state =>
  state.instagram.allInstagramApprovedAccounts;
export const youtubeAprrovedAccountsSelector = state =>
  state.instagram.allYotubeApprovedAccounts;
export const instagramAutoSearchSelector = state =>
  state.instagram.autoSearchInstagramAccount;

// campaign
export const allCampaignSelector = state => state.campaign.allCampaigns;

export const campaignSelector = state => state.campaign.campaignItem;

export const campaignOffsetSelector = state => state.campaign.params.offset;

export const campaignInfoSelector = state => state.campaign;

export const storyFormSelector = state =>
  state.advertisement.baseInformationStep.storyForm;

export const baseInfoFormSelector = state =>
  state.advertisement.baseInformationStep.baseInformationData;

export const creationStatusSelector = state =>
  state.advertisement.creationStatus;

export const newAdvertisementDataSelector = state =>
  state.advertisement.creationData;

export const advertisementStatisticSelector = state =>
  state.advertisement.advertisement.advertisementStatistic;

export const baseInformationDataSelector = createSelector(
  newAdvertisementDataSelector,
  formList => {
    const listOfBaseInformationItems = [
      'title',
      'banner',
      'endDate',
      'startDate',
      'campaign',
      '_id',
    ];

    const renderObject = Object.entries(formList);

    const selectedInformation = renderObject.reduce((acc, current) => {
      const key = current[0];
      const value = current[1];

      return listOfBaseInformationItems.includes(key)
        ? { ...acc, [key]: value }
        : acc;
    }, {});

    return selectedInformation;
  },
);

export const budgetDataSelector = createSelector(
  newAdvertisementDataSelector,
  formList => {
    const listOfBaseInformationItems = ['budget'];

    const renderObject = Object.entries(formList);

    const selectedInformation = renderObject.reduce((acc, current) => {
      const key = current[0];
      const value = current[1];

      return listOfBaseInformationItems.includes(key)
        ? { ...acc, [key]: value }
        : acc;
    }, {});

    return selectedInformation;
  },
);

export const SelectedBloggersInformationSelector = state =>
  state.advertisement.choiseBloggersStep.selected;

// advertisement
export const advertisementSelector = state =>
  state.advertisement.advertisement.advertisementItem;

export const allAdvertisementSelector = state =>
  state.advertisement.advertisement;

// changeStep
export const changeCreationAdvertisementStepSelector = state =>
  state.advertisement.creationStatus.activePage;

// news
export const paramsNewsSelector = store => store.news.params;
export const newsListSelector = store => store.news.allNews;
export const newsSelector = store => store.news.newsItem;
export const getUserRole = state =>
  state.sessionAdvertiser?.personalInformation?.role;

// user notiifcations
export const userNotificationSelector = state =>
  state.userNotification.userNotifications;
