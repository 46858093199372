import beginner from '../../../../../../assets/images/landing/conditions/beginner.svg';
import advance from '../../../../../../assets/images/landing/conditions/advance.svg';
import premium from '../../../../../../assets/images/landing/conditions/premium.svg';

export const partnership = [
  {
    text: 'referrals_landing_partnership_item_1_text',
    subtitle: 'referrals_landing_partnership_item_1_subtitle',
    percent: 'referrals_landing_partnership_item_1_percent',
    title: 'referrals_landing_partnership_item_1_title',
    img: beginner,
    alt: 'beginner',
  },
  {
    text: 'referrals_landing_partnership_item_2_text',
    subtitle: 'referrals_landing_partnership_item_2_subtitle',
    percent: 'referrals_landing_partnership_item_2_percent',
    title: 'referrals_landing_partnership_item_2_title',
    img: advance,
    alt: 'advance',
  },
  {
    text: 'referrals_landing_partnership_item_3_text',
    subtitle: 'referrals_landing_partnership_item_3_subtitle',
    percent: 'referrals_landing_partnership_item_3_percent',
    title: 'referrals_landing_partnership_item_3_title',
    img: premium,
    alt: 'premium',
  },
];
