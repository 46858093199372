const types = {
  ADD_NEW_STEPS_INFORMATION: 'ADD_NEW_STEPS_INFORMATION',

  ADD_ADVERTISEMENT_BUDGET_START:
    'advertisement/ADD_ADVERTISEMENT_BUDGET_START',
  ADD_ADVERTISEMENT_BUDGET_SUCCESS:
    'advertisement/ADD_ADVERTISEMENT_BUDGET_SUCCESS',
  ADD_ADVERTISEMENT_BUDGET_ERROR:
    'advertisement/ADD_ADVERTISEMENT_BUDGET_ERROR',

  EDIT_ADVERTISEMENT_START: 'advertisement/EDIT_ADVERTISEMENT_START',
  EDIT_ADVERTISEMENT_SUCCESS: 'advertisement/EDIT_ADVERTISEMENT_SUCCESS',
  EDIT_ADVERTISEMENT_ERROR: 'advertisement/EDIT_ADVERTISEMENT_ERROR',

  POST_ADVERTISEMENT_START: 'advertisement/POST_ADVERTISEMENT_START',
  POST_ADVERTISEMENT_SUCCESS: 'advertisement/POST_ADVERTISEMENT_SUCCESS',
  POST_ADVERTISEMENT_ERROR: 'advertisement/POST_ADVERTISEMENT_ERROR',

  CLEAR_ADVERTISEMENT_INGO_AFTER_POST:
    'advertisement/CLEAR_ADVERTISEMENT_INGO_AFTER_POST',
};

export default types;
