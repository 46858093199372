import * as yup from 'yup';

export const editBaseInformationFormSchema = yup.object().shape({
  title: yup.string().trim().required(),
  startDate: yup.date().min(new Date()).required(),
  endDate: yup
    .date()
    .min(yup.ref('startDate'))
    .test({
      test: function (endDate) {
        return this.parent.startDate < endDate;
      },
    })
    .required(),
  publishTimeFrom: yup.string().required(),
  publishTimeTo: yup.string().required(),
});
