import { get, remove, postFormData, putFormData, post } from '../apiService';

export const getCpaOffersList = () => {
  return get(`/cpaoffer`);
};
export const postCpaOffer = body => {
  return postFormData(`/cpaoffer`, body);
};


