import React from 'react';

import AdvantagesItem from './AdvantagesItem/AdvantagesItem';
import { advertisers } from './config';

import Wave from '../../../../../../assets/images/landing/advantages/wave.svg';
import Break from '../../../../../../assets/images/landing/global/break.svg';
import './styles.scss';

const Advantages = ({ t }) => {
  return (
    <>
      <div className="advantages-title">
        {t('advertisers_landing_our_advantages')}
      </div>
      <div className="advantages-subtitle">
        {t('advertisers_landing_launch_the_advertisement')}
      </div>
      <img className="advantages-break" src={Break} alt="Break" />
      <div className="advantages__container">
        <div className="advantages">
          {advertisers &&
            advertisers.map((item, index) => {
              return <AdvantagesItem key={index} item={item} t={t} />;
            })}
          <img className="advantages-break-second" src={Break} alt="Break" />
        </div>
      </div>
      <img className="advantages-wave" src={Wave} alt="wave" />
    </>
  );
};

export default Advantages;
