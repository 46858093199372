import React from 'react';

import StepsDescription from '../../../component/StepsDescription/StepDescription';
import ChoiceBloggersForm from '../../../component/Forms/Company/ChoiceBloggersForm/ChoiceBloggersForm';
import { withTranslation } from 'react-i18next';

const BasicInformation = ({ t }) => {
  return (
    <div>
      <div className="new-company__create">
        <div className="new-company__left">
          <ChoiceBloggersForm />
        </div>
        <div className="new-company__right">
          <StepsDescription
            options={{
              title: t('advertisement_Step2_info'),
              description: t('advertisement_Step2_info_description'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BasicInformation);
