import dayjs from 'dayjs';

export const croppingText = (text, size = 15) => {
  if (text) {
    return text.length > size ? text.slice(0, size) + '...' : text;
  }
};

export const getTextTypeTransaction = type => {
  const isBlogger =
    JSON.parse(localStorage.getItem('accaunt')).role === 'BLOGGER';
  const typeValue = {
    FILL_UP_ADVERTISER_WALLET: {
      text: 'finance_table_status_refill',
      isUp: !isBlogger,
    },
    FILL_UP_ADVERTISEMENT_WALLET: {
      text: 'finance_table_status_launch',
      isUp: isBlogger,
    },
    WITHDRAW_MONEY: {
      text: 'finance_table_status_withdrawal',
      isUp: !isBlogger,
    },
    PAYMENT_FOR_BLOGGER_SERVICE: {
      text: 'finance_table_status_completion',
      isUp: isBlogger,
    },
    REFUND_MONEY_TO_ADVERTISER: {
      text: 'finance_table_status_failure',
      isUp: !isBlogger,
    },
  };
  return typeValue[type];
};

export const normalizeDateToFilter = (dateFrom, type) => {
  let data = new Date();
  return (
    dayjs(dateFrom).format('YYYY-MM-DD') +
    ((type === 'dateTo' ? 'T23:59:59.00' : 'T00:00:00.00') +
      -data.getTimezoneOffset() / 60)
  );
};
export const generatorDeviceId = () => {
  const deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    const DeviceId =
      String.fromCharCode(Number.parseInt(15 * Math.random()) + 65) +
      Math.random() * 100000000000000000 +
      String.fromCharCode(Number.parseInt(15 * Math.random()) + 65);
    localStorage.setItem('deviceId', DeviceId);
    return DeviceId;
  } else {
    return deviceId;
  }
};
export const getZeroDate = () => {
  const zeroDate = new Date();
  zeroDate.setHours(0);
  zeroDate.setMinutes(0);
  zeroDate.setSeconds(0);

  return zeroDate;
};
