import React from 'react';
import backSvgrepoCom from '../../../../../assets/images/vectors/back-svgrepo-com.svg';
import Button from '../../../../component/common/Button/Button';
import Input from '../../../../component/common/Fields/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  amountWithdrawalPayTM,
  amountWithdrawalUPL,
} from '../../../../../schemas/finanse';
import { balanceFormConfig } from './secondStepFormConfig';
import { options } from '../../config';
import { withTranslation } from 'react-i18next';

const SecondStepForm = ({ goBack, onSubmit, dataInfo, t }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: dataInfo,
    resolver: yupResolver(
      dataInfo.provider === options[0].value
        ? amountWithdrawalPayTM
        : amountWithdrawalUPL,
    ),
  });
  const getInputsInSecondForm = () => {
    return balanceFormConfig[dataInfo.provider].map(
      ({ type, labelText, placeholderText, typeInput }, index) => (
        <div className="purchase__input" key={index + 'purchase__input' + type}>
          <Input
            options={{
              labelText: t(labelText),
              placeholderText: t(placeholderText),
              typeText: typeInput,
              onKeyDown: evt =>
                typeInput === 'number'
                  ? evt.key === 'e' ||
                    evt.key === '+' ||
                    evt.key === '' ||
                    evt.key === '-'
                    ? evt.preventDefault()
                    : ''
                  : null,
            }}
            inputChangeOptions={register(type)}
          />
          <p className="finances-page__error-text">
            {errors?.[type]?.message && t(errors[type].message)}
          </p>
        </div>
      ),
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="purchase__container">
        <div
          className="purchase__container__go-first-container"
          onClick={() => {
            goBack(getValues());
          }}
        >
          <img src={backSvgrepoCom} alt={'backSvgrepoCom'} />
        </div>
        {getInputsInSecondForm()}
      </div>
      <div className="purchase__buy-button">
        <Button
          options={{
            text: t('finance_blogger_form_button_withdraw'),
            renderType: 'primary',
            type: 'submit',
            disabled: !isValid,
          }}
        />
      </div>
    </form>
  );
};
export default withTranslation()(SecondStepForm);
