import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

import './styles.scss';

const Accordion = ({ cases, t }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderedCases = cases.map((item, index) => {
    const showDescription = index === activeIndex ? 'show-description' : '';
    const fontWeightBold = index === activeIndex ? 'font-weight-bold' : '';
    const ariaExpanded = index === activeIndex ? 'true' : 'false';
    return (
      <AccordionItem
        key={index}
        showDescription={showDescription}
        fontWeightBold={fontWeightBold}
        ariaExpanded={ariaExpanded}
        item={item}
        t={t}
        index={index}
        onClick={() => {
          setActiveIndex(e => (e === index ? null : index));
        }}
      />
    );
  });

  return <div className="accordion">{renderedCases}</div>;
};

export default Accordion;
