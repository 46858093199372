import * as yup from 'yup';
const getValidFile = value =>
  value &&
  value[0] &&
  value[0].size &&
  value[0].size < 10000000 &&
  (value[0].name.includes('.jpg') ||
    value[0].name.includes('.png') ||
    value[0].name.includes('.gif'));
export const instagramAccountAllStatistics = yup.object().shape({
  accountSubject: yup.mixed().required(),
  accountGender: yup.mixed().required(),
  storiesPerDay: yup
    .number()
    .typeError('amount mast be a number')
    .required()
    .test('The number must be greater than 0!', value => value > 0),
  pricePerPost: yup
    .number()
    .typeError('amount mast be a number')
    .required()
    .test('The number must be greater than 0!', value => value > 0),
  locationCitiesAndAgeRangeAll: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  locationCountriesAndAgeRangeMen: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  audienceReach: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  genderGeneral: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  ageFemale: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  storiesViews: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
});
export const instagramAccountUpdateScreen = yup.object().shape({
  locationCitiesAndAgeRangeAll: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  locationCountriesAndAgeRangeMen: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  audienceReach: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  genderGeneral: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  ageFemale: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
  storiesViews: yup
    .mixed()
    .required()
    .test(
      'Photo: .jpg, .png, .gif 600x600, 720x1280, 600x1067 (no more than 10MB)',
      value => {
        return getValidFile(value);
      },
    ),
});
export const updateStatistic = yup.object().shape({
  accountSubject: yup.mixed().required(),
  accountGender: yup.mixed().required(),
  storiesPerDay: yup
    .number()
    .typeError('amount mast be a number')
    .required()
    .test('The number must be greater than 0!', value => value > 0),
  pricePerPost: yup
    .number()
    .typeError('amount mast be a number')
    .required()
    .test('The number must be greater than 0!', value => value > 0),
});
