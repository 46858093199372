import types from './instagramTypes';

const initialState = {
  allInstagramApprovedAccounts: {
    items: [],
    count: 0,
    isPaywalled: true,
  },
  favoriteInstagramApprovedAccounts: {
    items: [],
    count: 0,
    isPaywalled: true,
  },
  autoSearchInstagramAccount: {
    items: [],
    count: 0,
    isPaywalled: true,
  },
  accountBlogger: {},
  filterInfo: {},
  offset: '&offset=0',
  offsetCount: 0,
  limit: 6,
  link: '',
  searchValue: '',
  sortParams: '&sort=subscribersCount',
  searchParams: '',
  filterParams: '',
  directionParams: '&direction=1',
};

export const instagramReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_INSTAGRAM_APPROVED_ACCOUNTS_SUCCESS:
      return { ...state, allInstagramApprovedAccounts: payload };
    case types.SET_FILTER_INFO:
      return { ...state, filterInfo: payload, offset: 0, offsetCount: 0 };
    case types.GET_AUTO_SEARCH_INSTAGRAM_ACCOUNT:
      return { ...state, autoSearchInstagramAccount: payload };
    case types.SET_LINK:
      return { ...state, link: payload, offset: 0, offsetCount: 0 };
    case types.SET_SEARCH:
      return { ...state, searchParams: payload, offset: 0, offsetCount: 0 };
    case types.SET_TYPE_SORT_TYPE:
      return { ...state, sortParams: payload, offset: 0, offsetCount: 0 };
    case types.SET_OFFSET:
      return { ...state, offset: payload };
    case types.SET_OFFSET_COUNT:
      return { ...state, offsetCount: payload };
    case types.SET_FILTER_PARAMS:
      return { ...state, filterParams: payload, offset: 0, offsetCount: 0 };
    case types.SET_SEARCH_VALUE:
      return { ...state, searchValue: payload };
    case types.SET_DIRECTION_PARAMS:
      return { ...state, directionParams: payload, offset: 0, offsetCount: 0 };
    case types.PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_SUCCESS:
      return {
        ...state,
        allInstagramApprovedAccounts: {
          ...state.allInstagramApprovedAccounts,
          items: payload,
        },
      };
    case types.SET_ACCOUNT_BLOGGER:
      return { ...state, accountBlogger: payload };
    case types.CLEAR_ALL_PARAMS:
      return {
        ...state,
        filterInfo: initialState.filterInfo,
        offset: initialState.offset,
        offsetCount: initialState.offsetCount,
        limit: initialState.limit,
        link: initialState.link,
        searchValue: initialState.searchValue,
        sortParams: initialState.sortParams,
        searchParams: initialState.searchParams,
        filterParams: initialState.filterParams,
        directionParams: initialState.directionParams,
      };
    default:
      return state;
  }
};

export default instagramReducer;
