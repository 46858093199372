import React, { useEffect, useState} from 'react';
import StepsDescription from '../../../component/StepsDescription/StepDescription';
import BudgetForm from '../../../component/Forms/Company/BudgetForm/BudgetForm';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss';
import { withTranslation } from 'react-i18next';
import {getAllOffersWithDemos} from '../../../../api/offer-service/offer-service'
import BlogerCardAdvertisement from '../../../component/renderCards/BlogerCard/BlogerCardAdvertisement'
const Budget = ({ t , match}) => {
  const [BloggersList, setBloggersList] = useState([])
  const [refresh, refreshState] = useState(1)
  useEffect(()=>{
    const id = localStorage.getItem("currentCompanyId")
    getAllOffersWithDemos(localStorage.getItem("currentCompanyId")).then((data)=>{
      if (data){
        setBloggersList(data.items);
      }
    })
  },[refresh])
  return (
    <div>
      <div className="new-company__create">
        <div className="new-company__left">
          {/*<div className="new-company__cards-list">
              {BloggersList.map(blogger =>{
                if (blogger.status === "ACTIVE")
                  return <BlogerCardAdvertisement
                    isPaywalled={false}
                    key={blogger._id}
                    bloggerInformation={blogger}
                    type={'all'}
                    refresh= {refresh}
                    refreshState = {refreshState}
                  />
                if (blogger.status === "PENDING")
                    return <BlogerCardAdvertisement
                      isPaywalled={false}
                      key={blogger._id}
                      bloggerInformation={blogger}
                      type={'all'}
                      refresh= {refresh}
                      refreshState = {refreshState}
                    />
                if (blogger.status === "DEMO_LOADED")
                    return <BlogerCardAdvertisement
                      isPaywalled={false}
                      key={blogger._id}
                      bloggerInformation={blogger}
                      type={'all'}
                      refresh= {refresh}
                      refreshState = {refreshState}
                    />
                  if (blogger.status === "WAITING_FOR_DEMO")
                    return <BlogerCardAdvertisement
                      isPaywalled={false}
                      key={blogger._id}
                      bloggerInformation={blogger}
                      type={'all'}
                      refresh= {refresh}
                      refreshState = {refreshState}
                    />
                return  <BlogerCardAdvertisement
                isPaywalled={false}
                key={blogger._id}
                bloggerInformation={blogger}
                type={'all'} />
                refresh= {refresh}
                refreshState = {refreshState}
              }
              )}
            </div>*/}
        </div>
        <div className="new-company__right">
          <StepsDescription
            options={{
              title: t('advertisement_Step3_title_Budget'),
              description: t('advertisement_Step3_text'),
            }}
          />
        </div>
        
      </div>
      <BudgetForm />
    </div>
  );
};

export default withTranslation()(Budget);
