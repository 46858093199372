import Input from '../../../../component/common/Fields/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../../../../component/common/Fields/CustomSelect/CustomSelect';
import { options } from '../../config';
import Button from '../../../../component/common/Button/Button';
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { amountRefill } from '../../../../../schemas/finanse';
import { setAdvertiserBudget } from '../../../../../redux/store/finance/financeOperations';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const RefillForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(amountRefill),
  });
  const dispatch = useDispatch();
  const onSubmit = info => {};
  const [t] = useTranslation();

  useEffect(() => {
    dispatch(setAdvertiserBudget());
  }, []);
  const { errorMessage } = useSelector(state => state.finance);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="purchase">
        <div className="purchase__container">
          <div className="purchase__input">
            <Input
              options={{
                labelText: t('finance_advertiser_form_label_am'),
                placeholderText: t('finance_advertiser_form_placeholder'),
                typeText: 'number',
                onKeyDown: evt =>
                  evt.key === 'e' ||
                  evt.key === '+' ||
                  evt.key === '' ||
                  evt.key === '-'
                    ? evt.preventDefault()
                    : '',
              }}
              inputChangeOptions={register('amount')}
            />
            {errors?.amount?.message ? (
              <p className="finances-page__error-text">
                {errors?.amount?.message}
              </p>
            ) : null}
          </div>

          <div className="purchase__input">
            <Controller
              control={control}
              render={field => (
                <CustomSelect
                  control={control}
                  name="method"
                  fieldOptions={field}
                  options={options}
                  t={t}
                />
              )}
            />
          </div>
        </div>
        <div className="purchase__buy-button">
          <Button
            options={{
              text: t('finance_advertiser_form'),
              renderType: 'primary',
              type: 'submit',
              disabled: true,
            }}
          />
        </div>
        {errorMessage ? (
          <div className="finances-page__error">{errorMessage}</div>
        ) : null}
      </div>
    </form>
  );
};
export default RefillForm;
