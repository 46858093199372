import React from 'react';

import './styles.scss';

const ContentFields = ({ options = {} }) => {
  const { title = '', subtitle = '', activeSubtitle } = options;

  return (
    <div className="campaign-item__container-right-item">
      <span className="campaign-item__container-right-item-title">{title}</span>
      <span
        className={
          activeSubtitle
            ? 'campaign-item__container-right-item-subtitle-active'
            : 'campaign-item__container-right-item-subtitle'
        }
      >
        {subtitle}
        {activeSubtitle}
      </span>
    </div>
  );
};

export default ContentFields;
