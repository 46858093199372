import React from 'react';
import StepsDescription from '../../../component/StepsDescription/StepDescription';
import CampaignLaunchForm from '../../../component/Forms/Company/CampaignLaunchForm/CampaignLaunchForm';
import { withTranslation } from 'react-i18next';

const CampaignLaunch = ({ t }) => {
  return (
    <div>
      <div className="new-company__create">
        <div className="new-company__left">
          <CampaignLaunchForm />
        </div>
        <div className="new-company__right">
          <StepsDescription
            options={{
              title: t('advertisement_Step3_info_title'),
              description: t('advertisement_Step3_info_description'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CampaignLaunch);
