import types from './baseInformationTypes';

import {
  contentTypesSelectOptions,
  improvisavionTypeSelectOptions,
} from '../../../../configs/selectOptions';

const initialStorisItem = {
  contentType: contentTypesSelectOptions[0].key,
  content: '',
  instruction: '',
  profile: '',
  site: '',
  file: null,
};

const initialState = {
  storyForm: initialStorisItem,
  baseInformationData: {
    banner: null,
  },

  error: null,
  loading: false,
};

export const baseInformationReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.ADD_BASE_ADVERTISEMENT_INFO_SUCCESS: {
      return {
        ...state,
        baseInformationData: payload,
      };
    }
    case types.SET_STORIES_LIST: {
      return {
        ...state,
        storyForm: payload,
      };
    }
    case types.CLEAR_INFORMATION_STORIES: {
      return {
        ...state,
        storyForm: initialState.storyForm,
      };
    }
    case types.CLEAR_BASE_INFORMATION: {
      return initialState;
    }
    case types.SET_BANNER: {
      return {
        ...state,
        baseInformationData: {
          ...initialState.baseInformationData,
          banner: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default baseInformationReducer;
