import book from '../../../../../../router/book';

export const navbar = [
  {
    title: 'For advertisers',
    link: book.advertisers,
  },
  {
    title: 'For bloggers',
    link: book.bloggers,
  },
  {
    title: 'For referrals',
    link: book.referrals,
  },
  {
    title: 'Blog',
    link: book.blog,
  },
];
