import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { sendSupportRequest } from '../../../../../redux/store/support/supportOperations';
import { buttonLoaderStatusSelector } from '../../../../../redux/selectors/selectors';

import { supportMessageSchema } from '../../../../../schemas/supportMessageSchema';
import FormContainer from '../../../common/FormContainer/FormContainer';
import Textarea from '../../../common/Fields/Textarea/Textarea';
import Input from '../../../common/Fields/Input/Input';
import Button from '../../../common/Button/Button';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const WriteUsModal = ({ closeModal, t }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(supportMessageSchema),
  });

  const loadingStatus = useSelector(buttonLoaderStatusSelector);
  const dispatch = useDispatch();

  const [messageLength, setMessageLength] = useState(0);

  useEffect(() => {
    const subscription = watch(({ body }) => setMessageLength(body?.length));
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = data => {
    dispatch(sendSupportRequest(data));
    closeModal();
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <div className="write-us">
        <FormContainer
          button={''}
          options={{ head: true, title: t('support_popup_subject_label') }}
        >
          <div className="write-us__input">
            <Input
              errors={errors?.subject?.message}
              options={{
                labelText: t('support_popup_subject_label'),
                placeholderText: t('support_popup_subject_placeholder'),
              }}
              inputChangeOptions={register('subject')}
            />
          </div>
          <div className="write-us__textarea">
            <Textarea
              errors={errors?.body?.message}
              options={{
                labelText: t('support_popup_message_label'),
                placeholderText: t('support_popup_message_placeholder'),
              }}
              inputChangeOptions={register('body')}
            />
          </div>
          <span
            className={`write-us__length ${messageLength > 1000 && 'limit'}`}
          >
            {messageLength || 0} / 1000
          </span>
          <div className="write-us__button">
            <Button
              loadingStatus={loadingStatus}
              options={{
                text: t('support_popup'),
                renderType: 'primary',
                type: 'submit',
                size: 'm',
              }}
            />
          </div>
        </FormContainer>
      </div>
    </form>
  );
};

export default withTranslation()(WriteUsModal);
