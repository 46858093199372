import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { clearAllParams } from '../../../redux/store/campaign/campaignActions';
import {
  fetchCampaignList,
  editOffsetAll,
  editOrderAll,
  editSortAll,
} from '../../../redux/store/campaign/campaignOperations';
import {
  allCampaignSelector,
  campaignOffsetSelector,
} from '../../../redux/selectors/selectors';

import SelectFilter from '../../component/HeaderListBlogger/selectFilter/selectFilter';
import MyCampaignsItem from '../../component/MyCampaignsItem/MyCampaignsItem';
import EmptyComponent from '../../component/EmptyComponent/EmptyComponent';
import { sortByList, orderByList } from '../../../constants/CampaignList';
import Pagination from '../../component/common/Pagination/Pagination';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const MyCompanies = () => {
  const allCampaigns = useSelector(allCampaignSelector);
  const offset = useSelector(campaignOffsetSelector);
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const sizeItemOnPage = 6;

  useEffect(() => {
    dispatch(fetchCampaignList(sizeItemOnPage));

    return () => dispatch(clearAllParams());
  }, [dispatch]);

  const onChangeSort = ({ value }) => {
    dispatch(editSortAll(sizeItemOnPage, value));
  };

  const onChangeOrder = ({ value }) => {
    dispatch(editOrderAll(sizeItemOnPage, value));
  };

  return (
    <div className="campaigns">
      <div className="campaigns__select">
        <div className="campaigns__select-right">
          <div className="campaigns__select-right-sort">
            <SelectFilter
              options={sortByList}
              onChange={onChangeSort}
              defaultValue={sortByList[0]}
              type={'sort'}
            />
          </div>
          <div className="campaigns__select-right-sort">
            <SelectFilter
              options={orderByList}
              onChange={onChangeOrder}
              defaultValue={orderByList[0]}
              type={'sortBy'}
            />
          </div>
        </div>
      </div>
      {allCampaigns.count ? (
        <div className="campaigns-list">
          {allCampaigns.items.map((item, index) => (
            <MyCampaignsItem key={index} item={item} t={t} />
          ))}
        </div>
      ) : (
        <EmptyComponent
          title={t('my_campaigns_empty_title')}
          text={t('my_campaigns_empty_text')}
          button={t('my_campaigns_empty_button')}
        />
      )}
      <Pagination
        items={allCampaigns}
        elementType="page"
        sizeItemOnPage={sizeItemOnPage}
        getNextPageItems={e => {
          dispatch(editOffsetAll(sizeItemOnPage, e));
        }}
        isStartedPage={offset}
      />
    </div>
  );
};

export default MyCompanies;
