import React, { useEffect, useState } from 'react';
import './styles.scss';
import { openModal } from '../../../redux/store/modal/modalActions';
import { modalTypes } from '../../../configs/modalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { stateScreenShotsApprove } from './const';
import profile from '../../../assets/images/vectors/profile.svg';
import { StatisticsIconComponent } from './statistik';
import {
  deleteInstagramAccount,
  getAccountInstagramListBlogger,
  reVerification,
} from '../../../redux/store/accountBlogger/accountBloggerOperations';
import ErrorComponent from '../../component/ErrorComponent/ErrorComponent';
import { typesErrorComponent } from '../../../configs/errorComponentTypes';
import Pagination from '../../component/common/Pagination/Pagination';
import { useHistory } from 'react-router-dom';
import { globalLoaderStatusSelector } from '../../../redux/selectors/selectors';
import paywallAvatar from '../../../assets/images/bloggers/paywallAvatar.svg';
import { withTranslation } from 'react-i18next';

const AccountBlogger = ({ t }) => {
  const dispatch = useDispatch();
  const [goToFirstPage, setGoToFirstPage] = useState(false);
  const accountList = useSelector(
    state => state.instagramAccountsBlogger.listAccount,
  );
  const history = useHistory();
  const statusLoader = useSelector(state => globalLoaderStatusSelector(state));
  useEffect(() => {
    setGoToFirstPage(e => !e);
  }, [accountList]);
  useEffect(() => {
    dispatch(getAccountInstagramListBlogger());
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (window.location.search) {
        const message = window.location?.search
          ?.split('=')[1]
          ?.split('+')
          .join(' ');
        dispatch(
          openModal({
            isOpen: true,
            renderType: modalTypes.getText,
            details: {
              text: message,
              onClick: () => {
                history.push('/account-blogger');
              },
            },
          }),
        );
      }
    }, 500);
  }, []);
  const sizeItemOnPage = 9;
  const getActiveStatusKey = (verified, isScreenShotsApprove, id) => {
    if (
      (verified && isScreenShotsApprove === stateScreenShotsApprove.UNSET) ||
      isScreenShotsApprove === stateScreenShotsApprove.REJECTED_APPROVE
    ) {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.instagramStatisticsApprove,
          details: { id, type: 'ALL' },
        }),
      );
    } else if (isScreenShotsApprove === stateScreenShotsApprove.APPROVED) {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.instagramStatisticsApprove,
          details: { id, type: 'UPDATE_SCREEN' },
        }),
      );
    } else if (typeof verified !== 'boolean') {
    } else if (!verified) {
      dispatch(reVerification(id));
    }
  };

  const openUpdatePrise = id => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.statisticsBlogger,
        details: { id },
      }),
    );
  };
  const item = ({ avatar, isScreenShotsApprove, userName, verified, _id }) => (
    <>
      <div className="account-blogger__item-head">
        <img
          className="account-blogger__item-logo"
          src={avatar ? avatar  : paywallAvatar}
          onError={e => {
            e.target.onerror = null;
            e.target.src = paywallAvatar;
          }}
          alt={'logo'}
        />
        <p className="account-blogger__item-name">{userName}</p>
        {typeof verified === 'boolean' ? (
          verified ? (
            <div className="account-blogger__item-active" />
          ) : (
            <div className="account-blogger__item-rejected" />
          )
        ) : (
          <div className="account-blogger__item-pending" />
        )}
      </div>
      <div className="account-blogger__item-body">
        {verified &&
          <p>Approved</p>
        }
        {!verified &&
          <p>Waiting for approve</p>
        }
        <button
          className={`account-blogger__item-button account-blogger__item-button-remove ${
            isScreenShotsApprove === stateScreenShotsApprove.WAIT_FOR_APPROVE
              ? 'account-blogger__item-button-disabled'
              : ''
          }`}
          onClick={() => {
            isScreenShotsApprove !== stateScreenShotsApprove.WAIT_FOR_APPROVE &&
              dispatch(deleteInstagramAccount(_id));
          }}
        >
          {t('account_delete_account')}
        </button>
      </div>
    </>
  );
  const getAllItems = () =>
    accountList.items?.map((e, index) => (
      <div className="account-blogger__item" key={index + 'instagramItem'}>
        {item(e)}
      </div>
    ));
  return (
    <div className="account-blogger">
      {accountList.count !== 0 ? (
        <div>
          <div className="account-blogger__container">{getAllItems()}</div>
          <Pagination
            items={accountList}
            elementType="page"
            sizeItemOnPage={sizeItemOnPage}
            getNextPageItems={e => {
              dispatch(getAccountInstagramListBlogger(sizeItemOnPage, e));
            }}
            initialPage={goToFirstPage ? 0 : null}
          />
        </div>
      ) : (
        <div>
          {<ErrorComponent type={typesErrorComponent.NOT_INSTAGRAM_ACCOUNT} />}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(AccountBlogger);
