import React, { useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import './styles.scss';

const Range = ({ options = {}, onChange }) => {
  const {
    labelText = '',
    type = 'age',
    maxSize = 65,
    minSize = 13,
    activeMax,
    activeMin,
  } = options;
  const [value, setValue] = useState({
    min: activeMin ? activeMin : minSize,
    max: activeMax ? activeMax : maxSize,
  });
  useEffect(() => {
    document
      .getElementsByClassName('input-range__slider')[0]
      .classList.add('first-element-range');
    if (activeMax && activeMin) {
      onChange({ min: activeMin, max: activeMax });
    }
  }, []);
  useEffect(() => {
    if (value.min === 64) {
      document.getElementsByClassName(
        'input-range__label-container',
      )[2].style.display = 'none';
    }
    if (value.min === 55) {
      document.getElementsByClassName(
        'input-range__label-container',
      )[2].style.display = 'block';
    }
  }, [value]);

  const checkingAge = ({ min, max }) => {
    if (min <= 13) {
      min = 13;
    } else if (18 >= min || min < 24) {
      min = 18;
    } else if (25 >= min || min < 35) {
      min = 25;
    } else if (35 >= min || min < 45) {
      min = 35;
    } else if (45 >= min || min < 55) {
      min = 45;
    } else if (55 >= min || min < 64) {
      min = 55;
    } else if (56 >= min) {
      min = 65;
    }

    if (max <= 17) {
      max = 17;
    } else if ((24 >= max || max < 34) && min !== 25) {
      max = 24;
    } else if ((34 >= max || max < 44) && min !== 35) {
      max = 34;
    } else if ((44 >= max || max < 54) && min !== 45) {
      max = 44;
    } else if ((54 >= max || max < 64) && min !== 55) {
      max = 54;
    } else if (64 >= max && min !== 65) {
      max = 64;
    } else {
      max = 65;
    }
    onChange({ min, max, type });
    setValue({ min, max });
  };
  const _onChange = e => {
    if (type === 'age') {
      checkingAge(e);
    } else {
      setValue(e);
      onChange(e);
    }
  };
  return (
    <div className="input-range__container">
      <label className="input-range__label">
        <p>{labelText}</p>
      </label>
      <InputRange
        formatLabel={v => (v === 64 && value.max !== 64 ? '65+' : `${v}`)}
        step={1}
        maxValue={maxSize}
        minValue={minSize}
        value={value}
        onChange={_onChange}
      />
    </div>
  );
};

export default Range;
