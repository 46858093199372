import { types } from './accountBloggerTypes';
const initialState = {
  listAccount: {
    items: [],
  },
};

export const instagramAccountsBlogger = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.UPDATE_ACCOUNT_LIST:
      return { ...state, listAccount: payload };

    default:
      return state;
  }
};

export default instagramAccountsBlogger;
