import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  SubHeader,
  Benefits,
  Earnings,
  Banner,
  Blogger,
  Brands,
} from './component/index';
import { setAuthenticationStep } from '../../../../redux/store/session/sessionOperations';
import { sessionAuthenticationSteps } from '../../../../constants/sessionAuthenticationSteps';
import { openModal } from '../../../../redux/store/modal/modalActions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Bloggers = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  useEffect(() => {
    if (window.location.pathname.includes('registration')) {
      dispatch(setAuthenticationStep(sessionAuthenticationSteps.email));
      dispatch(openModal({ renderType: 'AUTH', isOpen: true }));
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Make money on advertising posts - Bloggerly</title>
        <meta
          name="description"
          content="Our service will allow you to easily make money on advertising posts and stories, naturally and completely safe!"
        />
      </Helmet>
      <SubHeader t={t} />
      <Benefits t={t} />
      <Blogger t={t} />
      <Earnings t={t} />
      <Brands t={t} />
      <Banner t={t} />
    </>
  );
};

export default Bloggers;
