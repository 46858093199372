import React from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../../../redux/store/modal/modalActions';

import { getLastWord } from '../../../../helpers/getLastWord';
import { modalTypes } from '../../../../configs/modalTypes';

import { ReactComponent as CloseIcon } from '../../../../assets/images/vectors/close.svg';
import './styles.scss';

const typeOfVideo = ['mp4', 'mov'];

const UploadedFile = ({
  fileResetHandler,
  selectedFile,
  name,
  handleUploadClick,
  approved,
  status,
  errors,
}) => {
  const dispatch = useDispatch();

  const activeInfo = status === 'ACTIVE' && approved;
  const handleCheckStatus = approved === null || activeInfo || approved;
  const video = typeOfVideo.includes(getLastWord(name));

  return (
    <div className="stories-custom">
      <p className="stories-custom__title">Photo/Video</p>
      <div className="stories-custom__header">
        <p className="stories-custom__header-text">
          Video: .mp4 or .mov 900x1600, 720x1280, 600x1067 (no more than 50MB)
          <br /> Photo: .jpg, .jpeg, .png, .gif 600x600, 720x1280, 600x1067 (no
          more than 10MB)
        </p>
        <input
          disabled={handleCheckStatus}
          id="file-upload__contained"
          className="file-upload__input"
          accept=".mp4,.mov,.jpg,.png,.gif,.jpeg"
          multiple
          type="file"
          onChange={handleUploadClick}
        />
        <div className="file-upload__container">
          <label htmlFor="file-upload__contained">
            <div disabled={handleCheckStatus} className="file-upload__button">
              Upload
            </div>
          </label>
        </div>
      </div>
      <div className="uploaded-container">
        <div
          className={
            errors
              ? 'stories-custom__footer-background__errors'
              : 'stories-custom__footer-background'
          }
        >
          <div
            onClick={() =>
              dispatch(
                openModal({
                  isOpen: true,
                  renderType: modalTypes.showCustomContent,
                  details: { selectedFile, name },
                }),
              )
            }
          >
            {!video ? (
              <img
                className="uploaded-file"
                src={selectedFile}
                alt="uploaded"
              />
            ) : (
              <>
                <video
                  className="uploaded-file"
                  src={selectedFile}
                  alt="uploaded"
                />
                <span className="uploaded-file__watch" />
              </>
            )}
          </div>
          {selectedFile &&
            (handleCheckStatus ? null : (
              <CloseIcon
                onClick={fileResetHandler}
                className="stories-custom__footer-background-delete"
              />
            ))}
        </div>
      </div>
      <p className="stories-custom__footer-name">{name}</p>
    </div>
  );
};

export default UploadedFile;
