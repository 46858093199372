import React from 'react';
import Slider from 'react-slick';

import BlogItemComponent from '../BlogItem';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles.scss';

const CustomSlider = ({ items }) => {
  const settings = {
    className: 'custom-slider',
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    fade: true,
  };

  return (
    <div className="slider-container">
      <h1 className="slider-container-title">Related posts</h1>
      <Slider {...settings}>
        {items.map(news => (
          <BlogItemComponent key={news._id} news={news} />
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;
