import React from 'react';

import './styles.scss';
import '../general.scss';

const Input = ({
  options = {},
  inputChangeOptions = {},
  errors = false,
  onHandleClick,
  onFocus = () => {},
  onBlur = () => {},
}) => {
  const {
    labelText = '',
    placeholderText = '',
    placeholderTextLast = '',
    descriptionText = '',
    dynamicText = '',
    renderType = 'single',
    typeText = 'text',
    approved = false,
    status = 'DRAFT',
    isStory = false,
    isDisabled = false,
    onKeyDown = () => {},
    maxLength,
    width,
    accept
  } = options;

  const approvedStory = isStory && approved;
  const activeInfo = status === 'ACTIVE' && approved;
  return (
    <div className="custom-input__container">
      <label className="custom-input__label">{labelText}</label>
      <div className="custom-input-container">
        {renderType === 'single' && (
          <>
            <input
            style = {{width: width}}
              onClick={onHandleClick}
              readOnly={approved === null || activeInfo || approvedStory}
              {...inputChangeOptions}
              placeholder={placeholderText}
              disabled={isDisabled}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              accept={accept}
              maxLength={maxLength}
              className={
                errors
                  ? 'custom-input__erros custom-input__single-item'
                  : 'custom-input custom-input__single-item'
              }
              type={typeText}
            />
            {dynamicText && (
              <p className="custom-input__single-item-dynamic-text">
                {dynamicText}
              </p>
            )}
            {descriptionText && (
              <p className="field__description">{descriptionText}</p>
            )}
          </>
        )}
        {renderType === 'dual' && (
          <div className="custom-input__dual">
            <input
              placeholder={placeholderText}
              className="custom-input custom-input__dual-item"
              type="text"
            />
            <input
              placeholder={placeholderTextLast}
              className="custom-input custom-input__dual-item"
              type="text"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
