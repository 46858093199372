import React from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';
import { partnership } from './config';

import Break from '../../../../../../assets/images/landing/global/break.svg';
import './styles.scss';

const Partnership = ({ openModal, setAuthenticationStep, t }) => {
  return (
    <div className="partnership">
      <div className="partnership__title">
        {t('referrals_landing_partnership_title')}
      </div>
      <div className="partnership__subtitle">
        {t('referrals_landing_partnership_subtitle')}
      </div>
      <div className="partnership__content">
        {partnership.map(
          ({ img, alt, percent, subtitle, title, text }, index) => (
            <div key={index} className="partnership__content-block">
              <img
                src={img}
                alt={alt}
                className="partnership__content-block-img"
              />
              <div className="partnership__content-block-title">{t(title)}</div>
              <div className="partnership__content-block-text">{t(text)}</div>
              <div className="partnership__content-block-percent">
                {t(percent)}
              </div>
              <div className="partnership__content-block-subtitle">
                {t(subtitle)}
              </div>
            </div>
          ),
        )}
      </div>
      <div className="partnership__bottom">
        <img src={Break} alt="Break" className="partnership__bottom-break" />
        <div className="partnership__bottom-title">
          {t('referrals_landing_partnership_button_title')}
        </div>
        <span
          onClick={() =>
            handleRegistrationButtonClick(setAuthenticationStep, openModal)
          }
          className="partnership__bottom-button"
        >
          {t('referrals_landing_partnership_button')}
        </span>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(Partnership);
