import React from 'react';
import { useGetUserRole } from '../../../hooks/getUserRole';
import WithdrawForm from '../../pages/finance/balanceForm/withdrawForm/WithdrawForm';
import RefillForm from '../../pages/finance/balanceForm/refillForm/refillForm';
import './styles.scss';

const QuickRefillPopUp = ({ closeModal, details }) => {
  const role = useGetUserRole();

  return (
    <div className={'refill-popup'}>
      {role === 'BLOGGER' ? <WithdrawForm /> : <RefillForm />}
    </div>
  );
};
export default QuickRefillPopUp;
