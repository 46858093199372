import React from 'react';
import dayjs from 'dayjs';

import FormContainer from '../common/FormContainer/FormContainer';

const OfferComments = ({ details: { comments, name } }) => {
  return (
    <FormContainer>
      <div className="form-block">
        {comments.map(({ message, createdAt, _id }) => (
          <div className="offer-comments" key={_id}>
            <p className="offer-comments__message">{message}</p>
            <p className="offer-comments__date">
              {dayjs(createdAt).format('D MMMM HH:mm')}
            </p>
            <p className="offer-comments__blogger">{name}</p>
          </div>
        ))}
      </div>
    </FormContainer>
  );
};

export default OfferComments;
