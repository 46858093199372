import { sessionTypes } from './sessionTypes';

export const sessionLoginStart = () => ({
  type: sessionTypes.SESSION_LOGIN_START,
});

export const sessionLoginSuccess = data => ({
  type: sessionTypes.SESSION_LOGIN_SUCCESS,
  payload: data,
});

export const sessionLoginError = err => ({
  type: sessionTypes.SESSION_LOGIN_ERROR,
  payload: err,
});

export const sessionPhoneNumberRequest = () => ({
  type: sessionTypes.SESSION_PHONE_NUMBER_START,
});

export const sessionPhoneNumberSuccess = data => ({
  type: sessionTypes.SESSION_PHONE_NUMBER_SUCCESS,
  payload: data,
});

export const sessionPhoneNumberError = data => ({
  type: sessionTypes.SESSION_PHONE_NUMBER_ERROR,
  payload: data,
});

export const sessionConfirmationCodeRequest = () => ({
  type: sessionTypes.SESSION_CONFIRMATION_CODE_START,
});

export const sessionConfirmationCodeSuccess = data => ({
  type: sessionTypes.SESSION_CONFIRMATION_CODE_SUCCESS,
  payload: data,
});

export const sessionConfirmationCodeError = data => ({
  type: sessionTypes.SESSION_CONFIRMATION_CODE_ERROR,
  payload: data,
});

export const sessionAdvertiserPersonalDataRequest = () => ({
  type: sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_START,
});

export const sessionAdvertiserPersonalDataSuccess = data => ({
  type: sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_SUCCESS,
  payload: data,
});

export const sessionAdvertiserPersonalDataError = data => ({
  type: sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_ERROR,
  payload: data,
});

export const changeAuthenticationStep = step => ({
  type: sessionTypes.CHANGE_AUTHENTICATION_STEP,
  payload: step,
});

export const resendConfirmationCodeStart = () => ({
  type: sessionTypes.RESEND_CONFIRMATION_CODE_START,
});

export const resendConfirmationCodeSuccess = code => ({
  type: sessionTypes.RESEND_CONFIRMATION_CODE_SUCCESS,
  payload: code,
});

export const resendConfirmationCodeError = error => ({
  type: sessionTypes.RESEND_CONFIRMATION_CODE_ERROR,
  payload: error,
});

export const sessionlogOut = () => ({
  type: sessionTypes.SESSION_LOGOUT,
});

export const setFcmToken = data => ({
  type: sessionTypes.SESSION_SET_FCM_TOKEN,
  payload: data,
});

export const getInformationAboutUserStart = () => ({
  type: sessionTypes.GET_INFORMATION_ABOUT_USER_START,
});

export const getInformationAboutUserSuccess = data => ({
  type: sessionTypes.GET_INFORMATION_ABOUT_USER_SUCCESS,
  payload: data,
});

export const getInformationAboutUserError = () => ({
  type: sessionTypes.GET_INFORMATION_ABOUT_USER_ERROR,
});

export const putUserByIdStart = () => ({
  type: sessionTypes.PUT_USER_BY_ID_START,
});

export const putUserByIdSuccess = data => ({
  type: sessionTypes.PUT_USER_BY_ID_SUCCESS,
  payload: data,
});

export const putUserByIdError = error => ({
  type: sessionTypes.PUT_USER_BY_ID_ERROR,
  payload: error,
});

export const clearNewEmail = () => ({
  type: sessionTypes.CLEAR_NEW_EMAIL,
});
