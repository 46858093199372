import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { getApprovedAccounts } from '../../../api/instagram-service/instagram-service';
import { withTranslation } from "react-i18next";
import { openModal } from '../../../redux/store/modal/modalActions';
import {
  editDirection,
  editOffset,
  editSearch,
  editSort,
} from '../../../redux/store/instagram/instagramOperations';
import {
  getOptionsSearchSelector,
  instagramSelector,
} from '../../../redux/store/instagram/instagramSelector';
import {
  instagramAprrovedAccountsSelector,
  sessionPersonalInformationSelector,
} from '../../../redux/selectors/selectors';

import HeaderListBlogger from '../../component/HeaderListBlogger/HeaderListBlogger';
import BloggerCard from '../../component/renderCards/BlogerCard/BlogerCard';
import ErrorComponent from '../../component/ErrorComponent/ErrorComponent';
import { typesErrorComponent } from '../../../configs/errorComponentTypes';
import { useSearchFromParams } from '../../../hooks/useSearchFromParams';
import Pagination from '../../component/common/Pagination/Pagination';
import Paywall from '../../component/common/Fields/Paywall/Paywall';
import { modalTypes } from '../../../configs/modalTypes';

import './styles.scss';
import {
  clearAllParams,
  setOffset,
  setOffsetCount,
  setSearchValue,
  setDirectionValue,
  getInstagramApprovedAccountsSuccess,
  setSortType,
  getInstagramApprovedAccountsError,
} from '../../../redux/store/instagram/instagramActions';
import { changeGlobalLoaderStatus } from '../../../redux/store/globalLoader/globalLoaderActions';
import {get} from '../../../api/apiService'

const BloggerList = ({ t }) => {
  const BloggerList = useSelector(instagramAprrovedAccountsSelector);
  const { isPaywalled } = useSelector(sessionPersonalInformationSelector);
  const prevSearchValue = useSelector(getOptionsSearchSelector);
  const {
    offset,
    offsetCount,
    sortParams,
    directionParams,
    searchValue,
  } = useSelector(instagramSelector);

  const [userNameValue, setUserNameValue] = useState('');
  const query = useSearchFromParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const limit = 6;

  const dirUrl = query.get('direction');
  const searchUrl = query.get('search');
  const sortUrl = query.get('sort');
  const page = query.get('page');

  useEffect(() => {
    //get('instagram/approved-accounts').then((res)=>console.log(res))
    const offset = page ? `&offset=${Math.ceil(page - 1) * 6}` : '&offset=0';
    const sort = sortUrl ? `&sort=${sortUrl}` : '&sort=subscribersCount';
    const direction = dirUrl ? `&direction=${dirUrl}` : '&direction=1';
    const search = searchUrl ? `&userName=${searchUrl}` : '';
    const limit = 'limit=6';

    dispatch(changeGlobalLoaderStatus(true));
    getApprovedAccounts(limit + sort + direction + offset + search)
      .then(
        response => dispatch(getInstagramApprovedAccountsSuccess(response)),
        dispatch(setSearchValue(searchUrl)),
        dispatch(setSortType(sort)),
        dispatch(setDirectionValue(direction)),
        dispatch(setOffset(offset)),
        dispatch(setOffsetCount((page - 1) * 6)),
      )
      .catch(({ message }) => {
        dispatch(getInstagramApprovedAccountsError(message));
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
    return () => dispatch(clearAllParams());
  }, []);

  useEffect(() => {
    const sort = `?${sortParams?.substr(1)}`;
    const page = `&page=${Math.ceil(offsetCount / limit + 1) || 1}`;
    const search = searchValue ? `&search=${searchValue}` : '';

    history.push(sort + directionParams + page + search);
  }, [searchValue, history, directionParams, sortParams, offsetCount]);

  const _onChengSort = ({ value }) => {
    dispatch(editSort(value));
  };
  const _onClickSearch = () => {
    if (prevSearchValue !== userNameValue) {
      dispatch(editSearch(userNameValue));
    }
  };
  const _onChengSearch = ({ target }) => {
    setUserNameValue(target.value);
  };
  const _onChengDirection = ({ value }) => {
    dispatch(editDirection(value));
  };
  const _onClickFilter = () => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.manualSearchBloggersFilter,
      }),
    );
  };
  const getNotFound = () => {
    return BloggerList?.items.length === 0 ? (
      <ErrorComponent type={typesErrorComponent.NOT_FOUND_SEARCH} />
    ) : null;
  };

  return (
    <div className="bloggers">
      <HeaderListBlogger
        onClickSearch={_onClickSearch}
        onChengSearch={_onChengSearch}
        onChengDirection={_onChengDirection}
        onChengSort={_onChengSort}
        onClickFilter={_onClickFilter}
      />
      {isPaywalled && <Paywall title="blogger_list_paywalled" t={t} />}
      <div className="bloggers__list">
        {BloggerList.items.map(blogger => (
          <div key={blogger._id} className="bloggers__list-item">
            <BloggerCard
              isPaywalled={isPaywalled}
              type={'tertiary'}
              bloggerInformation={blogger}
              t={t}
            />
          </div>
        ))}
      </div>
      {getNotFound()}
      <Pagination
        items={BloggerList}
        elementType="page"
        sizeItemOnPage={limit}
        getNextPageItems={e => {
          dispatch(editOffset(e));
        }}
        isStartedPage={offset}
        initialPage={page - 1 || 0}
      />
    </div>
  );
};

export default withTranslation()(BloggerList);
