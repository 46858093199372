import * as api from '../../../../api/advertisement-service/advertisement-service';

import * as baseInformationActions from '../baseInformation/baseInformationActions';
import * as creationStatusAction from '../creationStatus/creationStatusActions';
import * as globalLoaderActions from '../../globalLoader/globalLoaderActions';
import * as campaignActions from '../../campaign/campaignActions';
import * as advertisementActions from './advertisementActions';
import { setNotifyStatus } from '../../notify/notifyActions';

import { advertisementCreationSteps } from '../../../../constants/advertisementCreationSteps';
import { clearChoiseBloggersInformation } from '../choiceBloggers/choiceBloggersActions';
import { setAcutalBloggerList } from '../choiceBloggers/choiceBloggersOperations';
import { addNewStepInformation } from '../creationData/creationDataActions';

import book from '../../../../views/router/book';

// post
export const postAdvertisementCopy =
  (id, campaignId, history, type = 'campaign') =>
  dispatch => {
    dispatch(advertisementActions.postAdvertisementCopyStart());
    dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

    return api
      .postAdvertisementDuplicate(id)
      .then(response => {
        dispatch(advertisementActions.postAdvertisementCopySuccess(response));
        dispatch(campaignActions.clearAllParams());
        dispatch(advertisementActions.clearAllParams());
        dispatch(
          setNotifyStatus({
            title: 'Your advertisement was successfully copied',
            type: 'success',
          }),
        );
        if (type === 'advertisement') {
          return history.push(
            `${book.myCompanies}/${campaignId}/advertisements`,
          );
        } else {
          return dispatch(fetchAdvertisementStatistic());
        }
      })
      .catch(({ message }) => {
        dispatch(advertisementActions.postAdvertisementCopyError(message));
      })
      .finally(() =>
        dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
      );
  };

// get
export const fetchAdvertisement =
  (id, params = '') =>
  (dispatch, getState) => {
    const {
      advertisement: {
        advertisement: {
          params: { sortParams = '', orderParams = '', statusParams = '' },
        },
      },
    } = getState();
    dispatch(advertisementActions.getAdvertisementStart());
    dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

    return api
      .getAdvertisementById(
        id + '?limit=6' + sortParams + statusParams + orderParams + params,
      )
      .then(response => {
        dispatch(advertisementActions.getAdvertisementSuccess(response));
      })
      .catch(({ message }) => {
        dispatch(advertisementActions.getAdvertisementError(message));
      })
      .finally(() =>
        dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
      );
  };

export const fetchAdvertisementStatistic =
  (params = '') =>
  (dispatch, getState) => {
    const {
      advertisement: {
        advertisement: {
          params: {
            sortParams = '',
            dateFromParams = '',
            dateToParams = '',
            orderParams = '',
          },
        },
      },
    } = getState();

    dispatch(advertisementActions.getAdvertisementStatisticStart());
    dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

    return api
      .getAdvertisementStatistic(
        '?limit=10' +
          sortParams +
          orderParams +
          dateFromParams +
          dateToParams +
          params,
      )
      .then(response => {
        dispatch(
          advertisementActions.getAdvertisementStatisticSuccess(response),
        );
      })
      .catch(({ message }) => {
        dispatch(advertisementActions.getAdvertisementStatisticError(message));
      })
      .finally(() =>
        dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
      );
  };

// set
export const editSort = data => dispatch => {
  dispatch(advertisementActions.setSortType(`&sortBy=${data}`));
  dispatch(fetchAdvertisementStatistic());
};

export const editOrder = data => dispatch => {
  dispatch(advertisementActions.setOrderType(`&orderBy=${data}`));
  dispatch(fetchAdvertisementStatistic());
};

export const editOffset = data => dispatch => {
  dispatch(advertisementActions.setOffset(`&offset=${data}`));
  const url = `&offset=${data}`;
  dispatch(fetchAdvertisementStatistic(url));
};

export const editDateFrom = data => dispatch => {
  dispatch(advertisementActions.setDateFrom(`&dateFrom=${data}`));
  dispatch(fetchAdvertisementStatistic());
};

export const editDateTo = data => dispatch => {
  dispatch(advertisementActions.setDateTo(`&dateTo=${data}`));
  dispatch(fetchAdvertisementStatistic());
};

export const editSortItem = (id, data) => dispatch => {
  dispatch(advertisementActions.setSortType(`&sortBy=${data}`));
  dispatch(fetchAdvertisement(id));
};

export const editOrderItem = (id, data) => dispatch => {
  dispatch(advertisementActions.setOrderType(`&orderBy=${data}`));
  dispatch(fetchAdvertisement(id));
};

export const editStatusItem = (id, data) => dispatch => {
  dispatch(advertisementActions.setStatus(`&status=${data}`));
  dispatch(fetchAdvertisement(id));
};

export const editOffsetItem = (id, data) => dispatch => {
  dispatch(advertisementActions.setOffset(`&offset=${data}`));
  const url = `&offset=${data}`;
  dispatch(fetchAdvertisement(id, url));
};

// delete
export const deleteAdvertisement = id => dispatch => {
  dispatch(advertisementActions.deleteAdvertisementStart());

  return api
    .deleteAdvertisementById(id)
    .then(response => {
      dispatch(advertisementActions.deleteAdvertisementSuccess(response));
      dispatch(
        setNotifyStatus({
          title: 'Your advertisement was successfully deleted',
          type: 'success',
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(advertisementActions.deleteAdvertisementError(message));
    });
};

// set current step
export const setCurrentStep = (advertisement, story, type) => dispatch => {
  dispatch(setAcutalBloggerList(advertisement.offers));
  dispatch(campaignActions.selectedCampaign(advertisement.campaign));
  dispatch(addNewStepInformation(advertisement));
  dispatch(baseInformationActions.setStoriesList(story));

  if (
    !advertisement.budget &&
    !advertisement.offers.length &&
    !story.approved
  ) {
    dispatch(
      creationStatusAction.changeCreationAdvertisementStep({
        activePage: advertisementCreationSteps.base,
        activeStep: 0,
      }),
    );
  } else if (type === 'edit') {
    dispatch(
      creationStatusAction.changeCreationAdvertisementStep({
        activePage: advertisementCreationSteps.base,
        activeStep: 0,
        typeOperation: 'edit',
      }),
    );
  } else if (
    !advertisement.budget &&
    !advertisement.offers.length &&
    story.approved
  ) {
    dispatch(
      creationStatusAction.changeCreationAdvertisementStep({
        activePage: advertisementCreationSteps.manual,
        activeStep: 1,
        doneSteps: [0],
      }),
    );
  } else if (!advertisement.budget) {
    dispatch(
      creationStatusAction.changeCreationAdvertisementStep({
        activePage: advertisementCreationSteps.budget,
        activeStep: 2,
        doneSteps: [0, 1],
      }),
    );
  } else if (advertisement.status === 'ACTIVE') {
    dispatch(
      creationStatusAction.changeCreationAdvertisementStep({
        activePage: advertisement.typeOfAllocation,
        activeStep: 1,
        doneSteps: [0, 1, 2, 3],
      }),
    );
  } else {
    dispatch(
      creationStatusAction.changeCreationAdvertisementStep({
        activePage: advertisementCreationSteps.advertisementLaunch,
        activeStep: 3,
        doneSteps: [0, 1, 2],
      }),
    );
  }
};

// clear
export const clearInformationAdboutAdvertisement = () => dispatch => {
  dispatch(creationStatusAction.clearCreationAdvertisementStep());
  dispatch(baseInformationActions.clearBaseInformation());
  dispatch(campaignActions.deleteSelectedCampaign());
  dispatch(clearChoiseBloggersInformation());
  dispatch(advertisementActions.clearAdvertisement());
};
