export const categoriesNews = [
  { text: 'blog_item_latest', key: 'LATEST_NEWS' },
  { text: 'blog_item_about', key: 'ABOUT_SERVICES' },
  { text: 'blog_item_analytics', key: 'ANALYTICS' },
  { text: 'blog_item_guides', key: 'GUIDES' },
  { text: 'blog_item_interviews', key: 'INTERVIEWS' },
  { text: 'blog_item_market', key: 'MARKET_NEWS' },
  { text: 'blog_item_cases', key: 'CASES' },
  { text: 'blog_item_reviews', key: 'REVIEWS' },
];
