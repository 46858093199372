import React from 'react';

import DevelopmentProcess from '../../component/developmentProcess/DevelopmentProcess';

const Profile = () => {
  return (
    <div>
      <DevelopmentProcess name={'Profile'} />
    </div>
  );
};

export default Profile;
