import React from 'react';

const PaymentMethodOptionTemplateItem = option => {
  const { label, image, t } = option;

  return (
    <div className="payment-item-container">
      <div className="payment-item__pointer" />
      <div className="payment-item__brand-logo-container">
        <img className="payment-item__brand-logo" src={image} alt={''} />
      </div>
      <p className="payment-item__brand-name">{t(label)}</p>
    </div>
  );
};

export default PaymentMethodOptionTemplateItem;
