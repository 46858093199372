import headerLandingPage from './landingPage/headerLandingPage.json';
import advertisersLandingPage from './landingPage/advertisersLandingPage.json';
import bloggersLandingPage from './landingPage/bloggersLandingPage.json';
import referralsLandingPage from './landingPage/referralsLandingPage.json';
import footerLandingPage from './landingPage/footerLandingPage.json';
import blog from './blog.json';
import auntification from './auntification.json';
import home from './home.json';
import myCampaigns from './myCampaigns.json';
import statistics from './statistics.json';
import finance from './finance.json';
import referral from './referral.json';
import support from './support';
import settings from './settings.json';
import accountBlogger from './accountBlogger.json';
import navigation from './navigation.json';
import listBloggers from './listBloggers.json';
import errorComponent from './errorComponent.json';
import createAdvertisement from './createAdvertisement.json';

const translation = {
  ...headerLandingPage,
  ...advertisersLandingPage,
  ...bloggersLandingPage,
  ...referralsLandingPage,
  ...footerLandingPage,
  ...blog,
  ...auntification,
  ...home,
  ...myCampaigns,
  ...statistics,
  ...finance,
  ...referral,
  ...support,
  ...settings,
  ...accountBlogger,
  ...navigation,
  ...listBloggers,
  ...errorComponent,
  ...createAdvertisement,
};
export default translation;
