import {
  getAdvertiserBudget,
  getAdvertiserTransactionHistory,
  getBloggerBudget,
  getBloggerTransactionHistory,
  postRefillAdvertiser,
  postWithdrawalBlogger,
} from '../../../api/finance-servise/finance-servise';
import dayjs from 'dayjs';
import {
  advertiserBlogger,
  advertiserBudget,
  errorRefill,
  errorWithdrawal,
  transactionHistoryAdvertiser,
  transactionHistoryBlogger,
} from './financeAction';
import { getTextTypeTransaction } from '../../../utilites/globalUtilites';
import { getCurrentUserInformation } from '../../../api/session-service/session-service';
import { sessionAdvertiserPersonalDataSuccess } from '../session/sessionActions';
import { setNotifyStatus } from '../notify/notifyActions';
import { instagramAprrovedAccountsSelector } from '../../selectors/selectors';
import { fetchInstagramApprovedAccounts } from '../instagram/instagramOperations';
import { isSupportedNotification } from '../../../helpers/firebase/isSupportedNotification';
import { changeGlobalLoaderStatus } from '../globalLoader/globalLoaderActions';
import { setErrorHandler } from '../errorHandler/errorHandlerOperations';
import { openModal } from '../modal/modalActions';
import { modalTypes } from '../../../configs/modalTypes';

export const setTransactionHistoryBlogger = (
  offset,
  sortBy,
  orderBy,
  dateTo,
  dateFrom,
) => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getBloggerTransactionHistory(
    10,
    offset,
    sortBy,
    orderBy,
    'ALL',
    dateTo,
    dateFrom,
  )
    .then(({ items, count }) => {
      const body = items.map(({ amount, createdAt, type, status }, index) => {
        return {
          number: index,
          operation: getTextTypeTransaction(type),
          date: dayjs(createdAt).format('D MMMM HH:mm'),
          status: status ? status : '----',
          sum: amount,
        };
      });
      dispatch(transactionHistoryBlogger({ body, count }));
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
export const setTransactionHistoryAdvertiser = (
  offset,
  sortBy,
  orderBy,
  dateTo,
  dateFrom,
) => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getAdvertiserTransactionHistory(
    10,
    offset,
    sortBy,
    orderBy,
    'ALL',
    dateTo,
    dateFrom,
  )
    .then(({ items, count }) => {
      const body = items.map(({ amount, createdAt, type, status }, index) => {
        return {
          number: index,
          operation: getTextTypeTransaction(type),
          date: dayjs(createdAt).format('D MMMM HH:mm'),
          status: status ? status : '----',
          sum: amount,
        };
      });
      dispatch(transactionHistoryAdvertiser({ body, count }));
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};

export const setAdvertiserBudget = () => dispatch => {
  getAdvertiserBudget()
    .then(r => {
      dispatch(advertiserBudget(r));
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally();
};

export const setBloggerBudget = () => (dispatch, getState) => {
  const user = localStorage.getItem('accaunt');
  const personalInformation = JSON.parse(user);
  getBloggerBudget()
    .then(r => {
      dispatch(advertiserBlogger(r));
      const updateUser = {
        ...personalInformation,
        budget: r.available,
        notification: isSupportedNotification() ? Notification.permission : '',
      };
      localStorage.setItem('accaunt', JSON.stringify(updateUser));
      dispatch(sessionAdvertiserPersonalDataSuccess(updateUser));
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally();
};

export const updateUserAfterRefill = (type, location) => (
  dispatch,
  getState,
) => {
  const { isPaywalled } = instagramAprrovedAccountsSelector(getState());
  getCurrentUserInformation()
    .then(user => {
      localStorage.setItem('accaunt', JSON.stringify(user));
      dispatch(sessionAdvertiserPersonalDataSuccess(user));
      if (user.isPaywalled !== isPaywalled && location !== '/finance')
        dispatch(fetchInstagramApprovedAccounts('', type));
    })
    .catch(r => dispatch(setErrorHandler(r)));
};

export const refillOnPageFinance = (
  sum,
  reset = () => {},
  type,
  location,
) => dispatch => {
  postRefillAdvertiser(sum)
    .then(() => {
      dispatch(updateUserAfterRefill(type, location));
      reset();
      dispatch(setAdvertiserBudget());
      dispatch(setTransactionHistoryAdvertiser());
      dispatch(
        setNotifyStatus({
          title: `Your balance fiiled up on ${sum}$`,
          type: 'success',
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(errorRefill(message));
      dispatch(clearsError('Refill'));
    })
    .finally();
};
export const withdrawOnPageFinance = body => dispatch => {
  postWithdrawalBlogger(body)
    .then(() => {
      dispatch(setBloggerBudget());
      dispatch(setTransactionHistoryBlogger());
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.getText,
          details: {
            text: 'finance_blogger_success_info_text',
            onClick: () => {},
            buttonText: 'finance_blogger_success_info_button',
          },
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(errorWithdrawal(message));
      dispatch(clearsError('Withdrawal'));
    })
    .finally();
};
const clearsError = type => dispatch => {
  setTimeout(() => {
    if (type === 'Refill') {
      dispatch(errorRefill(null));
    } else {
      dispatch(errorWithdrawal(null));
    }
  }, 15000);
};
