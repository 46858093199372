import React, { createRef, useEffect } from 'react';

import close from '../../../assets/images/vectors/close.svg';
import './styles.scss';

export default function Modal({ children, onClose, status }) {
  const backdropRef = createRef();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  const handleKeyPress = e => {
    if (e.code !== 'Escape') return;

    onClose(false);
  };

  const handleBackdropClick = e => {
    const { current } = backdropRef;

    if (current && e.target !== current) return;
    onClose(false);
  };

  return (
    <div
      className={`backdrop ${status ? 'backdrop-visual' : 'backdrop-hidden'}`}
      ref={backdropRef}
      onClick={handleBackdropClick}
    >
      <div className={`modal ${status ? 'modal' : 'modal-not-show'}`}>
        {children}
        <img
          onClick={onClose}
          className="modal-close"
          src={close}
          alt="close"
        />
      </div>
    </div>
  );
}
