import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormState } from 'react-hook-form';

import { openModal } from '../../../../redux/store/modal/modalActions';

import Checkbox from '../../../component/common/Fields/Checkbox/CheckBox';
import FormContainer from '../../common/FormContainer/FormContainer';
import Select from '../../../component/common/Fields/Select/Select';
import { accauntSettings } from '../../../../schemas/settingsForm';
import Input from '../../../component/common/Fields/Input/Input';
import Button from '../../../component/common/Button/Button';
import { modalTypes } from '../../../../configs/modalTypes';
import {
  formingAnObjectForSendingForm,
  isEmptyObject,
} from '../../../../helpers/isEmptyObject';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const SettingsForm = ({
  putUserPersonalData,
  personalInformation,
  dispatch,
  loading,
  t,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(accauntSettings),
  });
  const {
    firstName,
    lastName,
    email,
    emailSubscribed,
    newEmail,
    role,
  } = personalInformation;

  useEffect(() => {
    if (emailSubscribed) setValue('emailSubscribed', emailSubscribed);
    if (firstName) setValue('firstName', firstName);
    if (lastName) setValue('lastName', lastName);
    if (email) setValue('email', email);
  }, [firstName, lastName, email, emailSubscribed, setValue]);

  const { dirtyFields } = useFormState({
    control,
  });

  const handleFormSubmit = data => {
    const newData = formingAnObjectForSendingForm(data, dirtyFields);
    dispatch(putUserPersonalData(newData));
    reset(data);
  };

  const changeEmailStep = () => {
    if (newEmail) {
      return dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.editEmailConfirmCode,
        }),
      );
    }
    return dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.editCurrentEmail,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="settings-form">
      <FormContainer button={''}>
        <div className="form-block-container">
          <div className="form-block form-block-part">
            <Input
              errors={errors?.firstName}
              options={{
                labelText: t('settings_first_label_name'),
                placeholderText: t('settings_first_placeholder_name'),
              }}
              inputChangeOptions={register('firstName')}
            />
          </div>
          <div className="form-block form-block-part">
            <Input
              errors={errors?.lastName}
              options={{
                labelText: t('settings_lest_label_name'),
                placeholderText: t('settings_lest_placeholder_name'),
              }}
              inputChangeOptions={register('lastName')}
            />
          </div>
        </div>
        {email && (
          <div className="form-block-container">
            <div className="edit-form__email">
              <span className="edit-form__email-title">
                {t('settings_email')}
              </span>
              <div
                tabIndex="1"
                onClick={changeEmailStep}
                className="edit-form__email-body"
              >
                {email}
              </div>
            </div>
            <div className="form-block form-block-part">
              <div className="edit-form__password">
                <span className="edit-form__password-title">
                  {t('settings_password')}
                </span>
                <div
                  tabIndex="0"
                  onClick={() =>
                    dispatch(
                      openModal({
                        isOpen: true,
                        renderType: modalTypes.editCurrentPassword,
                      }),
                    )
                  }
                  className="edit-form__password-body"
                >
                  ********
                </div>
              </div>
            </div>
          </div>
        )}

        {role !== 'BLOGGER' && (
          <div className="form-block">
            <div className="create-accaunt__terms">
              {!email ? (
                <div
                  tabIndex="2"
                  onClick={changeEmailStep}
                  className="settings-form__add-email custom-btn"
                >
                  {t('settings_add')}
                </div>
              ) : (
                <Checkbox inputChangeOptions={register('emailSubscribed')} />
              )}
              <p className="create-accaunt__terms-description">
                {t('settings_i_want')}
              </p>
            </div>
          </div>
        )}
      </FormContainer>
      <div className="settings-form__button-container">
        <Button
          loadingStatus={loading}
          options={{
            text: t('settings_save'),
            renderType: 'primary',
            type: 'submit',
            size: 'm',
            disabled: isEmptyObject(dirtyFields),
          }}
        />
      </div>
    </form>
  );
};

export default withTranslation()(SettingsForm);
