import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useForm, Controller, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';

import { changeCreationAdvertisementStep } from '../../../../../redux/store/advertisement/creationStatus/creationStatusActions';
import { editAdvertisement } from '../../../../../redux/store/advertisement/creationData/creationDataOperations';
import { closeModal } from '../../../../../redux/store/modal/modalActions';
import {
  newAdvertisementDataSelector,
  storyFormSelector,
} from '../../../../../redux/selectors/selectors';

import {
  isEmptyObject,
  formingAnObjectForSendingForm,
} from '../../../../../helpers/isEmptyObject';
import FormContainer from '../../../common/FormContainer/FormContainer';
import { editBaseInformationFormSchema } from '../../../../../schemas/editBaseInformationFormSchema';
import { advertisementCreationSteps } from '../../../../../constants/advertisementCreationSteps';
import { DateTimeSelect } from '../BaseInfoForm/Selects';
import Input from '../../../common/Fields/Input/Input';
import Button from '../../../common/Button/Button';

import storyImage from '../../../../../assets/images/dummy/storyImage.svg';
import storyVideo from '../../../../../assets/images/dummy/storyVideo.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const CampaignLaunchFormEditCommon = ({ t }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(editBaseInformationFormSchema),
  });

  const {
    _id,
    title,
    endDate,
    startDate,
    publishTimeFrom,
    publishTimeTo,
    banner,
    typeOfAllocation,
  } = useSelector(newAdvertisementDataSelector);
  const story = useSelector(storyFormSelector);

  const { dirtyFields } = useFormState({
    control,
  });

  const dispatch = useDispatch();
  dayjs.extend(utc);

  useEffect(() => {
    if (banner) setValue(banner);

    if (title && endDate && startDate && publishTimeFrom && publishTimeTo) {
      setValue('title', title);
      setValue('endDate', dayjs(endDate).format('YYYY-MM-DD'));
      setValue('startDate', dayjs(startDate).format('YYYY-MM-DD'));
      setValue('publishTimeFrom', dayjs(publishTimeFrom).format('hh:mm'));
      setValue('publishTimeTo', dayjs(publishTimeTo).format('hh:mm'));
    }
  }, []);

  const onSubmit = data => {
    const newData = {
      title: data.title,
      startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      publishTimeFrom: dayjs(
        new Date().setHours(...data.publishTimeFrom.split(':'), 0),
      ).format('YYYY-MM-DDTHH:mm:ssZ'),
      publishTimeTo: dayjs(
        new Date().setHours(...data.publishTimeTo.split(':'), 0),
      ).format('YYYY-MM-DDTHH:mm:ssZ'),
    };

    const updatedData = formingAnObjectForSendingForm(newData, dirtyFields);

    if (isEmptyObject(dirtyFields)) {
      dispatch(closeModal());
    } else {
      dispatch(editAdvertisement(_id, updatedData));
      dispatch(closeModal());
    }
  };

  const redirectToChoiceOfBloggers = () => {
    dispatch(
      changeCreationAdvertisementStep({
        activePage: typeOfSearch(),
        activeStep: 1,
      }),
    );
    dispatch(closeModal());
  };

  const typeOfSearch = () => {
    if (typeOfAllocation === 'AUTO_SEARCH') {
      return advertisementCreationSteps.auto;
    } else if (typeOfAllocation === 'CUSTOM_SEARCH') {
      return advertisementCreationSteps.manual;
    } else if (typeOfAllocation === 'FAVORITE_BLOGGERS') {
      return advertisementCreationSteps.featured;
    }
  };

  const handleCheckBanner = () => {
    if (story?.contentType === 'IMPROVISATION') {
      return storyImage;
    } else if (
      story?.contentType === 'CUSTOM' &&
      story?.mimeType?.includes('video')
    ) {
      return storyVideo;
    } else if (
      story?.contentType === 'CUSTOM' &&
      story?.mimeType?.includes('image')
    ) {
      return banner || storyImage;
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <div className="compaign-launch">
        <section className="form-section">
          <FormContainer button={''}>
            <div className="form-block">
              <img
                className="compaign-launch-banner"
                src={handleCheckBanner()}
                alt="banner"
              />
            </div>
            <div className="form-block">
              <Input
                errors={errors?.title?.message}
                options={{
                  labelText: t('company_types_create_popup_campaign_label'),
                  placeholderText: t(
                    'company_types_create_popup_campaign_placeholder',
                  ),
                }}
                inputChangeOptions={register('title')}
              />
            </div>
            <DateTimeSelect
              Controller={Controller}
              getValues={getValues}
              control={control}
              errors={errors}
            />
            <div className="compaign-launch__residence">
              {t('company_types_create_popup_targeting')}
            </div>
            <Button
              handleButtonClick={() => redirectToChoiceOfBloggers()}
              options={{
                text: t('company_types_create_popup_button'),
                type: 'primary',
                size: 's',
              }}
            />
            <button
              type="submit"
              className="compaign-launch__btn-save custom-btn"
            >
              {t('company_types_create_popup_save')}
            </button>
          </FormContainer>
        </section>
      </div>
    </form>
  );
};

export default withTranslation()(CampaignLaunchFormEditCommon);
