import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollToTop from '../../hooks/scrollToTop';

import book from './book';
import { privateRoutes, publicRoutes } from './config';
import PrivateRoute from './PrivateRoute';
import ErrorComponent from '../component/ErrorComponent/ErrorComponent';

import { useGetUserRole } from '../../hooks/getUserRole';

const Router = () => {
  const roleUser = useGetUserRole();
  return (
    <>
      <ScrollToTop />
      <Switch>
        {privateRoutes.map(({ path, component, isExact, role }) => {
          return (
            <PrivateRoute
              key={path}
              path={path}
              exact={isExact}
              component={
                role === 'ALL' || role === roleUser
                  ? component
                  : () => <ErrorComponent type={'ACCESS_DENIED'} />
              }
              redirectPath={book.advertisers}
            />
          );
        })}
        {publicRoutes.map(({ path, component, isExact }) => (
          <Route key={path} path={path} exact={isExact} component={component} />
        ))}
        <Redirect to={book.notFound} />
      </Switch>
    </>
  );
};

export default connect(null, null)(Router);
