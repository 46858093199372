import React from 'react';

import { numberWithSpaces } from '../../../helpers/numberWithSpaces';

import './styles.scss';

const FinanceItem = ({ item, t }) => {
  const { number, operation, date, status, sum } = item;

  return (
    <tbody className="finance-item__tbody">
      <tr>
        <td className="finance-item__td">{number + 1}</td>
        <td className="finance-item__td">{t(operation.text)}</td>
        <td className="finance-item__td"> {date}</td>
        <td className="finance-item__td">{status}</td>
        <td
          className={`finance-item__td ${
            operation.isUp ? 'finance-item__td-up' : 'finance-item__td-down'
          }`}
        >
          $ {sum && numberWithSpaces(sum)}
        </td>
      </tr>
    </tbody>
  );
};

export default FinanceItem;
