import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sessionPersonalInformationSelector } from '../../../../redux/selectors/selectors';
import { setNotifyStatus } from '../../../../redux/store/notify/notifyActions';
import { openModal } from '../../../../redux/store/modal/modalActions';

import FormContainer from '../../common/FormContainer/FormContainer';
import { modalTypes } from '../../../../configs/modalTypes';

import icon from '../../../../assets/images/support/icon.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const WriteUs = ({ t }) => {
  const { email } = useSelector(sessionPersonalInformationSelector);
  const dispatch = useDispatch();

  const createTicket = () => {
    if (!email) {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.editCurrentEmail,
        }),
      );
      dispatch(
        setNotifyStatus({
          title: 'You need to add an email to create a request',
          type: 'error',
        }),
      );
    } else {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.supportWriteUs,
        }),
      );
    }
  };

  return (
    <div className="support__right-bottom">
      <FormContainer>
        <div className="support__right-bottom-content">
          <img
            className="support__right-bottom-content__img"
            src={icon}
            alt="icon"
          />
          <span className="support__right-bottom-content__title">
            {t('support_FAQ_help_title')}
          </span>
          <span className="support__right-bottom-content__subtitle">
            {t('support_FAQ_help_description')}
          </span>
          <button
            onClick={createTicket}
            className="support__right-bottom-content__button"
          >
            {t('support_FAQ_help_button')}
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default withTranslation()(WriteUs);
