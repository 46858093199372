import { removeWithResponse,get, put, postFormData,download } from '../apiService';

export const deleteOfferById = id => {
  return removeWithResponse(`/offer/${id}`);
};

export const getAllOffers = () => {
  return get(`/offer/`);
};
export const getAllOffersWithDemos = (advId) => {
  return get(`/offer/getAllOffersWithDemo/${advId}`);
};

export  const  uploadDemo = async (data, id) => {
  return offerUploadDemo(id, data).then((dataT) => {
      console.log("OK")
      return true
    }).catch(r => console.log(r));
};
export const changeOfferStatusAndPay = (id, body) =>{
  return put(`/offer/${id}/set-status-by-id-and-pay`, body)
}
export const getOfferDemos = (id) => {
  return download(`/offer/${id}/download-demo/`);
};
export const offerUploadDemo = async (id, body) =>{
  return postFormData(`/offer/${id}/upload-demo`, body)
}
export const offerSendResult = (id, body) =>{
  return put(`/offer/${id}/send-result`, body)
}
export const changeOfferStatusById = (id, body) =>{
  return put(`/offer/${id}/set-status`, body)
}
export const changeOfferStatus = (id, body) =>{
  return put(`/offer/${id}/set-status-by-id`, body)
}

export const changeOfferDemoStatusById = (id, body) =>{
  return put(`/offer/${id}/set-demo-status`, body)
}