import React, { useState } from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';
import useWindowWidth from '../../../../../../hooks/useWindow';
import { step } from './config';
import nextItem from '../../../../../../assets/images/vectors/next_icon.svg';
import prevItem from '../../../../../../assets/images/vectors/prev_icon.svg';

import wave from '../../../../../../assets/images/landing/global/wave.svg';
import './styles.scss';

const Step = ({ openModal, setAuthenticationStep, t }) => {
  const width = useWindowWidth();

  const [state, setstate] = useState({
    position: 0,
    transform: 'translateX(0px)',
    style: {
      transform: 'translateX(0px)',
    },
  });

  const nextSlide = event => {
    event.preventDefault();
    let position = state.position;

    if (!(position <= -2650)) {
      position -= 730;
    } else position = 0;

    setstate({
      position,
      style: {
        transform: `translateX(${position}px)`,
      },
    });
  };
  const prevSlide = event => {
    event.preventDefault();
    let position = state.position;

    if (position === 0) position = 0;
    else position += 730;

    setstate({
      position,
      style: {
        transform: `translateX(${position}px)`,
      },
    });
  };
  return (
    <>
      <div className="step__container">
        <div className="step">
          <div className="step__header">
            <div className="step__header-title">
              {t('referrals_landing_step_title')}
            </div>
            <div className="step__header-subtitle">
              {t('referrals_landing_step_subtitle_1')} <br />{' '}
              {t('referrals_landing_step_subtitle_2')}
            </div>
            <span
              onClick={() =>
                handleRegistrationButtonClick(setAuthenticationStep, openModal)
              }
              className="step__header-button"
            >
              {t('referrals_landing_step_button_register')}
            </span>
          </div>
          <div className="slider__container">
            <div className="slider__container-prev" onClick={prevSlide}>
              <img
                src={prevItem}
                alt={'next'}
                className="slider__container_icon"
              />
            </div>
            <div
              className="slider"
              style={state.style}
              onClick={width >= 1024 ? nextSlide : null}
            >
              {step.map(({ title, subtitle }, index) => (
                <div key={index} className="step__content-block">
                  <div className="step__content-block-number">{t(title)}</div>
                  <div className="step__content-block-title">{t(subtitle)}</div>
                </div>
              ))}
            </div>
            <div className="slider__container-next" onClick={nextSlide}>
              <img
                src={nextItem}
                alt={'next'}
                className="slider__container_icon"
              />
            </div>
          </div>
          <div className="slider__button">
            <span
              onClick={() =>
                handleRegistrationButtonClick(setAuthenticationStep, openModal)
              }
              className="slider__button-content"
            >
              {t('referrals_landing_step_button')}
            </span>
          </div>
        </div>
      </div>
      <img className="step__wave" src={wave} alt="wave" />
    </>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(Step);
