import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';

import { putUserPersonalData } from '../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../redux/store/modal/modalActions';
import {
  buttonLoaderStatusSelector,
  sessionPersonalInformationSelector,
} from '../../../../../redux/selectors/selectors';

import { formingAnObjectForSendingForm } from '../../../../../helpers/isEmptyObject';
import FormContainer from '../../../Authentication/shared/Container/Container';
import { accauntEmailSettings } from '../../../../../schemas/settingsForm';
import { isEmptyObject } from '../../../../../helpers/isEmptyObject';
import { modalTypes } from '../../../../../configs/modalTypes';
import Input from '../../../common/Fields/Input/Input';
import Button from '../../../common/Button/Button';

import './styles.scss';
import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';
import { withTranslation } from 'react-i18next';

const EditEmailSettingsForm = ({ t }) => {
  const personalInformation = useSelector(sessionPersonalInformationSelector);
  const loading = useSelector(buttonLoaderStatusSelector);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: personalInformation,
    resolver: yupResolver(accauntEmailSettings),
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const handleFormSubmit = data => {
    const newData = formingAnObjectForSendingForm(data, dirtyFields);
    dispatch(putUserPersonalData(newData))
      .then(() => {
        dispatch(
          openModal({
            isOpen: true,
            renderType: modalTypes.editEmailConfirmCode,
          }),
        );
      })
      .catch(error =>
        dispatch(setNotifyStatus({ title: error, type: 'error' })),
      );
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="settings-form">
      <FormContainer name="E-mail">
        <div className="edit-settings__item">
          <div className="form-block-container">
            <Input
              errors={errors?.email}
              options={{
                labelText: t('settings_edit_email_label'),
                placeholderText: t('settings_edit_email_placeholder'),
              }}
              inputChangeOptions={register('email')}
            />
          </div>
        </div>
        <div className="edit-settings__btn">
          <Button
            loadingStatus={loading}
            options={{
              text: t('settings_edit_button'),
              renderType: 'primary',
              type: 'submit',
              size: 'm',
              disabled: isEmptyObject(dirtyFields),
            }}
          />
        </div>
      </FormContainer>
    </form>
  );
};

export default withTranslation()(EditEmailSettingsForm);
