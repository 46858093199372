import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';

import { uploadDemo } from '../../../../api/offer-service/offer-service.js';
import { closeModal } from '../../../../redux/store/modal/modalActions';

import { formingAnObjectForSendingForm } from '../../../../helpers/isEmptyObject';
import FormContainer from '../../common/FormContainer/FormContainer';
import { isEmptyObject } from '../../../../helpers/isEmptyObject';
import Input from '../../common/Fields/Input/Input';

import { useTranslation } from 'react-i18next';

const SendDemoForm = ({ details: { _id, refreshState , refresh, onLoad} }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const dispatch = useDispatch();

  const { dirtyFields } = useFormState({
    control,
  });

  useEffect(() => {
  }, []);

  const onSubmit = data => {
    const formData = new FormData();
    formData.append(
      "file", data.demoFile[0],
    );

  
    if (isEmptyObject(dirtyFields)) {
      dispatch(closeModal());
    } else {
      uploadDemo(formData,_id).then((data)=>{
        refreshState(refresh*-1)
        dispatch(closeModal())
      })
    }
  };
  const [t] = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <div className="compaign-launch">
        <section className="form-section">
          <FormContainer button={''}>
            <div className="form-block">
              <Input
                errors={errors?.title?.message}
                options={{
                  typeText: "file",
                  labelText: t('my_campaigns_modal_send_label'),
                  placeholderText: t('my_campaigns_modal_edit_placeholder'),
                  accept: ".mp4"
                }}
                inputChangeOptions={register('demoFile')}
              />
            </div>
            <button
              type="submit"
              className="compaign-launch__btn-save custom-btn"
            >
              Upload Demo Video
            </button>
          </FormContainer>
        </section>
      </div>
    </form>
  );
};

export default SendDemoForm;
