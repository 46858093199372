import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';

import { offerSendResult } from '../../../../api/offer-service/offer-service';
import { closeModal } from '../../../../redux/store/modal/modalActions';

import { formingAnObjectForSendingForm } from '../../../../helpers/isEmptyObject';
import FormContainer from '../../common/FormContainer/FormContainer';
import { isEmptyObject } from '../../../../helpers/isEmptyObject';
import Input from '../../common/Fields/Input/Input';

import { useTranslation } from 'react-i18next';
import { ref } from 'yup';

const SendResult = ({ details: { _id, refreshState , refresh, onLoad} }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const dispatch = useDispatch();

  const { dirtyFields } = useFormState({
    control,
  });

  useEffect(() => {
  }, []);
  const createJSON = data => {
    const formData = {}
    const newData = Object.entries(data);
    newData.forEach(item => formData[item[0]] = item[1]);
    return formData;
  };
  const onSubmit = data => {
    console.log(data)
    const formData = createJSON(data)

  
    if (isEmptyObject(dirtyFields)) {
      dispatch(closeModal());
    } else {
      offerSendResult(_id,formData).then((data)=>{
        refreshState(refresh*-1)
        dispatch(closeModal());
      })
    }
  };
  const [t] = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <div className="compaign-launch">
        <section className="form-section">
          <FormContainer button={''}>
            <div className="form-block">
              <Input
                errors={errors?.title?.message}
                options={{
                  labelText: t('my_campaigns_modal_send_label'),
                  placeholderText: t('my_campaigns_modal_edit_placeholder'),
                }}
                inputChangeOptions={register('result_link')}
              />
            </div>
            <button
              type="submit"
              className="compaign-launch__btn-save custom-btn"
            >
              Send result
            </button>
          </FormContainer>
        </section>
      </div>
    </form>
  );
};

export default SendResult;
