import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReduser';

const environment = process.env.NODE_ENV;

const middlewares = [thunk];
const devtools =  typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
  ? a => a
  : window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()

const enhancer =
  environment === 'development'
    ? compose(applyMiddleware(...middlewares), devtools)
    : compose(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, enhancer);
