import book from '../../../views/router/book';
import { setHistory } from './errorHandlerActions';
import { getUserInfo, updateToken } from '../session/sessionOperations';
import { setNotifyStatus } from '../notify/notifyActions';

export const setErrorHandler = ({ message, status }) => (
  dispatch,
  getState,
) => {
  const history = getState().errorHandler.history;

  switch (status) {
    case 400:
      dispatch(setNotifyStatus({ title: message, type: 'error' }));
      break;
    case 401:
      let userInfo = localStorage.getItem('session');
      if (userInfo) {
        const { refreshToken } = JSON.parse(userInfo);
        if (refreshToken) {
          dispatch(updateToken(refreshToken));
        }
      } else history.push(book.advertisers);

      break;
    case 403:
      dispatch(getUserInfo());
      history.push(book.accessDeniedError);
      break;
    case 404:
      history.push(book.notFound);
      break;
    default: {
      if (status > 400) {
        history.push(book.somethingWrong);
      }
    }
  }
};
export const initHistory = history => dispatch => {
  dispatch(setHistory(history));
};
