import React from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';

import WorkDesktop from './WorkDesktop/WorkDesktop';
import WorkMobile from './WorkMobile/WorkMobile';

import './styles.scss';

const Work = ({ setAuthenticationStep, openModal, t }) => {
  return (
    <div className="work">
      <div className="work-title">{t('advertisers_landing_how_it_works')}</div>
      <div className="work-subtitle">
        {t('advertisers_landing_introduce_your_new')}
      </div>
      <WorkDesktop t={t} />
      <WorkMobile t={t} />
      <span
        onClick={() =>
          handleRegistrationButtonClick(setAuthenticationStep, openModal)
        }
        className="work-button"
      >
        {t('advertisers_landing_launch-the_advertisement')}
      </span>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(Work);
