import types from './notificationTypes';

const initialState = {
  userNotifications: {
    count: 0,
    unreadCount: 0,
    messages: [],
  },
};

const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER_NOTIFICATIONS_SUCCESS:
    case types.PUT_USER_NOTIFICATION_IS_READ_SUCCESS:
      return { ...state, userNotifications: payload };
    default:
      return state;
  }
};

export default notificationReducer;
