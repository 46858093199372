import types from './advetisementTypes';

// get
export const getAdvertisementStart = () => ({
  type: types.GET_ADVERTISEMENT_START,
});

export const getAdvertisementSuccess = data => ({
  type: types.GET_ADVERTISEMENT_SUCCESS,
  payload: data,
});

export const getAdvertisementError = (error = '') => ({
  type: types.GET_ADVERTISEMENT_ERROR,
  payload: error,
});

export const getAdvertisementStatisticStart = () => ({
  type: types.GET_ADVERTISEMENT_STATISTIC_START,
});

export const getAdvertisementStatisticSuccess = data => ({
  type: types.GET_ADVERTISEMENT_STATISTIC_SUCCESS,
  payload: data,
});

export const getAdvertisementStatisticError = (error = '') => ({
  type: types.GET_ADVERTISEMENT_STATISTIC_ERROR,
  payload: error,
});

// delete
export const deleteAdvertisementStart = () => ({
  type: types.DELETE_ADVERTISEMENT_START,
});

export const deleteAdvertisementSuccess = id => ({
  type: types.DELETE_ADVERTISEMENT_SUCCESS,
  payload: id,
});

export const deleteAdvertisementError = (error = '') => ({
  type: types.DELETE_ADVERTISEMENT_ERROR,
  payload: error,
});

// remove offer
export const removeOfferFromList = list => ({
  type: types.REMOVE_OFFER_FROM_LIST,
  payload: list,
});

// set
export const setOffset = data => ({
  type: types.SET_OFFSET,
  payload: data,
});

export const setSortType = data => ({
  type: types.SET_SORT_TYPE,
  payload: data,
});

export const setOrderType = data => ({
  type: types.SET_ORDER_TYPE,
  payload: data,
});

export const setDateFrom = data => ({
  type: types.SET_DATE_FROM_PARAMS,
  payload: data,
});

export const setDateTo = data => ({
  type: types.SET_DATE_TO_PARAMS,
  payload: data,
});

export const clearAllParams = () => ({
  type: types.CLEAR_ALL_PARAMS,
});

export const clearDateParams = () => ({
  type: types.CLEAR_DATE_PARAMS,
});

export const setStatus = data => ({
  type: types.SET_STATUS,
  payload: data,
});

// post
export const postAdvertisementCopyStart = () => ({
  type: types.POST_ADVERTISEMENT_COPY_START,
});

export const postAdvertisementCopySuccess = (data = {}) => ({
  type: types.POST_ADVERTISEMENT_COPY_SUCCESS,
  payload: data,
});

export const postAdvertisementCopyError = (error = '') => ({
  type: types.POST_ADVERTISEMENT_COPY_ERROR,
  payload: error,
});

// clear advertisement
export const clearAdvertisement = () => ({
  type: types.CLEAR_ADVERTISEMENT,
});
