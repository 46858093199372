import React from 'react';
import { Helmet } from 'react-helmet-async';

import './styles.scss';

const Agreement = () => {
  return (
    <>
      <Helmet>
        <title>Terms of service of advertising service - Bloggerly</title>
        <meta
          name="description"
          content="On this page you can find terms of service on protection of personal data of automated advertising exchange Bloggerly"
        />
      </Helmet>
      <div className="conditions">
        <h1 className="conditions__title">
          Terms of Service and protection <br /> of user's personal data
        </h1>
        {/* Terms of Service and protection of user's personal data */}
        <p className="conditions__item-text">
          Welcome to the website frontend-dev.bloggerly.lampawork.com Please
          read the terms of service ("Agreement") Bloggerly.
        </p>
        <p className="conditions__item-text">
          The use of the service and any derivative works thereof constitutes
          your full understanding, approval and acceptance of this Agreement.
        </p>
        <p className="conditions__item-text">
          By signing up on the website frontend-dev.bloggerly.lampawork.com or
          use any of its functionality you agree to all the terms of the
          Agreement, undertake to observe them or to stop using and confirm that
          you reached an age of 18 years or you have a legal notice that allows
          you to use the Site and/or application.
        </p>
        <p className="conditions__item-text">
          The Agreement may be amended by the site Administration without any
          special notice, but without canceling other types of notifications
          (via the site or email), and the new version of the Agreement shall
          enter into force upon its posting, unless otherwise provided by the
          new version of the Agreement.
        </p>
        {/* Terms and definitions */}
        <h1 className="conditions__subtitle">Terms and definitions</h1>
        <p>
          Bloggerly system is an Internet service designed to conduct
          advertising campaigns of Users - individuals or legal entities in need
          of the movement of their own goods, services, results of intellectual
          or other activities on the Sites owned by Users on the rights of
          management.
        </p>
        <div className="conditions__item-text">
          <p className="conditions__item-active">Administration, moderators</p>—
          the representatives of support and management of the system.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">Site</p> — a resource placed on
          the Internet and to which the User has the right to edit and publish.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">User</p> — a natural or legal
          person who has registered an account in the system, as a blogger or as
          an advertiser.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">Account</p> — a unique account
          in the system that allows you to identify the User in the system and
          use all its available functions.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">Rules</p> — the rules of the
          system.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">Publication</p> — is the User
          identity information posted on the site.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">Personal data</p> — any
          information relating to a particular or determined on the basis of
          such information to an individual (the subject of personal data),
          including his surname, name, patronymic, year, month, date and place
          of birth, address, e-mail address, phone number, family, social,
          property status, education, profession, income, other information.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">
            Confidentiality of personal data
          </p>
          - mandatory for compliance with the designated responsible person who
          has access to personal data, the requirement to prevent their
          distribution without the consent of the subject or other legal basis.
        </div>
        <div className="conditions__item-text">
          <p className="conditions__item-active">Processing of personal data</p>{' '}
          - actions (operations) with personal data, including collection,
          systematization, accumulation, storage, clarification (update,
          change), use, distribution (including transfer), depersonalization,
          blocking.
        </div>
        {/* 1. Generalities */}
        <h1 className="conditions__subtitle">1. Generalities</h1>
        <p className="conditions__item-text">
          This User agreement is a legally binding agreement between the User
          and the site Administration, the subject of which is the provision by
          The site Administration to the User of services for the use of the
          Site and its services (hereinafter – the Services). The user is
          obliged to fully read this Agreement before registering on the Site.
          User registration on the Site means full and unconditional acceptance
          of this Agreement by the User.
        </p>
        <p className="conditions__item-text">
          This Agreement shall enter into force upon its approval by the
          Administration and shall remain in force indefinitely until replaced
          by a new Agreement.
        </p>
        <p>
          This Agreement and the relationship between the Site and the User that
          arose in connection with the use of the services shall be subject to
          the law of India.
        </p>
        <p className="conditions__item-text">
          The user is granted a personal non-exclusive and non-transferable
          right to use the software provided in the services on a single
          computer, provided that neither the User nor any other person with the
          assistance of the User will copy or modify the software; create
          programs derived from the software; penetrate the software in order to
          obtain program codes; sell, assign, lease, transfer to third parties
          in any other form of rights in respect of the software services
          provided to the User under this Agreement, as well as modify the
          services, including for the purpose of obtaining unauthorized access
          to them.
        </p>
        <p className="conditions__item-text">
          Under the Administration of the Site (hereinafter – the site
          Administration, Administration) in this Agreement and other special
          documents posted on the Site.
        </p>
        <p className="conditions__item-text">
          Appeals, suggestions and claims of individuals and legal entities to
          the Administration of the Site in connection with this Agreement and
          all questions on the functioning of the Site, violations of the rights
          and interests of third parties when using it, can be sent to the
          e-mail address: contact.us.bloggerly@gmail.com
        </p>
        <p className="conditions__item-text">
          No provisions of this Agreement grant the User the right to use the
          brand name, trademarks, domain names and other distinctive signs of
          The site Administration. The right to use the brand name, trademarks,
          domain names and other distinctive signs of the site Administration
          may be granted only by written agreement with The site Administration.
        </p>
        {/* 2. Registration on the site and User status */}
        <h1 className="conditions__subtitle">
          2. Registration on the site and User status
        </h1>
        <p className="conditions__item-text">
          In order to use the services of the Site, the User agrees to provide
          accurate and complete information about themselves on the issues
          proposed in the Registration Form, to form a personal User page,
          including unique for each User login (e-mail address or mobile phone
          number to access the Site) and password to access the Site, as well as
          to maintain this information up to date.
        </p>
        <p className="conditions__item-text">
          In case of providing incorrect information or if the site
          Administration has serious reasons to believe that the information
          provided is incomplete or inaccurate, the site Administration has the
          right to block or delete the User account and refuse the User to use
          its services.
        </p>
        <p>
          The registration form of the Site may request additional information
          from the User.
        </p>
        <p className="conditions__item-text">
          The user is responsible for the accuracy, relevance, completeness of
          the information provided during registration.
        </p>
        <p className="conditions__item-text">
          User registration on the Site is free of charge, voluntary and is made
          at the address on the Internet: frontend-dev.bloggerly.lampawork.com
        </p>
        <p className="conditions__item-text">
          The user of the Site is an individual or a legal entity registered on
          the Site in accordance with the procedure established by this
          Agreement, who has reached the age allowed in accordance with the
          legislation of India for the acceptance of this Agreement (hereinafter
          – the User).
        </p>
        <p className="conditions__item-text">
          After successful registration of the User on The site, the
          Administration assumes the rights and obligations to the User
          specified in this Agreement.
        </p>
        <p className="conditions__item-text">
          After completing the registration process, the User can use the chosen
          login and password to access the personalized part of the Site
          services. At the same time, the User is fully responsible for the
          security of the login and password, as well as for everything that
          will be done using the services of the Site by the user's login and
          password.
        </p>
        <p className="conditions__item-text">
          Each User has the right to register in the System only one Account. At
          the same time, an unlimited number of Plans can be placed on one
          Account.
        </p>
        <p className="conditions__item-text">
          Registration in the System is completely voluntary. The
          administration, ensuring the possibility of constant, most complete
          and convenient interaction between Users, is not responsible for any
          damage that may be incurred by Users in connection with the use or
          inability to properly use the System.
        </p>
        <p className="conditions__item-text">
          The user is obliged to immediately notify the site Administration of
          any case of manual (not allowed by the User) access with login and
          password and/or any security violation, and is obliged to carry out an
          independent safe shutdown under his password at the end of each
          session of the Site and ensures the confidentiality of his password
          access to the Site.
        </p>
        <p className="conditions__item-text">
          By accepting this Agreement by registering on the Site, the User
          confirms his / her consent to the storage, processing, broadcasting,
          copying by the Administration of his / her personal data provided
          during registration, as well as voluntarily posted by the User on his
          / her personal page.
        </p>
        <p className="conditions__item-text">
          Processing of personal data of the User is carried out in accordance
          with the legislation of India. The site administration processes the
          personal data of the User in order to provide the User with services,
          including in order to receive personalized (targeted) advertising;
          check, research and analysis of such data, allowing to maintain and
          improve the services and sections of the Site, as well as to develop
          new services and sections of the Site.
        </p>
        <p className="conditions__item-text">
          The site administration shall take all necessary measures to protect
          the User's personal data from unauthorized access, modification,
          disclosure or destruction (in accordance with section 3 of this
          Agreement).
        </p>
        <p className="conditions__item-text">
          The login and password chosen by the User are necessary and sufficient
          information for the User's access to the Site. The user has no right
          to transfer the login and the password to the third parties, bears
          full responsibility for their safety, independently choosing a way of
          their storage.
        </p>
        <p className="conditions__item-text">
          If the User proves otherwise, any actions committed using the login
          and password are completed by the respective User.
        </p>
        <p className="conditions__item-text">
          After registration, the User has the right to create, use and
          determine the content of their own personal page.
        </p>
        <p className="conditions__item-text">
          The user understands that the site Administration does not take part
          in the formation and use of the content and control of access of other
          users to the user's personal page.
        </p>
        <p className="conditions__item-text">
          Removal of the User's personal page is carried out by the site
          Administration within the period agreed with the User on the personal
          written application of the user, sent to the e-mail address of
          technical support: contact.us.bloggerly@gmail.com
        </p>
        <p className="conditions__item-text">
          The User's e-mail must be sent from the e-mail address specified by
          the User when registering on the Website or changed when the User's
          personal page is used next time. In the application, it is necessary
          to specify the mobile phone number to which the User's personal page
          is attached for the purposes of authenticating The User's will.
        </p>
        {/* 3. Protection of User's personal data and confidentiality. */}
        <h1 className="conditions__subtitle">
          3. Protection of User's personal data and confidentiality.
        </h1>
        <p className="conditions__item-text">
          The user gives his / her full consent to the transfer, receipt,
          storage and use by the system Administration of any kind of
          information and personal data received by the System during the
          provision of services to the User.
        </p>
        <p className="conditions__item-text">
          The administration of the System, for its part, guarantees the
          non-proliferation of user data.
        </p>
        <p>
          The protection of the User's personal data is understood as a set of
          measures (organizational, administrative, technical, legal) aimed at
          the prevention of unlawful or accidental access to them, destruction,
          modification, deletion, copying, dissemination of personal data of
          subjects, as well as other illegal actions.
        </p>
        <p className="conditions__item-text">
          Protection of personal data of the User is carried out at the expense
          of the Administration in accordance with the Federal law of India.
        </p>
        <p className="conditions__item-text">
          The site administration takes all necessary organizational,
          administrative, legal and technical measures to protect the personal
          data of Users, including:
        </p>
        <p className="conditions__item-point">
          Encryption (cryptographic) tools.
        </p>
        <p className="conditions__item-point">Antivirus protection.</p>
        <p className="conditions__item-point">Security analysis.</p>
        <p className="conditions__item-point">
          Intrusion detection and prevention.
        </p>
        <p className="conditions__item-point">Access control.</p>
        <p className="conditions__item-point">Registration and accounting.</p>
        <p className="conditions__item-point">Integrity.</p>
        <p className="conditions__item-text">
          Access to the personal data of the User have the employees of the site
          Administration, who need personal data for the full and complete
          performance of their duties.
        </p>
        <p className="conditions__item-text">
          Protection of personal data of Users stored in electronic databases of
          data of the site Administration, from unauthorized access, distortion
          and destruction of information, as well as from other illegal actions,
          is provided by the System administrator.
        </p>
        <p className="conditions__item-text">
          Protection of access to electronic databases containing Users'
          personal data is provided by the use of licensed anti-virus and
          anti-hacking programs that prevent unauthorized access to the local
          network, as well as the differentiation of access rights using the
          account.
        </p>
        <p className="conditions__item-text">
          All electronic folders and files containing Users ' personal data are
          protected by a password, which is set by the responsible System
          administrator.
        </p>
        <p className="conditions__item-text">
          Confidential information in the Agreement means any information that
          has actual or potential commercial value due to its unknown to third
          parties, to which there is no free access on a legal basis and to the
          confidentiality of which the owner takes all possible measures.
        </p>
        <p className="conditions__item-text">
          Information constituting confidential information may be denounced, in
          the form of photographs, in electronic, graphic, as well as in any
          other form.
        </p>
        <p className="conditions__item-text">
          All objects placed on the Site, including design elements, text,
          graphic images, illustrations, scripts, programs, and other objects
          and their collections (hereinafter-the Content), are subject to the
          exclusive rights of the Administration, Users of the Site and other
          rightholders, all rights to these objects are protected.
        </p>
        <p className="conditions__item-text">
          In addition to its own Content, the User may not upload or otherwise
          make available to the public (publish on the Site) the Content of
          other sites, databases and other results of intellectual activity in
          the absence of an Express consent of the owner to such actions.
        </p>
        <p className="conditions__item-text">
          Nothing in this Agreement can be considered as a transfer of exclusive
          rights to the Content.
        </p>
        <p className="conditions__item-text">
          The user is solely responsible for any Content or any other
          information which it loads or otherwise brings to the General public
          (the audience-em) on the Website or with its help. The user has no
          right to upload, transmit or publish Content on the Site if he / she
          does not have the appropriate rights to perform such actions acquired
          or transferred to him / her.
        </p>
        <p className="conditions__item-text">
          The site Administration may, but is not obliged to, view the Site for
          the presence of prohibited Content and may remove or move (without
          warning) any Content or users in its sole discretion, for any reason
          or no reason, including without limitation the movement or removal of
          Content that, in the personal opinion of the Administration, violates
          this Agreement, and/or may violate the rights, harm or threaten the
          safety of other Users or third parties.
        </p>
        <p className="conditions__item-text">
          By posting its Content on the Site, The user transfers to the
          Administration the right to de-lat copies of its Content in order to
          streamline and facilitate the publication and storage of user Content
          on the Site.
        </p>
        <p className="conditions__item-text">
          By posting its Content in any part of the Site, the User automatically
          grants the Administration a non-exclusive right to use it by copying,
          public performance, reproduction, processing, translation and
          distribution for the purposes of the Site or in connection with them,
          including for its promotion. For these purposes, the Administration
          may make derivative works or insert the User's Content as part of the
          relevant collections, perform other actions that serve to achieve
          these goals.
        </p>
        <p className="conditions__item-text">
          If the User deletes his Content from the Site, the Administration has
          the right to keep archival copies of the user Content for an
          indefinite period.
        </p>
        <p className="conditions__item-text">
          The site contains (or may contain) links to other sites on the
          Internet (third party sites) as well as articles, photographs,
          illustrations, graphics, music, sounds, videos, information,
          applications, programs and other Content belonging to or originating
          from third parties (third party Content) that is the result of
          intellectual activity.
        </p>
        <p className="conditions__item-text">
          These third parties and their Content are not checked by the
          Administration for compliance with certain requirements (reliability,
          completeness, integrity, etc.). Ad-ministration is not responsible for
          any information posted on third-party sites to which the User accesses
          through the Site or through the Content of third parties, including,
          inter alia, any opinions or statements expressed on third-party sites
          or in their Content.
        </p>
        <p className="conditions__item-text">
          The links or guidelines for downloading files and / or installing
          third-party programs posted on the Site do not imply support or
          approval of these actions by the Administration.
        </p>
        <p className="conditions__item-text">
          The link to any site, product, service, any information of commercial
          or non-commercial nature, posted on the Site, is not an approval or
          recommendation of these products (services) by the Administration.
        </p>
        <p className="conditions__item-text">
          If the user decides to leave the Site and go to third-party sites or
          use or install third-party programs, He does it at his own risk and
          from that moment this Agreement no longer applies to the User.
        </p>
        {/* 4. Obligations Of The User */}
        <h1 className="conditions__subtitle">4. Obligations Of The User</h1>
        <p className="conditions__item-active-point">
          4.1 Using the services of the Site, the User is obliged to:
        </p>
        <p className="conditions__item-point">
          comply with the provisions of the current legislation of India, this
          Agreement and other special documents of The site Administration;
        </p>
        <p className="conditions__item-point">
          provide accurate, complete and up-to-date data during registration,
          monitor their updating;
        </p>
        <p className="conditions__item-point">
          inform the site Administration about unauthorized access to the
          personal page and / or unauthorized access and / or use of the user's
          password and login;
        </p>
        <p className="conditions__item-point">
          not to provide access to other Users to their own personal country or
          to separate information contained on it in case it may lead to
          violation of this Agreement, special documents of The site
          Administration;
        </p>
        <p className="conditions__item-point">
          do not post on the personal page information and objects (including
          links to them) that may violate the rights and interests of others;
        </p>
        <p className="conditions__item-point">
          before placing information and objects (including, but not limited to,
          the images of other persons, other people's texts of different
          content) to pre-evaluate the legality of their placement;
        </p>
        <p className="conditions__item-point">
          keep confidential and not provide other Users and third parties with
          personal data (including, but not limited to, home addresses, phone
          numbers, e-mail addresses, passport data, Bank information) that have
          become known to him as a result of communication with other Users and
          other use of the Site without obtaining the appropriate prior
          permission of the latter;
        </p>
        <p className="conditions__item-point">
          back up important information stored on the User's personal page.
        </p>
        <p className="conditions__item-point">
          be correct in correspondence and personal communication with another
          User(s), as well as refrain from actions and (or) replicas in any form
          aimed at insulting, obscenity, discrimination on racial, religious,
          sexual, national and other grounds, undermining the authority, image
          and trust of other Users, the Administration and the System as a
          whole.
        </p>
        <p className="conditions__item-point">
          The user is obliged to refrain from dissemination of unverified,
          false, misleading and other information that causes and (or) may cause
          harm to Users, Administration, reputation of the System and third
          parties. The user undertakes at the first request of the
          Administration of the System to remove from the personal site (from
          the site or other Internet resource) information of this kind.
        </p>
        <p className="conditions__item-text">
          In case of doubts about the legality of the implementation of certain
          actions, including the placement of information or the provision of
          access, the Administration of the Site recommends to refrain from the
          implementation of the latter.
        </p>

        <p className="conditions__item-active-point">
          4.2. Using the Site the User is prohibited:
        </p>

        <p className="conditions__item-point">
          register as a User on behalf of or instead of another person ("fake
          account") or register a group (Association) of persons or a legal
          entity as a User, at the same time, it is possible to register on
          behalf of and on behalf of another individual or legal entity, subject
          to obtaining the necessary powers in the manner and form provided by
          the legislation of India;
        </p>
        <p className="conditions__item-point">
          mislead Users about their identity by using the login and password of
          another registered User;
        </p>
        <p className="conditions__item-point">
          misrepresent yourself, your age, or your relationship with others;
        </p>
        <p className="conditions__item-point">
          upload, store, publish, distribute and provide access or otherwise use
          any information which: contains threats, discreditied, offends,
          denigrates the honor and dignity or business reputation or violates
          the privacy of other Users or third parties; violates the rights of
          minors; is vulgar or obscene, contains foul language, contains
          pornographic images and texts or scenes of a sexual nature involving
          minors; contains scenes of violence or inhumane treatment of animals;
          contains a description of the means and methods of suicide, any
          incitement to commit it; promotes and / or promotes incitement to
          racial, religious, ethnic hatred or enmity, promotes fascism or the
          ideology of racial superiority; contains extremist materials; promotes
          criminal activity or contains advice, instructions or guidelines for
          the Commission of criminal acts, contains information of limited
          access, including, but not limited to, state and commercial secrets,
          information about the privacy of third parties.; contains advertising
          or describes the attractiveness of the use of drugs, including "
          digital drugs "(audio files that affect the human brain due to
          binaural beats), information about the distribution of drugs, recipes
          for their manufacture and advice on use; is fraudulent; and violates
          other rights and interests of citizens and legal entities or the
          requirements of the legislation of India;
        </p>
        <p className="conditions__item-point">
          illegally upload, store, publish, distribute and provide access to or
          otherwise use the intellectual property of Users and third parties;
        </p>
        <p className="conditions__item-point">
          to carry out mass mailing of messages without the consent Of users of
          the Site;
        </p>
        <p className="conditions__item-point">
          use the software and carry out actions aimed at disrupting the normal
          functioning of the Site and its services or Users ' personal pages;
        </p>
        <p className="conditions__item-point">
          download, store, publish, distribute and make available or otherwise
          exploit viruses, Trojans and other malware;
        </p>
        <p className="conditions__item-point">
          use automated scripts (programs) without special permission of The
          site Administration to collect information on the Site and (or)
          interact with the Site and its services;
        </p>
        <p className="conditions__item-point">
          in any way, including, but not limited to, by deception, abuse of
          trust, hacking, attempt to gain access to the login and password of
          another User;
        </p>
        <p className="conditions__item-point">
          illegal collection and processing of personal data of other persons;
        </p>
        <p className="conditions__item-point">
          to carry out (try to get) access to any services in any other way than
          through the interface provided by The site Administration, except for
          cases when such actions were directly permitted to the User in
          accordance with a separate agreement with the Administration;
        </p>
        <p className="conditions__item-point">
          reproduce, duplicate, copy, sell, trade and resell the services for
          any purpose, except where such actions have been expressly authorized
          by the User in accordance with the terms of a separate agreement with
          the Administration;
        </p>
        <p className="agreement__item-point">
          posting commercial and political advertisements outside of special
          Website sections established by the Website Administration. The user
          purchases paid services on the Site in the manner prescribed in the
          special documents of the Administration posted on the Site;
        </p>
        <p className="agreement__item-point">
          modify, sell, distribute the content and programs, in whole or in
          parts, except as specifically provided by the site Administration or
          its advertisers;
        </p>
        <p className="agreement__item-point">
          post any other information that, in the personal opinion of the
          Administration, is undesirable, does not correspond to the purposes of
          creating the Site, infringes the interests of Users or for other
          reasons is undesirable for posting on the Site.
        </p>

        <p className="conditions__item-text">
          The User is personally responsible for any information that is posted
          on the Site, reported to other Users, as well as for any interactions
          with other Users carried out at their own risk.
        </p>
        <p className="conditions__item-text">
          Inaction on the part of the Site Administration in case of violation
          by the User or other Users of the provisions of this Agreement does
          not deprive The site Administration of the right to take appropriate
          actions to protect their interests later, and does not mean the site
          Administration's refusal of its rights in the event of subsequent such
          or similar violations.
        </p>
        <p className="conditions__item-text">
          In case the User disagrees with this Agreement or its updates, the
          User must refuse to use it, informing the site Administration in the
          prescribed manner.
        </p>
        {/* 5. Responsibility of Users and Administration of the System */}
        <h1 className="conditions__subtitle">
          5. Responsibility of Users and Administration of the System
        </h1>
        <p className="conditions__item-text">
          Users are responsible for their own actions in connection with the
          creation and placement of information on their own personal page on
          the Site, as well as in connection with the placement of information
          in other sections of the Site in accordance with the current
          legislation of India.
        </p>
        <p className="conditions__item-text">
          The Site Administration provides the technical possibility of its use
          by Users, does not participate in the formation of the content of the
          personal pages of the users and does not control and is not
          responsible for the actions or inaction of any persons in relation to
          the use of the Site or the formation and use of the content of the
          personal pages of Users on the Site.
        </p>
        <p className="conditions__item-text">
          The Administration reserves the right at any time to change the design
          of the website, its content, list of services, modify or supplement
          the scripts, software and other objects used or stored on the Website,
          any server applications at any time with or without prior notice.
        </p>
        <p className="conditions__item-text">
          The Site Administration carries out preliminary moderation or
          censorship of user information and takes actions to protect the rights
          and interests of persons.
        </p>
        <p className="conditions__item-text">
          The Site Administration is not responsible for the user's violation of
          this Agreement and reserves the right, at its sole discretion, as well
          as upon receipt of information from other users or third parties about
          the user's violation of this Agreement, to change (moderate) or delete
          any information published by the User that violates the prohibitions
          established by this Agreement( including personal messages), restrict
          or terminate the User's access to all or any of the sections or
          services of the Site at any time for any reason or without
          explanation, with or without prior knowledge, without being
          responsible for any harm that may be caused to the user by such
          action.
        </p>
        <p className="conditions__item-text">
          The Site Administration reserves the right to delete the User's
          personal page and(or) suspend, restrict or terminate the User's access
          to any of the Site services, if the Administration finds that in its
          opinion, the User poses a threat to the Site and(or) its Users. The
          Site Administration is not responsible for the temporary blocking or
          deletion of information, or removal of the user's personal page
          (termination of registration) carried out in accordance with this
          Agreement.
        </p>
        <p className="conditions__item-text">
          Deletion of the User's personal page means automatic deletion of all
          information posted on it, as well as all User information entered
          during registration on the Site. After removal of the personal page,
          the User loses access rights to the Site.
        </p>
        <p className="conditions__item-text">
          The Site Administration ensures the functioning and operability of the
          Site and undertakes to promptly restore its operability in the event
          of technical failures and interruptions. The site administration is
          not responsible for temporary failures and interruptions in the work
          of the Site and the loss of information caused by them. The
          administration is not responsible for any damage to The user's
          computer or other person, mobile devices, any other equipment or
          software caused by or related to downloading materials from the Site
          or links posted on the Site.
        </p>
        <p className="conditions__item-text">
          The Site Administration has the right to dispose of statistical
          information related to the operation of the Site, as well as User
          information to provide targeted display of advertising information to
          different audiences of Site Users. For the purposes of organizing the
          functioning and technical support of the Site and the implementation
          of this Agreement, the Site Administration has the technical ability
          to access the personal pages of Users, which is implemented only in
          the cases established by this Agreement.
        </p>
        <p className="conditions__item-text">
          The Site Administration has the right to send the User information
          about the development of the Site and its services, as well as to
          advertise its own activities and services.
        </p>
        <p className="conditions__item-text">
          The User acknowledges that the Site Administration may impose
          restrictions on the use of the services, including: the period of
          storage of any content, the maximum amount of disk space, the maximum
          number of calls to the service for a specified period of time, etc.
          the Site Administration may prohibit automatic processing of its
          services, as well as stop receiving any information generated
          automatically. The Site Administration may send information messages
          to its users.
        </p>
        <p className="conditions__item-text">
          The Site Administration is not responsible for any delays, failures,
          incorrect or untimely delivery, deletion or non-preservation of any
          user's personal information.
        </p>
        <p className="conditions__item-text">
          The User agrees that the Site Administration reserves the right to
          remove Users who have not used their access for a long time.
        </p>
        <p className="conditions__item-text">
          The User is aware that the advertising of certain goods and services
          in the territory of the countries in which the actions are performed
          within the System is limited by the current legislation, the
          discrepancy of advertising placed on the site, the requirements of the
          law may be regarded by law enforcement or other authorities as a crime
          or an administrative offense, the responsibility for which may be
          incurred by the person who posted such a Publication.
        </p>
        <p className="conditions__item-text">
          For non-compliance with the rules the Administration has the right:
        </p>
        <p className="conditions__item-point">
          warn a User who does not comply with one or more of the requirements
          of the Rules about the possible consequences of such non-compliance;
        </p>
        <p className="conditions__item-point">
          block the Account of the User who does not comply with one or more
          requirements of the Rules for the period necessary to clarify the
          circumstances and eliminate the consequences of this non-compliance
          (non-compliance);
        </p>
        <p className="conditions__item-point">
          suspend the activity of The user's Site if at least one of its
          parameters does not meet the requirements of the Rules and the
          Agreement, until the specified parameters are reached;
        </p>
        <p className="conditions__item-point">
          delete the User's Account if there are grounds to believe that his
          actions or omissions directly related to the use of the Account will
          inevitably cause harm to the administration or third parties, or are
          (will be) criminal or illegal;
        </p>
        <p className="conditions__item-point">
          block the existing partnership between registered users, if it will be
          found signs of bad faith, contrary to the Rules.
        </p>
        <p className="conditions__item-text">
          The User who does not agree with any of the listed actions of the
          Administration has the right to send a reasonable request to the
          support service or to the mail contact.us.bloggerly@gmail.com
        </p>
        {/* 6. Procedure for dispute resolution and consideration of appeals */}
        <h1 className="conditions__subtitle">
          6. Procedure for dispute resolution and consideration of appeals
        </h1>
        <p className="conditions__item-text">
          All disputes between Users, as well as between Users and the
          Administration, are considered in accordance with the Rules on the
          basis of an appeal received by the support service at
          contact.us.bloggerly@gmail.com
          <br /> Any correspondence that occurred between Users outside the
          system, as well as the consequences that occurred in the course of
          such, shall not be considered by the Administration.
        </p>
        <p className="conditions__item-text">
          When considering appeals, the Administration is guided by the
          principles of objectivity and comprehensiveness, as well as the
          interest in an early and impartial resolution of the issue, taking
          into account the interests of all parties.
        </p>
        <p className="conditions__item-text">
          The response to the appeal of the User is carried out within a period
          not exceeding 5 (five) working days from the date of receipt. If any
          additional actions are required in connection with the application
          (technical works, organizational measures, etc.), the Administration
          of the System shall notify the User(s).
        </p>
        {/* 7. Final provision */}
        <h1 className="conditions__subtitle">7. Final provision</h1>
        <p className="conditions__item-text">
          This User Agreement constitutes an agreement between the User and the
          site Administration regarding the use of the Site and its services.
        </p>
        <p className="conditions__item-text">
          This User Agreement shall be governed by and construed in accordance
          with the laws of India. Issues not regulated by the Agreement are
          subject to resolution in accordance with the legislation of India.
        </p>
        <p className="conditions__item-text">
          Apple and Bloggerly in relation to each other are independent parties.
          Apple cannot be deemed as a sponsor of any activity according to the
          Site and/or application.
        </p>
        <p className="conditions__item-text">
          In case of any disputes or disagreements related to the execution of
          this Agreement, the User and the site Administration will make every
          effort to resolve them through negotiations between them. If the
          disputes are not resolved through negotiations, the disputes shall be
          settled in the manner prescribed by the current legislation of India.
        </p>
        <p className="conditions__item-text">
          This User Agreement shall enter into force for the User from the
          moment of its accession to it and shall be valid for an indefinite
          period.
        </p>
        <p className="conditions__item-text">
          Nothing in this Agreement can be understood as the establishment
          between the User and the site Administration of Agency relations,
          partnership relations, relations of joint activity, personal
          employment relations, or any other relations not expressly provided
          for in this Agreement.
        </p>
        <p className="conditions__item-text">
          This User agreement is made in English.
        </p>
      </div>
    </>
  );
};

export default Agreement;
