import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import './styles.scss';
import ResetPasswordForms from './resetPasswordForms';
import { useDispatch, useSelector } from 'react-redux';
import * as sessionActions from '../../../redux/store/session/sessionActions';
import * as api from '../../../api/session-service/session-service';

const ResetPassword = () => {
  const { id } = useParams();
  const authenticated = useSelector(
    store => store.sessionAdvertiser.authenticated,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (authenticated) {
      dispatch(sessionActions.sessionlogOut());
      api.logoutSocialAuth();
      localStorage.removeItem('session');
      localStorage.removeItem('accaunt');
      localStorage.removeItem('isAuth');
    }
  }, [authenticated, dispatch]);
  return (
    <div className="reset-password">
      <h1 className="reset-password-title">Reset Password</h1>
      <div className="reset-password__body">
        <ResetPasswordForms id={id} />
      </div>
    </div>
  );
};

export default ResetPassword;
