export const advertisementCreationSteps = {
  base: 'base-information',
  bloggersFiltering: 'bloggers-filtering',
  ads: 'ads',
  auto: 'AUTO_SEARCH',
  manual: 'CUSTOM_SEARCH',
  featured: 'FAVORITE_BLOGGERS',
  choiceBloggers: 'CUSTOM_SEARCH',
  advertisementLaunch: 'advertisement-launch',
  budget: 'budget',
};
