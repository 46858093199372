import book from './book';

import Home from '../pages/home/Home';

import Blog from '../pages/blog/Blog';
import BlogItem from '../pages/blogItem/BlogItem';
import BloggerList from '../pages/bloggerList/BloggerList';
import Finance from '../pages/finance/Finance';
import MyCompanies from '../pages/myCompanies/MyCompanies';
import Advertisement from '../pages/advertisement/Advertisement';
import CampaignsAdvertisements from '../pages/CampaignsAdvertisements/CampaignsAdvertisements';
import Paywall from '../pages/paywall/Paywall';
import Settings from '../pages/settings/Settings';
import Statistics from '../pages/statistics/Statistics';
import NotFound from '../pages/notFound/NotFound';
import Profile from '../pages/profile/Profile';

import CompanyContainer from '../pages/newCompany/CompanyContainer';
import Advertisers from '../pages/landing/Advertisers/Advertisers';
import Bloggers from '../pages/landing/Bloggers/Bloggers';
import Referrals from '../pages/landing/Referrals/Referrals';
import Agreement from '../pages/landing/Conditions/Agreement';
import Policy from '../pages/landing/Conditions/Policy';
import ResetPassword from '../pages/resetPassword/resetPassword';
import AccountBlogger from '../pages/accountBlogger/AccountBlogger';
import Offers from '../pages/offers/Offers';
import CpaOffer from '../pages/cpaoffer/CpaOffer';
import Accounts from '../pages/accounts/Accounts';
import Support from '../pages/support/Support';
import ErrorComponent from '../component/ErrorComponent/ErrorComponent';
import React from 'react';
import Referral from '../pages/referral/referral';

export const privateRoutes = [
  {
    isExact: true,
    path: `${book.home}`,
    component: Home,
    role: 'ALL',
  },
  {
    path: `${book.bloggerList}`,
    component: BloggerList,
    role: 'ADVERTISER',
  },
  {
    path: `${book.finance}`,
    component: Finance,
    role: 'ALL',
  },
  {
    path: `${book.myCompanies}`,
    component: MyCompanies,
    isExact: true,
    role: 'ADVERTISER',
  },
  {
    path: `${book.myCompaniesItem}`,
    component: CampaignsAdvertisements,
    isExact: true,
    role: 'ADVERTISER',
  },
  {
    path: `${book.paywall}`,
    component: Paywall,
    role: 'ADVERTISER',
  },
  {
    path: `${book.settings}`,
    component: Settings,
    role: 'ALL',
  },
  {
    path: `${book.statistics}`,
    component: Statistics,
    isExact: true,
    role: 'ALL',
  },
  {
    path: `${book.statisticsItem}`,
    component: Advertisement,
    isExact: true,
    role: 'ADVERTISER',
  },
  {
    path: `${book.myCompaniesAdvertisementsItem}`,
    component: Advertisement,
    isExact: true,
    role: 'ADVERTISER',
  },
  {
    path: `${book.notFound}`,
    component: NotFound,
    role: 'ALL',
  },
  {
    path: `${book.profile}`,
    component: Profile,
    role: 'ALL',
  },

  //new company

  {
    path: `${book.basicInformation}`,
    component: CompanyContainer,
    isExact: true,
    role: 'ADVERTISER',
  },
  {
    path: `${book.offers}`,
    component: Offers,
    isExact: true,
    role: 'BLOGGER',
  },
  {
    path: `${book.accounts}`,
    component: Accounts,
    isExact: true,
    role: 'BLOGGER',
  },
  {
    path: `${book.cpaoffer}`,
    component: CpaOffer,
    isExact: true,
    role: 'BLOGGER',
  },
  {
    path: `${book.support}`,
    component: Support,
    isExact: true,
    role: 'ALL',
  },
  {
    path: `${book.accountBlogger}`,
    component: AccountBlogger,
    isExact: true,
    role: 'BLOGGER',
  },
  {
    path: `${book.accountBloggerError}`,
    component: AccountBlogger,
    isExact: true,
    role: 'BLOGGER',
  },
  {
    path: `${book.referral}`,
    component: () => <Referral />,
    isExact: false,
    role: 'BLOGGER',
  },
];

export const publicRoutes = [
  {
    path: `${book.advertisers}`,
    component: Advertisers,
    isExact: true,
  },
  {
    path: [`${book.bloggers}`, `${book.refer}`],
    component: Bloggers,
    isExact: false,
  },
  {
    path: `${book.referrals}`,
    component: Referrals,
    isExact: true,
  },
  {
    path: `${book.blog}`,
    component: Blog,
    isExact: true,
  },
  {
    path: `${book.blogItem}`,
    component: BlogItem,
    isExact: true,
  },
  {
    path: `${book.agreement}`,
    component: Agreement,
    isExact: true,
  },
  {
    path: `${book.policy}`,
    component: Policy,
    isExact: true,
  },

  {
    path: `${book.resetPassword}`,
    component: ResetPassword,
    isExact: false,
  },
  {
    path: `${book.accessDeniedError}`,
    component: () => <ErrorComponent type={'ACCESS_DENIED'} />,
    isExact: false,
  },
  {
    path: `${book.somethingWrong}`,
    component: () => <ErrorComponent type={'SOMETHING_WRONG'} />,
    isExact: false,
  },
  
];
