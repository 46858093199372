import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';

import { editCampaign } from '../../../redux/store/campaign/campaignOperations';
import { closeModal } from '../../../redux/store/modal/modalActions';

import { formingAnObjectForSendingForm } from '../../../helpers/isEmptyObject';
import { campaignFormSchema } from '../../../schemas/campaignFormSchema';
import FormContainer from '../common/FormContainer/FormContainer';
import { isEmptyObject } from '../../../helpers/isEmptyObject';
import Input from '../common/Fields/Input/Input';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const EditCommonCampaign = ({ details: { _id, title } }) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(campaignFormSchema),
  });

  const dispatch = useDispatch();

  const { dirtyFields } = useFormState({
    control,
  });

  useEffect(() => {
    setValue('title', title);
  }, []);

  const onSubmit = data => {
    const newData = {
      title: data.title,
    };

    if (isEmptyObject(dirtyFields)) {
      dispatch(closeModal());
    } else {
      dispatch(
        editCampaign(_id, formingAnObjectForSendingForm(newData, dirtyFields)),
      );
      dispatch(closeModal());
    }
  };
  const [t] = useTranslation();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <div className="compaign-launch">
        <section className="form-section">
          <FormContainer button={''}>
            <div className="form-block">
              <Input
                errors={errors?.title?.message}
                options={{
                  labelText: t('my_campaigns_modal_edit_label'),
                  placeholderText: t('my_campaigns_modal_edit_placeholder'),
                }}
                inputChangeOptions={register('title')}
              />
            </div>
            <button
              type="submit"
              className="compaign-launch__btn-save custom-btn"
            >
              {t('my_campaigns_modal_edit_button')}
            </button>
          </FormContainer>
        </section>
      </div>
    </form>
  );
};

export default EditCommonCampaign;
