export const step = [
  {
    title: 'referrals_landing_step_item_1_title',
    subtitle: 'referrals_landing_step_item_1_subtitle',
  },
  {
    title: 'referrals_landing_step_item_2_title',
    subtitle: 'referrals_landing_step_item_2_subtitle',
  },
  {
    title: 'referrals_landing_step_item_3_title',
    subtitle: 'referrals_landing_step_item_3_subtitle',
  },
  {
    title: 'referrals_landing_step_item_4_title',
    subtitle: 'referrals_landing_step_item_4_subtitle',
  },
  {
    title: 'referrals_landing_step_item_5_title',
    subtitle: 'referrals_landing_step_item_5_subtitle',
  },
];
