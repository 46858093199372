const initialState = {
  budgetInformationData: {},
  error: null,
  loading: false,
};

export const budgetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    default:
      return state;
  }
};

export default budgetReducer;
