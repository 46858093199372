import types from './globalLoaderTypes';

const initialState = {
  buttonLoader: false,
  globalLoader: false,
  contentLoader: false,
};

export const globalLoaderReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case types.CHANGE_BUTTON_LOADER_STATUS:
      return {
        ...state,
        buttonLoader: payload,
      };
    case types.CHANGE_GLOBAL_LOADER_STATUS:
      return {
        ...state,
        globalLoader: payload,
      };
    case types.CHANGE_CONTENT_LOADER_STATUS:
      return {
        ...state,
        contentLoader: payload,
      };

    default:
      return state;
  }
};
