import { post, get, put } from '../apiService';

export const postLoginUser = data => {
  return post('/auth/login', data);
};

export const postEmail = data => {
  return post('/auth/email-verify', data);
};

export const postConfirmationData = data => {
  return post('/auth/signin-with-email', data);
};

export const postPersonalInformation = (data, referralId) => {
  return post(
    `/auth/registration${referralId ? '?referrer=' + referralId : ''}`,
    data,
  );
};

export const getCurrentUserInformation = () => {
  return get('/user/me');
};

export const googleAuth = (data, referralId) => {
  return post(
    `/auth/login-with-service${referralId ? '?referrer=' + referralId : ''}`,
    data,
  );
};

export const facebookAuth = (data, referralId) => {
  return post(
    `/auth/login-with-facebook${referralId ? '?referrer=' + referralId : ''}`,
    data,
  );
};

export const logoutSocialAuth = () => {
  return get('/auth/logout');
};

export const postForgotPasswordForm = body => {
  return post('/auth/forgot-password', body);
};
export const postResetPassword = body => {
  return post('/auth/reset-password', body);
};
export const postUpdateToken = (refreshToken, fcmToken, deviceId) => {
  const body = { refreshToken, deviceId };
  if (fcmToken) {
    body.fcmToken = fcmToken;
  }
  return post('/auth/update-token', body);
};

export const putUser = body => {
  return put('/user/', body);
};

export const putUserConfirmationEmail = body => {
  return put('/user/change-email-by-code/', body);
};
