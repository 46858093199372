import * as api from '../../../api/notifications-service/notifications-service';

import { changeContentLoaderStatus } from '../globalLoader/globalLoaderActions';
import { userNotificationSelector } from '../../selectors/selectors';
import * as userNotificationActions from './notificationActions';

import { handleNotificationRedirect } from '../../../helpers/handleNotificationRedirect';

export const fetchUserNotifications = limit => dispatch => {
  const isAuth = localStorage.getItem('session');
  if (!isAuth) return;

  dispatch(userNotificationActions.getUserNotificationsStart());
  dispatch(changeContentLoaderStatus(true));

  return api
    .getUserNotifications(limit)
    .then(notifications => {
      dispatch(
        userNotificationActions.getUserNotificationsSuccess(notifications),
      );
    })
    .catch(({ message }) =>
      dispatch(userNotificationActions.getUserNotificationsError(message)),
    )
    .finally(() => dispatch(changeContentLoaderStatus(false)));
};

export const editNotifcationIsRead =
  (id, action, actionId, isRead, history) => (dispatch, getState) => {
    dispatch(userNotificationActions.putUserNotificationIsReadStart());
    const notifications = userNotificationSelector(getState());

    if (isRead === 'READ') {
      handleNotificationRedirect(action, actionId, history);
      return;
    }

    return api
      .putIsReadNotification(id)
      .then(() => {
        const updateMessages = notifications.messages.map(item =>
          item._id === id ? { ...item, isRead: 'READ' } : item,
        );
        const updateNotifications = {
          ...notifications,
          count: notifications.count,
          messages: updateMessages,
          unreadCount: notifications.unreadCount - 1,
        };

        dispatch(
          userNotificationActions.putUserNotificationIsReadSuccess(
            updateNotifications,
          ),
        );
        handleNotificationRedirect(action, actionId, history);
      })
      .catch(({ message }) =>
        dispatch(
          userNotificationActions.putUserNotificationIsReadError(message),
        ),
      );
  };
