import book from '../views/router/book';

export const handleNotificationRedirect = (action, actionId, history) => {
  if (action === 'GO_TO_STATISTICS') {
    history.push(`${book.statistics}/${actionId}`);
  } else if (action === 'GO_TO_ACCOUNTS') {
    history.push(book.accountBlogger);
  } else if (action === 'GO_TO_FINANCE') {
    history.push(book.finance);
  } else if (action === 'GO_TO_OFFERS') {
    history.push(book.offers);
  }
};
