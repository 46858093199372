import types from './campaignTypes';

const initialQueryParams = {
  offset: 0,
  limit: 6,
  status: '&status=ALL',
  sortParams: '&sortBy=title',
  sortItemParams: '&sortBy=publicId',
  orderParams: '&orderBy=desc',
  dateFromParams: '',
  dateToParams: '',
};

const initialState = {
  allCampaigns: {
    items: [],
    count: 0,
  },
  campaignItem: {
    advertisements: {
      items: [],
      count: null,
    },
  },
  params: initialQueryParams,
};

export const campaignReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_CAMPAIGN_LIST_SUCCESS:
      return { ...state, allCampaigns: payload };
    case types.SET_SORT_TYPE:
      return {
        ...state,
        params: {
          ...state.params,
          sortParams: payload,
          offset: 0,
        },
      };
    case types.SET_SORT_ITEM_TYPE:
      return {
        ...state,
        params: {
          ...state.params,
          sortItemParams: payload,
          offset: 0,
        },
      };
    case types.SET_ORDER_TYPE:
      return {
        ...state,
        params: {
          ...state.params,
          orderParams: payload,
          offset: 0,
        },
      };
    case types.SET_STATUS:
      return {
        ...state,
        params: {
          ...state.params,
          status: payload,
          offset: 0,
        },
      };
    case types.SET_OFFSET:
      return {
        ...state,
        params: {
          ...state.params,
          offset: payload,
        },
      };
    case types.CLEAR_ALL_PARAMS:
      return {
        ...state,
        params: initialQueryParams,
      };
    case types.GET_CAMPAIGN_SUCCESS:
    case types.POST_CAMPAIGN_SUCCESS:
    case types.SELECTED_CAMPAIGN:
      return { ...state, campaignItem: payload };
    case types.DELETE_SELECTED_CAMPAIGN: {
      return { ...state, campaignItem: initialState.campaignItem };
    }
    case types.DELETE_CAMPAIGN_SUCCESS:
    case types.EDIT_CAMPAIGN_SUCCESS:
      return {
        ...state,
        allCampaigns: {
          ...state.allCampaigns,
          items: payload,
        },
      };
    case types.DELETE_CAMPAIGN_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default campaignReducer;
