import React, { useEffect, useState } from 'react';
import './styles.scss';
import statIcon from '../../../../src/assets/images/statisticsBloggerInfo/stat.svg';
import userIcon from '../../../../src/assets/images/statisticsBloggerInfo/user.svg';
import paywallAvatar from '../../../assets/images/bloggers/paywallAvatar.svg';
import {
  editBloggerListIsFavorite,
  getBlogger,
} from '../../../redux/store/instagram/instagramOperations';
import { getBloggerIngo } from '../../../api/instagram-service/instagram-service';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../common/Button/Button';
import * as api from '../../../api/instagram-service/instagram-service';
import * as instagramActions from '../../../redux/store/instagram/instagramActions';
import { sessionPersonalInformationSelector } from '../../../redux/selectors/selectors';
import { openModal } from '../../../redux/store/modal/modalActions';
import { modalTypes } from '../../../configs/modalTypes';
import Rating from '../../component/common/Rating/Rating';
import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';
import { setErrorHandler } from '../../../redux/store/errorHandler/errorHandlerOperations';
import { useGetUserRole } from '../../../hooks/getUserRole';
import { withTranslation } from 'react-i18next';

const StatisticsBloggerInfo = ({ details, closeModal, t }) => {
  const [body, setBody] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [showLoaderButton, setShowLoaderButton] = useState(false);
  const userRole = useGetUserRole();
  const { isPaywalled } = useSelector(sessionPersonalInformationSelector);

  const arr = ['13-17', '18-24', '35-44', '45-54', '55-64', '65'];
  const [isFavorite, setIsFavorite] = useState(() =>
    details.type === 'featured' ? true : details.isFavorite,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getBloggerIngo(details.id)
      .then(e => {
        const statistics = arr.map(item =>
          e.statistics.reduce((acum, b) => {
            if (b.ageCategory === item) {
              acum[b.gender + 'Score'] = b.score;
              acum.ageCategory = b.ageCategory;
            }
            return acum;
          }, {}),
        );
        setBody({ ...e, statistics });
        setShowLoader(false);
      })
      .catch(r => dispatch(setErrorHandler(r)));
    getBlogger(details);
  }, []);

  const { statistics, accountId } = body;
  const loader = () => {
    return showLoader ? <div className="lds-dual-ring" /> : null;
  };
  const _onClick = () => {
    const data = {
      accountId: details.id,
      status: !isFavorite,
    };
    setShowLoaderButton(true);
    api
      .putApprovedAccountsFavoriteStatus(data)
      .then(({ message }) => {
        setShowLoaderButton(false);
        dispatch(editBloggerListIsFavorite(data, details.type));
        setIsFavorite(e => !e);
        dispatch(
          setNotifyStatus({
            title: message,
            type: 'success',
          }),
        );
      })
      .catch(({ message }) => {
        dispatch(
          instagramActions.putApprovedAccountsFavoriteStatusError(message),
        );
        setShowLoaderButton(false);
      });
  };
  const _onClickUpdate = () => {
    const {
      pricePerPost,
      storiesPerDay,
      accountSubject,
      accountGender,
    } = body?.accountId;
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.instagramStatisticsApprove,
        details: {
          id: details.id,
          type: 'UPDATE_PRISE',
          body: { pricePerPost, storiesPerDay, accountSubject, accountGender },
        },
      }),
    );
  };
  const getButton = () => {
    if (userRole === 'BLOGGER') {
      return (
        <Button
          options={{
            text: t('account_account_blogger_statistics'),
          }}
          handleButtonClick={_onClickUpdate}
        />
      );
    } else {
      return details?.type === 'tertiary' ? (
        showLoaderButton ? (
          <div className="statistics-blogger__container-loader">
            <div className="lds-dual-ring" />
          </div>
        ) : (
          <Button
            options={{
              text: t(
                !isFavorite
                  ? 'blogger_list_account_add_favorites'
                  : 'blogger_list_account_remove_favorites',
              ),
              renderType: !isFavorite ? 'primary' : 'secondary',
            }}
            handleButtonClick={_onClick}
          />
        )
      ) : null;
    }
  };
  return (
    <div className="statistics-blogger">
      <>
        <h2 className="statistics-blogger-title">
          {t('blogger_list_account_blogger_statistics')}
        </h2>
        <div className="statistics-blogger__body">
          <div className="statistics-blogger__left statistics-blogger_border">
            <div className="statistics-blogger__left__header">
              <img
                src={accountId?.avatar ? accountId?.avatar : paywallAvatar}
                alt={'avatar'}
                className="statistics-blogger__left__header-logo"
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = paywallAvatar;
                }}
              />
              {!isPaywalled && (
                <p className="statistics-blogger__left__header-nickname">
                  @{accountId?.userName}
                </p>
              )}
              <p className="statistics-blogger__left__header-type-page">
                {accountId?.accountType}
              </p>
              <div className="statistics-blogger__left__header-container">
                <div className="statistics-blogger__left__header-icon">
                  <img src={statIcon} alt={' '} />
                  <img src={userIcon} alt={' '} />
                </div>
                <div className="statistics-blogger__left__header-rating">
                  <Rating rating={accountId?.accountQuality} />
                </div>
              </div>
            </div>
            <div className="statistics-blogger__left__item">
              <p className="statistics-blogger__left__item-name">
                {t('blogger_list_account_followers')}
              </p>
              <p className="statistics-blogger__left__item-value">
                {accountId?.subscribersCount}
              </p>
            </div>
            <div className="statistics-blogger__left__item">
              <p className="statistics-blogger__left__item-name">
                {t('blogger_list_account_audience_reach')}
              </p>
              <p className="statistics-blogger__left__item-value">
                {accountId?.audienceReach}
              </p>
              {loader()}
            </div>
            <div className="statistics-blogger__left__item">
              <p className="statistics-blogger__left__item-name">
                {t('blogger_list_account_story_views')}
              </p>
              <p className="statistics-blogger__left__item-value">
                {accountId?.storiesViews}
              </p>{' '}
              {loader()}
            </div>
            <div className="statistics-blogger__left__item">
              <p className="statistics-blogger__left__item-name">
                {t('blogger_list_account_stories_day')}
              </p>
              <div className="statistics-blogger__left__item-value">
                {loader()}
                <span>{accountId?.storiesPerDay}</span>
              </div>
            </div>
            <div className="statistics-blogger__left__item">
              <p className="statistics-blogger__left__item-name">
                {t('blogger_list_account_price_views')}
              </p>
              <div className="statistics-blogger__left__item-value">
                {loader()}
                {accountId?.priceForOneThousand}
              </div>
            </div>
            <div className="statistics-blogger__left__item">
              <p className="statistics-blogger__left__item-name">
                {t('blogger_list_account_price_per_post')}
              </p>
              <div className="statistics-blogger__left__item-value">
                {loader()}
                {accountId?.pricePerPost}
              </div>
            </div>
            <div className="statistics-blogger__left__item">
              <p className="statistics-blogger__left__item-name">
                {t('blogger_list_account_recommended_price')}
              </p>
              <div className="statistics-blogger__left__item-value">
                {loader()}
                {accountId?.recommendedPrice}
              </div>
            </div>
          </div>
          <div className="statistics-blogger__right">
            <table border="1" className="statistics-blogger__right__table">
              <tbody>
                <tr>
                  <th>{t('blogger_list_account_age')}</th>
                  <th>{t('blogger_list_account_all')}</th>
                  <th>{t('blogger_list_account_male')}</th>
                  <th>{t('blogger_list_account_female')}</th>
                </tr>
                {statistics?.map(
                  (
                    { ageCategory, ageMaleScore, ageFemaleScore, allScore },
                    index,
                  ) => (
                    <tr key={index + 'category'}>
                      <td>
                        {ageCategory === '65'
                          ? ageCategory + ' +'
                          : ageCategory}
                      </td>
                      <td>{allScore}</td>
                      <td>{ageMaleScore}</td>
                      <td>{ageFemaleScore}</td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
            <div className="statistics-blogger__right__item statistics-blogger_border">
              <div className="statistics-blogger__right__item-title">
                {t('blogger_list_account_gender')}
              </div>
              <p className="statistics-blogger__right__item-text">
                {t('blogger_list_account_gender_male')}
              </p>
              <div className="statistics-blogger__right__item-text statistics-blogger__right__item-text-value">
                {accountId?.genderGeneral?.men}
                {loader()}
              </div>
              <p className="statistics-blogger__right__item-text">
                {t('blogger_list_account_gender_female')}
              </p>
              <div className="statistics-blogger__right__item-text statistics-blogger__right__item-text-value">
                {accountId?.genderGeneral?.women}
                {loader()}
              </div>
            </div>
            <div className="statistics-blogger__right__item statistics-blogger_border">
              <div className="statistics-blogger__right__item-title">
                {t('blogger_list_account_top_cities')}
              </div>
              {accountId?.locationCities?.map(
                ({ cityName, percent }, index) => (
                  <div
                    key={index + cityName}
                    className="statistics-blogger__place"
                  >
                    <p className="statistics-blogger__right__item-text">
                      {cityName}
                    </p>
                    <p className="statistics-blogger__right__item-text statistics-blogger__right__item-text-value">
                      {percent}
                    </p>
                  </div>
                ),
              )}
            </div>
            <div className="statistics-blogger__right__item statistics-blogger_border">
              <div className="statistics-blogger__right__item-title">
                {t('blogger_list_account_top_countries')}
              </div>
              {accountId?.locationCountries?.map(
                ({ countryName, percent }, index) => (
                  <div
                    key={countryName + index}
                    className="statistics-blogger__place"
                  >
                    <p className="statistics-blogger__right__item-text">
                      {countryName}
                    </p>
                    <p className="statistics-blogger__right__item-text statistics-blogger__right__item-text-value">
                      {percent}
                    </p>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
        {getButton()}
      </>
    </div>
  );
};
export default withTranslation()(StatisticsBloggerInfo);
