import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { resendConfirmationCode } from '../../../../../redux/store/session/sessionOperations';

import './styles.scss';
import { clearTimer, createTimer } from '../../../../../utilites/count';
import { withTranslation } from 'react-i18next';

const ResendCode = ({ resendConfirmationCode, page, t }) => {
  const [timerCount, setTimerCount] = useState(0);

  useEffect(() => {
    if (timerCount > 0) setTimeout(() => setTimerCount(window.time), 1000);
  });
  useEffect(() => {
    createTimer(60, 0);
  }, []);
  useEffect(() => {
    setTimerCount(window.time);
  }, [window.time]);

  const handleResendClick = () => {
    clearTimer();
    createTimer(60, 0, true);
    setTimerCount(60);
    resendConfirmationCode(page);
  };

  return (
    <>
      {timerCount > 0 ? (
        <div className="confirmation-code__resend">
          <p className="confirmation-code__resend-description">
            {t('authentication_confirmation_time')}
            {timerCount}
            {t('authentication_confirmation_seconds')}
          </p>
        </div>
      ) : (
        <div className="confirmation-code__resend">
          <p
            onClick={handleResendClick}
            className="confirmation-code__resend-description-link"
          >
            {t('authentication_confirmation_resend')}
          </p>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  resendConfirmationCode,
};

export default connect(null, mapDispatchToProps)(withTranslation()(ResendCode));
