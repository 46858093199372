import types from './instagramTypes';

export const getInstagramApprovedAccountsStart = () => ({
  type: types.GET_INSTAGRAM_APPROVED_ACCOUNTS_START,
});
export const getInstagramApprovedAccountsSuccess = (data = {}) => ({
  type: types.GET_INSTAGRAM_APPROVED_ACCOUNTS_SUCCESS,
  payload: data,
});
export const getInstagramApprovedAccountsError = (error = '') => ({
  type: types.GET_INSTAGRAM_APPROVED_ACCOUNTS_ERROR,
  payload: error,
});
export const setFilterInfo = data => ({
  type: types.SET_FILTER_INFO,
  payload: data,
});
export const setLink = data => ({
  types: types.SET_LINK,
  payload: data,
});
export const setOffset = data => ({
  type: types.SET_OFFSET,
  payload: data,
});
export const setSearch = data => ({
  type: types.SET_SEARCH,
  payload: data,
});
export const setSortType = data => ({
  type: types.SET_TYPE_SORT_TYPE,
  payload: data,
});
export const setFilterParams = data => ({
  type: types.SET_FILTER_PARAMS,
  payload: data,
});
export const setSearchValue = data => ({
  type: types.SET_SEARCH_VALUE,
  payload: data,
});
export const setDirectionValue = data => ({
  type: types.SET_DIRECTION_PARAMS,
  payload: data,
});
export const setOffsetCount = data => ({
  type: types.SET_OFFSET_COUNT,
  payload: data,
});

export const clearAllParams = () => ({
  type: types.CLEAR_ALL_PARAMS,
});

export const getInstagramFavoriteAccountsStart = () => ({
  type: types.GET_INSTAGRAM_FAVORITE_ACCOUNTS_START,
});

export const getInstagramFavoriteAccountsError = (error = '') => ({
  type: types.GET_INSTAGRAM_FAVORITE_ACCOUNTS_ERROR,
  payload: error,
});

// put favorite status
export const putApprovedAccountsFavoriteStatusStart = () => ({
  type: types.PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_START,
});

export const putApprovedAccountsFavoriteStatusSuccess = (data = {}) => ({
  type: types.PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_SUCCESS,
  payload: data,
});

export const putApprovedAccountsFavoriteStatusError = (error = '') => ({
  type: types.PUT_INSTAGRAM_APPROVED_ACCOUNTS_FAVORITE_STATUS_ERROR,
  payload: error,
});
export const setAccountBlogger = data => ({
  type: types.SET_ACCOUNT_BLOGGER,
  payload: data,
});
export const saveAutoSearchInstagramAccount = data => ({
  type: types.GET_AUTO_SEARCH_INSTAGRAM_ACCOUNT,
  payload: data,
});
