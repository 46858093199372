import exchange from '../../../../../../assets/images/landing/profitable/exchange.svg';
import calendar from '../../../../../../assets/images/landing/profitable/calendar.svg';
import time from '../../../../../../assets/images/landing/profitable/time.svg';
import like from '../../../../../../assets/images/landing/profitable/like.svg';

export const profitable = [
  {
    title: 'referrals_landing_profitable_item_1_title',
    subtitle: 'referrals_landing_profitable_item_1_subtitle',
    img: time,
    alt: 'time',
  },
  {
    title: 'referrals_landing_profitable_item_2_title',
    subtitle: 'referrals_landing_profitable_item_2_subtitle',
    img: like,
    alt: 'like',
  },
  {
    title: 'referrals_landing_profitable_item_3_title',
    subtitle: 'referrals_landing_profitable_item_3_subtitle',
    img: exchange,
    alt: 'exchange',
  },
  {
    title: 'referrals_landing_profitable_item_4_title',
    subtitle: 'referrals_landing_profitable_item_4_subtitle',
    img: calendar,
    alt: 'calendar',
  },
];
