import './LandingNew.css';
import CustomerCase from './components/CustomerCase/CustomerCase.js';
import FAQ from './components/FAQ/FAQ.js';
import Footer from './components/Footer/Footer.js';
import GetDemo from './components/GetDemo/GetDemo.js';
import Header from './components/Header/Header.js';
import HowItWorks from './components/HowItWorks/HowItWorks.js';
import OurBloggers from './components/OurBloggers/OurBloggers.js';
import Reviews from './components/Reviews/Reviews.js';
import Pricing from './components/Pricing/Pricing.js';
import { useState } from 'react';
import ContactUsPopup from './components/ContactUsPopup/ContactUsPopup.js';

function LandingNew() {
  const [isOpen, setisOpen] = useState(false)
  const toggleOpen = () => {
    setisOpen(true)
  }
  return (
    <div style={{width: "100vw"}} className='landing-new'>
      <Header setisOpen={toggleOpen}/>
      <GetDemo  setisOpen={setisOpen}/>
      <HowItWorks  setisOpen={setisOpen}/>
      <OurBloggers  setisOpen={setisOpen}/>
      <Pricing  setisOpen={setisOpen}/>
      <Reviews  setisOpen={setisOpen}/>
      <FAQ  setisOpen={setisOpen}/>
      <Footer  setisOpen={setisOpen}/>
      <ContactUsPopup isPopupOpen={isOpen} setisPopupOpen={setisOpen} />
    </div>
  );
}

export default LandingNew;
