import { ReactComponent as ArrowIcon } from '../../../../../assets/images/vectors/arrow.svg';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const PreviousStep = ({ changeCurentStep }) => {
  const [t] = useTranslation();

  return (
    <div onClick={changeCurentStep} className="auth-form__back">
      <ArrowIcon className="auth-form__back-icon" />
      <p className="auth-form__back-description">{t('return_previous_step')}</p>
    </div>
  );
};

export default PreviousStep;
