import * as api from '../../../../api/advertisement-service/advertisement-service';

import * as globalLoaderActions from '../../globalLoader/globalLoaderActions';
import * as advertisementСreationActions from './creationDataActions';
import * as advertisementActions from '../advertisement/advertisementActions';
import * as campaignActions from '../../campaign/campaignActions';
import * as choiseBloggersActions from '../choiceBloggers/choiceBloggersActions';
import { createFormData } from '../../../../helpers/formDataConvertor';

import { changeCreationAdvertisementStep } from '../creationStatus/creationStatusActions';
import {
  advertisementSelector,
  newAdvertisementDataSelector,
} from '../../../selectors/selectors';
import { advertisementCreationSteps } from '../../../../constants/advertisementCreationSteps';
import { setNotifyStatus } from '../../notify/notifyActions';

export const editAdvertisement = (id, data) => (dispatch, getState) => {
  const { budget } = newAdvertisementDataSelector(getState());
  dispatch(advertisementСreationActions.editAdvertisementStart());
  dispatch(globalLoaderActions.changeGlobalLoaderStatus(true));

  const changedBaseInfoDataFormat = createFormData(data);

  return api
    .putAdvertisementById(id, changedBaseInfoDataFormat)
    .then(advertisement => {
      dispatch(
        advertisementСreationActions.editAdvertisementSuccess({
          ...advertisement,
          budget,
        }),
      );
      dispatch(
        setNotifyStatus({
          title: 'Your advertisement was successfully edited',
          type: 'success',
        }),
      );
    })
    .catch(({ message }) =>
      dispatch(advertisementСreationActions.editAdvertisementError(message)),
    )
    .finally(() =>
      dispatch(globalLoaderActions.changeGlobalLoaderStatus(false)),
    );
};

export const postAdvertisement =
  (id, history, book) => (dispatch, getState) => {
    dispatch(advertisementСreationActions.postAdvertisementStart());
    const advertisement = advertisementSelector(getState());

    const updatedAds = {
      ...advertisement,
      status: 'ACTIVE',
    };

    return api
      .postAdvertisementById(id)
      .then(() => {
        dispatch(
          setNotifyStatus({
            title: 'Your advertisement was successfully created',
            type: 'success',
          }),
        );
        dispatch(campaignActions.getCampaignSuccess(updatedAds));
        dispatch(advertisementActions.getAdvertisementSuccess(updatedAds));
        dispatch(
          advertisementСreationActions.postAdvertisementSuccess(updatedAds),
        );

        dispatch(
          changeCreationAdvertisementStep({
            activeStep: 0,
            doneSteps: [],
            activePage: advertisementCreationSteps.base,
          }),
        );
        dispatch(
          advertisementСreationActions.clearAdvertisementInfoAfterPost(),
        );
        dispatch(choiseBloggersActions.clearChoiseBloggersInformation());
        dispatch(campaignActions.deleteSelectedCampaign());
        history.push(book.statistics);
      })
      .catch(({ message }) => {
        dispatch(
          setNotifyStatus({
            title: message,
            type: 'error',
          }),
        );
      });
  };
