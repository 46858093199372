const ErrorInternetConnection = () => (
  <svg
    width="340"
    height="222"
    viewBox="0 0 340 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M110.87 90.5C104.57 98.9753 100.004 108.817 97.6988 119.5M96.7244 125C96.3815 127.464 96.1576 129.966 96.0586 132.5"
      stroke="#988EAF"
      stroke-width="3.5"
    />
    <path
      d="M266.121 188.014C265.92 180.942 264.239 173.87 261.02 167.294M259.252 163.997C258.41 162.558 257.49 161.149 256.491 159.775"
      stroke="#988EAF"
      stroke-width="3.5"
    />
    <path
      d="M245.445 146.18C246.894 146.905 248.525 147.81 249.792 148.716"
      stroke="#7D4E24"
      stroke-width="3"
      stroke-miterlimit="10"
    />
    <path
      d="M109.046 137.848H51.625C46.5531 137.848 42.2057 142.014 42.2057 147.267C42.2057 152.52 46.3719 156.686 51.625 156.686H68.8333C72.9995 156.686 76.4412 160.128 76.4412 164.294C76.4412 168.46 72.9995 171.902 68.8333 171.902H9.4193C4.34737 171.902 0 176.068 0 181.321C0 186.393 4.16623 190.741 9.4193 190.741H144.007C123.9 181.321 109.771 161.215 109.046 137.848Z"
      fill="#E5E8F2"
    />
    <path
      d="M330.581 98.7215H221.534V104.699C226.969 113.756 230.048 124.443 230.048 135.674C230.048 141.289 229.323 146.543 227.874 151.796H305.765C311.018 151.796 315.184 147.629 315.184 142.376C315.184 137.123 311.018 132.957 305.765 132.957H276.601C272.254 132.957 268.812 129.515 268.812 125.168C268.812 121.002 272.072 117.741 276.058 117.56H330.581C335.834 117.56 340 113.394 340 108.141C340 102.888 335.834 98.7215 330.581 98.7215Z"
      fill="#E5E8F2"
    />
    <path
      d="M196.718 41.8434H150.89C145.637 41.8434 141.47 37.6772 141.47 32.4241C141.47 27.171 145.637 23.0048 150.89 23.0048H162.483C168.823 23.0048 173.894 17.9329 173.894 11.593C173.894 5.25307 168.641 0 162.301 0H106.329C99.9892 0 94.9173 5.07193 94.9173 11.4118C94.9173 17.7518 99.9892 22.8237 106.329 22.8237H113.031C118.284 22.8237 122.451 26.9899 122.451 32.243C122.451 37.496 118.284 41.6623 113.031 41.6623H113.756C107.416 41.6623 102.344 46.7342 102.344 53.0741C102.344 59.414 107.416 64.4859 113.756 64.4859H196.899C203.239 64.4859 208.311 59.414 208.311 53.0741C208.311 46.9153 203.058 41.8434 196.718 41.8434Z"
      fill="#E5E8F2"
    />
    <path
      d="M203.058 25.3597H196.537C192.009 25.3597 188.386 21.7369 188.386 17.2084C188.386 12.6799 192.009 9.05706 196.537 9.05706H203.058C207.587 9.05706 211.21 12.6799 211.21 17.2084C211.21 21.7369 207.587 25.3597 203.058 25.3597Z"
      fill="#E5E8F2"
    />
    <path
      d="M24.9973 143.825H18.4762C13.9477 143.825 10.3249 140.203 10.3249 135.674C10.3249 131.146 13.9477 127.523 18.4762 127.523H24.9973C29.5258 127.523 33.1486 131.146 33.1486 135.674C33.3297 140.203 29.5258 143.825 24.9973 143.825Z"
      fill="#E5E8F2"
    />
    <path
      d="M70.1018 88.2153C75.604 88.2153 80.0645 83.7548 80.0645 78.2526C80.0645 72.7503 75.604 68.2899 70.1018 68.2899C64.5995 68.2899 60.139 72.7503 60.139 78.2526C60.139 83.7548 64.5995 88.2153 70.1018 88.2153Z"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M241.46 36.5902V51.6249M233.852 44.1983H248.887"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M162.845 155.78C159.041 159.584 159.041 165.743 162.845 169.366C166.649 173.17 172.808 173.17 176.431 169.366C180.235 165.562 180.235 159.403 176.431 155.78C172.627 152.158 166.468 152.158 162.845 155.78Z"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M169.729 127.342C159.585 127.342 150.165 131.327 142.92 138.391C140.022 141.108 140.022 145.818 142.92 148.535C145.637 151.252 150.347 151.252 153.064 148.535C157.411 144.006 163.389 141.652 169.729 141.652C176.069 141.652 181.865 144.006 186.394 148.535C187.843 149.984 189.654 150.709 191.465 150.709C193.277 150.709 195.088 149.984 196.537 148.535C199.436 145.818 199.436 141.108 196.537 138.391C189.292 131.327 179.872 127.342 169.729 127.342Z"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M132.233 115.567C130.059 117.198 128.067 118.828 126.255 120.821C123.538 123.538 123.538 128.247 126.255 130.964C129.154 133.681 133.682 133.681 136.399 130.964C145.275 122.088 157.23 117.198 169.729 117.198C182.409 117.198 194.183 122.088 203.059 130.964C204.508 132.413 206.319 133.138 208.131 133.138C209.942 133.138 211.754 132.413 213.203 130.964C215.92 128.247 215.92 123.538 213.203 120.821C201.61 109.228 186.213 102.706 169.729 102.706C161.94 102.706 154.513 104.156 147.63 106.873"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M142.014 109.409C140.384 110.314 138.753 111.22 137.123 112.126"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M158.897 211.151L158.897 207.103L162.948 207.103L162.948 211.151L158.897 211.151Z"
      fill="#988EAF"
    />
    <path
      d="M166.999 211.151L166.999 207.103L171.05 207.103L171.05 211.151L166.999 211.151Z"
      fill="#988EAF"
    />
    <path
      d="M175.1 211.151L175.1 207.103L179.151 207.103L179.151 211.151L175.1 211.151Z"
      fill="#988EAF"
    />
    <path
      d="M241.151 110.151L237.103 110.151L237.103 106.101L241.151 106.101L241.151 110.151Z"
      fill="#988EAF"
    />
    <path
      d="M241.151 102.05L237.103 102.05L237.103 97.9989L241.151 97.9989L241.151 102.05Z"
      fill="#988EAF"
    />
    <path
      d="M241.151 93.9481L237.103 93.9481L237.103 89.8973L241.151 89.8973L241.151 93.9481Z"
      fill="#988EAF"
    />
    <path
      d="M211.572 92.2003C210.123 90.7512 208.493 89.4832 206.862 88.2152M202.153 84.7736C192.734 78.796 181.503 75.1732 169.548 75.1732C136.037 75.1732 108.866 102.344 108.866 135.855C108.866 169.366 136.037 196.537 169.548 196.537C176.25 196.537 182.59 195.45 188.567 193.458C206.5 187.48 220.81 173.532 226.788 155.599C228.962 149.441 230.049 142.739 230.049 135.855C230.049 120.821 224.614 107.235 215.738 96.7289"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M221.353 219.904C239.261 219.904 253.777 205.388 253.777 187.48C253.777 169.573 239.261 155.056 221.353 155.056C203.446 155.056 188.929 169.573 188.929 187.48C188.929 205.388 203.446 219.904 221.353 219.904Z"
      fill="#F4F5F9"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M301.511 191.025C306.212 191.025 310.022 187.214 310.022 182.512C310.022 177.811 306.212 174 301.511 174C296.811 174 293 177.811 293 182.512C293 187.214 296.811 191.025 301.511 191.025Z"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
    <path
      d="M205.775 172.808L236.932 204.145M205.775 204.145L236.932 172.808"
      stroke="#988EAF"
      stroke-width="3.5"
      stroke-miterlimit="10"
    />
  </svg>
);
export default ErrorInternetConnection;
