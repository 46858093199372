import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';

import { fetchNews } from '../../../redux/store/news/newsOperations';
import { newsSelector } from '../../../redux/selectors/selectors';

import BlogNavigation from '../../component/BlogItem/BlogNavigation/BlogNavigation';
import ErrorComponent from '../../component/ErrorComponent/ErrorComponent';
import { typesErrorComponent } from '../../../configs/errorComponentTypes';
import CustomSlider from '../../component/BlogItem/BlogSlider/BlogSlider';
import { compareKeyBySelect } from '../../../helpers/compareKeyBySelect';
import BlogItemComponent from '../../component/BlogItem/BlogItem';
import { categoriesNews } from '../../../constants/newsList';

import categoryImage from '../../../assets/images/landing/blog/category.svg';
import dateImage from '../../../assets/images/landing/blog/date.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const BlogItem = ({
  match: {
    params: { id },
  },
}) => {
  const newsItem = useSelector(newsSelector);
  const {
    banner,
    data,
    category,
    title,
    summary,
    body,
    _id,
    similarNews,
  } = newsItem;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  useEffect(() => {
    dispatch(fetchNews(id));
  }, [dispatch, id]);

  return (
    <>
      <Helmet>
        <title>{`${
          title || 'Blog about advertising on Instagram'
        } - Bloggerly`}</title>
        <meta
          name="description"
          content={
            summary ||
            'Check the latest news about our service, Instagram and all the lifehacks we will share with you!'
          }
        />
      </Helmet>
      <div className="news-item">
        <div className="news-item__navigation">
          <BlogNavigation background="white" t={t} />
        </div>
        {_id ? (
          <>
            <div className="news-item__banner">
              <img
                className="news-item__banner-img"
                src={banner}
                alt="banner"
              />
            </div>
            <div className="news-item__body">
              <h1 className="news-item__body-title">{title}</h1>
              <div className="news-item__body-content">
                <div className="news-item__body-content">
                  <img src={dateImage} alt="date" />
                  <span className="news-item__body-content-data">
                    {dayjs(data).format('DD.MM.YYYY')}
                  </span>
                  <img src={categoryImage} alt="category" />
                  <span className="news-item__body-content-category">
                    {category &&
                      t(compareKeyBySelect(categoriesNews, category))}
                  </span>
                </div>
              </div>
              <div className="news-item__body-description">
                <section
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></section>
              </div>
            </div>
            {similarNews.length ? <CustomSlider items={similarNews} /> : null}
            {similarNews.length ? (
              <div className="news-item__related">
                <h1 className="news-item__related-content-title">
                  Related posts
                </h1>
                <div className="news-item__related-content">
                  {similarNews.map(news => (
                    <BlogItemComponent key={news._id} news={news} />
                  ))}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <div>
            <ErrorComponent type={typesErrorComponent.NOT_FOUND_SEARCH} />
          </div>
        )}
      </div>
    </>
  );
};

export default BlogItem;
