import types from './creationStatusTypes';

export const changeCreationAdvertisementStep = step => ({
  type: types.CHANGE_CREATION_STATUS_STEP,
  payload: step,
});

export const clearCreationAdvertisementStep = () => ({
  type: types.CLEAR_CREATION_STATUS_STEP,
});
