import * as yup from 'yup';

export const amountRefill = yup.object().shape({
  amount: yup
    .number('Amount must be a number')
    .typeError('Amount must be a number')
    .required('The number is required!')
    .test('The number must be greater than 0!', value => value > 0),
});
export const amountWithdrawal = yup.object().shape({
  amount: yup
    .number()
    .typeError('finance_blogger_error_1_amount')
    .required('finance_blogger_error_2_amount')
    .test('finance_blogger_error_3_amount', value => value > 0),
});
export const amountWithdrawalPayTM = yup.object().shape({
  channel: yup.string().required('finance_blogger_payTm_error_1_channel'),
  account: yup
    .number()
    .typeError('finance_blogger_payTm_error_1_account')
    .required('finance_blogger_payTm_error_2_account'),
});
export const amountWithdrawalUPL = yup.object().shape({
  channel: yup.string().required('finance_blogger_UPL_error_1_channel'),
  account: yup
    .number()
    .typeError('finance_blogger_UPL_error_1_account')
    .required('finance_blogger_UPL_error_2_account'),
  accountName: yup.string().required('finance_blogger_UPL_error_1_accountName'),
  bankCode: yup
    .string()
    .required('finance_blogger_UPL_error_1_bankCode')
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'finance_blogger_UPL_error_2_bankCode'),
});
