import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '../../../common/Button/Button';
import Input from '../../../common/Fields/Input/Input';
import Social from '../../shared/Social/Social';
import AuthFormContainer from '../../shared/Container/Container';

import { sendEmail } from '../../../../../redux/store/session/sessionOperations';
import {
  sessionFormLoadingStatusSelector,
  sessionFormErrorSelector,
} from '../../../../../redux/selectors/selectors';
import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';
import { email } from '../../../../../schemas/authFormsSchemas';

import './styles.scss';
import '../../general.scss';
import { sessionAuthenticationSteps } from '../../../../../constants/sessionAuthenticationSteps';
import { changeAuthenticationStep } from '../../../../../redux/store/session/sessionActions';
import SwitchItem from '../../switch/switch';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const ConfirmationEmailForm = ({
  loadingStatus,
  sendEmail,
  changeAuthenticationStep,
  setNotifyStatus,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isValid },
    setValue,
    watch,
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(email),
  });
  const [typeUser, setTypeUser] = useState('BLOGGER');
  const [isChecked, setIsChecked] = useState(true);
  const [referralId, setReferralId] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    const linkInfo = window.location?.search?.split('?referrer=');

    if (linkInfo && linkInfo[1]) {
      setReferralId(linkInfo[1]);
      setIsChecked(true);
      setTypeUser('BLOGGER');
    }
  }, []);
  useEffect(() => {
    const subscription = watch(({ email }, { name, type }) => {
      if (name === 'email' && email.length > 3 && email.includes(' ')) {
        setValue('email', email.replace(' ', ''));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const _onClickLogin = () => {
    changeAuthenticationStep(sessionAuthenticationSteps.login);
  };
  const handleFormSubmit = data => {
    sendEmail({ email: data, role: typeUser, referralId }).catch(err =>
      setNotifyStatus({ title: err, type: 'error' }),
    );
  };
  const _onSelectTypeUser = type => {
    setTypeUser(type);
    setIsChecked(e => !e);
  };
  return (
    <form className="auth-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <AuthFormContainer name={t('authentication_registration')}>
        <SwitchItem onSelect={_onSelectTypeUser} isChecked={isChecked} t={t} />
        <div className="auth-form-block">
          <Input
            options={{
              placeholderText: t(
                'authentication_confirmation_email_placeholder',
              ),
              labelText: t('authentication_confirmation_email_email'),
            }}
            inputChangeOptions={register('email')}
          />
        </div>
        <Social role={typeUser} referralId={referralId} />
        <div className="auth-form-button-container">
          <Button
            loadingStatus={loadingStatus}
            options={{
              text: 'Continue',
              renderType: 'primary',
              size: 'l',
              type: 'submit',
              disabled: !isValid,
            }}
          />
          <button
            className="auth-form-button-container-subButton"
            onClick={_onClickLogin}
          >
            {t('authentication_confirmation_email_button')}
          </button>
        </div>
      </AuthFormContainer>
    </form>
  );
};

const mapStateToProps = state => ({
  loadingStatus: sessionFormLoadingStatusSelector(state),
  requestError: sessionFormErrorSelector(state),
});

const mapDispatchToProps = {
  sendEmail,
  changeAuthenticationStep,
  setNotifyStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ConfirmationEmailForm));
