import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sessionPersonalInformationSelector } from '../../../../redux/selectors/selectors';
import {
  getUserInfo,
  sessionlogOut,
} from '../../../../redux/store/session/sessionOperations';

import book from '../../../router/book';

import arrow from '../../../../assets/images/header/arrow.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const Profile = ({ t }) => {
  const [selectStatus, changeSelectStatus] = useState(false);
  const { firstName, lastName } = useSelector(
    sessionPersonalInformationSelector,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const toggleVisibleSelect = () => {
    changeSelectStatus(!selectStatus);
  };

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  return (
    <div
      className="profile"
      onMouseEnter={toggleVisibleSelect}
      onMouseLeave={toggleVisibleSelect}
    >
      <span className="profile__title">
        {firstName} {lastName}
      </span>
      <img
        className={selectStatus ? 'profile__arrow-active' : 'profile__arrow'}
        src={arrow}
        alt="arrow"
      />

      <div className="profile__dropdown-item" />
      {selectStatus && (
        <div className="profile__dropdown">
          <Link to={book.settings}>
            <span className="profile__dropdown-item-title">
              {t('header_in_login_menu_profile')}
            </span>
          </Link>
          <Link to={book.advertisers}>
            <span
              onClick={() => dispatch(sessionlogOut(history))}
              className="profile__dropdown-item-title"
            >
              {t('header_in_login_menu_logout')}
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Profile);
