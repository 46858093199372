import types from './financeTypes';

export const advertiserBudget = payload => ({
  type: types.GET_ADVERTISEMENT_BUDGET,
  payload,
});
export const transactionHistoryAdvertiser = payload => ({
  type: types.GET_TRANSACTION_HISTORY_ADVERTISER,
  payload,
});
export const transactionHistoryBlogger = payload => ({
  type: types.GET_TRANSACTION_HISTORY_BLOGGER,
  payload,
});
export const advertiserBlogger = payload => ({
  type: types.GET_BLOGGER_BUDGET,
  payload,
});
export const errorWithdrawal = payload => ({
  type: types.ERRORS_WITHDRAWAL,
  payload,
});
export const errorRefill = payload => ({
  type: types.ERROR_REFILL,
  payload,
});
