import React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  Join,
  Profitable,
  Partnership,
  Banner,
  Question,
  Step,
  Advantages,
  SubHeader,
} from './component/index';
import { useTranslation } from 'react-i18next';

const Referrals = () => {
  const [t] = useTranslation();

  return (
    <>
      <Helmet>
        <title>Bloggerly partnership program - Bloggerly</title>
        <meta
          name="description"
          content="Earn up to 50% of the service profit from all users you refer!"
        />
      </Helmet>
      <SubHeader t={t} />
      <Advantages t={t} />
      <Step t={t} />
      <Join t={t} />
      <Profitable t={t} />
      <Partnership t={t} />
      <Question t={t} />
      <Banner t={t} />
    </>
  );
};

export default Referrals;
