import { useForm, Controller, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  baseInformationDataSelector,
  creationStatusSelector,
  campaignSelector,
  baseInfoFormSelector,
  storyFormSelector,
  advertisementSelector,
  buttonLoaderStatusSelector,
} from '../../../../../redux/selectors/selectors';

import { baseInformationFormSchema } from '../../../../../schemas/baseInformationFormSchema';
import ModeratorComment from '../../../common/Fields/ModeratorComment/ModeratorComment';
import { handleStoryContent } from '../../../../../helpers/correctShowBanner';
import { CampaignSelect, ContentSelect, DateTimeSelect , ContentSelectInstagram} from './Selects';
import FormContainer from '../../../common/FormContainer/FormContainer';
import Select from '../../../common/Fields/Select/Select';
import Input from '../../../common/Fields/Input/Input';
import {
  createCorrectData,
  checkTypeOperation,
} from '../../../../../helpers/updateBaseInfoData';
import Button from '../../../common/Button/Button';
import {
  contentTypesSelectOptions,
  improvisavionTypeDescriptions,
  contentTypesSelectDescription,
} from '../../../../../configs/selectOptions';

import './styles.scss';
import { withTranslation } from 'react-i18next';
import { get } from '../../../../../api/apiService';
const CampainTypes = {
  NONE:"none",
  YOUTUBE:"youtube",
  INSTAGRAM:"instagram"
}
const BaseInfoForm = ({ t }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(baseInformationFormSchema),
  });
  const [campaignType, setCampaignType] = useState(CampainTypes.NONE)
  const { typeOperation } = useSelector(creationStatusSelector);
  const loading = useSelector(buttonLoaderStatusSelector);
  const { status } = useSelector(advertisementSelector);
  const { banner } = useSelector(baseInfoFormSelector);
  const campaign = useSelector(campaignSelector);
  const story = useSelector(storyFormSelector);
  const {
    title,
    campaign: campaignInfo,
    startDate,
    endDate,
    _id,
  } = useSelector(baseInformationDataSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const [isValid, setIsValid] = useState({ state: 'initial', valid: false });
  const { dirtyFields } = useFormState({
    control,
  });

  const improvisation = improvisavionTypeDescriptions[watch('story.content')];
  const content = contentTypesSelectDescription[watch('story.contentType')];
  const contentType = watch('story.contentType');
  const storyItem = getValues().story;

  useEffect(() => {
    if (campaign._id) {
      setIsValid({ state: 'changed', valid: false });
    } else if (isValid.state === 'changed' && !campaign._id) {
      setIsValid({ state: 'initial', valid: true });
    }
  }, [campaign._id, isValid.state]);
  
  useEffect(() => {
    if (title) setValue('title', title);
    if (campaignInfo) setValue('campaign', campaignInfo);
    if (campaignInfo) if (campaignInfo.campaignType) localStorage.setItem("campaignType", campaignInfo.campaignType)
    if (endDate) setValue('endDate', dayjs(endDate).format('YYYY-MM-DD'));
    if (startDate) setValue('startDate', dayjs(startDate).format('YYYY-MM-DD'));
    /*if (publishTimeFrom)
      setValue('publishTimeFrom', dayjs(publishTimeFrom).format('hh:mm'));
    if (publishTimeTo)
      setValue('publishTimeTo', dayjs(publishTimeTo).format('hh:mm'));*/
    if (banner) setValue('banner', banner);
    if (story.approved) setValue('story.approved', story.approved);
    if (story.mimeType) setValue('story.mimeType', story.mimeType);
    if (story.content) setValue('story.content', story.content);
    if (story.contentType) setValue('story.contentType', story.contentType);
    if (story.file) setValue('story.file', story.file);
    if (story.filename) setValue('story.filename', story.filename);
    if (story.instruction) setValue('story.instruction', story.instruction);
    if (story.profile) setValue('story.profile', story.profile);
    if (story.site) setValue('story.site', story.site);
    if (story.technicalDescription) setValue('story.technicalDescription', story.technicalDescription);
  }, []);
  const onSubmit = data => {
    if (!campaign._id) {
      window.scroll(0, 0);
      setIsValid({ valid: true, state: 'initial' });
    }

    const newData = createCorrectData(campaign._id, data);

    checkTypeOperation(
      dispatch,
      typeOperation,
      story,
      storyItem,
      history,
      newData,
      _id,
      dirtyFields,
      status,
    );
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <ModeratorComment
        options={{
          approved: story.approved,
          comments: story.comments,
          status,
        }}
      />
      <section className="form-section">
        <FormContainer button={''}>
          <div className="base-info-form__header">
            <div className="form-block">
              <img
                className="base-info-form__header-banner"
                src={handleStoryContent(banner, contentType, story?.mimeType)}
                alt="banner"
              />
            </div>
            <div className="base-info-form__header-attach">
              <CampaignSelect
                campaign={campaign}
                isValid={isValid}
                story={story}
              />
            </div>
          </div>
          <div className="form-block">
            <Input
              errors={errors?.title?.message}
              options={{
                labelText: t('advertisement_BaseInfo_name_label'),
                placeholderText: t('advertisement_BaseInfo_name_placeholder'),
                approved: story.approved,
                status,
              }}
              inputChangeOptions={register('title')}
            />
          </div>
          <DateTimeSelect
            Controller={Controller}
            getValues={getValues}
            control={control}
            errors={errors}
            status={status}
            story={story}
          />
        </FormContainer>
      </section>
      {campaignType === CampainTypes.YOUTUBE &&
      <section className="form-section">
        <FormContainer
          story={story}
          options={{ head: true, text: 'Story'}}
          button={''}
        >
          <ContentSelect
            descrription={improvisation}
            contentType={contentType}
            Controller={Controller}
            register={register}
            control={control}
            errors={errors}
            status={status}
            story={story}
          />
        </FormContainer>
      </section>
      }
      {campaignType === CampainTypes.INSTAGRAM &&
      <section className="form-section">
        <FormContainer
          story={story}
          options={{ head: true, text: 'Story', title: "Instagram story"}}
          button={''}
        >
          <ContentSelectInstagram
            descrription={improvisation}
            contentType={contentType}
            Controller={Controller}
            register={register}
            control={control}
            errors={errors}
            status={status}
            story={story}
          />
              <div className="form-block-container">
            {/*<div className="form-block form-block-part">
              <Input
                errors={errors?.story?.profile?.message}
                inputChangeOptions={register('story.profile')}
                options={{
                  labelText: t('advertisement_BaseInfo_Profile_label'),
                  placeholderText: t(
                    'advertisement_BaseInfo_Upload_placeholder',
                  ),
                  approved: story.approved,
                  status: status,
                  isStory: true,
                }}
              />
              </div>*/}
            <div className="form-block form-block-part">
              <Input
                errors={errors?.story?.site?.message}
                inputChangeOptions={register('story.site')}
                options={{
                  labelText: t('advertisement_BaseInfo_Link_label'),
                  placeholderText: t('advertisement_BaseInfo_Link_placeholder'),
                  typeText: 'url',
                  approved: story.approved,
                  status: status,
                  isStory: true,
                }}
              />
            </div>
          </div>
        </FormContainer>
    
      </section>
      }
      {campaignType === CampainTypes.NONE &&
      <section className="form-section">
        <p> Please select content type </p>
        <Button
          loadingStatus={loading}
          options={{
            text: t(
              'Youtube',
            ),
            renderType: 'primary',
            size: 'm',
          }}
          handleButtonClick={()=>{
            setCampaignType(CampainTypes.YOUTUBE)
            localStorage.setItem('campaignType', CampainTypes.YOUTUBE)
            setValue('campaignType', 'youtube')
          }}
        />
        <Button
          loadingStatus={loading}
          options={{
            text: t(
              "Instagram",
            ),
            renderType: 'primary',
            size: 'm',
          }}
          handleButtonClick={()=>{
            localStorage.setItem('campaignType', CampainTypes.INSTAGRAM)
            setCampaignType(CampainTypes.INSTAGRAM)
            setValue('campaignType', 'instagram')
          }}
        />
      </section>
      }
      <div
        className={
          !story.approved
            ? 'base-info-form__button approved'
            : 'base-info-form__button'
        }
      >
        <Button
          loadingStatus={loading}
          options={{
            disabled: story.approved === null,
            text: t(
              story.approved
                ? 'advertisement_BaseInfo_button_Proceed'
                : 'advertisement_BaseInfo_button_to_moderation',
            ),
            renderType: 'primary',
            type: 'submit',
            size: story.approved ? 'm' : 'l',
          }}
          onClick={()=>{
            localStorage.setItem('currentCompanyId', _id);
          }}
        />
      </div>
    </form>
  );
};

export default withTranslation()(BaseInfoForm);
