import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { getMessaging, onMessage } from 'firebase/messaging';

import { fetchUserNotifications } from '../../redux/store/notification/notificationOperations';
import { globalLoaderStatusSelector } from '../../redux/selectors/selectors';
import { setNotifyStatus } from '../../redux/store/notify/notifyActions';
import { checkingStatusUser } from '../../redux/store/session/sessionOperations';

import GlobalLoader from '../component/loaders/GlobalLoader/GlobalLoader';
import Notifications from '../component/Header/Notification/firebaseNotification';
import Toastify from '../component/Toastify/Toastify';
import Modal from '../component/Modal/RenderModals';
import App from './App';
import { useHistory } from 'react-router-dom';
import { setHistory } from '../../redux/store/errorHandler/errorHandlerActions';

const Main = () => {
  const [internetStatus, setInternetStatus] = useState(
    () => window.navigator.onLine,
  );

  const isLoading = useSelector(globalLoaderStatusSelector);

  const dispatch = useDispatch();

  const checkingOnline = () => {
    setInternetStatus(true);
  };
  const checkingOffline = () => {
    setInternetStatus(false);
  };
  useEffect(() => {
    window.addEventListener('online', checkingOnline);
    window.addEventListener('offline', checkingOffline);
    return () => {
      window.removeEventListener('online', checkingOnline);
      window.removeEventListener('offline', checkingOffline);
    };
  }, [checkingOnline, checkingOffline]);
  const history = useHistory();
  useEffect(() => {
    dispatch(setHistory(history));
    dispatch(checkingStatusUser());
  }, []);

  const messaging = getMessaging();
  onMessage(messaging, payload => {
    dispatch(fetchUserNotifications());
    dispatch(
      setNotifyStatus({
        title: payload.data.title,
        type: 'custom',
        content: payload.data,
      }),
    );
  });
 
  return (
    <HelmetProvider>
      <App internetStatus={internetStatus} />
      {isLoading && <GlobalLoader />}
      <Modal />
      <Toastify />
      <Notifications />

    </HelmetProvider>
  );
};

export default Main;
