import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { sessionPersonalInformationSelector } from '../../../../redux/selectors/selectors';

import book from '../../../router/book';

import arrow from '../../../../assets/images/header/arrow.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const Balance = ({ t }) => {
  const [selectStatus, changeSelectStatus] = useState(false);
  const { budget } = useSelector(sessionPersonalInformationSelector);
  const [userRole] = useState(() => {
    const accaunt = localStorage.getItem('accaunt');
    return accaunt && JSON.parse(accaunt).role;
  });
  const toggleVisibleSelect = () => {
    changeSelectStatus(!selectStatus);
  };

  return (
    <div
      className="balance"
      onMouseEnter={toggleVisibleSelect}
      onMouseLeave={toggleVisibleSelect}
    >
      <div className="balance__header">
        <span className="balance__title">{budget || 0}$</span>
        <img
          className={selectStatus ? 'balance__arrow-active' : 'balance__arrow'}
          src={arrow}
          alt="arrow"
        />
      </div>

      <div className="balance__dropdown-item" />
      {selectStatus && (
        <div className="balance__dropdown">
          <Link to={book.finance}>
            <span className="balance__dropdown-item-title">
              {t(
                userRole === 'BLOGGER'
                  ? 'header_in_login_menu_withdraw'
                  : 'header_in_login_menu_wallet',
              )}
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Balance);
