import React from 'react';
import usePathname from '../../../../hooks/usePathname';

import book from '../../../router/book';

import './styles.scss';

const GlobalLoader = () => {
  const pathname = usePathname();
  const width =
    pathname === book.blog || pathname.includes(book.blog) ? '100%' : '';

  return (
    <div style={{ width: width }} className="loading loading-bloggerly">
      <span className="loading-item" data-text="B">
        B
      </span>
      <span className="loading-item" data-text="L">
        L
      </span>
      <span className="loading-item" data-text="O">
        O
      </span>
      <span className="loading-item" data-text="G">
        G
      </span>
      <span className="loading-item" data-text="G">
        G
      </span>
      <span className="loading-item" data-text="E">
        E
      </span>
      <span className="loading-item" data-text="R">
        R
      </span>
      <span className="loading-item" data-text="L">
        L
      </span>
      <span className="loading-item" data-text="Y">
        Y
      </span>
    </div>
  );
};

export default GlobalLoader;
