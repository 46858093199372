import { advertisementCreationSteps } from '../../../constants/advertisementCreationSteps';

export const listOfStages = [
  { stepText: 'advertisement_step_1', stepNumber: 1 },
  { stepText: 'advertisement_step_2', stepNumber: 2 },
  { stepText: 'advertisement_step_3', stepNumber: 3 },
  { stepText: 'advertisement_step_4', stepNumber: 4 },
];

export const stepsList = {
  0: advertisementCreationSteps.base,
  1: advertisementCreationSteps.choiceBloggers,
  2: advertisementCreationSteps.budget,
  3: advertisementCreationSteps.advertisementLaunch,
};

export const stepsClassNames = {
  item: {
    active: 'staps-bar__item-active',
    done: 'staps-bar__item-done',
  },
  number: {
    active: 'staps-bar__item-number-active',
    done: 'staps-bar__item-number-done',
  },
  text: {
    active: 'staps-bar__item-text-active',
    done: 'staps-bar__item-text-done',
  },
};
