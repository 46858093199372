import React from 'react';

import './styles.scss';

const Checkbox = ({ inputChangeOptions, options = {} }) => {
  const { textLabel = '', number = 1 } = options;

  return (
    <div className="checkbox">
      <input
        {...inputChangeOptions}
        className="custom-checkbox"
        type="checkbox"
        id={'color-' + number}
      />
      <label className="custom-checkbox__label" htmlFor={'color-' + number}>
        {textLabel}
      </label>
    </div>
  );
};

export default Checkbox;
