import React from 'react';
import dayjs from 'dayjs';

import FormContainer from '../../FormContainer/FormContainer';
import usePathname from '../../../../../hooks/usePathname';
import book from '../../../../router/book';

import { ReactComponent as ModerationIcon } from '../../../../../assets/images/vectors/question.svg';
import { ReactComponent as RejectedIcon } from '../../../../../assets/images/vectors/rejected.svg';
import { ReactComponent as ApprovedIcon } from '../../../../../assets/images/vectors/accept.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const ModeratorComment = ({ options }) => {
  const pathname = usePathname();
  const [t] = useTranslation();

  const {
    approved,
    comments,
    status,
    handleClick = () => {},
    endDate,
  } = options;

  return (
    <>
      {approved === false && (
        <div className="moderator-comment">
          <RejectedIcon className="moderator-comment-icon" />
          <FormContainer
            options={{
              head: true,
              centerTitle: t('my_campaigns_advertisement_head_rejected'),
            }}
            button={''}
          >
            {comments.map(({ _id, message, createdAt, user }) => (
              <div key={_id} className="moderator-comment-container">
                <p className="moderator-comment-container-message">{message}</p>
                <div className="moderator-comment-container-moderator">
                  {t('my_campaigns_advertisement_head_moderator')}
                  <span className="moderator-comment-container-moderator-name">
                    {user?.firstName ? user?.firstName : 'Moderator'}{' '}
                    {user?.lastName ? user?.lastName : ''}
                  </span>
                  <p> {dayjs(createdAt).format('D MMMM HH:mm')}</p>
                </div>
              </div>
            ))}
            {!!handleClick && pathname !== book.basicInformation && (
              <button
                className="moderator-comment-btn custom-btn"
                onClick={handleClick}
              >
                {t('my_campaigns_advertisement_head_update')}
              </button>
            )}
          </FormContainer>
        </div>
      )}
      {approved === null && (
        <div className="moderator-comment">
          <ModerationIcon className="moderator-comment-icon" />
          <FormContainer
            options={{
              head: true,
              centerTitle: t('my_campaigns_advertisement_head_moderation'),
            }}
            button={''}
          >
            <div className="moderator-comment-container">
              <p className="moderator-comment-container-message">
                {t('my_campaigns_advertisement_head_content_on_moderation')}
              </p>
              {!!handleClick && pathname !== book.basicInformation && (
                <button
                  className="moderator-comment-btn custom-btn"
                  onClick={handleClick}
                >
                  {t('my_campaigns_advertisement_head_preview')}
                </button>
              )}
            </div>
          </FormContainer>
        </div>
      )}
      {approved && (
        <div className="moderator-comment">
          <ApprovedIcon className="moderator-comment-icon" />
          <FormContainer
            options={{
              head: true,
              centerTitle: t('my_campaigns_advertisement_head_approved'),
            }}
            button={''}
          >
            <div className="moderator-comment-container">
              <p className="moderator-comment-container-message">
                {status !== 'ACTIVE'
                  ? t('my_campaigns_advertisement_head_ready')
                  : `${
                      new Date() > new Date(endDate)
                        ? t('my_campaigns_advertisement_head_finished')
                        : t('my_campaigns_advertisement_head_started')
                    }`}
              </p>
              {status !== 'ACTIVE' &&
                !!handleClick &&
                pathname !== book.basicInformation && (
                  <button
                    className="moderator-comment-btn custom-btn"
                    onClick={handleClick}
                  >
                    {t('my_campaigns_advertisement_head_settings')}
                  </button>
                )}
            </div>
          </FormContainer>
        </div>
      )}
    </>
  );
};

export default ModeratorComment;
