import React from 'react';
import { connect } from 'react-redux';

import LoginForm from './Forms/LoginForm/LoginForm';
import ConfirmationEmailForm from './Forms/ConfirmationEmailForm/ConfirmationEmailForm';
import ConfirmationCodeForm from './Forms/ConfirmationCodeForm/ConfirmationCodeForm';
import CreateAccauntForm from './Forms/CreateAccauntForm/CreateAccauntForm';

import { sessionAuthenticationSteps } from '../../../constants/sessionAuthenticationSteps';
import { sessionAuthenticationStepSelector } from '../../../redux/selectors/selectors';
import ForgotPasswordForm from './Forms/ForgotPasswordForm/ForgotPasswordForm';
import ResetPasswordSuccess from './Forms/resetPasswordSuccess/resetPasswordSuccess';

const AuthentificationStepsContainer = ({ type, currentStep }) => {
  const AuthConfig = {
    [sessionAuthenticationSteps.login]: <LoginForm type={type} />,
    [sessionAuthenticationSteps.email]: <ConfirmationEmailForm type={type} />,
    [sessionAuthenticationSteps.code]: <ConfirmationCodeForm type={type} />,
    [sessionAuthenticationSteps.accaunt]: <CreateAccauntForm />,
    [sessionAuthenticationSteps.resetPassword]: <ForgotPasswordForm />,
    [sessionAuthenticationSteps.resetPasswordSuccess]: <ResetPasswordSuccess />,
  };

  return <div>{AuthConfig[currentStep]}</div>;
};

const mapStateToProps = state => ({
  currentStep: sessionAuthenticationStepSelector(state),
});

export default connect(mapStateToProps, null)(AuthentificationStepsContainer);
