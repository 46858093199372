import storyVideo from '../assets/images/dummy/storyVideo.svg';
import storyImage from '../assets/images/dummy/storyImage.svg';

export const handleStoryContent = (banner, contentType, mimeType) => {
  if (!banner && !banner?.type && contentType === 'IMPROVISATION') {
    return storyImage;
  } else if (!banner && !banner?.type && contentType === 'CUSTOM') {
    return storyImage;
  } else if (banner && banner?.type && banner?.type?.includes('image')) {
    return URL.createObjectURL(banner) || storyImage;
  } else if (banner && banner?.type && banner?.type?.includes('video')) {
    return storyVideo;
  } else if (banner && !banner?.type && contentType === 'IMPROVISATION') {
    return storyImage;
  } else if (banner && !banner?.type && mimeType?.includes('image')) {
    return banner || storyImage;
  } else if (banner && !banner?.type && mimeType?.includes('video')) {
    return storyVideo;
  }
};

export const handleBannerContent = (
  contentType,
  mimeType,
  banner,
  image,
  video,
) => {
  if (contentType === 'IMPROVISATION') {
    return image;
  } else if (contentType === 'CUSTOM' && mimeType?.includes('video')) {
    return video;
  } else if (contentType === 'CUSTOM' && mimeType?.includes('image')) {
    return banner || image;
  }
};
