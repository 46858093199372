import React from 'react';
import { useState } from 'react';

import FormContainer from '../../common/FormContainer/FormContainer';
import BloggerlyIs from '../BloggerlyIs/BloggerlyIs';
import { faq } from '../config';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const Faq = ({ t }) => {
  const [active, setActive] = useState(2);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(e => (e === index ? null : index));
  };

  return (
    <FormContainer>
      <div className="support__left-bloggerly">
        <BloggerlyIs />
      </div>
      <div className="support__left-faq">
        <h1 className="support__left-faq__title">FAQ</h1>
        {faq.map(({ title, subtitle }, index) => (
          <div
            className={
              active === index
                ? 'support__left-faq__block-active'
                : 'support__left-faq__block-inactive'
            }
            key={index}
          >
            <div
              onClick={e => eventHandler(e, index)}
              className={`support__left-faq__container ${
                active === index
                  ? 'support__left-faq__active'
                  : 'support__left-faq__inactive'
              }`}
            >
              <span>{t(title)}</span>
              <div className="support__left-faq__content">
                <div
                  className={
                    active === index
                      ? 'support__left-faq__plus'
                      : 'support__left-faq__minus'
                  }
                ></div>
              </div>
            </div>
            <div
              className={
                active === index
                  ? 'support__left-faq__panel-plus'
                  : 'support__left-faq__panel-minus'
              }
            >
              {t(subtitle)}
            </div>
          </div>
        ))}
      </div>
    </FormContainer>
  );
};

export default withTranslation()(Faq);
