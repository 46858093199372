export const minValue = arr => {
  const minPrice = Math.min(...arr.map(({ price }) => price));
  const blogger = arr.find(({ price }) => minPrice === price);
  return blogger.price;
};

export const maxValue = arr => {
  const maxPrice = Math.max(...arr.map(({ price }) => price));
  const blogger = arr.find(({ price }) => maxPrice === price);
  return blogger.price;
};

export const sumLinkClicks = profileVisits => {
  return Math.round(profileVisits * 0.18);
};

export const sumProfileVisits = profileVisits => {
  return Math.round(profileVisits * 0.36);
};
