import React from 'react';

import StepsDescription from '../../../component/StepsDescription/StepDescription';
import BaseInfoForm from '../../../component/Forms/Company/BaseInfoForm/BaseInfoForm';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const BaseInfo = ({ t }) => {
  return (
    <div>
      <div className="new-company__create">
        <div className="new-company__left">
          <BaseInfoForm />
        </div>
        <div className="new-company__right">
          <StepsDescription
            options={{
              title: t('advertisement_step_1_title'),
              description: t('advertisement_step_1_description'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BaseInfo);
