import dayjs from 'dayjs';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';

const DatePicker = ({
  onChange,
  selected,
  errors,
  statusStories,
  minDate,
  dateRef,
  maxDate,
}) => {
  return (
    <div className="custom-picker__container">
      <ReactDatePicker
        className={errors ? 'custom-picker__errors' : 'custom-picker'}
        readOnly={statusStories}
        selected={selected ? new Date(selected) : null}
        value={selected ? dayjs(selected).format('DD.MM.YYYY') : null}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText="dd.mm.yyyy"
        ref={dateRef}
      />
    </div>
  );
};

export default DatePicker;
