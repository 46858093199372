import React from 'react';
import './styles.scss';
import ErrorComponent from '../../component/ErrorComponent/ErrorComponent';
import { typesErrorComponent } from '../../../configs/errorComponentTypes';

const NotFound = () => {
  return <ErrorComponent type={typesErrorComponent.PAGE_NOT_FOUND} />;
};

export default NotFound;
