import React, { useState } from 'react';

import FormContainer from '../../../../common/FormContainer/FormContainer';
import { CreateCampaign, PickCampaign } from './index';

import '../../../general.scss';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const types = ['company_types_pick', 'company_types_create'];

const AttachToCampaign = ({ t }) => {
  const [active, setActive] = useState(types[0]);

  return (
    <div className="attach">
      <section className="form-section">
        <FormContainer button={''}>
          <div className="form-block">
            <div className="tab-attach">
              {types.map(type => (
                <div
                  key={type}
                  onClick={() => setActive(type)}
                  className={
                    active === type
                      ? 'tab-attach__item-active'
                      : 'tab-attach__item'
                  }
                >
                  {t(type)}
                </div>
              ))}
            </div>
            {active === types[0] && <PickCampaign />}
            {active === types[1] && <CreateCampaign />}
          </div>
        </FormContainer>
      </section>
    </div>
  );
};

export default withTranslation()(AttachToCampaign);
