import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';

import { postAdvertisementCopy } from '../../../redux/store/advertisement/advertisement/AdvertisementOperations';

import { handleRedirect } from '../../../helpers/handleRedirect';
import IconApprovedStories from '../common/Icon/ApprovedStories/IconApprovedStories';
import book from '../../router/book';

import './styles.scss';

const AdvertisementStatisticItem = ({
  statistic: {
    acceptOffers,
    allOffers,
    budget,
    endDate,
    startDate,
    spentMoney,
    status,
    title,
    _id: id,
    content,
    publicId,
  },
  history,
  t,
}) => {
  const handleCheckContent = () => {
    switch (content) {
      case true:
        return 'approved';
      case false:
        return 'rejected';
      case null:
        return 'moderation';
      default:
        return null;
    }
  };

  const dispatch = useDispatch();
  const copyRef = useRef();
  const allRef = useRef();

  const redirect = `${book.statistics}/${id}`;

  const copyAdvertisement = () => {
    if (content) dispatch(postAdvertisementCopy(id));
  };

  return (
    <tbody className="statistic">
      <tr
        onClick={({ target }) =>
          handleRedirect(target, allRef, copyRef, history, redirect)
        }
        className="statistic-item"
        ref={allRef}
      >
        <td>{dayjs(startDate).format('DD.MM.YYYY')}</td>
        <td>{dayjs(endDate).format('DD.MM.YYYY')}</td>
        <td>{title}</td>
        <td>ID: {publicId}</td>
        <td>
          {handleCheckContent()} <IconApprovedStories approved={content} />
        </td>
        <td>{status.toLowerCase()}</td>
        <td>
          {acceptOffers} / {allOffers}
        </td>
        <td>
          $ {spentMoney} / $ {budget}
        </td>
        <td
          disabled={content === null || !content}
          ref={copyRef}
          data-after-not={t('statistics_table_not_approved')}
          data-after-copy={t('statistics_table_copy')}
          onClick={copyAdvertisement}
        />
      </tr>
    </tbody>
  );
};

export default withRouter(AdvertisementStatisticItem);
