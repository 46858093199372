import * as yup from 'yup';

export const schema = yup.object().shape({
  pay: yup.string().required(),
  price: yup.string().required(),
  geography: yup.string().required(),
  liftimeFirst: yup.string().required(),
  liftimeSecond: yup.string().required(),
  gender: yup.string().required(),
  accauntType: yup.string().required(),
  age: yup.string().required(),
});
