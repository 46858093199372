import React from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { postAdvertisement } from '../../../redux/store/advertisement/creationData/creationDataOperations';
import {
  postAdvertisementCopy,
  setCurrentStep,
} from '../../../redux/store/advertisement/advertisement/AdvertisementOperations';

import ModeratorComment from '../common/Fields/ModeratorComment/ModeratorComment';
import { typesErrorComponent } from '../../../configs/errorComponentTypes';
import { handleBannerContent } from '../../../helpers/correctShowBanner';
import { compareKeyBySelect } from '../../../helpers/compareKeyBySelect';
import { openModal } from '../../../redux/store/modal/modalActions';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import { modalTypes } from '../../../configs/modalTypes';
import PlacementStatus from './PlacementStatus';
import book from '../../../views/router/book';
import ContentFields from './ContentFields';
import {
  contentTypesSelectOptions,
  improvisavionTypeSelectOptions,
  contentTypesChoiceOfBloggers,
} from '../../../configs/selectOptions';

import storyVideo from '../../../assets/images/dummy/storyVideoItem.svg';
import storyImage from '../../../assets/images/dummy/storyImageItem.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const AdvertisementItem = ({ advertisement, paramsId, isPaywalled,refresh,refreshState }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();

  const {
    title,
    startDate,
    endDate,
    status,
    _id: id,
    offers,
    offersCount,
    budget,
    story,
    typeOfAllocation,
    banner,
    campaign,
  } = advertisement;
  const {
    contentType,
    content,
    profile,
    instruction,
    file,
    site,
    approved,
    comments,
    filename,
    mimeType,
  } = story;

  const statusEdit = approved === null || status === 'ACTIVE';
  const statusDelete = approved !== false || !story;
  const statusLaunch =
    !approved || status === 'ACTIVE' || approved === null || !budget;

  const handleCheckStatus = status => {
    if (status) {
      return 'campaign__left-top-container-btn-disabled';
    }
    return 'campaign__left-top-container-btn custom-btn';
  };

  const handleRedirectToCreateAds = type => {
    dispatch(setCurrentStep(advertisement, story, type));
    history.push(book.basicInformation);
  };

  const copyAdvertisement = () => {
    if (approved) {
      dispatch(
        postAdvertisementCopy(id, campaign?._id, history, 'advertisement'),
      );
    }
  };

  return (
    <>
      {title && paramsId === id ? (
        <>
          <div className="campaign">
            <div className="campaign__left">
              <ModeratorComment
                options={{
                  approved: approved,
                  comments: comments,
                  status,
                  endDate,
                  handleClick: () => handleRedirectToCreateAds(''),
                }}
              />
              <div className="campaign__left-top">
                <h1 className="campaign__left-top-title">{title}</h1>
                <div className="campaign__left-top-container">
                  <div className="campaign__left-top-container-title">
                    <p>{t('my_campaigns_advertisement_parameters')}</p>
                    <div className="campaign__left-top-container-title-btn">
                      <button
                        className={`campaign__left-top-container-btn-copy ${
                          approved ? 'custom-btn' : null
                        }`}
                        disabled={approved === null || !approved}
                        onClick={copyAdvertisement}
                      >
                        {t('my_campaigns_advertisement_copy')}
                      </button>
                      <button
                        disabled={statusEdit}
                        className={handleCheckStatus(statusEdit)}
                        onClick={() => handleRedirectToCreateAds('edit')}
                      >
                        {t('my_campaigns_advertisement_edit')}
                      </button>
                    </div>
                  </div>
                  <ContentFields
                    options={{
                      title: t('my_campaigns_advertisement_heading'),
                      subtitle: title,
                    }}
                  />
                  <ContentFields
                    options={{
                      title: t('my_campaigns_advertisement_publish_per'),
                      subtitle: `from ${dayjs(startDate).format(
                        'DD.MM.YYYY',
                      )} to ${dayjs(endDate).format('DD.MM.YYYY')}`,
                    }}
                  />

                  {typeOfAllocation && (
                    <ContentFields
                      options={{
                        title: t('my_campaigns_advertisement_type'),
                        subtitle:
                          typeOfAllocation &&
                          t(
                            compareKeyBySelect(
                              contentTypesChoiceOfBloggers,
                              typeOfAllocation,
                            ),
                          ),
                      }}
                    />
                  )}
                </div>
                <div className="campaign__left-top-container-stories">
                  <h1 className="campaign__left-top-container-stories-title">
                    {t('my_campaigns_advertisement_stories')}
                  </h1>
                  {contentType === 'CUSTOM' ? (
                    <>
                      <ContentFields
                        options={{
                          title: t('my_campaigns_advertisement_content_type'),
                          subtitle:
                            contentType &&
                            t(
                              compareKeyBySelect(
                                contentTypesSelectOptions,
                                contentType,
                              ),
                            ),
                        }}
                      />
                      {site && (
                        <ContentFields
                          options={{
                            title: t('my_campaigns_advertisement_link'),
                            activeSubtitle: site,
                          }}
                        />
                      )}
                      {profile && (
                        <ContentFields
                          options={{
                            title: t('my_campaigns_advertisement_mention'),
                            activeSubtitle: profile,
                          }}
                        />
                      )}
                      <ContentFields
                        handleClick={() =>
                          dispatch(
                            openModal({
                              isOpen: true,
                              renderType: modalTypes.showCustomContent,
                              details: {
                                selectedFile: file,
                                name: filename,
                              },
                            }),
                          )
                        }
                        options={{
                          title: t('my_campaigns_advertisement_file'),
                          file: file,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <ContentFields
                        options={{
                          title: t('my_campaigns_advertisement_content_type'),
                          subtitle:
                            contentType &&
                            t(
                              compareKeyBySelect(
                                contentTypesSelectOptions,
                                contentType,
                              ),
                            ),
                        }}
                      />
                      <ContentFields
                        options={{
                          title: t('my_campaigns_advertisement_description'),
                          subtitle: content,
                        }}
                      />
                      <ContentFields
                        options={{
                          title: t('my_campaigns_advertisement_instruction'),
                          subtitle: instruction,
                        }}
                      />
                      {site && (
                        <ContentFields
                          options={{
                            title: t('my_campaigns_advertisement_link'),
                            activeSubtitle: site,
                          }}
                        />
                      )}
                      {profile && (
                        <ContentFields
                          options={{
                            title: t('my_campaigns_advertisement_mention'),
                            activeSubtitle: profile,
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="campaign__right">
              <div className="campaign__right-container">
                <img
                  className="campaign__right-container-banner border-bottom"
                  src={handleBannerContent(
                    contentType,
                    mimeType,
                    banner,
                    storyImage,
                    storyVideo,
                  )}
                  alt="banner"
                />
                <div className="campaign__right-container-title">
                  <p>{t('my_campaigns_advertisement_working_status')}</p>
                </div>
                <div
                  className={
                    status === 'DRAFT'
                      ? 'campaign__right-container-subtitle-disabled'
                      : 'campaign__right-container-subtitle'
                  }
                >
                  {status?.toLowerCase()}
                </div>
              </div>
              <div className="campaign__left-top-container-btns">
                <button
                  disabled={statusDelete}
                  className={handleCheckStatus(statusDelete)}
                  onClick={() =>
                    dispatch(
                      openModal({
                        isOpen: true,
                        renderType: modalTypes.deleteAdvertisement,
                      }),
                    )
                  }
                >
                  {t('my_campaigns_advertisement_delete')}
                </button>
                {status === 'ACTIVE' ? (
                  <button
                    disabled={new Date() > new Date(endDate)}
                    className={
                      new Date() > new Date(endDate)
                        ? 'campaign__left-top-container-btn-disabled'
                        : 'campaign__left-top-container-btn custom-btn'
                    }
                    onClick={() => handleRedirectToCreateAds('')}
                  >
                    {t('my_campaigns_advertisement_edit_targeting')}
                  </button>
                ) : (
                  <button
                    disabled={statusLaunch}
                    className={handleCheckStatus(statusLaunch)}
                    onClick={() => dispatch(postAdvertisement(id))}
                  >
                    {t('my_campaigns_advertisement_launch')}
                  </button>
                )}
              </div>
            </div>
          </div>
          {approved && (
            <PlacementStatus
              id={id}
              offers={offers}
              offersCount={offersCount}
              isPaywalled={isPaywalled}
              refresh={refresh}
              refreshState={refreshState}
            />
          )}
        </>
      ) : (
        <ErrorComponent type={typesErrorComponent.NOT_FOUND_SEARCH} />
      )}
    </>
  );
};

export default AdvertisementItem;
