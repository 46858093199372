const types = {
  // get
  GET_CAMPAIGN_LIST_START: 'campaign/GET_CAMPAIGN_LIST_START',
  GET_CAMPAIGN_LIST_SUCCESS: 'campaign/GET_CAMPAIGN_LIST_SUCCESS',
  GET_CAMPAIGN_LIST_ERROR: 'campaign/GET_CAMPAIGN_LIST_ERROR',

  GET_CAMPAIGN_START: 'campaign/GET_CAMPAIGN_START',
  GET_CAMPAIGN_SUCCESS: 'campaign/GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_ERROR: 'campaign/GET_CAMPAIGN_ERROR',

  // post
  POST_CAMPAIGN_START: 'campaign/POST_CAMPAIGN_START',
  POST_CAMPAIGN_SUCCESS: 'campaign/POST_CAMPAIGN_SUCCESS',
  POST_CAMPAIGN_ERROR: 'campaign/POST_CAMPAIGN_ERROR',

  // edit
  EDIT_CAMPAIGN_START: 'campaign/EDIT_CAMPAIGN_START',
  EDIT_CAMPAIGN_SUCCESS: 'campaign/EDIT_CAMPAIGN_SUCCESS',
  EDIT_CAMPAIGN_ERROR: 'campaign/EDIT_CAMPAIGN_ERROR',

  // delete
  DELETE_CAMPAIGN_START: 'campaign/DELETE_CAMPAIGN_START',
  DELETE_CAMPAIGN_SUCCESS: 'campaign/DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_ERROR: 'campaign/DELETE_CAMPAIGN_ERROR',

  // selected campaign
  SELECTED_CAMPAIGN: 'campaign/SELECTED_CAMPAIGN',
  DELETE_SELECTED_CAMPAIGN: 'campaign/DELETE_SELECTED_CAMPAIGN',

  // set
  SET_SORT_TYPE: 'campaign/SET_SORT_TYPE',
  SET_SORT_ITEM_TYPE: 'campaign/SET_SORT_ITEM_TYPE',
  SET_ORDER_TYPE: 'campaign/SET_ORDER_TYPE',
  SET_STATUS: 'campaign/SET_STATUS',
  SET_OFFSET: 'campaign/SET_OFFSET',
  CLEAR_ALL_PARAMS: 'campaign/CLEAR_ALL_PARAMS',
};

export default types;
