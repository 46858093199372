import book from '../../router/book';

import {
  BloggerListIcon,
  FinanceIcon,
  MyCompanyIcon,
  ReferralIcon,
  StatisticsIcon,
  SupportIcon,
} from './IconComponent/IconComponent';

export const listOfMenuItemAdvertiser = [
  {
    name: 'left_bar_advertiser_my_campaigns',
    link: book.myCompanies,
    icon: isActive => <MyCompanyIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_advertiser_statistics',
    link: book.statistics,
    icon: isActive => <StatisticsIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_advertiser_finance',
    link: book.finance,
    icon: isActive => <FinanceIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_advertiser_bloggers_list',
    link: book.bloggerList,
    icon: isActive => <BloggerListIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_advertiser_support',
    link: book.support,
    icon: isActive => <SupportIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    type: 'line',
  },
  {
    name: 'left_bar_advertiser_blog',
    link: book.blog,
    type: 'subMenu',
  },
  {
    name: 'left_bar_advertiser_settings',
    link: book.settings,
    type: 'subMenu',
  },
  {
    name: 'left_bar_advertiser_log_out',
    type: 'subMenu',
    link: book.advertisers,
    handleFunction: true,
  },
  {
    type: 'line',
  },
];

export const listOfMenuItemBlogger = [
  {
    name: 'left_bar_blogger_accounts',
    link: book.accountBlogger,
    icon: isActive => <BloggerListIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_blogger_offers',
    link: book.offers,
    icon: isActive => <MyCompanyIcon isActive={isActive} />,
    type: 'menu',
  },
  
  {
    name: 'left_bar_cpa',
    link: book.cpaoffer,
    icon: isActive => <FinanceIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_blogger_statistics',
    link: book.statistics,
    icon: isActive => <StatisticsIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_blogger_finance',
    link: book.finance,
    icon: isActive => <FinanceIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_blogger_referral',
    link: book.referral,
    icon: isActive => <ReferralIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    name: 'left_bar_blogger_support',
    link: book.support,
    icon: isActive => <SupportIcon isActive={isActive} />,
    type: 'menu',
  },
  {
    type: 'line',
  },
  {
    name: 'left_bar_blogger_blog',
    link: book.blog,
    type: 'subMenu',
  },
  {
    name: 'left_bar_blogger_settings',
    link: book.settings,
    type: 'subMenu',
  },
  {
    name: 'left_bar_blogger_log_out',
    type: 'subMenu',
    link: book.advertisers,
    handleFunction: true,
  },
  {
    type: 'line',
  },
];
