import Tervivc from '../../../../../../assets/images/landing/partners/tervivc.svg';
import Xiaomi from '../../../../../../assets/images/landing/partners/xiaomi.svg';
import Ocado from '../../../../../../assets/images/landing/partners/ocado.svg';
import Alsa from '../../../../../../assets/images/landing/partners/alsa.svg';

export const partners = [
  { img: Tervivc, link: 'https://www.terviva.com/' },
  { img: Xiaomi, link: 'https://www.mi.com/' },
  { img: Ocado, link: 'https://www.ocado.com/' },
  { img: Alsa, link: 'https://www.alsa.es/' },
  { img: Tervivc, link: 'https://www.terviva.com/' },
  { img: Xiaomi, link: 'https://www.mi.com/' },
  { img: Ocado, link: 'https://www.ocado.com/' },
  { img: Alsa, link: 'https://www.alsa.es/' },
  { img: Tervivc, link: 'https://www.terviva.com/' },
  { img: Xiaomi, link: 'https://www.mi.com/' },
  { img: Ocado, link: 'https://www.ocado.com/' },
  { img: Alsa, link: 'https://www.alsa.es/' },
];
