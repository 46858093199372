export const cases = [
  {
    title: 'advertisers_landing_cases_item_1_title',
    subtitle: 'advertisers_landing_cases_item_1_subtitle',
    coverage: 'advertisers_landing_cases_item_1_coverage',
    likes: 'advertisers_landing_cases_item_1_likes',
  },
  {
    title: 'advertisers_landing_cases_item_2_title',
    subtitle: 'advertisers_landing_cases_item_2_subtitle',
    coverage: 'advertisers_landing_cases_item_2_coverage',
    likes: 'advertisers_landing_cases_item_2_likes',
  },
  {
    title: 'advertisers_landing_cases_item_3_title',
    subtitle: 'advertisers_landing_cases_item_3_subtitle',
    coverage: 'advertisers_landing_cases_item_3_coverage',
    likes: 'advertisers_landing_cases_item_3_likes',
  },
];
