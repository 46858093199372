import React from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';
import useWindowWidth from '../../../../../../hooks/useWindow';
import ReferralsIcon from './IconComponent';

import Referrals from '../../../../../../assets/images/landing/header/referrals.png';
import Break from '../../../../../../assets/images/landing/global/break.svg';
import './styles.scss';

const SubHeader = ({ openModal, setAuthenticationStep, t }) => {
  const width = useWindowWidth();

  return (
    <div className="header-referrals-bottom__container">
      <div className="header-referrals-bottom">
        <div className="header-referrals-bottom-left">
          <span className="header-referrals-bottom-left-title">
            {t('referrals_landing_header_title')}
          </span>
          <span className="header-referrals-bottom-left-subtitle">
            {t('referrals_landing_header_subtitle')}
          </span>
          <span
            onClick={() =>
              handleRegistrationButtonClick(setAuthenticationStep, openModal)
            }
            className="header-referrals-bottom-left-button"
          >
            {t('referrals_landing_header_button')}
          </span>
          <img
            className="header-referrals-bottom-left-break"
            src={Break}
            alt="Break"
          />
        </div>
        <div className="header-referrals-bottom-right">
          <div className="header-referrals-bottom-right-img">
            <ReferralsIcon width={width} />
            <img
              src={Referrals}
              className="header-referrals-bottom-right-img-referrals"
              alt="referrals"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(SubHeader);
