import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '../../../common/Button/Button';
import Input from '../../../common/Fields/Input/Input';
import Password from '../../../common/Fields/Password/Password';
import AuthFormContainer from '../../shared/Container/Container';
import Social from '../../shared/Social/Social';

import {
  sendLogin,
  setAuthenticationStep,
} from '../../../../../redux/store/session/sessionOperations';

import {
  sessionFormLoadingStatusSelector,
  sessionFormErrorSelector,
  sessionPersonalInformationSelector,
} from '../../../../../redux/selectors/selectors';

import { login } from '../../../../../schemas/authFormsSchemas';

import './styles.scss';
import '../../general.scss';
import { sessionAuthenticationSteps } from '../../../../../constants/sessionAuthenticationSteps';
import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';
import { useTranslation } from 'react-i18next';

const LoginForm = ({
  sendLogin,
  loadingStatus,
  history,
  setAuthenticationStep,
  setNotifyStatus,
  personalInformation: { fcmToken: activeFcmToken },
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,

    formState: { isValid },
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(login),
  });
  const [t] = useTranslation();
  useEffect(() => {
    const subscription = watch(({ email }, { name }) => {
      if (name === 'email' && email.length > 3 && email.includes(' ')) {
        setValue('email', email.replace(' ', ''));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const handleFormSubmit = data => {
    if (activeFcmToken) {
      data.fcmToken = activeFcmToken;
    }

    sendLogin(data, history).catch(err =>
      setNotifyStatus({ title: err, type: 'error' }),
    );
  };
  const _onClickRegistration = () => {
    setAuthenticationStep(sessionAuthenticationSteps.email);
  };
  const _onClickForgotPassword = () => {
    setAuthenticationStep(sessionAuthenticationSteps.resetPassword);
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <AuthFormContainer name={t('authentication_login_title')}>
        <div className="auth-form-block">
          <Input
            options={{
              placeholderText: t('authentication_login_email_placeholder'),
              labelText: t('authentication_login_email_label'),
            }}
            inputChangeOptions={register('email')}
          />
        </div>
        <div className="auth-form-block">
          <Password
            options={{
              placeholderText: t('authentication_login_password_placeholder'),
              labelText: t('authentication_login_password_label'),
            }}
            inputChangeOptions={register('password')}
          />
        </div>

        <Social role="ADVERTISER" />
        <div className="auth-form-button-container">
          <Button
            loadingStatus={loadingStatus}
            options={{
              text: t('authentication_login_button'),
              renderType: 'primary',
              size: 'l',
              type: 'submit',
              disabled: !isValid,
            }}
          />
          <button
            className="auth-form-button-container-subButton"
            type="button"
            onClick={_onClickRegistration}
          >
            {t('authentication_registration_button')}
          </button>
          <p
            onClick={_onClickForgotPassword}
            className="auth-form-button-container_forgot-password"
          >
            {t('authentication_forgot_password_button')}
          </p>
        </div>
      </AuthFormContainer>
    </form>
  );
};

const mapStateToProps = state => ({
  loadingStatus: sessionFormLoadingStatusSelector(state),
  requestError: sessionFormErrorSelector(state),
  personalInformation: sessionPersonalInformationSelector(state),
});

const mapDispatchToProps = {
  sendLogin,
  setAuthenticationStep,
  setNotifyStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoginForm));
