import React from 'react';

import { getLastWord } from '../../../../helpers/getLastWord';

import './styles.scss';

const typeOfVideo = ['mp4', 'mov'];

const ShowContent = ({ details: { selectedFile, name } }) => {
  const video = typeOfVideo.includes(getLastWord(name));
  return (
    <div className="show-content">
      {video ? (
        <video className="show-content-info" alt="uploaded" controls>
          <source src={selectedFile} />
        </video>
      ) : (
        <img className="show-content-info" src={selectedFile} alt="uploaded" />
      )}
    </div>
  );
};

export default ShowContent;
