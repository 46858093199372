import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as FilterIcon } from '../../../assets/images/vectors/filter.svg';
import {
  contentTypesSortCpaOfferOptions,
  typeSort,
} from '../../../configs/selectOptions';
import './styles.scss';
import SelectFilter from './selectFilter/selectFilter';
import { instagramSelector } from '../../../redux/store/instagram/instagramSelector';
import { withTranslation } from 'react-i18next';

const HeaderListCpaOffer = ({
  onChengSort,
  onChengDirection,
  t,
}) => {
  const { searchValue, sortParams, directionParams } = useSelector(
    instagramSelector,
  );

  const sortValue = contentTypesSortCpaOfferOptions.find(
    ({ value }) => value === sortParams?.substr(6),
  );

  const directionValue =
    directionParams.split('=')[1] === '-1' ? typeSort[1] : typeSort[0];

  return (
    <div className="header-list-blogger">
      <div className="header-list-blogger-left">

        <div className="header-list-blogger-sort-group">
          <div className="header-list-blogger-sort">
            <SelectFilter
              options={contentTypesSortCpaOfferOptions}
              onChange={onChengSort}
              defaultValue={sortValue}
              type={'sort'}
              page="secondary"
            />
          </div>
          <div className="header-list-blogger-sort">
            <SelectFilter
              options={typeSort}
              type={'sortBy'}
              defaultValue={directionValue}
              onChange={onChengDirection}
              page="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(HeaderListCpaOffer);
