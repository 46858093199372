import React from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import useWindowWidth from '../../../../../../hooks/useWindow';
import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';
import IconAdvertisers from './IconComponent';

import Advertisers from '../../../../../../assets/images/landing/header/advertisers.png';
import './styles.scss';

const SubHeader = ({ openModal, setAuthenticationStep, t }) => {
  const width = useWindowWidth();

  return (
    <div className="header-bottom">
      <div className="header-bottom-left">
        <span className="header-bottom-left-title">
          {t('advertisers_landing_title')}
        </span>
        <span className="header-bottom-left-subtitle">
          {t('advertisers_landing_description')}
        </span>
        <span
          onClick={() =>
            handleRegistrationButtonClick(setAuthenticationStep, openModal)
          }
          className="header-bottom-left-button"
        >
          {t('advertisers_landing_get_started')}
        </span>
      </div>
      <div className="header-bottom-right">
        <div className="header-bottom-right-img">
          <IconAdvertisers width={width} />
          <img
            src={Advertisers}
            className="header-bottom-right-img-icon"
            alt="advertisers"
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(SubHeader);
