import React from 'react';
import { connect } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { handleRegistrationButtonClick } from '../../../../../../helpers/handleRegistrationButtonClick';
import Accordion from '../Accordion/Accordion';
import { cases } from './config';

import Break from '../../../../../../assets/images/landing/global/break.svg';
import Wave from '../../../../../../assets/images/landing/case/wave.svg';
import './styles.scss';

const Case = ({ openModal, setAuthenticationStep, t }) => {
  return (
    <div className="case__container">
      <img className="case-wave" src={Wave} alt="Wave" />
      <div className="case">
        <div className="case-left">
          <div className="case-left__title">
            <p>{t('advertisers_landing_cases_title')}</p>
          </div>
          <div className="case-left__subtitle">
            <p> {t('advertisers_landing_cases_description')}</p>
          </div>
          <span
            onClick={() =>
              handleRegistrationButtonClick(setAuthenticationStep, openModal)
            }
            className="case-left__button"
          >
            {t('advertisers_landing_cases_button')}
          </span>
        </div>
        <div className="case-right">
          <img src={Break} alt="Break" className="case-break" />
          <Accordion cases={cases} t={t} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
  setAuthenticationStep,
};

export default connect(null, mapDispatchToProps)(Case);
