import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FirstStepFinance from './firstStepForm';
import SecondStepForm from './secondStepForm';
import {
  setBloggerBudget,
  withdrawOnPageFinance,
} from '../../../../../redux/store/finance/financeOperations';
import { options } from '../../config';

const WithdrawForm = () => {
  const { errorMessage } = useSelector(state => state.finance);
  const [activeStep, setActiveStep] = useState(1);
  const [data, setData] = useState({ provider: options[0].value });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBloggerBudget());
  }, []);

  const _onOnSubmitFirstStep = value => {
    setData(value);
    setActiveStep(2);
  };

  const _goBack = value => {
    setActiveStep(1);
  };
  const _onSubmitFinish = value => {
    setData({ provider: options[0].value });
    setActiveStep(1);
    value.account = value.account.toString();
    dispatch(withdrawOnPageFinance(value));
  };
  return (
    <div className="purchase">
      {activeStep === 1 ? (
        <FirstStepFinance onSubmit={_onOnSubmitFirstStep} dataInfo={data} />
      ) : (
        <SecondStepForm
          goBack={_goBack}
          onSubmit={_onSubmitFinish}
          dataInfo={data}
        />
      )}

      {errorMessage ? (
        <div className="finances-page__error">{errorMessage}</div>
      ) : null}
    </div>
  );
};
export default WithdrawForm;
