import React from 'react';

import Approved from '../../../../../assets/images/vectors/accept.svg';
import Rejected from '../../../../../assets/images/vectors/rejected.svg';
import Question from '../../../../../assets/images/vectors/question.svg';
import './styles.scss';

const IconApprovedStories = ({ approved }) => {
  return (
    <>
      {approved === null && (
        <img className="custom-icon" src={Question} alt="moderation-icon" />
      )}
      {approved && (
        <img className="custom-icon" src={Approved} alt="approved-icon" />
      )}
      {approved === false && (
        <img className="custom-icon" src={Rejected} alt="rejected-icon" />
      )}
    </>
  );
};

export default IconApprovedStories;
