import React from 'react';

import './styles.scss';
import '../general.scss';
import { withTranslation } from 'react-i18next';

const Select = ({
  options = {},
  listOfOptions = [],
  inputChangeOptions,
  handlerOnChange,
  correctValue,
  t,
}) => {
  const { labelText = '', descriptionText = '', approved = '' } = options;
  const _onChange = e => {
    if (e.target.value === 'placeholder') {
      return '';
    }
    handlerOnChange(e);
  };
  return (
    <div className="custom-select__container">
      <label className="custom-select__label">{t(labelText)}</label>
      <div className="custom-input-container">
        <select
          onChange={_onChange}
          {...inputChangeOptions}
          className="custom-select"
          readOnly={approved || approved === null}
          type="select"
          value={correctValue}
        >
          {listOfOptions.map(({ key, text }, index) =>
            key === 'placeholder' ? (
              <option disabled hidden value="" key={key + index}>
                {t(text)}
              </option>
            ) : (
              <option key={key + index} value={key}>
                {t(text)}
              </option>
            ),
          )}
        </select>
        {descriptionText && (
          <div className="field__description">
            <p>{t(descriptionText)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(Select);
