import { postFormData, post, get, putFormData, remove } from '../apiService';

// get
export const getAdvertisementList = async (offset, limit, status, sortBy) => {
  const sortInfo = sortBy ? `&sortBy=${sortBy}` : '';
  const statusInfo = status ? `&status=${status}` : '';
  const activeInfo = offset ? '?offset=' + offset : '?offset=0';
  const limitInfo = limit ? '&limit=' + limit : '&limit=6';
  return get('/item/' + activeInfo + limitInfo + statusInfo + sortInfo);
};

export const getAdvertisementById = id => {
  return get('/item/' + id);
};

export const getAdvertisementStatistic = (params = '') => {
  return get('/item/statistic' + params);
};

// post
export const postAdvertisementBaseInfo = data => {
  return postFormData('/item', data);
};

export const postAdvertisementById = id => {
  return post(`/item/${id}/start`);
};

export const postAdvertisementBudget = (id, data) => {
  return post(`/item/${id}/budget`, data);
};

export const postAdvertisementStories = (id, data) => {
  return postFormData(`/item/${id}/story`, data);
};

export const postAdvertisementOffer = (id, data) => {
  return post(`/item/${id}/offer`, data);
};

export const postAdvertisementDuplicate = id => {
  return post(`/item/${id}/copy`);
};

// put
export const putAdvertisementById = (id, data) => {
  return putFormData(`/item/${id}`, data);
};

export const putAdvertisementStories = (id, data) => {
  return putFormData(`/story/${id}`, data);
};

// delete
export const deleteAdvertisementById = id => {
  return remove(`/item/${id}`);
};
