import React from 'react';

import './styles.scss';

const Password = ({
  options = {},
  inputChangeOptions = {},
  onHandleClick,
  errors = false,
}) => {
  const { labelText = '', placeholderText = '', type = 'password' } = options;

  const changeTypeInput = e => {
    e.type = 'password';
  };

  return (
    <div className="custom-textarea__container">
      <label className="custom-input__label">{labelText}</label>
      <input
        onClick={onHandleClick}
        {...inputChangeOptions}
        placeholder={placeholderText}
        type={type}
        onFocus={e => changeTypeInput(e)}
        onInput={e => changeTypeInput(e)}
        className={errors ? 'custom-input__erros' : 'custom-input'}
      />
    </div>
  );
};

export default Password;
