import React from 'react';

import './styles.scss';

const Textarea = ({
  options = {},
  inputChangeOptions = {},
  errors = false,
}) => {
  const { labelText = '', placeholderText = '', approved = false } = options;
  return (
    <div className="custom-textarea__container">
      <label className="custom-textarea__label">{labelText}</label>
      <textarea
        readOnly={approved || approved === null}
        {...inputChangeOptions}
        placeholder={placeholderText}
        className={`${
          errors ? 'custom-textarea_errors' : 'custom-textarea_success'
        } : custom-textarea`}
      />
    </div>
  );
};
export default Textarea;
