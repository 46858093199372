export const questions = [
  {
    title: 'referrals_landing_question_item_1_title',
    subtitle: 'referrals_landing_question_item_1_subtitle',
  },
  {
    title: 'referrals_landing_question_item_2_title',
    subtitle: 'referrals_landing_question_item_2_subtitle',
  },
  {
    title: 'referrals_landing_question_item_3_title',
    subtitle: 'referrals_landing_question_item_3_subtitle',
  },
  {
    title: 'referrals_landing_question_item_4_title',
    subtitle: 'referrals_landing_question_item_4_subtitle',
  },
  {
    title: 'referrals_landing_question_item_5_title',
    subtitle: 'referrals_landing_question_item_5_subtitle',
  },
];
