import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../../Navbar/Navbar';

import LightLogo from '../../../../../../../assets/images/landing/header/bloggerly-white.svg';
import DarkLogo from '../../../../../../../assets/images/landing/header/bloggerly-black.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const MainMenu = ({ referrals, book, login, registration }) => {
  const [t, i18n] = useTranslation();

  return (
    <div className="header-top">
      <div className="header-top-logo">
        <Link to={book.advertisers}>
          <img src={referrals ? DarkLogo : LightLogo} alt="Logo" />
        </Link>
      </div>
      <Navbar referrals={referrals} />
      <div className="header-top-auth">
        <p
          className={
            referrals ? 'header-top-auth-login-dark' : 'header-top-auth-login'
          }
          onClick={login}
        >
          {t('header_login')}
        </p>

        <p onClick={registration} className="header-top-auth-registration">
          {t('header_registration')}
        </p>
      </div>
    </div>
  );
};

export default MainMenu;
