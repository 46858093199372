import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import { fetchNewsList } from '../../../redux/store/news/newsOperations';
import { setOffset, setSearch } from '../../../redux/store/news/newsActions';
import {
  paramsNewsSelector,
  newsListSelector,
} from '../../../redux/selectors/selectors';

import BlogNavigation from '../../component/BlogItem/BlogNavigation/BlogNavigation';
import ErrorComponent from '../../component/ErrorComponent/ErrorComponent';
import { typesErrorComponent } from '../../../configs/errorComponentTypes';
import { useSearchFromParams } from '../../../hooks/useSearchFromParams';
import Pagination from '../../component/common/Pagination/Pagination';
import Select from '../../component/common/Fields/Select/Select';
import { categoriesNews } from '../../../constants/newsList';
import BlogItem from '../../component/BlogItem/BlogItem';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const Blog = () => {
  const { offset, search } = useSelector(paramsNewsSelector);
  const news = useSelector(newsListSelector);
  const [t] = useTranslation();

  const query = useSearchFromParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const limit = 6;

  const searchFromUrl = query?.get('search')?.toUpperCase();
  const pageFromUrl = query?.get('page')?.toUpperCase();

  const [isFirst, setIsFirst] = useState(true);
  useEffect(() => {
    const currentOffset = Math.ceil(pageFromUrl - 1) * 6;
    dispatch(
      fetchNewsList({
        limit,
        offset: currentOffset,
        search: searchFromUrl || search,
      }),
    );
    dispatch(setSearch(searchFromUrl));
    if (currentOffset) {
      dispatch(setOffset(currentOffset));
    }
  }, [window.location.search]);

  useEffect(() => {
    if (isFirst) {
      const currentSearch = `?search=${search?.toLowerCase() || 'latest_news'}`;
      const currentPage = `&page=${Math.ceil(offset / limit) + 1 || 1}`;
      if (
        currentSearch + currentPage === window.location.search ||
        !window.location.search
      ) {
        history.replace(currentSearch + currentPage);
      }
    }
    setIsFirst(false);
  }, [history, offset, limit]);
  return (
    <>
      <Helmet>
        <title>Blog about advertising on Instagram - Bloggerly</title>
        <meta
          name="description"
          content="Check the latest news about our service, Instagram and all the lifehacks we will share with you!"
        />
      </Helmet>
      <div className="news">
        <BlogNavigation background="grey" t={t} />
        <div className="news__header">
          <h1 className="news__header-title">{t('blog_title')}</h1>
          <h2 className="news__header-subtitle">{t('blog_description')}</h2>
        </div>
        <div className="news__category">
          {categoriesNews.map(({ text, key }, index) => (
            <button
              disabled={searchFromUrl === key}
              onClick={() => {
                const currentSearch = `?search=${key?.toLowerCase()}`;
                const currentPage = `&page=1`;
                history.push(currentSearch + currentPage);
              }}
              className={`news__category-item ${
                searchFromUrl === key ? 'active' : null
              }`}
              key={text + index}
            >
              {t(text)}
            </button>
          ))}
        </div>
        <div className="news__select">
          <Select
            handlerOnChange={({ target: { value } }) =>
              dispatch(
                fetchNewsList({
                  limit,
                  offset: 0,
                  search: value,
                }),
              ).then(() => dispatch(setSearch(value)))
            }
            correctValue={search}
            listOfOptions={categoriesNews}
          />
        </div>
        {news.items.length ? (
          <>
            <div className="news__body">
              {news.items.map(news => (
                <BlogItem news={news} key={news._id} />
              ))}
            </div>
            <Pagination
              items={news}
              elementType="page"
              initialPage={pageFromUrl - 1 || 0}
              sizeItemOnPage={limit}
              getNextPageItems={offset => {
                dispatch(setOffset(offset));
                const currentSearch = `?search=${
                  search?.toLowerCase() || 'latest_news'
                }`;
                const currentPage = `&page=${
                  Math.ceil(offset / limit) + 1 || 1
                }`;
                history.push(currentSearch + currentPage);
              }}
              isStartedPage={offset}
            />
          </>
        ) : (
          <div className="news__body-empty">
            <ErrorComponent type={typesErrorComponent.NOT_FOUND_SEARCH} />
          </div>
        )}
      </div>
    </>
  );
};

export default Blog;
