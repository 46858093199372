import React from 'react';
import Select from 'react-select';

import PaymentMethodOptionTemplateItem from './components/PaymentMethodOptionTemplateItem';

import './styles.scss';

const CustomSelect = ({ options, fieldOptions, t }) => {
  const {
    field: { onChange, value },
  } = fieldOptions;

  const setDefaultOption = () => {
    return options.filter(item => item.value === value);
  };

  return (
    <div className="custom-input__container">
      <label className="custom-input__label">
        {t('finance_advertiser_form_label_pay')}
      </label>
      <div className="custom-input-container">
        <Select
          onChange={({ value }) => onChange(value)}
          defaultValue={setDefaultOption()}
          formatOptionLabel={e => PaymentMethodOptionTemplateItem({ ...e, t })}
          options={options}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
    </div>
  );
};

export default CustomSelect;
