import React from 'react';

import FormContainer from '../../component/common/FormContainer/FormContainer';
import { Faq, WriteUs, BloggerlyIs } from '../../component/Support';

import './styles.scss';

const Support = () => {
  return (
    <div className="support">
      <div className="support__left">
        <div className="support__left-info">
          <FormContainer>
            <BloggerlyIs />
          </FormContainer>
        </div>
        <Faq />
        <div className="support__left-write">
          <WriteUs />
        </div>
      </div>
      <div className="support__right">
        {/* <AddTicket /> // return after added support AddTicket */}
        <div className="support__right-write">
          <WriteUs />
        </div>
      </div>
    </div>
  );
};

export default Support;
