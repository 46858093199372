import types from './choiceBloggersTypes';

export const addNewBloggerToSelectedList = (blogger = {}) => ({
  type: types.ADD_BLOGGER_TO_SELECTED_LIST,
  payload: blogger,
});

export const removeNewBloggerFromSelectedList = (blogger = {}) => ({
  type: types.REMOVE_BLOGGER_FROM_SELECTED_LIST,
  payload: blogger,
});

export const clearChoiseBloggersInformation = () => ({
  type: types.CLEAR_CHOISE_BLOGGERS_INFORMATION,
});

// delete offer

export const deleteOfferStart = () => ({
  type: types.DELETE_OFFER_START,
});

export const deleteOfferSuccess = id => ({
  type: types.DELETE_OFFER_SUCCESS,
  payload: id,
});

export const deleteOfferError = (error = '') => ({
  type: types.DELETE_OFFER_ERROR,
  payload: error,
});

export const setActualBloggersPrice = data => ({
  type: types.SET_ACTUAL_BLOGGERS_PRICE,
  payload: data,
});
