import React, { useEffect, useState } from 'react';
import './styles.scss';
import { cowItWorksAdvertiser, cowItWorksBloggers } from './config';
import home_raket from '../../../assets/images/home/home-raket.svg';
import {
  getNewsList,
  getOffersCount,
  getProductionsCount,
} from '../../../api/news-service/news-service';
import { openModal } from '../../../redux/store/modal/modalActions';
import { modalTypes } from '../../../configs/modalTypes';
import { useDispatch, useSelector } from 'react-redux';
import book from '../../router/book';
import { useHistory } from 'react-router-dom';
import homeBlogger from '../../../assets/images/home/homeBlogger.svg';
import BlogItem from '../../component/BlogItem/BlogItem';
import { changeGlobalLoaderStatus } from '../../../redux/store/globalLoader/globalLoaderActions';
import { setErrorHandler } from '../../../redux/store/errorHandler/errorHandlerOperations';
import { useGetUserRole } from '../../../hooks/getUserRole';
import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';
import { sessionPersonalInformationSelector } from '../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const role = useGetUserRole();
  const [newsList, setNewsList] = useState([]);
  const [offersCount, setOffersCount] = useState({});
  const [productionsCount, setProductionsCount] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useSelector(sessionPersonalInformationSelector);
  const [t] = useTranslation();

  const getNewsInfo = () => {
    dispatch(changeGlobalLoaderStatus(true));
    getNewsList({ limit: 2, offset: 0, search: 'LATEST_NEWS' })
      .then(({ items }) => {
        setNewsList(items);
      })
      .catch(r => dispatch(setErrorHandler(r)))
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
  useEffect(() => {
    getNewsInfo();
    if (role) {
      if (role === 'BLOGGER') {
        getOffersCount()
          .then(e => {
            setOffersCount(e);
          })
          .catch(r => dispatch(setErrorHandler(r)));
      } else {
        getProductionsCount()
          .then(e => {
            setProductionsCount(e);
          })
          .catch(r => dispatch(setErrorHandler(r)));
      }
    }
  }, [role]);
  const _openModal = () => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.quickRefillPopUp,
      }),
    );
  };

  const _openCreateAdvertisement = () => {
    history.push(book.basicInformation);
  };
  const createTicket = () => {
    if (!email) {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.editCurrentEmail,
        }),
      );
      dispatch(
        setNotifyStatus({
          title: 'You need to add an email to create a request',
          type: 'error',
        }),
      );
    } else {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.supportWriteUs,
        }),
      );
    }
  };
  const getStep = () =>
    (role === 'BLOGGER' ? cowItWorksBloggers : cowItWorksAdvertiser).map(
      ({ title, image, text, button }, index) => (
        <div className="home-left__steps-item" key={index + 'step'}>
          <img className="home-left__steps-image" src={image} alt={''} />
          <div className="home-left__steps-text-container">
            <p className="home-left__steps-text-title">{t(title)}</p>
            <p className="home-left__steps-text-description">{t(text)}</p>
          </div>
          <div className="home-left__steps__head-mobile">
            <img
              className="home-left__steps__head-mobile-image"
              src={image}
              alt={''}
            />
            <p className="home-left__steps__head-mobile-title">{t(title)}</p>
          </div>
          <p className="home-left__steps__description">{t(text)}</p>

          {button ? (
            <button className="home-left__steps-button" onClick={_openModal}>
              {t(button.name)}
            </button>
          ) : null}
        </div>
      ),
    );
  const getProduct = () => (
    <div className="home-right__item">
      <p className="home-right__item-title">{t('home_products_title')}</p>
      <div className="home-right__item-container">
        <p className="home-right__item-description">
          {t('home_products_campaigns')}
        </p>
        <p className="home-right__item-value">{productionsCount.completed}</p>
      </div>
      <div className="home-right__item-container">
        <p className="home-right__item-description">
          {t('home_products_advertisements')}
        </p>
        <p className="home-right__item-value">
          {productionsCount.allAdvertisements}
        </p>
      </div>
      <div className="home-right__item-container">
        <p className="home-right__item-description">
          {t('home_products_active_advertisements')}
        </p>
        <p className="home-right__item-value">
          {productionsCount.activeAdvertisements}
        </p>
      </div>
      <button
        onClick={_openCreateAdvertisement}
        className="home-right__item-button"
      >
        {t('home_products_button')}
      </button>
    </div>
  );
  const getTicket = () => (
    <div className="home-right__item">
      <p className="home-right__item-title">{t('home_tickets')}</p>
      {/*<div className="home-right__item-container">*/}
      {/*  <p className="home-right__item-description">Awaiting for moderator</p>*/}
      {/*  <p className="home-right__item-value">7</p>*/}
      {/*</div>*/}
      {/*<div className="home-right__item-container">*/}
      {/*  <p className="home-right__item-description">Resolved</p>*/}
      {/*  <p className="home-right__item-value">25</p>*/}
      {/*</div>*/}
      <button onClick={createTicket} className="home-right__item-button">
        {t('home_button')}
      </button>
    </div>
  );
  const getOffers = () => (
    <div className="home-right__item">
      <p className="home-right__item-title">{t('home_offers_title')}</p>
      <div className="home-right__item-container">
        <p className="home-right__item-description">{t('home_offers_new')}</p>
        <p className="home-right__item-value">{offersCount.new}</p>
      </div>
      <div className="home-right__item-container">
        <p className="home-right__item-description">
          {t('home_offers_active')}
        </p>
        <p className="home-right__item-value">{offersCount.active}</p>
      </div>
      <div className="home-right__item-container">
        <p className="home-right__item-description">
          {t('home_offers_completed')}
        </p>
        <p className="home-right__item-value">{offersCount.completed}</p>
      </div>
    </div>
  );
  const getNews = () =>
    newsList.map(news => <BlogItem key={news._id} news={news} />);
  const getInfo = () => {
    if (role === 'BLOGGER') {
      return (
        <>
          {getOffers()}
          {getTicket()}
        </>
      );
    }
    return (
      <>
        {getProduct()}
        {getTicket()}
      </>
    );
  };
  return (
    <div className="home">
      <div className="home-left">
        <div className="home-left__header">
          <div className="home-left__header-content">
            <p className="home-left__header-title">{t('home_title')}</p>
            <p className="home-left__header-description">
              {t('home_description')}
            </p>
          </div>
          <img
            src={role === 'BLOGGER' ? homeBlogger : home_raket}
            alt={''}
            className="home-left__header-image"
          />
        </div>
        <div className="home-left__tablet-center">{getInfo()}</div>
        {/*<div className="home-left__news">
          <p className="home-sub-title">{t('home_sub_title')}</p>
          <div className="home-left__news-container">{getNews()}</div>
  </div>*/}
      </div>
      <div className="home-right">{getInfo()}</div>
    </div>
  );
};

export default Home;
