import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { clearCreationAdvertisementStep } from '../../../redux/store/advertisement/creationStatus/creationStatusActions';
import { clearChoiseBloggersInformation } from '../../../redux/store/advertisement/choiceBloggers/choiceBloggersActions';
import { clearAdvertisementInfoAfterPost } from '../../../redux/store/advertisement/creationData/creationDataActions';
import { deleteSelectedCampaign } from '../../../redux/store/campaign/campaignActions';
import { creationStatusSelector } from '../../../redux/selectors/selectors';

import StapsBar from '../../component/StapsBar/StapsBar';
import { CompanyConfig } from './config';

const CompanyContainer = ({
  currentStep,
  clearAdvertisementInfoAfterPost,
  clearCreationAdvertisementStep,
  deleteSelectedCampaign,
}) => {
  useEffect(() => {
    return () => {
      clearAdvertisementInfoAfterPost();
      clearCreationAdvertisementStep();
      deleteSelectedCampaign();
      clearChoiseBloggersInformation();
    };
  }, []);
  return (
    <div className="new-company">
      <StapsBar stapsStatusInfo={currentStep} />
      {CompanyConfig[currentStep.activePage]}
    </div>
  );
};

const mapStateToProps = state => ({
  currentStep: creationStatusSelector(state),
});

const mapDispatchToProps = {
  clearAdvertisementInfoAfterPost,
  clearCreationAdvertisementStep,
  deleteSelectedCampaign,
  clearChoiseBloggersInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContainer);
