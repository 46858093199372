import React from 'react';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const InitialFile = ({ handleUploadClick, approved, status, errors, t }) => {
  const activeInfo = status === 'ACTIVE' && approved;

  return (
    <div className="stories-custom">
      <p className="stories-custom__title">Photo/Video</p>
      <div className="stories-custom__header">
        <p className="stories-custom__header-text">
          {t('advertisement_BaseInfo_Video')}
          <br />
          {t('advertisement_BaseInfo_Photo')}
        </p>
        <input
          disabled={approved === null || activeInfo || approved}
          id="file-upload__contained"
          className="file-upload__input"
          accept=".mp4,.mov,.jpg,.png,.gif"
          multiple
          type="file"
          onChange={handleUploadClick}
        />
        <div className="file-upload__container">
          <label htmlFor="file-upload__contained">
            <div className="file-upload__button">
              {t('advertisement_BaseInfo_Upload')}
            </div>
          </label>
        </div>
      </div>
      <div className="uploaded-container">
        <div
          className={
            errors
              ? 'stories-custom__footer-background__errors'
              : 'stories-custom__footer-background'
          }
        >
          <span className="stories-custom__footer-background-display">
            {t('advertisement_BaseInfo_yourPhoto_Video')} <br />
            {t('advertisement_BaseInfo_displayed_here')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(InitialFile);
