import React from 'react';

import './styles.scss';

const AdvantagesItem = ({ item: { photo, title, subtitle }, t }) => {
  return (
    <div className="advantages-referrals-item">
      <img className="advantages-referrals-item-img" src={photo} alt="img" />
      <div className="advantages-referrals-item-content">
        <div className="advantages-referrals-item-content-title">
          {t(title)}
        </div>
        <div className="advantages-referrals-item-content-subtitle">
          {t(subtitle)}
        </div>
      </div>
    </div>
  );
};

export default AdvantagesItem;
