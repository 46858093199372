import React from 'react';

import first from '../../../../../../assets/images/landing/header/advertiser/first.png';
import second from '../../../../../../assets/images/landing/header/advertiser/second.png';
import third from '../../../../../../assets/images/landing/header/advertiser/third.png';

const IconComponent = ({ width }) => {
  const correctWidth = () => {
    if (width > '1920') {
      return '1200';
    } else if (width < '1921' && width > '1250') {
      return '851';
    } else if (width <= '1250') {
      return '120%';
    }
  };
  const correctHeight = () => {
    if (width > '1920') {
      return '1020';
    } else if (width < '1921' && width > '1250') {
      return '924';
    } else if (width <= '1250') {
      return '110%';
    }
  };
  const correctViewBox = () => {
    if (width > '1920') {
      return '0 0 1200 1020';
    } else if (width < '1921' && width > '1250') {
      return '0 0 851 924';
    } else if (width <= '1250') {
      return '100 0 1000 865';
    }
  };

  return (
    <svg
      width={correctWidth()}
      height={correctHeight()}
      viewBox={correctViewBox()}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M528 726C710.254 726 858 578.254 858 396C858 213.746 710.254 66 528 66C345.746 66 198 213.746 198 396C198 578.254 345.746 726 528 726Z"
        stroke="#CACDE4"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        strokeDasharray="2 8"
        id="motion1"
      >
        <animateMotion
          xlinkHref="#circle1"
          dur="100s"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
          begin="-20s"
        >
          <mpath xlinkHref="#motion1" />
        </animateMotion>
      </path>
      <path
        d="M527.5 804C753.108 804 936 621.108 936 395.5C936 169.892 753.108 -13 527.5 -13C301.892 -13 119 169.892 119 395.5C119 621.108 301.892 804 527.5 804Z"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        id="motion2"
      >
        <animateMotion
          xlinkHref="#circle2"
          calcMode="linear"
          dur="100s"
          begin="0s"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion2" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle5"
          dur="100s"
          begin="0s"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion2" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle6"
          dur="100s"
          begin="-40s"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion2" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle7"
          dur="100s"
          begin="-40s"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion2" />
        </animateMotion>
      </path>
      <path
        d="M528 923C819.054 923 1055 687.054 1055 396C1055 104.946 819.054 -131 528 -131C236.946 -131 1 104.946 1 396C1 687.054 236.946 923 528 923Z"
        stroke="#584187"
        strokeOpacity="0.15"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        strokeDasharray="2 8"
      />
      <path
        d="M528.423 652.847C670.042 652.847 784.847 538.042 784.847 396.423C784.847 254.805 670.042 140 528.423 140C386.805 140 272 254.805 272 396.423C272 538.042 386.805 652.847 528.423 652.847Z"
        stroke="#CACDE4"
        strokeWidth="1.234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        id="motion3"
      >
        <animateMotion
          xlinkHref="#circle3"
          dur="100s"
          begin="-80s"
          repeatCount="indefinite"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
        >
          <mpath xlinkHref="#motion3" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle4"
          calcMode="linear"
          dur="100s"
          begin="0s"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion3" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle9"
          dur="100s"
          begin="-60s"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion3" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle10"
          dur="100s"
          begin="-60s"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion3" />
        </animateMotion>
      </path>
      <circle
        id="circle4"
        cx="0"
        cy="0"
        r="36.5"
        fill="url(#pattern0)"
      ></circle>
      <circle id="circle3" cx="0" cy="0" r="42" fill="url(#pattern1)" />
      <circle id="circle1" cx="0" cy="0" r="55" fill="url(#pattern2)" />
      <circle id="circle5" cx="0" cy="0" r="8.5" fill="#CACDE4" />
      <circle
        id="circle2"
        cx="0"
        cy="0"
        r="13"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle9" cx="0" cy="0" r="6" fill="#CACDE4" />
      <circle
        id="circle10"
        cx="0"
        cy="0"
        r="10.5"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle6" cx="0" cy="0" r="11" fill="#CACDE4" />
      <circle
        id="circle7"
        cx="0"
        cy="0"
        r="15.5"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <g filter="url(#filter0_d)">
        <circle id="circle8" cx="607.5" cy="712.5" r="43.5" fill="#6946A7" />
      </g>
      <g filter="url(#filter1_d)">
        <circle cx="751.5" cy="276.5" r="50.5" fill="#FF3F74" />
      </g>
      <circle cx="528" cy="396" r="176" fill="#DBEBF8" />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        // x="352"
        // y="159"
        width="352"
        height="413"
      >
        <path
          d="M704 395.72C704 493.077 625.202 572 528 572C430.798 572 352 493.077 352 395.72C352 298.362 430.798 159 528 159C625.202 159 704 298.362 704 395.72Z"
          fill="#DBEBF8"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="400" y="160" width="257" height="412" fill="url(#pattern3)" />
      </g>
      <path
        d="M758.145 260H744.855C739.405 260 735 264.405 735 269.855V283.145C735 288.595 739.405 293 744.855 293H758.145C763.595 293 768 288.595 768 283.145V269.855C768 264.405 763.595 260 758.145 260ZM764.715 283.145C764.715 286.729 761.803 289.64 758.219 289.64H744.855C741.271 289.64 738.36 286.729 738.36 283.145V269.855C738.36 266.271 741.271 263.36 744.855 263.36H758.145C761.729 263.36 764.64 266.271 764.64 269.855V283.145H764.715Z"
        fill="white"
      />
      <path
        d="M751.5 267.989C746.796 267.989 742.989 271.796 742.989 276.5C742.989 281.204 746.796 285.011 751.5 285.011C756.204 285.011 760.011 281.204 760.011 276.5C760.011 271.796 756.204 267.989 751.5 267.989ZM751.5 281.726C748.588 281.726 746.274 279.412 746.274 276.5C746.274 273.588 748.588 271.274 751.5 271.274C754.412 271.274 756.726 273.588 756.726 276.5C756.726 279.412 754.412 281.726 751.5 281.726Z"
        fill="white"
      />
      <path
        d="M762.102 268.063C762.102 269.183 761.206 270.079 760.086 270.079C758.966 270.079 758.07 269.183 758.07 268.063C757.995 266.869 758.891 265.973 760.086 265.973C761.206 265.973 762.102 266.869 762.102 268.063Z"
        fill="white"
      />
      <path
        d="M619.824 703.155C616.922 700.282 612.086 700.282 609.046 703.155L607.526 704.659L606.006 703.155C603.104 700.282 598.268 700.282 595.228 703.155C592.189 706.027 592.327 710.815 595.228 713.825L596.748 715.33L607.526 726L618.304 715.33L619.824 713.825C622.725 710.815 622.725 706.164 619.824 703.155Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter2_d)">
        <circle cx="341.5" cy="217.5" r="40.5" fill="white" />
      </g>
      <path
        d="M355.747 229.867L349.889 223.937C351.625 221.767 352.71 218.946 352.71 215.909C352.71 208.749 346.996 203.036 339.909 203.036C332.749 203.036 327.036 208.749 327.036 215.909C327.036 223.069 332.749 228.782 339.909 228.782C343.019 228.782 345.839 227.697 348.009 225.889L353.867 231.82C354.373 232.326 355.241 232.326 355.82 231.82C356.253 231.241 356.254 230.373 355.747 229.867ZM329.784 215.909C329.784 210.34 334.34 205.784 339.909 205.784C345.478 205.784 350.034 210.34 350.034 215.909C350.034 218.657 348.949 221.188 347.141 222.996L347.069 223.069C345.261 224.877 342.729 226.034 339.909 226.034C334.268 225.962 329.784 221.478 329.784 215.909Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0"
            transform="translate(0 -0.0166667) scale(0.00833333)"
          />
        </pattern>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1"
            transform="translate(-0.05) scale(0.00588235)"
          />
        </pattern>
        <pattern
          id="pattern2"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image2"
            transform="translate(-0.104396) scale(0.00549451)"
          />
        </pattern>
        <filter
          id="filter0_d"
          x="529"
          y="634"
          width="157"
          height="157"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="17.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.687083 0 0 0 0 0.70325 0 0 0 0 0.808333 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="666"
          y="191"
          width="171"
          height="171"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="17.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.687083 0 0 0 0 0.70325 0 0 0 0 0.808333 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern3"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image3"
            transform="translate(-0.0116732) scale(0.00389105 0.00242718)"
          />
        </pattern>
        <filter
          id="filter2_d"
          x="266"
          y="142"
          width="151"
          height="151"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="17.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.687083 0 0 0 0 0.70325 0 0 0 0 0.808333 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="327.036"
          y1="232.199"
          x2="356.136"
          y2="232.199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7245" />
          <stop offset="1" stopColor="#FF3F74" />
        </linearGradient>
        <image id="image0" width="120" height="124" xlinkHref={first} />
        <image id="image1" width="187" height="170" xlinkHref={second} />
        <image id="image2" width="220" height="182" xlinkHref={third} />
      </defs>
    </svg>
  );
};

export default IconComponent;
