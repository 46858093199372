import { ReactComponent as FacebookIcon } from '../../../assets/images/vectors/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/images/vectors/google.svg';

export const socialMedia = [
  {
    name: 'google',
    Icon: GoogleIcon,
  },
  {
    name: 'facebook',
    Icon: FacebookIcon,
  },
];
