export const listOfCheckbox = [
  'Personal pages',
  'Family',
  'Animals',
  'Sports / Health',
  'Travels',
  'Photographer',
  'Auto / Moto',
  'Fashion / Beauty',
  'Entertainment',
  'Blog',
  'Media',
  'Other',
];
