import React, { useEffect, useState } from 'react';
import './styles.scss';
import ReferralTable from './referralTable/referralTable';
import {
  getReferralLink,
  getReferralStatistics,
} from '../../../api/referral-service/referral-service';
import { useDispatch } from 'react-redux';
import { setErrorHandler } from '../../../redux/store/errorHandler/errorHandlerOperations';
import { changeGlobalLoaderStatus } from '../../../redux/store/globalLoader/globalLoaderActions';
import { withTranslation } from 'react-i18next';

const Referral = ({ t }) => {
  const [statusCopy, setStatusCopy] = useState('referral_copy_status_1');
  const [link, setLink] = useState('');
  const [statistics, setStatistics] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeGlobalLoaderStatus(true));

    getReferralLink()
      .then(e => {
        setLink(e.link);
      })
      .catch(err => dispatch(setErrorHandler(err)));
    getReferralStatistics()
      .then(r => setStatistics(r))
      .catch(err => dispatch(setErrorHandler(err)))
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  }, []);

  const _copyLink = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setStatusCopy('referral_copy_status_2');
        setTimeout(() => {
          setStatusCopy('referral_copy_status_1');
        }, 1500);
      })
      .catch(err => dispatch(setErrorHandler()));
  };

  return (
    <div className="referral">
      <div className="referral__info">
        <div className="referral__info__item-container">
          <div className="referral__info-item referral__info-item-zero">
            <div>
              <p className="referral__info-item-text referral__info-item-bold">
                {t('referral_earned')}
              </p>
              <p className="referral__info-item-text referral__info-item-descriptions">
                {t('referral_by_links')}
              </p>
            </div>
            <p className="referral__info-item-text referral__info-item-number">
              {statistics?.profit}$
            </p>
          </div>
          <div className="referral__info-item referral__info-item-link">
            <p>{t('referral_affiliate_link')}</p>
            <div>
              <input className={'referral__info-link'} defaultValue={link} />
              <button onClick={_copyLink} className="referral__info-copy">
                {t('referral_copy')}
                <span className="referral__info-item-link-description">
                  {t(statusCopy)}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="referral__info__item-container">
          <div className="referral__info-item referral__info-item-first">
            <p className="referral__info-item-text">{t('referral_referred')}</p>
            <p className="referral__info-item-text referral__info-item-number">
              {statistics?.countOfAllTime}
            </p>
          </div>
          <div className="referral__info-item referral__info-item-second">
            <p className="referral__info-item-text">
              {t('referral_earned_on_month')}
            </p>
            <p className="referral__info-item-text referral__info-item-number">
              {statistics?.countOfMonth}
            </p>
          </div>
        </div>
      </div>
      <div className="referral__table">{<ReferralTable />}</div>
    </div>
  );
};
export default withTranslation()(Referral);
