import React from 'react';
import { typesErrorComponent } from '../../../configs/errorComponentTypes';
import somethingWentWrong from '../../../assets/images/error/somethingWentWrong.svg';
import accessDenied from '../../../assets/images/error/accessDenied.svg';
import pageNotFound from '../../../assets/images/error/pageNotFound.svg';
import notFoundSearch from '../../../assets/images/error/notFoundSerch.svg';
import InstagramAccount from '../../../assets/images/error/InstagramAccount.svg';

import Button from '../common/Button/Button';
import './styles.scss';
import { useHistory } from 'react-router';
import { openModal } from '../../../redux/store/modal/modalActions';
import { modalTypes } from '../../../configs/modalTypes';
import { useDispatch } from 'react-redux';
import ErrorInternetConnection from '../../../assets/images/components/errorInternetConnection';
import { withTranslation } from 'react-i18next';
const ErrorComponent = ({ type, t }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const options = {
    PAGE_NOT_FOUND: {
      image: pageNotFound,
      title: 'PAGE_NOT_FOUND_title',
      description: 'PAGE_NOT_FOUND_description',
      buttonText: 'PAGE_NOT_FOUND_buttonText',
      onClick: () => {
        history.push('/');
      },
    },
    NO_INTERNET: {
      image: <ErrorInternetConnection />,
      title: 'PAGE_NO_INTERNET_title',
      description: 'PAGE_NO_INTERNET_description',
      buttonText: 'PAGE_NO_INTERNET_buttonText',
      noShowButton: true,
      onClick: () => {},
    },
    SOMETHING_WRONG: {
      image: somethingWentWrong,
      title: 'PAGE_SOMETHING_WRONG_title',
      description: 'PAGE_SOMETHING_WRONG_description',
      buttonText: 'PAGE_SOMETHING_WRONG_buttonText',
      onClick: () => {},
    },
    ACCESS_DENIED: {
      image: accessDenied,
      title: 'PAGE_ACCESS_DENIED_title',
      description: 'PAGE_ACCESS_DENIED_description',
      buttonText: 'PAGE_ACCESS_DENIED_buttonText',
      onClick: history => {
        history.push('/');
      },
    },
    NOT_FOUND_SEARCH: {
      image: notFoundSearch,
      title: 'PAGE_NOT_FOUND_SEARCH_title',
      description: 'PAGE_NOT_FOUND_SEARCH_description',
      buttonText: 'PAGE_NOT_FOUND_SEARCH_buttonText',
      noShowButton: true,
      onClick: () => {},
    },
    NOT_INSTAGRAM_ACCOUNT: {
      image: InstagramAccount,
      title: 'PAGENOT_YOUTUBE_ACCOUNT_title',
      description: 'PAGENOT_YOUTUBE_ACCOUNT_description',
      buttonText: 'PAGENOT_YOUTUBE_ACCOUNT_buttonText',
      noShowButton: false,
      onClick: () => {
        dispatch(
          openModal({
            isOpen: true,
            renderType: modalTypes.addedNewInstagramAccount,
          }),
        );
      },
    },
  };
  const getItem = ({
    image,
    title,
    description,
    buttonText,
    onClick,
    noShowButton,
  }) => {
    return (
      <div className="error-component">
        {typesErrorComponent.NO_INTERNET === type ? (
          image
        ) : (
          <img src={image} className="error-component__image" alt={type} />
        )}
        <p className="error-component__title">{t(title)}</p>
        <p className="error-component__description">{t(description)}</p>
        {!noShowButton ? (
          <Button
            handleButtonClick={() => onClick(history)}
            options={{
              text: t(buttonText),
              renderType: 'primary',
              size: 'l',
              type: 'submit',
            }}
          />
        ) : null}
      </div>
    );
  };
  const getContent = () => {
    switch (type) {
      case typesErrorComponent.ACCESS_DENIED:
        return getItem(options.ACCESS_DENIED);
      case typesErrorComponent.NO_INTERNET:
        return getItem(options.NO_INTERNET);
      case typesErrorComponent.PAGE_NOT_FOUND:
        return getItem(options.PAGE_NOT_FOUND);
      case typesErrorComponent.SOMETHING_WRONG:
        return getItem(options.SOMETHING_WRONG);
      case typesErrorComponent.NOT_FOUND_SEARCH:
        return getItem(options.NOT_FOUND_SEARCH);
      case typesErrorComponent.NOT_INSTAGRAM_ACCOUNT:
        return getItem(options.NOT_INSTAGRAM_ACCOUNT);
      default:
        return getItem(options.SOMETHING_WRONG);
    }
  };
  return getContent();
};
export default withTranslation()(ErrorComponent);
