import types from './supportTypes';

const initialState = {
  subject: '',
  body: '',
};

const supportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.POST_SUPPORT_REQUEST_SUCCESS: {
      return {
        state: payload,
      };
    }
    default:
      return state;
  }
};

export default supportReducer;
