import React from 'react';
import './styles.scss';
const SwitchItem = ({ onSelect = () => {}, isChecked }) => {
  return (
    <span className="switcher switcher-1">
      <input
        checked={isChecked}
        type="checkbox"
        id="switcher-1"
        onChange={({ target }) => {
          onSelect(target.checked ? 'BLOGGER' : 'ADVERTISER');
        }}
      />
      <label htmlFor="switcher-1" />
    </span>
  );
};

export default SwitchItem;
