import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({
  elementType = 'placeholder',
  items = {},
  getNextPageItems = () => {},
  isStartedPage,
  sizeItemOnPage,
  initialPage,
}) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (isStartedPage === 0 && page !== 0) {
      setPage(0);
      getNextPageItems(0);
    }
  }, [isStartedPage, getNextPageItems, page]);

  useEffect(() => {
    if (initialPage && initialPage >= 0) {
      return setPage(initialPage);
    }
    return setPage(0);
  }, [initialPage]);

  const _handleNextList = ({ selected }) => {
    getNextPageItems(sizeItemOnPage * selected);
    setPage(selected);
  };

  if (elementType === 'placeholder') return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {items.count >= sizeItemOnPage && (
        <ReactPaginate
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          nextLabel={'Next'}
          previousLabel={'Prev'}
          pageCount={items.count ? Math.ceil(items.count / sizeItemOnPage) : 1}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          forcePage={page}
          onPageChange={_handleNextList}
          disabledClassName={'disabled'}
          // initialPage={initialPage ? initialPage : 0}
        />
      )}
    </div>
  );
};

export default Pagination;
