import React from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { handleBannerContent } from '../../../helpers/correctShowBanner';
import { numberWithSpaces } from '../../../helpers/numberWithSpaces';
import usePathname from '../../../hooks/usePathname';
import book from '../../router/book';

import moderation from '../../../assets/images/storyContent/moderation.svg';
import rejected from '../../../assets/images/storyContent/rejected.svg';
import accept from '../../../assets/images/storyContent/approved.svg';
import storyVideo from '../../../assets/images/dummy/storyVideo.svg';
import storyImage from '../../../assets/images/dummy/storyImage.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const AdvertisementsItem = ({ advertisement }) => {
  const {
    banner,
    title,
    bloggerCount,
    budget,
    status,
    _id,
    startDate,
    endDate,
    contentType,
    publicId,
    approved,
    mimeType,
  } = advertisement;

  const pathname = usePathname();
  const history = useHistory();

  const pathSplit = pathname.split('/');
  const campaignId = pathSplit[2];
  const [t] = useTranslation();

  const checkContentStatus = () => {
    if (approved) {
      return 'my_campaigns_advertisements_status_approved';
    } else if (approved === null) {
      return 'my_campaigns_advertisements_status_moderation';
    }
    return 'my_campaigns_advertisements_status_rejected';
  };

  const checkContentLogo = () => {
    if (approved) {
      return accept;
    } else if (approved === null) {
      return moderation;
    }
    return rejected;
  };

  return (
    <div
      onClick={() =>
        {
          localStorage.setItem('currentCompanyId', _id);
          history.push(`${book.myCompanies}/${campaignId}/advertisements/${_id}`)
          
        }
      }
      className="campaigns-advertisements__item"
    >
      <div className="campaigns-advertisements__item-left">
        <img
          className="campaigns-advertisements__item-left__image"
          src={handleBannerContent(
            contentType,
            mimeType,
            banner,
            storyImage,
            storyVideo,
          )}
          alt="banner"
        />
        <div className="campaigns-advertisements__item-left__content">
          <div className="campaigns-advertisements__item-left__content-title">
            <p className="campaigns-advertisements__item-left__content-title-text">
              {title}
            </p>
            <button className="campaigns-advertisements__item-left__content-title-copy custom-btn">
              Open
            </button>
          </div>
          <div className="campaigns-advertisements__item-left__content-content">
            <div className="campaigns-advertisements__item-left__content-content-top">
              <div
                className={
                  approved
                    ? 'campaigns-advertisements__item-left__content-content-top-approved'
                    : ' campaigns-advertisements__item-left__content-content-top-approved-none'
                }
              >
                <img
                  src={checkContentLogo()}
                  alt="content"
                  className="campaigns-advertisements__item-left__content-content-top-approved-img"
                />
                {t(checkContentStatus())}
              </div>
              <span className="campaigns-advertisements__item-left__content-content-top-id">
                ID: {publicId}
              </span>
            </div>
            <div className="campaigns-advertisements__item-left__content-content-bottom">
              <div className="campaigns-advertisements__item-left__content-content-bottom-date">
                {dayjs(startDate).format('DD.MM.YYYY')} -{' '}
                {dayjs(endDate).format('DD.MM.YYYY')}
              </div>
              <span className="campaigns-advertisements__item-left__content-content-bottom-status">
                {status}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="campaigns-advertisements__item-right">
        <div className="campaigns-advertisements__item-right__header">
          <div className="campaigns-advertisements__item-right__header-title">
            <p className="campaigns-advertisements__item-right__header-title-text">
              {title}
            </p>
            <button className="campaigns-advertisements__item-right__header-title-copy custom-btn">
              {t('my_campaigns_advertisements_open')}
            </button>
          </div>
          <div className="campaigns-advertisements__item-right__header-content">
            <div className="campaigns-advertisements__item-right__header-content-top">
              <div
                className={
                  approved
                    ? 'campaigns-advertisements__item-right__header-content-top-approved'
                    : ' campaigns-advertisements__item-right__header-content-top-approved-none'
                }
              >
                <img
                  src={checkContentLogo()}
                  alt="content"
                  className="campaigns-advertisements__item-right__header-content-top-approved-img"
                />
                {t(checkContentStatus())}
              </div>
              <span className="campaigns-advertisements__item-right__header-content-top-id">
                ID: {publicId}
              </span>
            </div>
            <div className="campaigns-advertisements__item-right__header-content-bottom">
              <div className="campaigns-advertisements__item-right__header-content-bottom-date">
                {dayjs(startDate).format('DD.MM.YYYY')} -{' '}
                {dayjs(endDate).format('DD.MM.YYYY')}
              </div>
              <span className="campaigns-advertisements__item-right__header-content-bottom-status">
                {status}
              </span>
            </div>
          </div>
        </div>
        <div className="campaigns-advertisements__item-right__footer">
          <div className="campaigns-advertisements__item-right__footer-item">
            <span className="campaigns-advertisements__item-right__footer-item-title">
              {t('my_campaigns_advertisements_bloggers')}
            </span>
            <span className="campaigns-advertisements__item-right__footer-item-subtitle">
              {numberWithSpaces(bloggerCount)}
            </span>
          </div>
          <div className="campaigns-advertisements__item-right__footer-item">
            <span className="campaigns-advertisements__item-right__footer-item-title">
              {t('my_campaigns_advertisements_type')}
            </span>
            <span className="campaigns-advertisements__item-right__footer-item-subtitle">
              {contentType?.toLowerCase()}
            </span>
          </div>
          <div className="campaigns-advertisements__item-right__footer-item">
            <span className="campaigns-advertisements__item-right__footer-item-title">
              {t('my_campaigns_advertisements_budget')}
            </span>
            <span className="campaigns-advertisements__item-right__footer-item-subtitle">
              $ {numberWithSpaces(budget) || 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisementsItem;
