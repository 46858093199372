import React, { useEffect, useState } from 'react';
import './styles.scss';
import { getListReferralTransactions } from '../../../../api/referral-service/referral-service';
import Pagination from '../../../component/common/Pagination/Pagination';
import { useDispatch } from 'react-redux';
import { setErrorHandler } from '../../../../redux/store/errorHandler/errorHandlerOperations';
import { withTranslation } from 'react-i18next';

const ReferralTable = ({ t }) => {
  const [bodyTable, setBodyTable] = useState({ count: 0, body: [] });
  const [goToFirstPage, setGoToFirstPage] = useState(false);
  const [sortParams, setSortParams] = useState({
    sortBy: 'createDate',
    orderBy: false,
  });
  const dispatch = useDispatch();
  const limit = 10;

  const getInfoTable = (offset, params) => {
    getListReferralTransactions(limit, offset, params)
      .then(({ count, items }) => {
        const body = items.map(({ amount, sender }) => ({
          amount,
          name: sender.name,
        }));
        setBodyTable({ count: count, body });
      })
      .catch(err => dispatch(setErrorHandler()));
  };
  useEffect(() => {
    getInfoTable(0, sortParams);
  }, []);
  const _nextPage = offset => {
    getInfoTable(offset, sortParams);
  };
  const _sortName = () => {
    const params = {
      sortBy: 'senderName',
      orderBy: sortParams.sortBy === 'senderName' ? !sortParams.orderBy : false,
    };
    setSortParams(() => params);

    getInfoTable(0, params);
    setGoToFirstPage(e => !e);
  };
  const _sortAmount = () => {
    const params = {
      sortBy: 'amount',
      orderBy: sortParams.sortBy === 'amount' ? !sortParams.orderBy : false,
    };
    getInfoTable(0, params);
    setSortParams(() => params);
  };
  const getTableBody = () => (
    <div className="referral-table__body ">
      <div className="referral-table__body-items referral-table__body-title">
        <div
          className="referral-table__body-left-item referral-table__body-left-item__title"
          onClick={_sortName}
        >
          <p>{t('referral_col_name')}</p>
        </div>
        <div
          className="referral-table__body-right-item referral-table__body-left-item__title"
          onClick={_sortAmount}
        >
          <p>{t('referral_col_amount')}</p>
        </div>
      </div>
      {getTableResults()}
    </div>
  );

  const getTableResults = () =>
    bodyTable.body.map(({ name, amount }, index) => (
      <div key={'index+' + name + index} className="referral-table__body-items">
        <div className="referral-table__body-left-item">
          <p>{name}</p>
        </div>
        <div className="referral-table__body-right-item">
          <p>+{amount} $</p>
        </div>
      </div>
    ));

  return (
    <div className="referral-table">
      {getTableBody()}
      <Pagination
        items={bodyTable}
        elementType="page"
        sizeItemOnPage={limit}
        getNextPageItems={_nextPage}
        initialPage={goToFirstPage ? 0 : null}
      />
    </div>
  );
};
export default withTranslation()(ReferralTable);
