import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  editStatusItem,
  editOrderItem,
  editSortItem,
  editOffsetItem,
} from '../../../redux/store/advertisement/advertisement/AdvertisementOperations';
import { allAdvertisementSelector } from '../../../redux/selectors/selectors';

import PlacementStatusItem from './PlacementStatusItem';
import SelectFilter from '../HeaderListBlogger/selectFilter/selectFilter';
import Pagination from '../common/Pagination/Pagination';
import {
  sortByItem,
  orderByItem,
  statusByItem,
} from '../../../constants/advertisementList';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const PlacementStatus = ({ id, offers, offersCount, isPaywalled, t, refresh, refreshState }) => {
  const {
    params: { offset },
  } = useSelector(allAdvertisementSelector);
  const dispatch = useDispatch();
  const sizeItemOnPage = 6;
  
  const onChangeSort = ({ value }) => {
    dispatch(editSortItem(id, value));
  };

  const onChangeOrder = ({ value }) => {
    dispatch(editOrderItem(id, value));
  };

  const onChangeStatus = ({ value }) => {
    dispatch(editStatusItem(id, value));
  };

  return (
    <div className="placement-status">
      <h1 className="placement-status-title">
        {t('my_campaigns_listBlogger_title')}
      </h1>
      <div className="placement-status__select">
        <div className="placement-status__select-left">
          <SelectFilter
            options={statusByItem}
            onChange={onChangeStatus}
            defaultValue={statusByItem[0]}
            type={'status'}
          />
        </div>
        <div className="placement-status__select-right">
          <div className="placement-status__select-right-sort">
            <SelectFilter
              options={sortByItem}
              onChange={onChangeSort}
              defaultValue={sortByItem[0]}
              type={'sort'}
            />
          </div>
          <div className="placement-status__select-right-sort">
            <SelectFilter
              options={orderByItem}
              onChange={onChangeOrder}
              defaultValue={orderByItem[0]}
              type={'sortBy'}
            />
          </div>
        </div>
      </div>
      <PlacementStatusItem
        isPaywalled={isPaywalled}
        offersCount={offersCount}
        offers={offers}
        refresh={refresh}
        refreshState={refreshState}
      />
      <Pagination
        items={{ count: offersCount, items: offers }}
        elementType="page"
        sizeItemOnPage={sizeItemOnPage}
        getNextPageItems={e => {
          dispatch(editOffsetItem(id, e));
        }}
        isStartedPage={offset}
      />
    </div>
  );
};

export default withTranslation()(PlacementStatus);
