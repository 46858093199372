import React from 'react';
import './style.scss';
import img1 from './ExampleAuth.png'
const ShowVerificationCode = ({ details }) => {
  const email = 'bloggerly_official';
  return (
    <div className="show-verification-code">
      <p>Your verification code is {details.code}</p>
      <p>
      Please, enter this code on your Youtube Channel “About” section (see
example)
        <img src={img1} style={{width: '100%', height: '400px'}}/>
      </p>
    </div>
  );
};
export default ShowVerificationCode;
