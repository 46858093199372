import React, { useState, useEffect } from 'react';

import { partners } from './config';

import useWindowWidth from '../../../../../../hooks/useWindow';

import Break from '../../../../../../assets/images/landing/global/break.svg';
import Arrow from '../../../../../../assets/images/landing/case/arrow.svg';
import './styles.scss';

const Brands = ({ t }) => {
  const width = useWindowWidth();

  const [animate, setAnimate] = useState({
    initialAnimate: false,
    right: false,
    left: false,
  });

  const [state, setState] = useState({
    start: 0,
    finish: 4,
  });

  useEffect(() => {
    if (width < 768) {
      setState({ ...state, finish: 2 });
    } else {
      setState({ ...state, finish: 4 });
    }
  }, [width]);

  const { start, finish } = state;
  const { right, left, initialAnimate } = animate;

  const leftClick = () => {
    if (start > 0 && finish > 0) {
      setState({
        ...state,
        start: start - 1,
        finish: finish - 1,
      });
      setAnimate({
        ...animate,
        initialAnimate: true,
        left: true,
        right: false,
      });
    }
  };

  const rightClick = () => {
    if (finish < partners.length) {
      setState({
        ...state,
        start: start + 1,
        finish: finish + 1,
      });
      setAnimate({
        ...animate,
        initialAnimate: true,
        left: false,
        right: true,
      });
    }
  };

  if (initialAnimate)
    setTimeout(() => {
      setAnimate({
        ...animate,
        initialAnimate: false,
        left: false,
        right: false,
      });
    }, 300);

  const handleDirectionAnimate = () => {
    if (right) {
      return 'brands__center-container-item-right';
    } else if (left) {
      return 'brands__center-container-item-left';
    }
    return 'brands__center-container-item';
  };

  return (
    <div className="brands">
      <div className="brands__title">
        {t('bloggers_landing_slider_first')} <br />
        {t('bloggers_landing_slider_second')}
        <img className="brands__wave" src={Break} alt="Break" />
      </div>
      <div className="brands__center">
        <div className="arrow-container">
          <div
            onClick={leftClick}
            className={!state.start ? `arrow-left faded-left` : 'arrow-left'}
          >
            <img
              className="brands__center-arrow-left-img"
              src={Arrow}
              alt="Arrow"
            />
          </div>
          <div
            onClick={rightClick}
            className={
              finish >= partners.length
                ? 'arrow-right faded-right'
                : 'arrow-right'
            }
          >
            <img
              className="brands__center-arrow-right-img"
              src={Arrow}
              alt="Arrow"
            />
          </div>
        </div>
        <div className="brands__center-container">
          {partners.slice(start, finish).map(({ img, link }, index) => (
            <div
              key={index}
              className={handleDirectionAnimate()}
              onClick={() => {
                window.open(link, '_blank');
              }}
            >
              <img
                className="brands__center-container-item-img"
                src={img}
                alt="partner"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
