import second from '../../../../../../assets/images/landing/join/second.svg';
import first from '../../../../../../assets/images/landing/join/first.svg';
import third from '../../../../../../assets/images/landing/join/third.svg';

export const join = [
  {
    title: 'referrals_landing_join_item_1_title',
    subtitle: 'referrals_landing_join_item_1_subtitle',
    img: first,
    alt: 'first',
  },
  {
    title: 'referrals_landing_join_item_2_title',
    subtitle: 'referrals_landing_join_item_2_subtitle',
    img: second,
    alt: 'second',
  },
  {
    title: 'referrals_landing_join_item_2_title',
    subtitle: 'referrals_landing_join_item_2_subtitle',
    img: third,
    alt: 'third',
  },
];
