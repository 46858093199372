import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import usePathname from '../../../../../../hooks/usePathname';
import { social, navbar, help } from './config';
import book from '../../../../../router/book';

import Logo from '../../../../../../assets/images/landing/footer/bloggerly.svg';
import Master from '../../../../../../assets/images/landing/footer/master.svg';
import Visa from '../../../../../../assets/images/landing/footer/visa.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const pathname = usePathname();
  const [t] = useTranslation();

  const scrollToTop = link => {
    if (pathname === link) window.scroll(0, 0);
  };

  return (
    <footer>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-left">
            <img src={Logo} alt="Logo" />
            <div className="footer-left__text">{t('footer_landing_text')}</div>
            <div className="footer-left__social">
              {social.map((item, index) => (
                <img
                  className="footer-left__social-item"
                  key={index}
                  src={item.icon}
                  alt={item.alt}
                />
              ))}
            </div>
          </div>
          <div className="footer-center">
            <div className="footer-center-title">Information</div>
            <div className="footer-center-section">
              {navbar.map(({ link, title }, index) => (
                <span key={index} className="footer-center-section__item">
                  <NavLink
                    className="footer-center-section__item-btn"
                    to={link}
                    onClick={() => scrollToTop(link)}
                  >
                    {t(title)}
                  </NavLink>
                </span>
              ))}
              <div className="footer-center-section-payment">
                <img
                  className="footer-center-section-payment-img"
                  src={Master}
                  alt="Master"
                />
                <img
                  className="footer-center-section-payment-img"
                  src={Visa}
                  alt="Visa"
                />
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="footer-right-title">Help</div>
            <div className="footer-right-section">
              {help.map(({ link, title }, index) => (
                <div key={index} className="footer-right-section__item">
                  <span className="footer-right-section__item-btn">
                    {t(title)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-confirmation">
        <div className="footer-confirmation__item">
          <p className="footer-confirmation__item-ts">
            {t('footer_landing_ts')}
          </p>
        </div>
        <div className="footer-confirmation__item">
          <Link
            onClick={() => scrollToTop(book.agreement)}
            to={book.agreement}
            className="footer-confirmation__item-btn"
          >
            {t('footer_landing_agreement')}
          </Link>
        </div>
        <div className="footer-confirmation__item">
          <Link
            onClick={() => scrollToTop(book.policy)}
            to={book.policy}
            className="footer-confirmation__item-btn"
          >
            {t('footer_landing_privacy')}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
