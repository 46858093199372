export const sortByList = [
  { label: 'my_campaigns_sort_title', value: 'title' },
  { label: 'my_campaigns_sort_budget', value: 'budget' },
  { label: 'my_campaigns_sort_advertisements', value: 'advertisements' },
];

export const orderByList = [
  { label: 'my_campaigns_order_high', value: 'desc' },
  { label: 'my_campaigns_order_low', value: 'asc' },
];

export const sortByItem = [
  { label: 'my_campaigns_advertisements_sort_title', value: 'title' },
  { label: 'my_campaigns_advertisements_sort_status', value: 'status' },
  {
    label: 'my_campaigns_advertisements_sort_published',
    value: 'publishedDate',
  },
  { label: 'my_campaigns_advertisements_sort_public', value: 'publicId' },
];

export const statusItem = [
  { label: 'my_campaigns_advertisements_filter_all', value: 'ALL' },
  { label: 'my_campaigns_advertisements_filter_active', value: 'ACTIVE' },
  { label: 'my_campaigns_advertisements_filter_draft', value: 'DRAFT' },
  { label: 'my_campaigns_advertisements_filter_completed', value: 'COMPLETED' },
];
