import * as dayjs from 'dayjs';
import { useHistory, withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as relativeTime from 'dayjs/plugin/relativeTime';

import {
  contentLoaderStatusSelector,
  userNotificationSelector,
} from '../../../../redux/selectors/selectors';
import {
  editNotifcationIsRead,
  fetchUserNotifications,
} from '../../../../redux/store/notification/notificationOperations';

import bell from '../../../../assets/images/header/bell.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const Notification = ({ t }) => {
  const isLoading = useSelector(contentLoaderStatusSelector);
  const { count, messages, unreadCount } = useSelector(
    userNotificationSelector,
  );

  const [selectStatus, setSelectStatus] = useState(false);
  const [limit, setLimit] = useState(6);
  const dispatch = useDispatch();
  const history = useHistory();
  dayjs.extend(relativeTime);

  useEffect(() => {
    dispatch(fetchUserNotifications(limit));
  }, [limit, dispatch]);

  const onToggleVisibleSelect = () => {
    setSelectStatus(!selectStatus);
    setLimit(6);
  };

  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (scrollHeight - scrollTop === clientHeight && count > limit) {
      setLimit(prev => prev + 6);
    }
  };

  const onHandleClick = (id, action, actionId, isRead) => {
    dispatch(editNotifcationIsRead(id, action, actionId, isRead, history));
  };

  return (
    <div
      className="notification"
      onMouseEnter={onToggleVisibleSelect}
      onMouseLeave={onToggleVisibleSelect}
    >
      <div
        className={
          unreadCount ? 'notification__img active' : 'notification__img'
        }
      >
        <img src={bell} alt="notification" />
      </div>
      <div className="notification__dropdown-item" />
      {selectStatus && (
        <div className="notification__dropdown">
          <div className="notification__dropdown-item-title">
            {t('header_in_login_menu_notifications')}
          </div>
          {count ? (
            <div
              onScroll={handleScroll}
              className="notification__dropdown-list"
            >
              {messages &&
                messages.map(
                  (
                    {
                      title,
                      isRead,
                      data,
                      _id,
                      notificationCase,
                      idForRedirect,
                      image_url,
                    },
                    index,
                  ) => (
                    <div
                      key={title + index}
                      onClick={() =>
                        onHandleClick(
                          _id,
                          notificationCase,
                          idForRedirect,
                          isRead,
                        )
                      }
                      className="notification__dropdown-list-item"
                    >
                      <div
                        style={{ background: 'rgba(62, 202, 242, 0.13)' }}
                        className={
                          isRead === 'NOT_READ'
                            ? 'notification__dropdown-list-item-image bell'
                            : 'notification__dropdown-list-item-image'
                        }
                      >
                        <img src={image_url} alt="notification" />
                      </div>
                      <div className="notification__dropdown-list-item-information">
                        <span className="notification__dropdown-list-item-information-title">
                          {title}
                        </span>
                        <span className="notification__dropdown-list-item-information-time">
                          {dayjs(data).fromNow()}
                        </span>
                      </div>
                    </div>
                  ),
                )}
              {isLoading && <div className="notification__loader" />}
            </div>
          ) : (
            <div className="notification__dropdown-list-load">
              <span>{t('header_in_login_menu_not_notification')}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(withTranslation()(Notification));
