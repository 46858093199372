import * as yup from 'yup';

export const baseInformationFormSchema = yup.object().shape({
  title: yup.string().trim().required(),
  startDate: yup.date().required(),
  endDate: yup
    .date()
    .min(yup.ref('startDate'))
    .test({
      test: function (endDate) {
        return this.parent.startDate < endDate;
      },
    })
    .required(),
  story: yup.object({
    contentType: yup.string().required(),
    content: yup.string(),
    instruction: yup
      .string()
      .trim()
      .when('contentType', {
        is: contentType => contentType === 'IMPROVISATION',
        then: yup.string().trim().required(),
      }),
    profile: yup.string().trim(),
    site: yup.string().trim().url(),
    file: yup.mixed().when('contentType', {
      is: contentType => contentType === 'CUSTOM',
      then: yup
        .mixed()
        .required()
        .test('file', 'Unsupported size', value => {
          if (value?.type?.includes('image')) {
            return value?.size <= 10000000;
          } else if (value?.type?.includes('video')) {
            return value?.size <= 50000000;
          } else if (value?.includes('/files/story/'))
            return value?.includes('/files/story/');
        }),
    }),
  }),
});
