import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { clearBaseInformation } from '../../../redux/store/advertisement/baseInformation/baseInformationActions';
import { deleteSelectedCampaign } from '../../../redux/store/campaign/campaignActions';
import { sessionlogOut } from '../../../redux/store/session/sessionOperations';

import usePathname from '../../../hooks/usePathname';
import Button from '../common/Button/Button';
import { listOfMenuItemAdvertiser, listOfMenuItemBlogger } from './config';
import book from '../../router/book';

import logo from '../../../assets/images/navigation/logo.svg';
import './styles.scss';
import { openModal } from '../../../redux/store/modal/modalActions';
import { modalTypes } from '../../../configs/modalTypes';
import { useGetUserRole } from '../../../hooks/getUserRole';
import { withTranslation } from 'react-i18next';

const { signin, signup, basicInformation, home } = book;

const routes = [signin, signup];

const LeftBar = ({ t }) => {
  const dispatch = useDispatch();
  const pathname = usePathname();
  const history = useHistory();
  const userRole = useGetUserRole();
  const [isOpen, setIsOpen] = useState(false);
  if (routes.includes(pathname)) return null;

  const createCampaign = () => {
    if (userRole === 'BLOGGER') {
      dispatch(
        openModal({
          isOpen: true,
          renderType: modalTypes.addedNewInstagramAccount,
        }),
      );
    } else {
      dispatch(clearBaseInformation());
      dispatch(deleteSelectedCampaign());
      history.push(basicInformation);
    }
  };

  const getFirstMenu = () =>
    (userRole === 'BLOGGER'
      ? listOfMenuItemBlogger
      : listOfMenuItemAdvertiser
    ).map(({ name, link, icon, type, handleFunction, rel, target }, index) =>
      type === 'line' ? (
        <div key={index} className="left-bar__first-menu-line" />
      ) : (
        <li
          key={link + name}
          className={
            pathname.includes(link)
              ? 'left-bar__first-menu-item-active'
              : 'left-bar__first-menu-item'
          }
        >
          <Link
            onClick={() => {
              if (handleFunction) {
                dispatch(sessionlogOut(history));
              }
              setIsOpen(false);
            }}
            to={link}
            className="left-bar__first-menu-container"
          >
            {type === 'menu' ? (
              <div className="left-bar__first-menu-icon">
                {icon(pathname.includes(link))}
              </div>
            ) : (
              ''
            )}
            <span
              style={pathname.includes(link) ? { color: '#FF1D70' } : {}}
              className={
                type === 'subMenu'
                  ? 'left-bar__first-menu-name left-bar__first-menu-name-no-icon'
                  : 'left-bar__first-menu-name'
              }
            >
              {t(name)}
            </span>
          </Link>
        </li>
      ),
    );

  return (
    <>
      <input
        type="checkbox"
        id="menuToggle"
        className="left-bar__mobile-key-input"
        checked={isOpen}
        onChange={({ target }) => {
          setIsOpen(target.checked);
        }}
      />
      <label className="left-bar__mobile-key" htmlFor="menuToggle">
        <div className="left-bar__mobile-key-icon">
          <span />
          <span />
          <span />
        </div>
      </label>

      <label className="left-bar__mobile-background" htmlFor="menuToggle" />

      <div className="left-bar">
        <label className="left-bar__mobile-close" htmlFor="menuToggle">
          <div className="left-bar__mobile-key-icon">
            <span />
            <span />
          </div>
        </label>
        <div className="left-bar__logo">
          <Link to={home}>
            <img src={logo} alt="bloggerly" />
          </Link>
        </div>
        <Button
          options={{
            isIcon: true,
            renderType: 'primary',
            size: 'm-s',
            text: t(
              userRole === 'BLOGGER'
                ? 'left_bar_blogger_new_account'
                : 'left_bar_blogger_create_advertisement',
            ),
            disabled: pathname.includes(basicInformation),
          }}
          handleButtonClick={() => createCampaign()}
        />

        <ul> {getFirstMenu()}</ul>
        <div className="left-bar__company-info">
          <span>{t('left_bar_blogger_company')}</span>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(LeftBar);
