import React from 'react';
import { useHistory } from 'react-router';

import book from '../../router/book';

import pageInDevelopment from '../../../assets/images/error/pageInDevelopment.svg';
import './styles.scss';

const DevelopmentProcess = ({ name }) => {
  const history = useHistory();

  return (
    <div className="development-process">
      <img
        src={pageInDevelopment}
        className="development-process-image"
        alt="development process"
      />
      <h1 className="development-process-title">Page in development process</h1>
      <h1 className="development-process-text">
        Sorry but now page ({name}) in development
      </h1>
      <button
        onClick={() => history.push(book.home)}
        className="development-process-btn"
      >
        Go to Home
      </button>
    </div>
  );
};

export default DevelopmentProcess;
