import dayjs from 'dayjs';

import { createFormData } from './formDataConvertor';

import {
  sendBaseInformationForm,
  changeBaseInformationForm,
  changeAllInformationForm,
  nextStepWithoutSendingData,
} from '../redux/store/advertisement/baseInformation/baseInformationOperations';

import {
  formingAnObjectForSendingForm,
  isEmptyObject,
} from '../helpers/isEmptyObject';

export const createCorrectData = (campaignId, data) => {
  const newBaseInfo = {
    banner: data.banner,
    title: data.title,
    campaignId: campaignId,
    startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
    endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
    campaignType: data.campaignType
  };

  return {
    ...newBaseInfo,
  };
};

export const checkTypeOperation = (
  dispatch,
  typeOperation,
  story,
  storyItem,
  history,
  newData,
  _id,
  dirtyFields,
  status,
) => {
  if (
    typeOperation === 'edit' &&
    story.approved &&
    isEmptyObject(dirtyFields)
  ) {
    return dispatch(nextStepWithoutSendingData());
  }

  if (story.approved === false) {
    const data = formingAnObjectForSendingForm(newData, dirtyFields);
    const infoStory = formingAnObjectForSendingForm(
      storyItem,
      dirtyFields.story,
    );
    return dispatch(
      changeAllInformationForm(_id, data, infoStory, storyItem, history),
    );
  }

  if (
    typeOperation === 'edit' &&
    story.approved &&
    !isEmptyObject(dirtyFields)
  ) {
    const data = formingAnObjectForSendingForm(newData, dirtyFields);
    return dispatch(changeBaseInformationForm(_id, data));
  }

  if (typeOperation !== 'edit') {
    return dispatch(sendBaseInformationForm(newData, storyItem, history));
  }

  if (typeOperation !== 'edit' && story.approved && status === 'ACTIVE') {
    return dispatch(nextStepWithoutSendingData());
  }
};

export const correctBaseInformation = (storyItem, data) => {
  let changedBaseInfoDataFormat;
  if (storyItem?.file?.type?.includes('image')) {
    changedBaseInfoDataFormat = createFormData({
      ...data,
      banner: storyItem?.file,
    });
  } else {
    changedBaseInfoDataFormat = createFormData({ ...data });
  }
  return changedBaseInfoDataFormat;
};
