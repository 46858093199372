import { useState, useEffect } from 'react';
import { isSupportedNotification } from '../helpers/firebase/isSupportedNotification';
import { getCurrentUserInformation } from '../api/session-service/session-service';
import { useDispatch } from 'react-redux';
import { setErrorHandler } from '../redux/store/errorHandler/errorHandlerOperations';

export const useGetUserRole = () => {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState(() => {
    const userJson = localStorage.getItem('accaunt');
    const user = userJson ? JSON.parse(userJson) : null;
    return user && user.role ? user.role : null;
  });
  useEffect(() => {
    if (!userRole) {
      getCurrentUserInformation()
        .then(user => {
          setUserRole(user.role);
          localStorage.setItem(
            'accaunt',
            JSON.stringify({
              ...user,
              notification: isSupportedNotification()
                ? Notification.permission
                : '',
            }),
          );
        })
        .catch(r => dispatch(setErrorHandler(r)));
    }
  }, []);
  return userRole;
};
