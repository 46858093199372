import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { sessionIsAuthSelector } from '../../../../redux/selectors/selectors';

import { usePathname } from '../../../../hooks/usePathname';
import book from '../../../router/book';

import bloggerly from '../../../../assets/images/landing/header/bloggerly-white.svg';
import './styles.scss';

const BlogNavigation = ({ background, t }) => {
  const authenticated = useSelector(sessionIsAuthSelector);
  const pathname = usePathname();

  return (
    <div className="navigation-for-news">
      <div className="navigation-for-news-left">
        {!authenticated ? (
          <Link to={book.advertisers}>
            <img
              className="navigation-for-news-left-img-active"
              src={bloggerly}
              alt="bloggerly"
            />
          </Link>
        ) : (
          <div>
            <img
              className="navigation-for-news-left-img"
              src={bloggerly}
              alt="bloggerly"
            />
          </div>
        )}
        {pathname === book.blog ? (
          <span className="navigation-for-news-left-title">
            {t('blog_name')}
          </span>
        ) : (
          <Link
            to={book.blog}
            className="navigation-for-news-left-title active"
          >
            blog
          </Link>
        )}
      </div>

      <div className="navigation-for-news-right">
        <Link
          to={book.home}
          className={`${
            background === 'grey'
              ? 'navigation-for-news-right-button'
              : 'navigation-for-news-right-button white'
          }`}
        />
      </div>
    </div>
  );
};

export default BlogNavigation;
