import { get, put } from '../apiService';

export const getUserNotifications = limit => {
  const limitInfo = limit ? '?limit=' + limit : '?limit=5';
  return get('/notifications/get-current-user-notifications' + limitInfo);
};

export const putIsReadNotification = id => {
  return put(`/notifications/change-notification-status/${id}`);
};

export const putNotificationSubscribe = body => {
  return put('/notifications/subscribe', body);
};
