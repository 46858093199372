import React from 'react';

import CustomTimePicker from '../../../../common/CustomTimePicker/CustomTimePicker';
import DatePicker from '../../../../common/DatePicker/DatePicker';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const DateTimeSelect = ({
  Controller,
  errors,
  control,
  story,
  status,
  getValues,
  t,
}) => {
  const activeInfo = status === 'ACTIVE' && story?.approved;
  const statusStories = story?.approved === null || activeInfo;

  const minEndDate = Date.parse(getValues().startDate) + 86400000;

  return (
    <>
      <div className="form-block">
        <div className="custom-date__container">
          <label className="custom-date__label">
            {t('advertisement_BaseInfo_publish_data')}
          </label>
          <div className="custom-date__dual">
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value, ref } }) => (
                <DatePicker
                  onChange={onChange}
                  selected={value}
                  statusStories={statusStories}
                  errors={errors?.startDate}
                  minDate={new Date(Date.now() + 86400000)}
                  dateRef={elem => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value, ref } }) => (
                <DatePicker
                  onChange={onChange}
                  selected={value}
                  statusStories={statusStories}
                  errors={errors?.endDate}
                  minDate={new Date(minEndDate)}
                  dateRef={elem => {
                    elem && ref(elem.input);
                  }}
                />
              )}
            />
            <p className="field__description">
              {t('advertisement_BaseInfo_publish_data_info')}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(DateTimeSelect);
