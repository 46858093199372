import React from 'react';

import first from '../../../../../../assets/images/landing/header/referral/first.png';
import second from '../../../../../../assets/images/landing/header/referral/second.png';
import third from '../../../../../../assets/images/landing/header/referral/third.png';
import fourth from '../../../../../../assets/images/landing/header/referral/fourth.png';

const IconComponent = ({ width }) => {
  const correctWidth = () => {
    if (width > '1920') {
      return '1200';
    } else if (width < '1921' && width > '1250') {
      return '850';
    } else if (width <= '1250') {
      return '110%';
    }
  };
  const correctHeight = () => {
    if (width > '1920') {
      return '1020';
    } else if (width < '1921' && width > '1250') {
      return '850';
    } else if (width <= '1250') {
      return '115%';
    }
  };
  const correctViewBox = () => {
    if (width > '1920') {
      return '0 0 1200 1020';
    } else if (width < '1921' && width > '1250') {
      return '0 0 920 1020';
    } else if (width <= '1250') {
      return '100 0 1000 1000';
    }
  };

  return (
    <svg
      width={correctWidth()}
      height={correctHeight()}
      viewBox={correctViewBox()}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M580.349 595.697C666.697 595.697 736.697 525.697 736.697 439.349C736.697 353 666.697 283 580.349 283C494 283 424 353 424 439.349C424 525.697 494 595.697 580.349 595.697Z"
        fill="url(#pattern0)"
      />
      <path
        d="M580.159 658.317C701.196 658.317 799.317 560.196 799.317 439.159C799.317 318.121 701.196 220 580.159 220C459.121 220 361 318.121 361 439.159C361 560.196 459.121 658.317 580.159 658.317Z"
        stroke="#6946A7"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        strokeDasharray="2 8"
        id="motion7"
      >
        <animateMotion
          xlinkHref="#circle21"
          dur="100s"
          begin="-80s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion7" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle22"
          dur="100s"
          begin="-80s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion7" />
        </animateMotion>
      </path>
      <path
        d="M580 789C773.3 789 930 632.3 930 439C930 245.7 773.3 89 580 89C386.7 89 230 245.7 230 439C230 632.3 386.7 789 580 789Z"
        stroke="#584187"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        strokeDasharray="2 8"
        id="motion8"
      >
        <animateMotion
          xlinkHref="#circle23"
          dur="100s"
          begin="-90s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion8" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle24"
          dur="100s"
          begin="-40s"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion8" />
        </animateMotion>
      </path>
      <path
        d="M580 878C822.453 878 1019 681.453 1019 439C1019 196.547 822.453 0 580 0C337.547 0 141 196.547 141 439C141 681.453 337.547 878 580 878Z"
        stroke="#584187"
        strokeOpacity="0.31"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        id="motion10"
      >
        <animateMotion
          xlinkHref="#circle26"
          dur="100s"
          begin="-15s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion10" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle31"
          dur="100s"
          begin="-5s"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion10" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle32"
          dur="100s"
          begin="-5s"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion10" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle33"
          dur="100s"
          begin="-35s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion10" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle34"
          dur="100s"
          begin="-35s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion10" />
        </animateMotion>
      </path>
      <path
        d="M580.5 1019C900.549 1019 1160 759.549 1160 439.5C1160 119.451 900.549 -140 580.5 -140C260.451 -140 1 119.451 1 439.5C1 759.549 260.451 1019 580.5 1019Z"
        stroke="#584187"
        strokeOpacity="0.12"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
      />
      <path
        d="M580.969 721.937C736.695 721.937 862.937 595.695 862.937 439.969C862.937 284.242 736.695 158 580.969 158C425.242 158 299 284.242 299 439.969C299 595.695 425.242 721.937 580.969 721.937Z"
        stroke="#FF3F74"
        strokeWidth="1.234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        id="motion9"
      >
        <animateMotion
          xlinkHref="#circle25"
          dur="100s"
          begin="-65s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion9" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle27"
          dur="100s"
          begin="-55s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion9" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle28"
          dur="100s"
          begin="-55s"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion9" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle29"
          dur="100s"
          begin="-15s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion9" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle30"
          dur="100s"
          begin="-15s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion9" />
        </animateMotion>
      </path>
      <circle id="circle24" cx="0" cy="0" r="40.5" fill="url(#pattern1)" />
      <circle id="circle25" cx="0" cy="0" r="36.5" fill="url(#pattern2)" />
      <circle id="circle23" cx="0" cy="0" r="42" fill="url(#pattern3)" />
      <circle id="circle26" cx="0" cy="0" r="55" fill="url(#pattern4)" />
      <circle id="circle27" cx="0" cy="0" r="9.5" fill="#FF3F74" />
      <circle
        id="circle28"
        cx="0"
        cy="0"
        r="14"
        stroke="#FF3F74"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle29" cx="0" cy="0" r="6.5" fill="#FF3F74" />
      <circle
        id="circle30"
        cx="0"
        cy="0"
        r="11"
        stroke="#FF3F74"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle31" cx="0" cy="0" r="12" fill="#6946A7" />
      <circle
        id="circle32"
        cx="0"
        cy="0"
        r="16.5"
        stroke="#6946A7"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle21" cx="0" cy="0" r="4.5" fill="#6946A7" />
      <circle
        id="circle22"
        cx="0"
        cy="0"
        r="9"
        stroke="#6946A7"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle33" cx="0" cy="0" r="7" fill="#6946A7" />
      <circle
        id="circle34"
        cx="0"
        cy="0"
        r="11.5"
        stroke="#6946A7"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0"
            transform="translate(-0.250599) scale(0.00239808)"
          />
        </pattern>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1"
            transform="translate(-0.0318627) scale(0.00490196)"
          />
        </pattern>
        <pattern
          id="pattern2"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image2"
            transform="translate(-0.0552632) scale(0.00526316)"
          />
        </pattern>
        <pattern
          id="pattern3"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image3"
            transform="translate(-0.209607) scale(0.00436681)"
          />
        </pattern>
        <pattern
          id="pattern4"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image4"
            transform="translate(-0.0631068) scale(0.00485437)"
          />
        </pattern>

        <image id="image1" width="217" height="204" xlinkHref={first} />
        <image id="image2" width="211" height="190" xlinkHref={second} />
        <image id="image3" width="325" height="229" xlinkHref={third} />
        <image id="image4" width="232" height="206" xlinkHref={fourth} />
      </defs>
    </svg>
  );
};

export default IconComponent;
