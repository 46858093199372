import React from 'react';
import { withRouter } from 'react-router-dom';

import { Notification, Balance, Profile } from './';
import usePathname from '../../../hooks/usePathname';
import book from '../../../views/router/book';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const routes = [book.signin, book.signup];

const Header = ({ history, t }) => {
  const pathname = usePathname();
  if (routes.includes(pathname)) return null;

  const pathSplit = pathname.split('/');
  const primaryId = pathSplit[2];
  const secondaryId = pathSplit[4];

  const handlerChangeTitle = () => {
    switch (pathname) {
      case book.statistics:
        return 'header_in_login_statistics';
      case book.myCompanies:
        return 'header_in_login_my_campaigns';
      case `${book.myCompanies}/${primaryId}/advertisements/${secondaryId}`:
      case `${book.myCompanies}/${primaryId}/advertisements`:
      case `${book.statistics}/${primaryId}`:
        return 'header_in_login_return';
      case book.basicInformation:
        return 'header_in_login_create_advertisement';
      case book.bloggerList:
        return 'header_in_login_bloggers_list';
      case book.blog:
        return 'header_in_login_blog';
      case book.settings:
        return 'header_in_login_settings';
      case book.profile:
        return 'header_in_login_profile';
      case book.finance:
        return 'header_in_login_finance';
      case book.offers:
        return 'header_in_login_offers';
      case book.accounts:
        return 'header_in_login_accounts';
      case book.cpaoffer:
          return 'header_in_login_cpa';
      case book.support:
        return 'header_in_login_support';
      case book.home:
        return 'header_in_login_your_activity';
      case book.accountBlogger:
        return 'header_in_login_instagram_account';
      case book.referral:
        return 'header_in_login_referral_info';

      default:
        return '';
    }
  };

  const handlerBackTo = () => {
    switch (pathname) {
      case `${book.myCompanies}/${primaryId}/advertisements`:
        history.push(book.myCompanies);
        break;
      case `${book.statistics}/${primaryId}`:
        history.push(book.statistics);
        break;
      case `${book.myCompanies}/${primaryId}/advertisements/${secondaryId}`:
        history.push(`${book.myCompanies}/${primaryId}/advertisements`);
        break;

      default:
        break;
    }
  };

  const handlerStyleTile = () => {
    switch (pathname) {
      case `${book.myCompanies}/${primaryId}/advertisements/${secondaryId}`:
      case `${book.myCompanies}/${primaryId}/advertisements`:
      case `${book.statistics}/${primaryId}`:
        return 'global-header__left-title-back';

      default:
        return 'global-header__left-title';
    }
  };

  return (
    <div className="global-header">
      <div className="global-header__left">
        <div className={handlerStyleTile()} onClick={handlerBackTo}>
          <p>{t(handlerChangeTitle())}</p>
        </div>
      </div>
      <div className="global-header__right">
        {/* <LanguageSelect /> */}
        <Notification />
        <Balance />
        <Profile />
      </div>
    </div>
  );
};

export default withRouter(withTranslation()(Header));
