import React, { useState } from 'react';

import MobileFinanceItemCard from './MobileFinanceItemCard';

import './styles.scss';
import SelectFilter from '../../HeaderListBlogger/selectFilter/selectFilter';
import {
  contentTypesSortFinance,
  typeSortFinance,
} from '../../../../configs/selectOptions';

const MobileFinanceItem = ({ items, onChengSort = () => {} }) => {
  const [directionValue, setDirectionValue] = useState(typeSortFinance[0]);
  const [sortValue, setSortValue] = useState(contentTypesSortFinance[0]);
  return (
    <div className="mobile-finance">
      <div className="mobile-finance__sort-container">
        <div className="mobile-finance__sort-container-item">
          <SelectFilter
            options={contentTypesSortFinance}
            onChange={e => {
              setSortValue(e);
              onChengSort({
                sortType: e.value,
                directionValue: directionValue.value,
              });
            }}
            defaultValue={sortValue}
            type={'sort'}
            page="secondary"
          />
        </div>
        <div className="mobile-finance__sort-container-item">
          <SelectFilter
            options={typeSortFinance}
            type={'sortBy'}
            defaultValue={directionValue}
            onChange={e => {
              setDirectionValue(e);
              onChengSort({
                sortType: sortValue.value,
                directionValue: e.value,
              });
            }}
            page="secondary"
          />
        </div>
      </div>
      <div className="mobile-finance-container">
        {items &&
          items.map((item, index) => (
            <MobileFinanceItemCard
              key={index + 'mobile-finance-container'}
              item={item}
            />
          ))}
      </div>
    </div>
  );
};

export default MobileFinanceItem;
