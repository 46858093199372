import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
// import Rating from '../../component/common/Rating/Rating';
import './CpaOfferCard.scss';

const CpaOfferCard = ({offer, _onClickText}) => {
  useEffect(()=>{
  })


  return (
    <div className="offer-card" key={offer._id}> 
      <div className="offer-card-main">
        <div className="offer-card__inner-container">
          {
            offer.title &&  
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
              Name
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.title}
              </p>
            </div>
          }
          {
            offer.price &&  
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
              Payout
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.price}
              </p>
            </div>
          }
          {
            offer.link &&
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
              Offer link
              </p>
               <button className="offer-card-link-button" onClick={()=>{ navigator.clipboard.writeText(offer.link + "/?subid="+ JSON.parse(localStorage.getItem('accaunt'))._id).then(function() {
                        console.log('Async: Copying to clipboard was successful!');
                      }, function(err) {
                        console.error('Async: Could not copy text: ', err);
                      });
                  }}>Copy</button>
            </div>
          }
          {
            offer.technicalDescription !== '' && <div className="offer-card-main__characteristic" >
            <p className="offer-card-main__characteristic-desc  offer-card-main__characteristic-key">
            Offer instruction
            </p> 
              <button className="offer-card-link-button" onClick={()=>{_onClickText("Technical Description", offer.technicalDescription, offer._id)}}>Open</button>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CpaOfferCard);
