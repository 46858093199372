import React from "react";
import { withTranslation } from "react-i18next";
// import Rating from '../../component/common/Rating/Rating';
import './OfferCard.scss';

const OfferCard = ({offer, _onClickText, _onClickComplete, _onClickDecline, _onClickAccept}) => {
  return (
    <div className="offer-card" key={offer._id}> 
      <div className="offer-card-head">
        {
        offer.banner &&
          <div className="offer-card-head__icons">
            <img alt="картинка"/>
            <p className="offer-card-head__profile"></p>
            <p className="offer-card-head__category"></p>
            <div className="offer-card-head__links">
              <div className="offer-card-head__stars">
                {/* <Rating rating={'accountQuality'} /> */}
              </div>
            </div>
          </div>
         }
      </div>
      <div className="offer-card-main">
        <div className="offer-card__inner-container">
          {
            offer.title &&  
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                Name
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.title}
              </p>
            </div>
          }
          {
            offer.price &&  
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
              Payout
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.price}
              </p>
            </div>
          }
          {
            offer.advertisement.publishTimeTo &&
            <div className="offer-card-main__characteristic">
            <p className="offer-card-main__characteristic-desc">
              PublishingTime
            </p>
            <p className="offer-card-main__characteristic-value-cust">
              {offer.advertisement.publishTimeTo}
            </p>
          </div> 
          }
          {
            offer.contentType &&
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                ContentType
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.contentType}
              </p>
          </div>
          }
          {
            offer.status &&
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                Status
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.status}
              </p>
            </div>
          }
          {
            offer.description &&
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                Description
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.description}
              </p>
            </div>
          }

          {
              offer.numFile !== '' && <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                {offer.File}
              </p> 
              <p className="offer-card-main__characteristic-desc">
                {offer.numFile}
              </p> 
          </div>
          }
          {
            offer.advertisement.technicalDescription !== '' && <div className="offer-card-main__characteristic" onClick={()=>{_onClickText(offer._id)}}>
            <p className="offer-card-main__characteristic-desc  offer-card-main__characteristic-key">
              Technical Description
            </p> 
            <p className="offer-card-main__characteristic-desc offer-card-main__characteristic-text">
              {offer.advertisement.technicalDescription}
            </p> 
          </div>
          }
        </div>
      </div>
      <div className="offer-solution">
        {
          offer && offer.status === "PENDING" &&
          <>
            <button className="offer-card-button" onClick={()=>{_onClickAccept(offer._id)}}>Accept</button>
            <button  className="offer-card-button" onClick={()=>{_onClickDecline(offer._id)}}>Decline</button>
          </>
        }
        {
          offer && offer.status === "ACTIVE" &&
          <>
            <button className="offer-card-button" onClick={()=>{_onClickComplete(offer._id)}}>Completed</button>
          </>
        }
        {
          offer && offer.status === "REJECTED" &&
          <>
            <h1>Rejected</h1>
          </>
        }
        {
          offer && offer.status === "MODERATION" &&
          <>
            <h1>On Moderation</h1>
          </>
        }
      </div>
      {/* {type === 'tertiary' && (
        <div className="offer-card-price__tertiary">
          <p className="offer-card-price__tertiary-desc">
            {t('blogger_list_item_price')}
          </p>
          <p className="offer-card-price__tertiary-value">
            $ {numberWithSpaces(pricePerPost)}
          </p>
        </div>
      )}
      {(type === 'featured' || type === 'all' || type === 'primary') && (
        <div className="offer-card-footer">
          <div className="offer-card-price">
            <p className="offer-card-price__desc">
              {t('blogger_list_item_price')}
            </p>
            <p className="offer-card-price__value">
              $ {numberWithSpaces(pricePerPost)}
            </p>
          </div>
          <div className="bargain">
            <span
              ref={addRef}
              onClick={toggleVisibleAddPrice}
              className={selectedBloger ? 'bargain-disabled' : 'bargain-click'}
            >
              {t('blogger_list_item_propose_own_price')}
            </span>
            {addPrice && !disabledButton && (
              <div className="bargain-input">
                <label className="custom-input__label">
                  {t('blogger_list_item_desired_price')}
                </label>
                <input
                  onInput={setChangedPrice}
                  className="custom-input-money"
                  type="number"
                  value={changedPrice}
                  onChange={handleChangedPrice}
                />
              </div>
            )}
            {selectedBloger && (
              <div className="bargain-input-desired">
                <label className="custom-input__label">
                  {t('blogger_list_item_not_desired_price')}
                </label>
                <span className="bargain-input-price">
                  {changedPrice === pricePerPost || changedPrice === ''
                    ? t('blogger_list_item_not_selected')
                    : `$ ${numberWithSpaces(changedPrice)}`}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {(type === 'featured' || type === 'all' || type === 'primary') &&
        (!uniqueBloggers(bloggersSelected?.bloggersList, id) ? (
          <button onClick={addOffer} className="blogger-card-button">
            {t('Select')}
          </button>
        ) : (
          <button
            disabled={offer?.status === 'ACTIVE'}
            onClick={deleteOffer}
            className="blogger-card-button blogger-card-button-selected"
          />
        ))} */}
    </div>
  );
};

export default withTranslation()(OfferCard);
