import React, {useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
// import Rating from '../../component/common/Rating/Rating';
import './OfferCard.scss';
import Moment from 'react-moment';
const OfferCardNew = ({refresh, refreshState, offer, _onClickText, _onClickComplete, _onClickDecline, _onClickAccept , _onClickUpload}) => {
  const [isRefreshed, setIsRefreshed] = useState(refresh)
  useEffect(()=>{
    setIsRefreshed(refresh)
  },[refresh, offer])
  return (
    <div className="offer-card" key={offer._id}> 
      <div className="offer-card-head">
        {
        offer.banner &&
          <div className="offer-card-head__icons">
            <img alt="картинка"/>
            <p className="offer-card-head__profile"></p>
            <p className="offer-card-head__category"></p>
            <div className="offer-card-head__links">
              <div className="offer-card-head__stars">
                {/* <Rating rating={'accountQuality'} /> */}
              </div>
            </div>
          </div>
         }
      </div>
      <div className="offer-card-main">
        <div className="offer-card__inner-container">
          {
            offer.title &&  
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                Name
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.title}
              </p>
            </div>
          }
          {
            offer.price &&  
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                Payout
              </p>
              <p className="offer-card-main__characteristic-value">
                {offer.price + "$"}
              </p>
            </div>
          }
          {
            offer.advertisement.endDate &&
            <div className="offer-card-main__characteristic">
            <p className="offer-card-main__characteristic-desc">
              PublishingTime
            </p>
            <p className="offer-card-main__characteristic-value-cust">
              From <Moment format="YY/MM/DD">{offer.advertisement.startDate}</Moment> to <Moment format="YY/MM/DD">{offer.advertisement.endDate}</Moment>
            </p>
          </div> 
          }
                    {
            offer.advertisement && offer.advertisement.story && offer.advertisement.story.content &&
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
              Ads scenario
              </p>
              <button className="offer-card-link-button" onClick={()=>{_onClickText("Ads scenario" ,offer.advertisement.story.content, offer._id)}}>Open</button>
            </div>
          }
          {
            offer.advertisement && offer.advertisement.story && offer.advertisement.story.instruction &&
            <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                Video description text
              </p>
              <button className="offer-card-link-button" onClick={()=>{_onClickText("Video description text", offer.advertisement.story.instruction, offer._id)}}>Open</button>
            </div>
          }
          {
              offer.numFile !== '' && <div className="offer-card-main__characteristic">
              <p className="offer-card-main__characteristic-desc">
                {offer.File}
              </p> 
              <p className="offer-card-main__characteristic-desc">
                {offer.numFile}
              </p> 
          </div>
          }
        </div>
      </div>
      <div className="offer-solution">
        {
          offer && offer.status === "WAITING_FOR_DEMO" &&
          <>
            <button className="offer-card-button"  onClick={()=>{_onClickUpload(offer._id)}}>Download demo</button>
            <button  className="offer-card-button" onClick={()=>{_onClickDecline(offer._id)}}>Decline</button>
          </>
        }
        {
          offer && offer.status === "DEMO_LOADED" && offer.demoFile &&
          <>
            <p className="offer-card-main__characteristic-desc">On advertiser moderation</p>
          </>
        }
        {
          offer && offer.status === "ACTIVE" &&
          <>
            <p  className="offer-card-main__characteristic-desc">Place the ads on your channel until <Moment format="YYYY/MM/DD">{offer.advertisement.endDate}</Moment></p>
          </>
        }
                {
          offer && offer.status === "ACTIVE" &&
          <>
            <button className="offer-card-button" onClick={()=>{_onClickComplete(offer._id)}}>I placed the ads</button>
          </>
        }
        {
          offer && offer.status === "REJECTED" &&
          <>
            <p className="offer-card-main__characteristic-desc">Rejected</p>
          </>
        }
        {
          offer && offer.status === "RESULT_LOADED" &&
          <>
            <p className="offer-card-main__characteristic-desc">Waiting for result checking</p>
          </>
        }
        {
          offer && offer.status === "COMPLETED" &&
          <>
            <p className="offer-card-main__characteristic-desc">Completed</p>
          </>
        }
        {
          offer && offer.status === "MODERATION" &&
          <>
            <p className="offer-card-main__characteristic-desc">On Moderation</p>
          </>
        }
      </div>
    </div>
  );
};

export default withTranslation()(OfferCardNew);
