import React from 'react';
import './styles.scss';
import DevelopmentProcess from '../../component/developmentProcess/DevelopmentProcess';

const Paywall = () => {
  return (
    <div>
      <DevelopmentProcess name={'Paywall'} />
    </div>
  );
};

export default Paywall;
