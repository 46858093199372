import React from 'react';

import './styles.scss';

const BenefitsItem = ({ item: { photo, title, text }, t }) => {
  return (
    <div className="benefits-item">
      <img className="benefits-item-img" src={photo} alt="img" />
      <span className="benefits-item-title">{t(title)}</span>
      <span className="benefits-item-text">{t(text)}</span>
    </div>
  );
};

export default BenefitsItem;
