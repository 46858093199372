import React from 'react';
import './style.scss';
const ShowVerificationCodeInstagram = ({ details }) => {
  const email = 'bloggerly_official';
  return (
    <div className="show-verification-code">
      <p>Your verification code is {details.code}</p>
      <p>
        Please send it to direct of our moderator
        <br />
        {/*<a href={`https://www.instagram.com/${email}`}>@{email}</a>*/}@bloggerly.bot to proceed
      </p>
    </div>
  );
};
export default ShowVerificationCodeInstagram 
