import { combineReducers } from 'redux';
import { sessionTypes } from './sessionTypes';

const authenticationStep = (state = 'login', { type, payload }) => {
  switch (type) {
    case sessionTypes.CHANGE_AUTHENTICATION_STEP:
      return payload;
    case sessionTypes.SESSION_LOGOUT:
      return 'login';

    default:
      return state;
  }
};

const personalInformation = (state = {}, { type, payload }) => {
  switch (type) {
    case sessionTypes.SESSION_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        email: payload.email.email,
        role: payload.role,
        referralId: payload.referralId,
      };
    case sessionTypes.RESEND_CONFIRMATION_CODE_SUCCESS:
    case sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_SUCCESS:
    case sessionTypes.GET_INFORMATION_ABOUT_USER_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case sessionTypes.SESSION_SET_FCM_TOKEN:
      return {
        ...state,
        fcmToken: payload,
      };
    case sessionTypes.SESSION_LOGOUT:
      return {};
    case sessionTypes.CLEAR_NEW_EMAIL: {
      return {
        ...state,
        newEmail: '',
      };
    }

    default:
      return state;
  }
};

const sessionData = (state = null, { type, payload }) => {
  switch (type) {
    case sessionTypes.SESSION_CONFIRMATION_CODE_SUCCESS:
      return payload;
    case sessionTypes.SESSION_LOGOUT:
      return null;
    default:
      return state;
  }
};

const authenticated = (state = false, { type }) => {
  switch (type) {
    case sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_SUCCESS:
    case sessionTypes.GET_INFORMATION_ABOUT_USER_SUCCESS:
    case sessionTypes.SESSION_CONFIRMATION_CODE_SUCCESS:
      return true;

    case sessionTypes.SESSION_LOGOUT:
      return false;
    default:
      return state;
  }
};

const error = (state = null, { type, payload }) => {
  switch (type) {
    case sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_ERROR:
    case sessionTypes.SESSION_CONFIRMATION_CODE_ERROR:
    case sessionTypes.SESSION_PHONE_NUMBER_ERROR:
    case sessionTypes.RESEND_CONFIRMATION_CODE_ERROR:
      return payload;
    default:
      return state;
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_START:
    case sessionTypes.SESSION_CONFIRMATION_CODE_START:
    case sessionTypes.SESSION_PHONE_NUMBER_START:
    case sessionTypes.RESEND_CONFIRMATION_CODE_START:
      return true;
    case sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_SUCCESS:
    case sessionTypes.SESSION_ABVERTISERS_PERSONAL_DATA_ERROR:
    case sessionTypes.SESSION_CONFIRMATION_CODE_SUCCESS:
    case sessionTypes.SESSION_CONFIRMATION_CODE_ERROR:
    case sessionTypes.SESSION_PHONE_NUMBER_SUCCESS:
    case sessionTypes.SESSION_PHONE_NUMBER_ERROR:
    case sessionTypes.RESEND_CONFIRMATION_CODE_SUCCESS:
    case sessionTypes.RESEND_CONFIRMATION_CODE_ERROR:
      return false;
    default:
      return state;
  }
};

export const sessionReducer = combineReducers({
  personalInformation,
  sessionData,
  authenticationStep,
  authenticated,
  error,
  loading,
});
