const types = {
  // get
  GET_NEWS_LIST_START: 'news/GET_NEWS_LIST_START',
  GET_NEWS_LIST_SUCCESS: 'news/GET_NEWS_LIST_SUCCESS',
  GET_NEWS_LIST_ERROR: 'news/GET_NEWS_LIST_ERROR',

  GET_NEWS_START: 'news/GET_NEWS_START',
  GET_NEWS_SUCCESS: 'news/GET_NEWS_SUCCESS',
  GET_NEWS_ERROR: 'news/GET_NEWS_ERROR',

  // set
  CLEAR_ALL_PARAMS: 'news/CLEAR_ALL_PARAMS',
  SET_SEARCH: 'news/SET_SEARCH',
  SET_OFFSET: 'news/SET_OFFSET',
};

export default types;
