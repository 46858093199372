import React from 'react';
import { useDispatch } from 'react-redux';

import { setAuthenticationStep } from '../../../../../../redux/store/session/sessionOperations';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { sessionAuthenticationSteps } from '../../../../../../constants/sessionAuthenticationSteps';
import { usePathname } from '../../../../../../hooks/usePathname';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import book from '../../../../../router/book';
import MainMenu from './MainMenu/MainMenu';

import './styles.scss';

const Header = () => {
  const dispatch = useDispatch();
  const pathname = usePathname();

  const handleLoginButtonClick = () => {
    dispatch(setAuthenticationStep(sessionAuthenticationSteps.login));
    dispatch(openModal({ renderType: 'AUTH', isOpen: true }));
  };

  const handleRegistrationButtonClick = () => {
    dispatch(setAuthenticationStep(sessionAuthenticationSteps.email));
    dispatch(openModal({ renderType: 'AUTH', isOpen: true }));
  };

  const handleCheckRoute = () => {
    return pathname === book.referrals;
  };

  return (
    <div className={pathname === book.referrals ? 'header-dark' : 'header'}>
      <MainMenu
        registration={handleRegistrationButtonClick}
        login={handleLoginButtonClick}
        referrals={handleCheckRoute()}
        book={book}
      />
      <BurgerMenu
        registration={handleRegistrationButtonClick}
        login={handleLoginButtonClick}
        referrals={handleCheckRoute()}
        book={book}
      />
    </div>
  );
};

export default Header;
