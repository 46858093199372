import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { changeCreationAdvertisementStep } from '../../../../../redux/store/advertisement/creationStatus/creationStatusActions';
import {
  newAdvertisementDataSelector,
  sessionPersonalInformationSelector,
} from '../../../../../redux/selectors/selectors';

import { advertisementCreationSteps } from '../../../../../constants/advertisementCreationSteps';
import { numberWithSpaces } from '../../../../../helpers/numberWithSpaces';
import FormContainer from '../../../common/FormContainer/FormContainer';
import Button from '../../../common/Button/Button';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const BudgetForm = ({ t }) => {
  const { budget } = useSelector(newAdvertisementDataSelector);
  const { budget: userBudget } = useSelector(
    sessionPersonalInformationSelector,
  );
  const dispatch = useDispatch();

  return (
    <div className="budget">
      <section className="form-section">
        <FormContainer button={''}>
          <div className="form-block">
            <div className="choice-budget__balance">
              <div className="choice-budget__balance-content">
                <div className="choice-budget__balance-content-title">
                  {t('advertisement_Step3_Available_balance')}
                </div>
                <div className="choice-budget__balance-content-subtitle">
                  $ {userBudget || 0}
                </div>
              </div>
              <div className="choice-budget">
                <div className="choice-budget__title">
                  {t('advertisement_Step3_Advertisement_budget')}
                </div>
                <div className="choice-budget__money">
                  $ {numberWithSpaces(budget) || 0}
                </div>
              </div>
            </div>
          </div>
        </FormContainer>
      </section>
      <div className="choice-bloggers-form__buttons">
        <Button
          handleButtonClick={() => {
            dispatch(
              changeCreationAdvertisementStep({
                activePage: advertisementCreationSteps.choiceBloggers,
                activeStep: 1,
              }),
            );
          }}
          options={{
            text: t('advertisement_Step3_return'),
            renderType: 'secondary',
            size: 'm',
          }}
        />
        <Button
          handleButtonClick={() =>
            dispatch(
              changeCreationAdvertisementStep({
                activePage: advertisementCreationSteps.advertisementLaunch,
                doneSteps: [0, 1, 2],
                activeStep: 3,
              }),
            )
          }
          options={{
            text: t('advertisement_Step3_proceed'),
            renderType: 'primary',
            size: 'm',
          }}
        />
      </div>
    </div>
  );
};

export default withTranslation()(BudgetForm);
