import types from './newsTypes';

// get
export const getNewsListStart = () => ({
  type: types.GET_NEWS_LIST_START,
});

export const getNewsListSuccess = data => ({
  type: types.GET_NEWS_LIST_SUCCESS,
  payload: data,
});

export const getNewsListError = (error = '') => ({
  type: types.GET_NEWS_LIST_ERROR,
  payload: error,
});

export const getNewsStart = () => ({
  type: types.GET_NEWS_START,
});

export const getNewsSuccess = data => ({
  type: types.GET_NEWS_SUCCESS,
  payload: data,
});

export const getNewsError = (error = '') => ({
  type: types.GET_NEWS_ERROR,
  payload: error,
});

// set
export const setOffset = data => ({
  type: types.SET_OFFSET,
  payload: data,
});

export const setSearch = data => ({
  type: types.SET_SEARCH,
  payload: data,
});

export const clearAllParams = () => ({
  type: types.CLEAR_ALL_PARAMS,
});
