import { advertisementCreationSteps } from '../../../constants/advertisementCreationSteps';

import BaseInfo from './BaseInfo/BaseInfo';
import Budget from './Budget/Budget';
import CampaignLaunch from './CampaignLaunch/CampaignLaunch';
import AdsSearch from './ChoiceBloggers/AdsSearch/AdsSearch';
import AutoSearch from './ChoiceBloggers/AutoSearch/AutoSearch';
import ManualSearch from './ChoiceBloggers/ManualSearch/ManualSearch';
import ChoiceBloggers from './ChoiceBloggers/ChoiceBloggers';

export const CompanyConfig = {
  [advertisementCreationSteps.base]: <BaseInfo />,
  [advertisementCreationSteps.choiceBloggers]: <ChoiceBloggers />,
  [advertisementCreationSteps.budget]: <Budget />,
  [advertisementCreationSteps.advertisementLaunch]: <CampaignLaunch />,
  [advertisementCreationSteps.auto]: <AutoSearch />,
  [advertisementCreationSteps.ads]: <AdsSearch />,
  [advertisementCreationSteps.manual]: <ManualSearch type={'all'} />,
  [advertisementCreationSteps.featured]: <ManualSearch type={'featured'} />, //<FeaturedBloggers />,
};
