import * as supportApi from '../../../api/support-service/support-service';

import { changeButtonLoaderStatus } from '../globalLoader/globalLoaderActions';
import { setNotifyStatus } from '../notify/notifyActions';
import { setErrorHandler } from '../errorHandler/errorHandlerOperations';

export const sendSupportRequest = data => dispatch => {
  dispatch(changeButtonLoaderStatus(true));

  return supportApi
    .postSupportRequest(data)
    .then(() => {
      dispatch(
        setNotifyStatus({
          title: 'Wait for a reply on your mail',
          type: 'success',
        }),
      );
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};
