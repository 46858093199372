import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { navbar } from './config';

import LightLogo from '../../../../../../../assets/images/landing/header/bloggerly-white.svg';
import DarkLogo from '../../../../../../../assets/images/landing/header/bloggerly-black.svg';
import './styles.scss';

const Menu = ({ open, setOpen, login, registration, referrals, book }) => {
  const handleShowMenu = () => {
    return `${referrals ? 'menu-dark' : 'menu'} ${
      open ? 'menu__open' : 'menu__close'
    }`;
  };

  const handleLogin = () => {
    login();
    setOpen(false);
  };

  const handleRegistration = () => {
    registration();
    setOpen(false);
  };

  return (
    <div className={handleShowMenu()}>
      <Link
        className="menu__open-img"
        onClick={() => setOpen(false)}
        to={book.advertisers}
      >
        <img src={referrals ? DarkLogo : LightLogo} alt="bloggerly" />
      </Link>
      {navbar.map(({ title, link, target, rel }, index) => (
        <span key={index + title} className="menu__open-link">
          <NavLink
            className={referrals ? 'menu__open-link-dark' : 'menu__open-link'}
            onClick={() => setOpen(false)}
            to={link}
          >
            {title}
          </NavLink>
        </span>
      ))}
      <span
        onClick={handleLogin}
        className={referrals ? 'menu__open-login-dark' : 'menu__open-login'}
      >
        Log in
      </span>
      <span onClick={handleRegistration} className="menu__open-registration">
        Registration
      </span>
    </div>
  );
};

export default Menu;
