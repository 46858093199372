import React from 'react';

import './styles.scss';

const MobileFinanceItemCard = ({
  item: { number, operation, date, status, sum },
}) => {
  return (
    <div className="mobile-finance__item">
      <div className="mobile-finance__item-header">
        <div className="mobile-finance__item-header-title">
          <span>Operation:</span>
          {operation.text}
        </div>
        <div className="mobile-finance__item-header-id">#{number}</div>
      </div>
      <div className="mobile-finance__item-footer">
        <div className="mobile-finance__item-footer-item">
          <span>Date:</span>
          {date}
        </div>
        <div className="mobile-finance__item-footer-item">
          <span>Sum:</span>
          {sum}
        </div>
        <div className="mobile-finance__item-footer-item">
          <span>Status:</span>
          {status}
        </div>
      </div>
    </div>
  );
};

export default MobileFinanceItemCard;
