import React from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '../../../common/Button/Button';
import Input from '../../../common/Fields/Input/Input';
import AuthFormContainer from '../../shared/Container/Container';

import {
  forgotPasswordForm,
  sendLogin,
  setAuthenticationStep,
} from '../../../../../redux/store/session/sessionOperations';
import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';
import {
  sessionFormLoadingStatusSelector,
  sessionFormErrorSelector,
} from '../../../../../redux/selectors/selectors';

import { email } from '../../../../../schemas/forgotPasswordSchema';

import './styles.scss';
import '../../general.scss';
import { sessionAuthenticationSteps } from '../../../../../constants/sessionAuthenticationSteps';
import { useTranslation } from 'react-i18next';

const ForgotPasswordForm = ({
  loadingStatus,
  history,
  setAuthenticationStep,
  forgotPasswordForm,
  setNotifyStatus,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(email),
  });
  const [t] = useTranslation();

  const handleFormSubmit = data => {
    forgotPasswordForm(data, history).catch(err =>
      setNotifyStatus({ title: err, type: 'error' }),
    );
  };

  const _onClickLogin = () => {
    setAuthenticationStep(sessionAuthenticationSteps.login);
  };
  return (
    <form className="auth-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <AuthFormContainer name={t('authentication_forgot_password')}>
        <div className="auth-form-block">
          <Input
            options={{
              placeholderText: t('authentication_forgot_password-placeholder'),
              labelText: t('authentication_forgot_password-label'),
            }}
            inputChangeOptions={register('email')}
          />
        </div>

        <div className="auth-form-button-container">
          <Button
            loadingStatus={loadingStatus}
            options={{
              text: t('authentication_forgot_password_send'),
              renderType: 'primary',
              size: 'l',
              type: 'submit',
              disabled: !isValid,
            }}
          />
          <button
            className="auth-form-button-container-subButton"
            type="button"
            onClick={_onClickLogin}
          >
            {t('authentication_login_button')}
          </button>
        </div>
      </AuthFormContainer>
    </form>
  );
};

const mapStateToProps = state => ({
  loadingStatus: sessionFormLoadingStatusSelector(state),
  requestError: sessionFormErrorSelector(state),
});

const mapDispatchToProps = {
  sendLogin,
  setAuthenticationStep,
  forgotPasswordForm,
  setNotifyStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ForgotPasswordForm));
