import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { putEmailByConfirmationCode } from '../../../../../redux/store/session/sessionOperations';
import {
  buttonLoaderStatusSelector,
  sessionPersonalInformationSelector,
} from '../../../../../redux/selectors/selectors';
import {
  clearNewEmail,
  sessionAdvertiserPersonalDataSuccess,
} from '../../../../../redux/store/session/sessionActions';
import {
  openModal,
  closeModal,
} from '../../../../../redux/store/modal/modalActions';

import ConfirmationCode from '../../../common/Fields/ConfirmationCode/ConfirmationCode';
import ResendCode from '../../../Authentication/Forms/ConfirmationCodeForm/ResendCode';
import PreviousStep from '../../../Authentication/shared/PreviousStep/PreviousStep';
import FormContainer from '../../../Authentication/shared/Container/Container';
import { modalTypes } from '../../../../../configs/modalTypes';
import Button from '../../../common/Button/Button';

import './styles.scss';
import { setNotifyStatus } from '../../../../../redux/store/notify/notifyActions';

const EditEmailSettingsForm = () => {
  const { handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const personalInformation = useSelector(sessionPersonalInformationSelector);
  const loading = useSelector(buttonLoaderStatusSelector);
  const dispatch = useDispatch();

  const [verificationCode, setVerificationCode] = useState('');

  const handleFormSubmit = () => {
    dispatch(putEmailByConfirmationCode(verificationCode))
      .then(() => {
        dispatch(
          setNotifyStatus({
            title: 'Personal information was successfully edited',
            type: 'success',
          }),
        );
        dispatch(closeModal());
        dispatch(
          sessionAdvertiserPersonalDataSuccess({
            ...personalInformation,
            newEmail: '',
          }),
        );
        localStorage.setItem(
          'accaunt',
          JSON.stringify({ ...personalInformation, newEmail: '' }),
        );
      })
      .catch(error =>
        dispatch(setNotifyStatus({ title: error, type: 'error' })),
      );
  };

  const handleChange = value => {
    setVerificationCode(value);
  };

  const changeCurentStep = () => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.editCurrentEmail,
      }),
    );
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit(handleFormSubmit)}>
      <FormContainer name="Verification">
        <div className="auth-form-block">
          <ConfirmationCode handleChange={handleChange} />
        </div>

        <div className="auth-form-button-container">
          <Button
            loadingStatus={loading}
            options={{
              text: 'Send Code',
              renderType: 'primary',
              size: 'm',
              type: 'submit',
              disabled: verificationCode.length < 4,
            }}
          />
        </div>
        <ResendCode page="settings" />
      </FormContainer>
      <div onClick={() => dispatch(clearNewEmail())}>
        <PreviousStep changeCurentStep={changeCurentStep} />
      </div>
    </form>
  );
};

export default EditEmailSettingsForm;
