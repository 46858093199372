import React from 'react';
import { Helmet } from 'react-helmet-async';

import './styles.scss';

const Policy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy policy of advertising service - Bloggerly</title>
        <meta
          name="description"
          content="On this page you can find privacy policy of automated advertising exchange Bloggerly"
        />
      </Helmet>
      <div className="conditions">
        <h1 className="conditions__title">Privacy policy</h1>
        <p className="conditions__item-text">
          The user gives his / her full consent to the transfer, receipt,
          storage and use by the system Administration of any kind of
          information and personal data received by the System during the
          provision of services to the User.
        </p>
        <p className="conditions__item-text">
          The administration of the System, for its part, guarantees the
          non-proliferation of user data.
        </p>
        <p className="conditions__item-text">
          The protection of the User's personal data is understood as a set of
          measures (organizational, administrative, technical, legal) aimed at
          the prevention of unlawful or accidental access to them, destruction,
          modification, deletion, copying, dissemination of personal data of
          subjects, as well as other illegal actions.
        </p>
        <p className="conditions__item-text">
          Protection of personal data of the User is carried out at the expense
          of the Administration in accordance with the law of India.
        </p>
        <p className="conditions__item-text">
          The site administration takes all necessary organizational,
          administrative, legal and technical measures to protect the personal
          data of Users, including:
        </p>
        <p className="conditions__item-point">
          Encryption (cryptographic) tools.
        </p>
        <p className="conditions__item-point">Antivirus protection.</p>
        <p className="conditions__item-point">Security analysis.</p>
        <p className="conditions__item-point">
          Intrusion detection and prevention.
        </p>
        <p className="conditions__item-point">Access control.</p>
        <p className="conditions__item-point">Registration and accounting.</p>
        <p className="conditions__item-point">Integrity.</p>
        <p className="conditions__item-text">
          Access to the personal data of the User have the employees of the site
          Administration, who need personal data for the full and complete
          performance of their duties.
        </p>
        <p className="conditions__item-text">
          Protection of personal data of Users stored in electronic databases of
          data of the site Administration, from unauthorized access, distortion
          and destruction of information, as well as from other illegal actions,
          is provided by the System administrator.
        </p>
        <p className="conditions__item-text">
          Protection of access to electronic databases containing Users'
          personal data is provided by the use of licensed anti-virus and
          anti-hacking programs that prevent unauthorized access to the local
          network, as well as the differentiation of access rights using the
          account.
        </p>
        <p className="conditions__item-text">
          All electronic folders and files containing Users ' personal data are
          protected by a password, which is set by the responsible System
          administrator.
        </p>
        <p className="conditions__item-text">
          Confidential information in the Agreement means any information that
          has actual or potential commercial value due to its unknown to third
          parties, to which there is no free access on a legal basis and to the
          confidentiality of which the owner takes all possible measures.
        </p>
        <p className="conditions__item-text">
          Information constituting confidential information may be denounced, in
          the form of photographs, in electronic, graphic, as well as in any
          other form.
        </p>
        <p className="conditions__item-text">
          All objects placed on the Site, including design elements, text,
          graphic images, illustrations, scripts, programs, and other objects
          and their collections (hereinafter-the Content), are subject to the
          exclusive rights of the Administration, Users of the Site and other
          rightholders, all rights to these objects are protected.
        </p>
        <p className="conditions__item-text">
          In addition to its own Content, the User may not upload or otherwise
          make available to the public (publish on the Site) the Content of
          other sites, databases and other results of intellectual activity in
          the absence of an Express consent of the owner to such actions.
        </p>
        <p className="conditions__item-text">
          Nothing in this Agreement can be considered as a transfer of exclusive
          rights to the Content.
        </p>
        <p className="conditions__item-text">
          The user is solely responsible for any Content or any other
          information which it loads or otherwise brings to the General public
          (the audience-em) on the Website or with its help. The user has no
          right to upload, transmit or publish Content on the Site if he / she
          does not have the appropriate rights to perform such actions acquired
          or transferred to him / her.
        </p>
        <p className="conditions__item-text">
          The site Administration may, but is not obliged to, view the Site for
          the presence of prohibited Content and may remove or move (without
          warning) any Content or users in its sole discretion, for any reason
          or no reason, including without limitation the movement or removal of
          Content that, in the personal opinion of the Administration, violates
          this Agreement, and/or may violate the rights, harm or threaten the
          safety of other Users or third parties.
        </p>
        <p className="conditions__item-text">
          By posting its Content on the Site, The user transfers to the
          Administration the right to de-lat copies of its Content in order to
          streamline and facilitate the publication and storage of user Content
          on the Site.
        </p>
        <p className="conditions__item-text">
          By posting its Content in any part of the Site, the User automatically
          grants the Administration a non-exclusive right to use it by copying,
          public performance, reproduction, processing, translation and
          distribution for the purposes of the Site or in connection with them,
          including for its promotion. For these purposes, the Administration
          may make derivative works or insert the User's Content as part of the
          relevant collections, perform other actions that serve to achieve
          these goals.
        </p>
        <p className="conditions__item-text">
          If the User deletes his Content from the Site, the Administration has
          the right to keep archival copies of the user Content for an
          indefinite period.
        </p>
        <p className="conditions__item-text">
          The site contains (or may contain) links to other sites on the
          Internet (third party sites) as well as articles, photographs,
          illustrations, graphics, music, sounds, videos, information,
          applications, programs and other Content belonging to or originating
          from third parties (third party Content) that is the result of
          intellectual activity.
        </p>
        <p className="conditions__item-text">
          These third parties and their Content are not checked by the
          Administration for compliance with certain requirements (reliability,
          completeness, integrity, etc.). Ad-ministration is not responsible for
          any information posted on third-party sites to which the User accesses
          through the Site or through the Content of third parties, including,
          inter alia, any opinions or statements expressed on third-party sites
          or in their Content.
        </p>
        <p className="conditions__item-text">
          The links or guidelines for downloading files and / or installing
          third-party programs posted on the Site do not imply support or
          approval of these actions by the Administration.
        </p>
        <p className="conditions__item-text">
          The link to any site, product, service, any information of commercial
          or non-commercial nature, posted on the Site, is not an approval or
          recommendation of these products (services) by the Administration.
        </p>
        <p className="conditions__item-text">
          If the user decides to leave the Site and go to third-party sites or
          use or install third-party programs, He does it at his own risk and
          from that moment this Agreement no longer applies to the User.
        </p>
      </div>
    </>
  );
};

export default Policy;
