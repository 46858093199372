const initialState = {
  error: null,
  loading: false,
};

export const launchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    default:
      return state;
  }
};

export default launchReducer;
