import React, { useState, useRef, useEffect } from 'react';

import Burger from './Burger';
import Menu from './Menu';

import './styles.scss';

const BurgerMenu = ({ referrals, book, login, registration }) => {
  const [open, setOpen] = useState(false);
  const burgerMenu = useRef();

  const handleKeyPress = e => {
    if (e.code !== 'Escape') return;
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  return (
    <div ref={burgerMenu}>
      <Burger book={book} referrals={referrals} open={open} setOpen={setOpen} />
      <Menu
        registration={registration}
        referrals={referrals}
        setOpen={setOpen}
        login={login}
        open={open}
        book={book}
      />
    </div>
  );
};

export default BurgerMenu;
