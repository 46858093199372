import React, { useEffect , useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchAdvertisement } from '../../../redux/store/advertisement/advertisement/AdvertisementOperations';
import {
  advertisementSelector,
  sessionPersonalInformationSelector,
} from '../../../redux/selectors/selectors';

import AdvertisementItem from '../../component/AdvertisementItem/AdvertisementItem';

const Campaign = ({ match }) => {
  const { isPaywalled } = useSelector(sessionPersonalInformationSelector);
  const advertisement = useSelector(advertisementSelector);
  const dispatch = useDispatch();
  const [refresh, refreshState] = useState(-1)

  const { id } = match.params;

  useEffect(() => {
    dispatch(fetchAdvertisement(id));
  }, [dispatch, id, refresh]);

  return (
    <AdvertisementItem
      advertisement={advertisement}
      isPaywalled={isPaywalled}
      paramsId={id}
      refreshState= {refreshState}
      refresh={refresh}
    />
  );
};

export default Campaign;
