import React, {useEffect, useState} from 'react';

import BlogerCardAdvertisement from '../renderCards/BlogerCard/BlogerCardAdvertisement';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const PlacementStatus = ({ offers, offersCount, isPaywalled, t, refresh, refreshState}) => {
  const [refreshC, refreshStateC] = useState(-1);
  useEffect(()=>{
    refreshStateC(refresh)
  },[refresh])
  return (
    <>
      {offersCount ? (
        <div className="placement-status-card">
          {offers?.map(blogger => (
            <BlogerCardAdvertisement
              key={blogger._id}
              isPaywalled={isPaywalled}
              bloggerInformation={blogger}
              refreshState={refreshState}
              refresh={refresh}
            />
          ))}
        </div>
      ) : (
        <EmptyComponent
          title={t('my_campaigns_listBlogger_empty_title')}
          text={t('my_campaigns_listBlogger_empty_text')}
        />
      )}
    </>
  );
};
export default withTranslation()(PlacementStatus);
