import React from 'react';

import { profitable } from './config';

import girl from '../../../../../../assets/images/landing/profitable/first.svg';
import wave from '../../../../../../assets/images/landing/profitable/wave.svg';
import './styles.scss';

const Profitable = ({ t }) => {
  return (
    <>
      <img className="profitable__wave" src={wave} alt="wave" />
      <div className="profitable__container">
        <div className="profitable">
          <div className="profitable__top">
            <div className="profitable__top-left">
              <div className="profitable__top-left-title">
                {t('referrals_landing_profitable_title')}
              </div>
              <div className="profitable__top-left-subtitle">
                {t('referrals_landing_profitable_subtitle')}
              </div>
            </div>
            <div className="profitable__top-right">
              <img src={girl} alt="girl" />
            </div>
          </div>
          <div className="profitable__content">
            {profitable.map(({ img, alt, title, subtitle }, index) => (
              <div key={index} className="profitable__content-block">
                <img
                  src={img}
                  alt={alt}
                  className="profitable__content-block-img"
                />
                <div className="profitable__content-block-title">
                  {t(title)}
                </div>
                <div className="profitable__content-block-subtitle">
                  {t(subtitle)}
                </div>
              </div>
            ))}
            <img src={girl} alt="girl" className="profitable__content-img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profitable;
