import React from 'react';

import IconApprovedStories from '../Icon/ApprovedStories/IconApprovedStories';
import './styles.scss';

const FormContainer = ({
  options = {},
  story = {},
  children = [] || {},
  button = {},
}) => {
  const { head = false, title = '', centerTitle } = options;

  return (
    <div className="form-container">
      {head && (
        <div className="form-container-head">
          <div
            className={
              centerTitle
                ? 'form-container__stories-list-center'
                : 'form-container__stories-list'
            }
          >
            {title}
            {centerTitle}
            {Object.keys(story).includes('content') && (
              <>
                <IconApprovedStories approved={story.approved} />
              </>
            )}
          </div>
          {button}
        </div>
      )}
      <div className="form-container-inner">{children}</div>
    </div>
  );
};

export default FormContainer;
