import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { postAdvertisementCopy } from '../../../../redux/store/advertisement/advertisement/AdvertisementOperations';

import IconApprovedStories from '../../common/Icon/ApprovedStories/IconApprovedStories';
import { handleRedirect } from '../../../../helpers/handleRedirect';
import book from '../../../router/book';
import { useTranslation } from 'react-i18next';

const MobileItemCard = ({ item }) => {
  const {
    acceptOffers,
    allOffers,
    budget,
    endDate,
    startDate,
    spentMoney,
    status,
    title,
    _id,
    content,
    publicId,
  } = item;

  const history = useHistory();
  const dispatch = useDispatch();
  const copyRef = useRef();
  const allRef = useRef();
  const [t] = useTranslation();
  const handleCheckContent = content => {
    switch (content) {
      case true:
        return 'approved';
      case false:
        return 'rejected';
      case null:
        return 'moderation';
      default:
        return null;
    }
  };

  const redirect = `${book.statistics}/${_id}`;

  const copyAdvertisement = () => {
    if (content) dispatch(postAdvertisementCopy(_id));
  };

  return (
    <div
      ref={allRef}
      onClick={({ target }) =>
        handleRedirect(target, allRef, copyRef, history, redirect)
      }
      key={_id}
      className="mobile-statistic__item"
    >
      <div className="mobile-statistic__item-header">
        <div className="mobile-statistic__item-header-top">
          <div className="mobile-statistic__item-header-top-title">
            <span className="mobile-statistic__item-header-top-title-header">
              {t('statistics_mobile_title')}
            </span>{' '}
            {title}
          </div>
          <div className="mobile-statistic__item-header-top-id">
            {t('statistics_mobile_id')} {publicId}
          </div>
        </div>
      </div>
      <div className="mobile-statistic__item-header-body">
        <div className="mobile-statistic__item-header-body-content">
          <span className="mobile-statistic__item-header-body-content-title">
            {t('statistics_mobile_content')}
          </span>{' '}
          {handleCheckContent(content)}{' '}
          <IconApprovedStories approved={content} />
        </div>
        <div className="mobile-statistic__item-header-body-status">
          {status}
        </div>
      </div>
      <div className="mobile-statistic__item-header-bottom">
        <div className="mobile-statistic__item-header-bottom-date">
          <span className="mobile-statistic__item-header-bottom-date-space">
            {t('statistics_mobile_from')}
          </span>{' '}
          {dayjs(startDate).format('DD.MM.YYYY')}
        </div>
        <div className="mobile-statistic__item-header-bottom-date">
          <span className="mobile-statistic__item-header-bottom-date-space">
            {t('statistics_mobile_to')}
          </span>{' '}
          {dayjs(endDate).format('DD.MM.YYYY')}
        </div>
      </div>
      <div className="mobile-statistic__item-body">
        <div className="mobile-statistic__item-body-bloggers">
          <span className="mobile-statistic__item-body-bloggers-title">
            {' '}
            {t('statistics_mobile_bloggers')}
          </span>
          {acceptOffers} / {allOffers}
        </div>
        <div className="mobile-statistic__item-body">
          <div className="mobile-statistic__item-body-spent">
            <span className="mobile-statistic__item-body-spent-title">
              {' '}
              {t('statistics_mobile_spent')}
            </span>
            $ {spentMoney} / $ {budget}
          </div>
        </div>
      </div>
      <div className="mobile-statistic__item-footer">
        <button
          disabled={content === null || !content}
          ref={copyRef}
          onClick={copyAdvertisement}
          className={`mobile-statistic__item-footer-btn ${
            content && 'custom-btn'
          }`}
        >
          {t('statistics_mobile-copy')}
        </button>
      </div>
    </div>
  );
};

export default MobileItemCard;
