import * as yup from 'yup';

export const accauntSettings = yup.object().shape({
  firstName: yup.string().min(2).max(20).trim(true).required(),
  lastName: yup.string().min(2).max(20).trim(true).required(),
  emailSubscribed: yup.boolean(),
  email: yup.string().email(),
  currency: yup.string(),
});

export const accauntPasswordSettings = yup.object().shape({
  currentPassword: yup
    .string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[[a-zA-Z0-9]{8,32}$/),
  newPassword: yup
    .string()
    .min(8)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[[a-zA-Z0-9]{8,32}$/),
  confirmNewPassword: yup
    .string()
    .min(8)
    .test('len', 'passwords must be the same', (val1, { parent }) => {
      return parent.newPassword === parent.confirmNewPassword;
    })
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[[a-zA-Z0-9]{8,32}$/),
});

export const accauntEmailSettings = yup.object().shape({
  email: yup.string().required().email(),
});
