import React, { useState } from 'react';
import Select from 'react-select';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const SelectFilter = ({
  options,
  imgPlaceholder,
  type,
  onChange,
  defaultValue,
  page = 'primary',
}) => {
  const [t] = useTranslation();

  const ValueContainer = () => <div />;
  const TemplateItem = option => {
    const { label } = option;
    return (
      <div className="payment-item-container">
        <div className="payment-item__pointer" />
        <p className="payment-item__brand-name">{t(label)}</p>
      </div>
    );
  };

  const handlerCheckType = () => {
    if (type === 'sort') {
      return 'select-filter-container-sort';
    } else if (type === 'sortBy') {
      return 'select-filter-container-sort-by';
    }
    return 'select-filter-container-status';
  };
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="select-filter">
      <input
        className="select-filter-handler"
        onMouseDown={() => {
          setIsOpen(true);
        }}
        onBlur={() => {
          setIsOpen(false);
        }}
      />
      {page === 'primary' && (
        <Select
          menuIsOpen={isOpen}
          onChange={onChange}
          formatOptionLabel={TemplateItem}
          defaultValue={defaultValue}
          options={options}
          components={{ ValueContainer }}
          placeholder={<img src={imgPlaceholder} alt="placeholder" />}
          className={`select-filter-container ${handlerCheckType()}`}
          classNamePrefix="react-select"
        />
      )}
      {page === 'secondary' && (
        <Select
          menuIsOpen={isOpen}
          onChange={onChange}
          formatOptionLabel={TemplateItem}
          value={defaultValue}
          options={options}
          components={{ ValueContainer }}
          placeholder={<img src={imgPlaceholder} alt="placeholder" />}
          className={`select-filter-container ${handlerCheckType()}`}
          classNamePrefix="react-select"
        />
      )}
    </div>
  );
};

export default SelectFilter;
