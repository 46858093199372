import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { openModal } from '../../../redux/store/modal/modalActions';

import { numberWithSpaces } from '../../../helpers/numberWithSpaces';
import { modalTypes } from '../../../configs/modalTypes';
import ContentFields from './ContentFields';
import book from '../../router/book';

import { ReactComponent as CampaignIcon } from '../../../assets/images/dummy/campaign.svg';
import './styles.scss';
import { handleRedirect } from '../../../helpers/handleRedirect';

const MyCampaignsItem = ({
  item: {
    _id,
    title,
    banner,
    totalBudget,
    advertisements: { active, all, completed, draft },
  },
  t,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirect = `${book.myCompanies}/${_id}/advertisements`;
  const secondBtnRef = useRef();
  const allRef = useRef();
  const btnRef = useRef();
  const thirdBtnRef = useRef();
  
  return (
    <div
      ref={allRef}
      onClick={({ target }) =>
        {
          handleRedirect(target, allRef, btnRef, history, redirect, secondBtnRef)
        }
      }
      className="campaign-item"
    >
      <div className="campaign-item__container-left">
        {banner ? (
          <img className="campaign-item__banner" src={banner} alt="banner" />
        ) : (
          <div className="campaign-item__banner-default">
            <CampaignIcon />
          </div>
        )}
        <span className="campaign-item__container-title">{title}</span>
        <div className="campaign-item__container-btns">
          <button
            ref={btnRef}
            disabled={active}
            onClick={() =>
              dispatch(
                openModal({
                  isOpen: true,
                  renderType: modalTypes.deleteCampaign,
                  details: { _id, title },
                }),
              )
            }
            className={
              active
                ? 'campaign-item__container-btns-disabled'
                : 'campaign-item__container-btns-item custom-btn'
            }
          >
            {t('my_campaigns_cart_delete')}
          </button>
          <button
            ref={secondBtnRef}
            onClick={() =>
              dispatch(
                openModal({
                  isOpen: true,
                  renderType: modalTypes.editCommonCampaign,
                  details: { _id, title, banner },
                }),
              )
            }
            className="campaign-item__container-btns-item custom-btn"
          >
            {t('my_campaigns_cart_edit')}
          </button>
        </div>
      </div>
      <div className="campaign-item__container">
        <div className="campaign-item__container-right">
          <ContentFields
            options={{
              title: t('my_campaigns_cart_advertisements'),
              subtitle: `${numberWithSpaces(all) || 0}`,
            }}
          />
          <ContentFields
            options={{
              title: t('my_campaigns_cart_drafts'),
              subtitle: `${numberWithSpaces(draft) || 0}`,
            }}
          />
          <ContentFields
            options={{
              title: t('my_campaigns_cart_active'),
              subtitle: `${numberWithSpaces(active) || 0}`,
            }}
          />
          <ContentFields
            options={{
              title: t('my_campaigns_cart_completed'),
              subtitle: `${numberWithSpaces(completed) || 0}`,
            }}
          />
          <span className="campaign-item__container-right-item-budget">
            {t('my_campaigns_cart_total_budget')}
          </span>
          <span className="campaign-item__container-right-item-budget-subtitle">{`$ ${
            numberWithSpaces(totalBudget) || 0
          }`}</span>
        </div>
      </div>
    </div>
  );
};

export default MyCampaignsItem;
