import React from 'react';

import './styles.scss';
import '../general.scss';

//types = 'single' || 'dual'

const Date = ({
  options = {},
  inputChangeOptions,
  inputChangeOptionsSecond,
  errors = false,
  errorsSecond = false,
  handlerOnChangeDateFrom,
  handlerOnChangeDateTo,
}) => {
  const {
    labelText = '',
    plaseholderText = '',
    descriptionText = '',
    type = 'single',
    from = '',
    to = '',
    approved = false,
    status = 'DRAFT',
  } = options;

  const activeInfo = status === 'ACTIVE' && approved;

  const onChangeDateFrom = e => {
    if (e.target.value === 'placeholder') {
      return '';
    }
    handlerOnChangeDateFrom(e);
  };

  const onChangeDateTo = e => {
    if (e.target.value === 'placeholder') {
      return '';
    }
    handlerOnChangeDateTo(e);
  };

  return (
    <div className="custom-date__container">
      {labelText && <label className="custom-date__label">{labelText}</label>}
      <div className="custom-input-container">
        {type === 'dual' && (
          <div className="custom-date__dual">
            <div className={from && 'custom-date__dual-from'}>{from}</div>
            <input
              readOnly={approved === null || activeInfo}
              onChange={onChangeDateFrom}
              {...inputChangeOptions}
              placeholder={plaseholderText}
              className={
                errors
                  ? 'custom-date__errors custom-date__dual-item'
                  : 'custom-date custom-date__dual-item'
              }
              type="date"
            />
            <div className={to && 'custom-date__dual-to'}>{to}</div>
            <input
              readOnly={approved === null || activeInfo}
              onChange={onChangeDateTo}
              {...inputChangeOptionsSecond}
              placeholder={plaseholderText}
              className={
                errorsSecond
                  ? 'custom-date__errors custom-date__dual-item'
                  : 'custom-date custom-date__dual-item'
              }
              type="date"
            />
            {descriptionText && (
              <p className="field__description">{descriptionText}</p>
            )}
          </div>
        )}
        {type === 'single' && (
          <>
            <input
              {...inputChangeOptions}
              placeholder={plaseholderText}
              className={
                errors
                  ? 'custom-date__errors custom-date__single-item'
                  : 'custom-date custom-date__single-item'
              }
              type="date"
            />
            {descriptionText && (
              <p className="field__description">{descriptionText}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Date;
