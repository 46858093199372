import React from 'react';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const BloggerlyIs = ({ t }) => {
  return (
    <>
      <h1 className="support__left-title">{t('support_what_bloggerly')}</h1>
      <div className="support__left-subtitle">
        <span className="support__left-subtitle-top">
          {t('support_sub_title')}
        </span>
        <span className="support__left-subtitle-bottom">
          {t('support_sub_text')}
        </span>
      </div>
    </>
  );
};

export default withTranslation()(BloggerlyIs);
