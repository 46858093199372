import React from 'react';
import ReactCodeInput from 'react-code-input';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const ConfirmationCode = ({ handleChange }) => {
  const [t] = useTranslation();

  const props = {
    onChange: handleChange,
    inputStyle: {
      outline: 'none',
      padding: '24px',
      margin: '0 10px',
      border: 'none',
      borderBottom: '1px solid red',
      height: '50px',
      width: '60px',
      focus: '::focus{color:white}',
    },
  };

  return (
    <div>
      <p className="confirmation-code__description">
        {t('authentication_confirmation_text')}
      </p>

      <div className="confirmation-code">
        <ReactCodeInput type="text" fields={4} {...props} />
      </div>
    </div>
  );
};

export default ConfirmationCode;
