import React from 'react';
import { useDispatch } from 'react-redux';

import { deleteSelectedCampaign } from '../../../../../../redux/store/campaign/campaignActions';
import { openModal } from '../../../../../../redux/store/modal/modalActions';

import { modalTypes } from '../../../../../../configs/modalTypes';

import { ReactComponent as TrashIcon } from '../../../../../../assets/images/vectors/trash.svg';
import './../styles.scss';
import { withTranslation } from 'react-i18next';

const CampaignSelect = ({ isValid, story, campaign, t }) => {
  const { _id, title } = campaign;
  const { approved } = story;

  const dispatch = useDispatch();

  const activeInfo = approved === false || approved || approved === null;

  return (
    <>
      {!_id ? (
        <button
          className={
            isValid.valid
              ? 'base-info-form__header-attach-btn custom-btn validation-campaign'
              : 'base-info-form__header-attach-btn custom-btn'
          }
          type={'button'}
          onClick={() =>
            dispatch(
              openModal({
                isOpen: true,
                renderType: modalTypes.attachToCampaign,
              }),
            )
          }
        >
          {t('company_select')}
        </button>
      ) : (
        <div className="base-info-form__header-attach-campaign">
          <p>{t('company_name')}</p>
          <div className="base-info-form__header-attach-campaign-name">
            <p>{title}</p>
            {activeInfo ? null : (
              <TrashIcon
                onClick={() => dispatch(deleteSelectedCampaign())}
                className="base-info-form__header-attach-icon"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default withTranslation()(CampaignSelect);
