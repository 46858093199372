import React from 'react';
import AuthFormContainer from '../../shared/Container/Container';
import { closeModal } from '../../../../../redux/store/modal/modalActions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ResetPasswordSuccess = () => {
  const dispatch = useDispatch();
  const _close = () => {
    dispatch(closeModal());
  };
  const [t] = useTranslation();

  return (
    <div className="auth-form  ">
      <AuthFormContainer name={t('authentication_forgot_password')}>
        <p className="auth-form-text">
          {t('authentication_reset_password_text_info')}
        </p>
        <button
          className="custom-button custom-button-primary custom-button-l "
          type="button"
          onClick={_close}
        >
          {t('authentication_reset_password_button')}
        </button>
      </AuthFormContainer>
    </div>
  );
};
export default ResetPasswordSuccess;
