import React from 'react';
import StarRatings from 'react-star-ratings';

const Rating = ({ rating = 0 , rate = 0}) => {
  return (
    <StarRatings
      rating={rate}
      starRatedColor="#FF2A5E"
      starEmptyColor="#D9DBE7"
      numberOfStars={5}
      starDimension="13px"
      starSpacing="2px"
      name="rating"
    />
  );
};

export default Rating;
