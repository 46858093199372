import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BlogerCard from '../../../../component/renderCards/BlogerCard/BlogerCard';
import {
  instagramAutoSearchSelector,
  newAdvertisementDataSelector,
} from '../../../../../redux/selectors/selectors';
import { getAutoSearchInstagramAccount } from '../../../../../redux/store/instagram/instagramOperations';
import StepsDescription from '../../../../component/StepsDescription/StepDescription';
import SelectedBloggers from '../../../../component/SelectedBloggers/SelectedBloggers';
import { fetchAdvertisement } from '../../../../../redux/store/advertisement/advertisement/AdvertisementOperations';

import './styles.scss';
import '../../general.scss';
import Pagination from '../../../../component/common/Pagination/Pagination';
import { withTranslation } from 'react-i18next';

const AutoSearch = ({ t }) => {
  const { _id } = useSelector(state => newAdvertisementDataSelector(state));
  const dispatch = useDispatch();

  const BloggersList = useSelector(state => instagramAutoSearchSelector(state));
  const sizeItemOnPage = 6;

  useEffect(() => {
    dispatch(fetchAdvertisement(_id));
    dispatch(
      getAutoSearchInstagramAccount({
        advertisementId: _id,
        limit: sizeItemOnPage,
        offset: 0,
      }),
    );
  }, [_id, dispatch]);
  const getNotFound = () => {
    return BloggersList?.items.length === 0 ? (
      <div className="bloggers__notFound">
        <p>{t('advertisement_Step2_AutoSearch_Nothing')}</p>
      </div>
    ) : null;
  };
  const _nextPage = page => {
    dispatch(
      getAutoSearchInstagramAccount({
        advertisementId: _id,
        limit: sizeItemOnPage,
        offset: page,
      }),
    );
  };
  return (
    <div>
      <div className="new-company__create">
        <div className="new-company__left">
          <div className="new-company__cards-list">
            {BloggersList.items.map(blogger => (
              <BlogerCard key={blogger._id} bloggerInformation={blogger} />
            ))}
          </div>
          {getNotFound()}

          <div className="auto-search__show-more">
            <Pagination
              items={BloggersList}
              elementType="page"
              sizeItemOnPage={sizeItemOnPage}
              getNextPageItems={_nextPage}
            />
          </div>
        </div>
        <div className="new-company__right">
          <div className="auto-search__selected-bloggers">
            <SelectedBloggers />
          </div>

          <StepsDescription
            options={{
              title: t('advertisement_Step2_AutoSearch_info'),
              description: t('advertisement_Step2_AutoSearch_description'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AutoSearch);
