export const dataBloggerTypeInfo = {
  accountGender: [
    {
      key: 'placeholder',
      text: 'select option',
    },
    {
      key: 'MALE',
      text: 'blogger_list_sort_gender_Male',
    },
    {
      key: 'FEMALE',
      text: 'blogger_list_sort_gender_Female',
    },
    {
      key: 'OTHER',
      text: 'blogger_list_sort_gender_Other',
    },
    {
      key: 'PREFER_NOT_TO_TELL',
      text: 'blogger_list_sort_gender_notTell',
    },
  ],
  accountType: [
    {
      key: 'placeholder',
      text: 'select option',
    },
    {
      key: 'PUBLIC',
      text: 'blogger_list_sort_accountType_Public',
    },
    {
      key: 'BLOGGER',
      text: 'blogger_list_sort_accountType_Blogger',
    },
  ],
  audienceGender: [
    {
      key: 'all',
      text: 'blogger_list_sort_audience_gender_all',
    },
    {
      key: 'ageMale',
      text: 'blogger_list_sort_audience_gender_male',
    },
    {
      key: 'ageFemale',
      text: 'blogger_list_sort_audience_gender_female',
    },
  ],
  countryName: [
    {
      key: 'placeholder',
      text: 'select option',
    },
    {
      key: 'ukraine',
      text: 'Ukraine',
    },
  ],
};

export const UpdateBloggerTypeInfo = {
  accountSubject: [
    {
      key: 'PERSONAL_PAGES',
      text: 'Personal pages',
    },
    {
      key: 'FAMILY',
      text: 'Family',
    },
    {
      key: 'ANIMAL',
      text: 'Animal',
    },
    {
      key: 'SPORT_HEALTH',
      text: 'Sport health',
    },
    {
      key: 'TRAVEL',
      text: 'Travel',
    },
    {
      key: 'PHOTOGRAPHER',
      text: 'Photographer',
    },
    {
      key: 'AUTO_MOTO',
      text: 'Auto moto',
    },
    {
      key: 'FASHION_BEAUTY',
      text: 'Fashion beauty',
    },
    {
      key: 'ENTERTAINMENT',
      text: 'Entertainment',
    },
    {
      key: 'BLOG',
      text: 'Blog',
    },
    {
      key: 'MASS_MEDIA',
      text: 'Mass media',
    },
    {
      key: 'OTHER',
      text: 'Other',
    },
  ],
  accountGender: [
    {
      key: 'placeholder',
      text: 'select option',
    },
    {
      key: 'MALE',
      text: 'Male',
    },
    {
      key: 'FEMALE',
      text: 'Female',
    },
    {
      key: 'OTHER',
      text: 'Other',
    },
    {
      key: 'PREFER_NOT_TO_TELL',
      text: 'Prefer not to tell',
    },
  ],
};
