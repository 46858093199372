import moderatorIcon from '../../../assets/images/support/moderatorIcon.svg';

export const faq = [
  {
    title: 'support_FAQ_item_1_title',
    subtitle: 'support_FAQ_item_1_description',
  },
  {
    title: 'support_FAQ_item_2_title',
    subtitle: 'support_FAQ_item_2_description',
  },
  {
    title: 'support_FAQ_item_3_title',
    subtitle: 'support_FAQ_item_3_description',
  },
  {
    title: 'support_FAQ_item_4_title',
    subtitle: 'support_FAQ_item_4_description',
  },
  {
    title: 'support_FAQ_item_5_title',
    subtitle: 'support_FAQ_item_5_description',
  },
  {
    title: 'support_FAQ_item_6_title',
    subtitle: 'support_FAQ_item_6_description',
  },
  {
    title: 'support_FAQ_item_7_title',
    subtitle: 'support_FAQ_item_7_description',
  },
  {
    title: 'support_FAQ_item_8_title',
    subtitle: 'support_FAQ_item_8_description',
  },
];

export const tickets = [
  {
    number: '122784',
    title: 'Problems with login',
    data: '27.06.2021',
    status: 'New',
    ownMessage:
      'Good afternoon. There was a problem of the following nature. When you try to go from any page of the site to your personal account, the forum gives an error and says that the page is inaccessible.',
    moderatorComments: [
      {
        id: 1,
        image: moderatorIcon,
        name: 'Sergey Grigorenko',
        data: '27.06.2021',
        message:
          'Good day, Thank you for contacting us. You need to contact the technical support service of the Official Site, attaching a detailed description of the error, as well as providing screenshots and a user certificate. We hope this information will help you.',
      },
    ],
  },
  {
    number: '445329',
    title: 'Are there additional services for advertisers.',
    data: '23.06.2021',
    status: 'Closed',
    ownMessage:
      'Good afternoon. There was a problem of the following nature. When you try to go from any page of the site to your personal account, the forum gives an error and says that the page is inaccessible.',
    moderatorComments: [
      {
        id: 2,
        image: moderatorIcon,
        name: 'Sergey Grigorenko',
        data: '27.06.2021',
        message:
          'Good day, Thank you for contacting us. You need to contact the technical support service of the Official Site, attaching a detailed description of the error, as well as providing screenshots and a user certificate. We hope this information will help you.',
      },
      {
        id: 4,
        image: moderatorIcon,
        name: 'Sergey Grigorenko',
        data: '27.06.2021',
        message:
          'Good day, Thank you for contacting us. You need to contact the technical support service of the Official Site, attaching a detailed description of the error, as well as providing screenshots and a user certificate. We hope this information will help you.',
      },
    ],
  },
  {
    number: '267331',
    title: 'How advertisers can find my profile',
    data: '19.06.2021',
    status: 'Closed',
    ownMessage:
      'Good afternoon. There was a problem of the following nature. When you try to go from any page of the site to your personal account, the forum gives an error and says that the page is inaccessible.',
    moderatorComments: [
      {
        id: 3,
        image: moderatorIcon,
        name: 'Sergey Grigorenko',
        data: '27.06.2021',
        message:
          'Good day, Thank you for contacting us. You need to contact the technical support service of the Official Site, attaching a detailed description of the error, as well as providing screenshots and a user certificate. We hope this information will help you.',
      },
    ],
  },
];
