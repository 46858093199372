import React from 'react';

import Second from '../../../../../../../assets/images/landing/work/second.svg';
import First from '../../../../../../../assets/images/landing/work/first.svg';
import Third from '../../../../../../../assets/images/landing/work/third.svg';
import './styles.scss';

const WorkDesktop = ({ t }) => {
  return (
    <div className="work-container">
      <div className="work-container-left">
        <div className="work-container-left-first">
          <p>{t('advertisers_landing_how_works_step_1_title')}</p>
        </div>
        <div className="work-container-left-subtitle">
          <p>{t('advertisers_landing_how_works_step_1_description')}</p>
        </div>
        <img className="work-container__img-second" src={Second} alt="Second" />
        <div className="work-container-left-second">
          <p>{t('advertisers_landing_how_works_step_2_title')}</p>
        </div>
        <div className="work-container-left-subtitle">
          <p>{t('advertisers_landing_how_works_step_2_description')}</p>
        </div>
      </div>
      <div className="work-container-center">
        <div className="work-container-center">
          <div className="work-container-center-first">01</div>
          <div className="work-container-center-line" />
          <div className="work-container-center-first">02</div>
          <div className="work-container-center-line" />
          <div className="work-container-center-first">03</div>
        </div>
      </div>
      <div className="work-container-right">
        <img className="work-container__img-first" src={First} alt="First" />
        <div className="work-container-right-title">
          <p>{t('advertisers_landing_how_works_step_3_title')}</p>
        </div>
        <div className="work-container-right-subtitle">
          <p>{t('advertisers_landing_how_works_step_3_description')}</p>
        </div>
        <img className="work-container__img-third" src={Third} alt="Third" />
      </div>
    </div>
  );
};

export default WorkDesktop;
