import { post, get, putFormData, remove, put } from '../apiService';

export const getListInstagramBlogger = (limit = 9, offset = 0) => {
  return get(
    `/instagram/get-current-user-account?limit=${limit}&offset=${offset}`,
  );
};
export const postVerificationInstagramAccount = (id, bodyFormData) => {
  return putFormData(`/instagram/upload-screenshots/${id}`, bodyFormData);
};
export const createInstagramAccount = (userName,serviceType) => {
  //return createInstagramAccountLocalTest(userName)
  return post('/instagram/create-account', { userName, serviceType});
};
export const createInstagramAccountNew = (userName, pricePerPost, serviceType) => {
  //return createInstagramAccountLocalTest(userName)
  return post('/instagram/create-account-new', { userName, pricePerPost , serviceType});
};
export const createInstagramAccountLocalTest = userName => {
  return post('/instagram/create-account-test', { userName });
};
export const deletedInstagramAccount = id => {
  return remove(`/instagram/delete-current-user-account/${id}`);
};
export const reVerificationAccountById = id => {
  return put(`/instagram/send-for-re-verification/${id}`);
};
export const instagramUpdateScreenshots = (id, body) => {
  return putFormData(`/instagram/upload-screenshots-to-update/${id}`, body);
};
export const updateInstagramStatistic = (id, body) => {
  return put(`/instagram/update-current-user-approve-account/${id}`, body);
};
