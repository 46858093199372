import types from './creationStatusTypes';

import { advertisementCreationSteps } from '../../../../constants/advertisementCreationSteps';

const initialState = {
  activePage: advertisementCreationSteps.base,
  doneSteps: [],
  activeStep: 0,
  typeOperation: null,
};

const creationStatusReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CHANGE_CREATION_STATUS_STEP:
      return {
        ...state,
        ...payload,
      };

    case types.CLEAR_CREATION_STATUS_STEP:
      return initialState;
    default:
      return state;
  }
};

export default creationStatusReducer;
