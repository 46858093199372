export const createTimer = (timeStart, timeEnd, update = false) => {
  if (!window.timeID || update) {
    window.time = timeStart;
    window.timeID = setInterval(() => {
      window.time -= 1;
      if (window.time === timeEnd) {
        clearInterval(window.timeID);
      }
    }, 1000);
  }
};
export const clearTimer = () => {
  clearInterval(window.timeID);
  window.timeID = undefined;
};
