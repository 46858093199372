import React from 'react';

import './styles.scss';
import '../general.scss';

const Number = ({
  options = {},
  inputChangeOptions,
  inputChangeOptionsLast,
}) => {
  const {
    labelText = '',
    placeholderText = '',
    placeholderTextLast = '',
    descriptionText = '',
    renderType = 'single',
  } = options;
  return (
    <div className="custom-input-number__container">
      <label className="custom-input-number__label">{labelText}</label>
      <div className="custom-input-number-container">
        {renderType === 'single' && (
          <>
            <input
              {...inputChangeOptions}
              placeholder={placeholderText}
              className="custom-input-number custom-input-number__single-item"
              type="number"
            />
            {descriptionText && (
              <p className="field__description">{descriptionText}</p>
            )}
          </>
        )}
        {renderType === 'dual' && (
          <div className="custom-input-number__dual">
            <input
              {...inputChangeOptions}
              placeholder={placeholderText}
              className="custom-input-number custom-input-number__dual-item"
              type="number"
            />
            <input
              {...inputChangeOptionsLast}
              placeholder={placeholderTextLast}
              className="custom-input-number custom-input-number__dual-item"
              type="number"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Number;
