import React from 'react';

import './styles.scss';

const ButtonLoader = ({ size }) => {
  let styleSpinner = { width: size, height: size };

  return (
    <div className="spinner-container">
      <div className="spinner" style={styleSpinner}></div>
    </div>
  );
};

export default ButtonLoader;
