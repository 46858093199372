export const sendStoryDependingOfType = (storyItem, type) => {
  const story = Object.assign({}, storyItem);

  if (type === 'IMPROVISATION') {
    delete story.file;
    delete story.comments;
    delete story._id;
    delete story.approved;

    if (story.profile === '') delete story.profile;
    if (story.site === '') delete story.site;

    return { ...story };
  } else {
    delete story.instruction;
    delete story.comments;
    delete story._id;
    delete story.approved;

    if (story.profile === '') delete story.profile;
    if (story.site === '') delete story.site;

    return { ...story };
  }
};
