import React from 'react';

import first from '../../../../../../assets/images/landing/header/blogger/first.png';
import second from '../../../../../../assets/images/landing/header/blogger/second.png';
import third from '../../../../../../assets/images/landing/header/blogger/third.png';
import fourth from '../../../../../../assets/images/landing/header/blogger/fourth.png';

const IconComponent = ({ width }) => {
  const correctWidth = () => {
    if (width > '1920') {
      return '1200';
    } else if (width < '1921' && width > '1250') {
      return '851';
    } else if (width <= '1250') {
      return '120%';
    }
  };
  const correctHeight = () => {
    if (width > '1920') {
      return '1020';
    } else if (width < '1921' && width > '1250') {
      return '924';
    } else if (width <= '1250') {
      return '110%';
    }
  };
  const correctViewBox = () => {
    if (width > '1920') {
      return '0 0 1200 1020';
    } else if (width < '1921' && width > '1250') {
      return '0 0 851 924';
    } else if (width <= '1250') {
      return '100 0 1000 865';
    }
  };

  return (
    <svg
      width={correctWidth()}
      height={correctHeight()}
      viewBox={correctViewBox()}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M528 726C710.254 726 858 578.254 858 396C858 213.746 710.254 66 528 66C345.746 66 198 213.746 198 396C198 578.254 345.746 726 528 726Z"
        stroke="#CACDE4"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        strokeDasharray="2 8"
        id="motion4"
      >
        <animateMotion
          xlinkHref="#circle11"
          dur="100s"
          begin="-40s"
          calcMode="linear"
          keyPoints="1;0"
          keyTimes="0;1"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion4" />
        </animateMotion>
      </path>
      <path
        d="M527.5 804C753.108 804 936 621.108 936 395.5C936 169.892 753.108 -13 527.5 -13C301.892 -13 119 169.892 119 395.5C119 621.108 301.892 804 527.5 804Z"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        id="motion5"
      >
        <animateMotion
          xlinkHref="#circle12"
          dur="100s"
          begin="-15s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion5" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle13"
          dur="100s"
          begin="-5s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion5" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle14"
          dur="100s"
          begin="-5s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion5" />
        </animateMotion>
      </path>
      <path
        d="M528 923C819.054 923 1055 687.054 1055 396C1055 104.946 819.054 -131 528 -131C236.946 -131 1 104.946 1 396C1 687.054 236.946 923 528 923Z"
        stroke="#584187"
        strokeOpacity="0.15"
        strokeWidth="1.2336"
        strokeMiterlimit="10"
        strokeDasharray="2 8"
      ></path>
      <path
        d="M528.423 652.847C670.042 652.847 784.847 538.042 784.847 396.423C784.847 254.805 670.042 140 528.423 140C386.805 140 272 254.805 272 396.423C272 538.042 386.805 652.847 528.423 652.847Z"
        stroke="#CACDE4"
        strokeWidth="1.234"
        strokeMiterlimit="10"
        strokeLinecap="round"
        id="motion6"
      >
        <animateMotion
          xlinkHref="#circle15"
          dur="100s"
          begin="-85s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion6" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle16"
          dur="100s"
          begin="-65s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion6" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle17"
          dur="100s"
          begin="-55s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion6" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle18"
          dur="100s"
          begin="-55s"
          repeatCount="indefinite"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
        >
          <mpath xlinkHref="#motion6" />
        </animateMotion>

        <animateMotion
          xlinkHref="#circle19"
          dur="100s"
          begin="-15s"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion6" />
        </animateMotion>
        <animateMotion
          xlinkHref="#circle20"
          dur="100s"
          begin="-15s"
          keyPoints="1;0"
          keyTimes="0;1"
          calcMode="linear"
          repeatCount="indefinite"
        >
          <mpath xlinkHref="#motion6" />
        </animateMotion>
      </path>
      <circle id="circle11" cx="0" cy="0" r="40.5" fill="url(#pattern0)" />
      <circle id="circle12" cx="0" cy="0" r="36.5" fill="url(#pattern1)" />
      <circle id="circle15" cx="0" cy="0" r="42" fill="url(#pattern2)" />
      <circle id="circle16" cx="0" cy="0" r="55" fill="url(#pattern3)" />
      <circle id="circle17" cx="0" cy="0" r="8.5" fill="#CACDE4" />
      <circle
        id="circle18"
        cx="0"
        cy="0"
        r="13"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle19" cx="0" cy="0" r="6" fill="#CACDE4" />
      <circle
        id="circle20"
        cx="0"
        cy="0"
        r="10.5"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <circle id="circle13" cx="0" cy="0" r="11" fill="#CACDE4" />
      <circle
        id="circle14"
        cx="0"
        cy="0"
        r="15.5"
        stroke="#CACDE4"
        strokeOpacity="0.3"
        strokeWidth="9"
      />
      <g filter="url(#filter0_d)">
        <circle cx="607.5" cy="712.5" r="43.5" fill="#342253" />
      </g>
      <path
        d="M620.902 719.656C622.235 717.415 623 714.797 623 712C623 703.716 616.284 697 608 697C599.716 697 593 703.716 593 712C593 720.284 599.716 727 608 727C610.919 727 613.642 726.166 615.947 724.725L622.516 726.516L620.902 719.656Z"
        stroke="white"
        strokeWidth="2.8"
        strokeLinejoin="round"
      />
      <g filter="url(#filter1_d)">
        <circle cx="199.5" cy="416.5" r="56.5" fill="#FF3F74" />
      </g>
      <path
        d="M218 406H181L196.857 418.197L199.5 437L218 406ZM218 406L196.841 418.184"
        stroke="white"
        strokeWidth="2.8"
        strokeLinejoin="round"
      />
      <circle cx="528" cy="396" r="176" fill="#6946A7" />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        //  x="352" y="159"
        width="352"
        height="413"
      >
        <path
          d="M704 395.72C704 493.077 625.202 572 528 572C430.798 572 352 493.077 352 395.72C352 298.362 430.798 159 528 159C625.202 159 704 298.362 704 395.72Z"
          fill="#DBEBF8"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          x="368.435"
          y="173.095"
          width="317.092"
          height="414.785"
          fill="url(#pattern4)"
        />
      </g>
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0"
            transform="translate(-0.1875) scale(0.00520833)"
          />
        </pattern>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image1"
            transform="translate(-0.0664557) scale(0.00632911)"
          />
        </pattern>
        <pattern
          id="pattern2"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image2"
            transform="translate(-0.255656) scale(0.00452489)"
          />
        </pattern>
        <pattern
          id="pattern3"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image3"
            transform="translate(-0.213855) scale(0.0060241)"
          />
        </pattern>
        <filter
          id="filter0_d"
          x="529"
          y="634"
          width="157"
          height="157"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="17.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.687083 0 0 0 0 0.70325 0 0 0 0 0.808333 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d"
          x="108"
          y="325"
          width="183"
          height="183"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="17.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.687083 0 0 0 0 0.70325 0 0 0 0 0.808333 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern4"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image4"
            transform="translate(-0.00906861) scale(0.00329494 0.00251889)"
          />
        </pattern>
        <image id="image0" width="264" height="192" xlinkHref={first} />
        <image id="image1" width="179" height="158" xlinkHref={second} />
        <image id="image2" width="334" height="221" xlinkHref={third} />
        <image id="image3" width="237" height="166" xlinkHref={fourth} />
      </defs>
    </svg>
  );
};

export default IconComponent;
