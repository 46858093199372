const types = {
  GET_SELECTED_BLOGGERS: 'advertisement/GET_SELECTED_BLOGGERS',
  ADD_BLOGGER_TO_SELECTED_LIST: 'advertisement/ADD_BLOGGER_TO_SELECTED_LIST',
  REMOVE_BLOGGER_FROM_SELECTED_LIST:
    'advertisement/REMOVE_BLOGGER_FROM_SELECTED_LIST',
  CLEAR_CHOISE_BLOGGERS_INFORMATION:
    'advertisement/CLEAR_CHOISE_BLOGGERS_INFORMATION',

  DELETE_OFFER_START: 'advertisement/DELETE_OFFER_START',
  DELETE_OFFER_SUCCESS: 'advertisement/DELETE_OFFER_SUCCESS',
  DELETE_OFFER_ERROR: 'advertisement/DELETE_OFFER_ERROR',

  SET_ACTUAL_BLOGGERS_PRICE: 'advertisement/SET_ACTUAL_BLOGGERS_PRICE',
};

export default types;
