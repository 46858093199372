import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sessionPersonalInformationSelector } from '../../../../../redux/selectors/selectors';
import {
  authLoginWithFacebook,
  authLoginWithGoogle,
} from '../../../../../redux/store/session/sessionOperations';

import { generatorDeviceId } from '../../../../../utilites/globalUtilites';
import useFacebookLogin from '../../../../../hooks/useFacebookLogin';
import useGoogleLogin from '../../../../../hooks/useGoogleLogin';
import { socialMedia } from '../../socialMediaConfig';

import './styles.scss';
import { useTranslation } from 'react-i18next';

const Social = ({ role, referralId }) => {
  const { fcmToken } = useSelector(sessionPersonalInformationSelector);
  const [facebookLogin] = useFacebookLogin();
  const [googleLogin] = useGoogleLogin();

  const deviceId = generatorDeviceId();
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation();

  const handleFacebookLogin = async () => {
    const { id, accessToken } = await facebookLogin.login();
    const body = {
      userId: id,
      accessToken,
      deviceId,
      role,
    };
    if (fcmToken) {
      body.fcmToken = fcmToken;
    }
    dispatch(authLoginWithFacebook(body, history, referralId));
  };

  const handleGoogleLogin = async () => {
    const response = await googleLogin();
    const tokenFromGoogle = response.getAuthResponse().id_token;
    const body = {
      tokenFromGoogle,
      deviceId,
      role,
    };
    if (fcmToken) {
      body.fcmToken = fcmToken;
    }
    dispatch(authLoginWithGoogle(body, history, referralId));
  };

  const handleOnClick = name => {
    if (name === 'facebook') {
      handleFacebookLogin();
    } else {
      handleGoogleLogin();
    }
  };
  return (
    <div className="auth-form-social">
      <div className="auth-form-social__description">
        <span className="auth-form-social__description-text">
          {t('authentication_social')}
        </span>
      </div>
      <div className="auth-form-social__container">
        {socialMedia.map(({ Icon, name }) => (
          <div
            key={name}
            tabIndex="0"
            onClick={() => handleOnClick(name)}
            className="auth-form-social__icon-container"
          >
            <Icon className="auth-form-social__icon" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Social;
