import types from './modalTypes';

export const openModal = (status = false) => ({
  type: types.OPEN_MODAL,
  payload: status,
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
