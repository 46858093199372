const book = Object.freeze({
  home: '/',
  blog: '/blog',
  blogItem: '/blog/:id',
  bloggerList: '/list-of-bloggers',
  finance: '/finance',
  cpaoffer: '/cpaoffer',
  myCompanies: '/my-campaigns',
  myCompaniesItem: '/my-campaigns/:id/advertisements',
  myCompaniesAdvertisementsItem: '/my-campaigns/:id/advertisements/:id',
  signin: '/signin',
  signup: '/signup',
  profile: '/profile',

  //
  choiceBloggersLink: '/new-advertisement/choice-bloggers',

  //
  newCompany: '/new-advertisement',
  basicInformation: '/new-advertisement/basic-information',
  choiceBloggers: '/new-advertisement/choice-bloggers/:id',
  choiceBloggersAuto: '/new-advertisement/choice-bloggers/:id/auto',
  choiceBloggersManual: '/new-advertisement/choice-bloggers/:id/manual',
  choiceBloggersAds: '/new-advertisement/choice-bloggers/:id/ads',
  choiceBloggersFeatured: '/new-advertisement/choice-bloggers/:id/featured',
  //
  budget: '/new-advertisement/:id/budget',
  advertisementLaunch: '/new-advertisement/:id/advertisement-launch',
  notFound: '/not-found',
  paywall: '/paywall',
  settings: '/settings',
  statistics: '/statistics',
  statisticsItem: '/statistics/:id',
  bloggers: '/for-bloggers',
  referrals: '/for-referrals',
  advertisers: '/for-advertisers',
  agreement: '/agreement',
  resetPassword: '/reset-password/:id',
  policy: '/policy',
  withdrawal: '/withdrawal',
  accountBlogger: '/account-blogger',
  accountBloggerError: '/account-blogger/:error',
  support: '/support',
  offers: '/offers',
  accounts: '/accounts',
  accessDeniedError: '/error-access',
  somethingWrong: 'something-wrong',
  referral: '/referral',
  refer: '/registration',
});

export default book;
