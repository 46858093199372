import * as yup from 'yup';

export const login = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).required(),
});

export const email = yup.object().shape({
  email: yup.string().email().required(),
});

export const accaunt = yup.object().shape({
  firstName: yup.string().min(2).max(20).trim(true).required(),
  lastName: yup.string().min(2).max(20).trim(true).required(),
  // email: yup.string().required().email(),
  terms: yup.bool().required().oneOf([true]),
  createPassword: yup.string().required().min(8),
  confirmPassword: yup.string().required().min(8),
});
export const resetPassword = yup.object().shape({
  newPassword: yup.string().required().min(8),
  repeatNewPassword: yup
    .string()
    .required()
    .min(8)
    .test('len', 'passwords must be the same', (val1, { parent }) => {
      return parent.newPassword === parent.repeatNewPassword;
    }),
});
