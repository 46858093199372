import { combineReducers } from 'redux';

import baseInformationReducer from '../advertisement/baseInformation/baseinformationReducer';
import choiseBloggersReducer from '../advertisement/choiceBloggers/choiceBloggersReducer';
import creationStatusReducer from '../advertisement/creationStatus/creationStatusReducer';
import creationDataReducer from '../advertisement/creationData/creationDataReducer';
import { advertisementReducer } from './advertisement/advertisementReducer';
import budgetReducer from '../advertisement/budget/budgetReducer';
import launchReducer from './launch/launchReducer';

export const advertisementCombineReducer = combineReducers({
  baseInformationStep: baseInformationReducer,
  choiseBloggersStep: choiseBloggersReducer,
  creationStatus: creationStatusReducer,
  creationData: creationDataReducer,
  launchStep: launchReducer,
  budgetStep: budgetReducer,
  advertisement: advertisementReducer,
});
