import Instagram from '../../../../../../assets/images/landing/footer/instagram.svg';
import Facebook from '../../../../../../assets/images/landing/footer/facebook.svg';
import Youtube from '../../../../../../assets/images/landing/footer/youtube.svg';
import VK from '../../../../../../assets/images/landing/footer/vk.svg';

import book from '../../../../../router/book';

export const navbar = [
  {
    title: 'footer_landing_navbar_advertisers',
    link: book.advertisers,
  },
  {
    title: 'footer_landing_navbar_bloggers',
    link: book.bloggers,
  },
  {
    title: 'footer_landing_navbar_referrals',
    link: book.referrals,
  },
  {
    title: 'footer_landing_navbar_blog',
    link: book.blog,
  },
];

export const help = [
  {
    title: 'footer_landing_help_rules',
  },
  {
    title: 'footer_landing_help_project',
  },
  {
    title: 'footer_landing_help_team',
  },
  {
    title: 'footer_landing_help_contacts',
  },
];

export const social = [
  { icon: Instagram, alt: 'Instagram' },
  { icon: Facebook, alt: 'Facebook' },
  { icon: Youtube, alt: 'Youtube' },
  { icon: VK, alt: 'VK' },
];
