import React from 'react';
import { Helmet } from 'react-helmet-async';

import ErrorComponent from '../component/ErrorComponent/ErrorComponent';
import { typesErrorComponent } from '../../configs/errorComponentTypes';
import { landingsBookList } from '../../constants/landingsBookList';
import LeftBar from '../component/leftBar/LeftBar';
import usePathname from '../../hooks/usePathname';
import Landing from '../pages/landing/Main/Main';
import Header from '../component/Header/Header';
import Router from '../router/router';

import '../../assets/styles/globalStyles.scss';
import '../../assets/styles/_mixin.scss';
import '../../assets/styles/normalize.scss';
import '../../assets/styles/_fonts.scss';
import '../../assets/styles/base.scss';
import '../../assets/styles/container.scss';
import './styles.scss';
import book from '../router/book';
import LandingNew from '../pages/landingNew/LandingNew';

const App = ({ internetStatus }) => {
  const pathname = usePathname();

  return (
    <>
      <Helmet>
        <title>Bloggerly</title>
        <meta
          name="description"
          content="Bloggerly — a platform for working with bloggers"
        />
      </Helmet>
        {
          landingsBookList.includes(pathname) ||
          pathname.includes('reset-password') ||
          pathname.includes('registration') ||
          pathname.includes(`${book.blog}/`) ? (
            <Landing internetStatus={internetStatus} />
          ) : (
            <div className="main">
              <LeftBar />
              <div className="container main__right">
                <Header />
                <div className="main__right-body">
                  <div className="main__right-body-content">
                    {internetStatus ? (
                      <>
                        <Router />
                      </>
                    ) : (
                      <ErrorComponent type={typesErrorComponent.NO_INTERNET} />
                    )}
                  </div>
                </div>
              </div>
            </div>

          )
        }
    </>
  );
};

export default App;
