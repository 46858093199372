import * as api from '../../../../api/advertisement-service/advertisement-service';
import * as apiService from '../../../../api/session-service/session-service';
import * as apiOffer from '../../../../api/offer-service/offer-service';
import * as choiceBloggersActions from './choiceBloggersActions';
import { addNewStepInformation } from '../creationData/creationDataActions';

import { openModal } from '../../../store/modal/modalActions';
import { modalTypes } from '../../../../configs/modalTypes';

import {
  changeCreationAdvertisementStepSelector,
  SelectedBloggersInformationSelector,
  advertisementSelector,
  sessionPersonalInformationSelector,
} from '../../../selectors/selectors';
import {
  addNewBloggerToSelectedList,
  removeNewBloggerFromSelectedList,
  setActualBloggersPrice,
} from './choiceBloggersActions';
import {
  getAdvertisementSuccess,
  removeOfferFromList,
} from '../advertisement/advertisementActions';

import { isSupportedNotification } from '../../../../helpers/firebase/isSupportedNotification';
import { advertisementCreationSteps } from '../../../../constants/advertisementCreationSteps';
import { changeCreationAdvertisementStep } from '../creationStatus/creationStatusActions';
import { sessionAdvertiserPersonalDataSuccess } from '../../session/sessionActions';
import { setNotifyStatus } from '../../notify/notifyActions';

export const addBloggerToSelectedList =
  (blogger = {}, changedPrice) =>
  (dispatch, getState) => {
    const selectedData = SelectedBloggersInformationSelector(getState());
    const { budget, offers } = advertisementSelector(getState());

    let price = blogger.pricePerPost;
    if (changedPrice) {
      price = Number.parseInt(changedPrice);
    }

    const selectedOffer = offers.find(
      ({ user: { _id } }) => _id === blogger._id,
    );

    const updatedList = {
      ...selectedData,
      count: selectedData.count + 1,
      coverage: selectedData.coverage + blogger.audienceReach,
      price: selectedData.price + price,
      additionalTargeting: !selectedOffer
        ? selectedData.price + price - budget
        : 0,
      bloggersList: [...selectedData.bloggersList, { id: blogger._id, price }],
    };

    dispatch(addNewBloggerToSelectedList(updatedList));
  };

export const setAcutalBloggerList =
  (bloggers = []) =>
  dispatch => {
    const price = bloggers.reduce((acc, { price }) => acc + price, 0);

    const coverage = bloggers.reduce(
      (acc, { user: { audienceReach } }) => acc + audienceReach,
      0,
    );

    const bloggersList = bloggers.map(({ user: { _id: id }, price }) => ({
      id,
      price,
    }));

    const updatedList = {
      count: bloggers.length,
      coverage,
      price,
      bloggersList,
    };

    dispatch(addNewBloggerToSelectedList(updatedList));
  };

export const actualBloggersPrice = () => (dispatch, getState) => {
  const selectedData = SelectedBloggersInformationSelector(getState());

  const updatedList = {
    ...selectedData,
    count: selectedData.count,
    coverage: selectedData.coverage,
    price: selectedData.price,
  };

  dispatch(setActualBloggersPrice(updatedList));
};

export const removeBloggerFromSelectedList =
  (blogger = {}, changedPrice) =>
  (dispatch, getState) => {
    const selectedData = SelectedBloggersInformationSelector(getState());
    const { budget, offers } = advertisementSelector(getState());

    let price = blogger.pricePerPost;
    if (changedPrice) {
      price = Number.parseInt(changedPrice);
    }

    const selectedOffer = offers.find(
      ({ user: { _id } }) => _id === blogger._id,
    );

    const updatedList = {
      ...selectedData,
      count: selectedData.count - 1,
      coverage: selectedData.coverage - blogger.audienceReach,
      price: selectedData.price - price,
      additionalTargeting: !selectedOffer
        ? selectedData.price - price - budget
        : 0,
      bloggersList: selectedData.bloggersList.filter(
        ({ id }) => id !== blogger._id,
      ),
    };
    dispatch(removeNewBloggerFromSelectedList(updatedList));
  };

export const sendChoiseBloggers = (id, type) => (dispatch, getState) => {
  const typeOfSearch = changeCreationAdvertisementStepSelector(getState());
  const { budget: userBudget } = sessionPersonalInformationSelector(getState());
  const { bloggersList, price } = SelectedBloggersInformationSelector(
    getState(),
  );
  const {
    offers,
    _id: idAds,
    budget,
    status,
  } = advertisementSelector(getState());

  const existingBloggerList = () => {
    const newBloggersForNewOffer = [];

    bloggersList.forEach(({ id, price }) => {
      if (!offers.length) {
        return newBloggersForNewOffer;
      }

      if (!offers.find(({ user: { _id } }) => _id === id)) {
        newBloggersForNewOffer.push({ id, price });
      }
    });

    return newBloggersForNewOffer;
  };

  const sendData = {
    typeOfAllocation: type,
  };

  if (!existingBloggerList().length && offers.length) {
    return dispatch(
      changeCreationAdvertisementStep({
        activePage: advertisementCreationSteps['budget'],
        doneSteps: [0, 1],
        activeStep: 2,
      }),
    );
  } else if (existingBloggerList().length) {
    sendData.bloggers = existingBloggerList();
  } else {
    sendData.bloggers = bloggersList;
  }

  //sendChoiseBloggers(idAds, typeOfSearch);

  if (price - budget > userBudget)
    return dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.insufficientFunds,
      }),
    );

  api
    .postAdvertisementOffer(id, sendData)
    .then(offer => {
      dispatch(addNewStepInformation(offer));

      return apiService.getCurrentUserInformation().then(user => {
        dispatch(
          sessionAdvertiserPersonalDataSuccess({
            ...user,
            notification: isSupportedNotification()
              ? Notification.permission
              : '',
          }),
        );
        localStorage.setItem(
          'accaunt',
          JSON.stringify({
            ...user,
            notification: isSupportedNotification()
              ? Notification.permission
              : '',
          }),
        );
        dispatch(
          changeCreationAdvertisementStep({
            activePage: advertisementCreationSteps['budget'],
            doneSteps: [0, 1],
            activeStep: 2,
          }),
        );
      });
    })
    .catch(({ message }) => {
      if (message.includes('end date') && status === 'DRAFT') {
        dispatch(
          setNotifyStatus({
            title:
              "You can'\t add more offers due to it won't be completed in publish end date. Create the new ad with valid publish dates or change publish dates here",
            type: 'error',
          }),
        );
      }
    });
};

export const deleteOfferByUserId =
  (id, blogger, changedPrice) => (dispatch, getState) => {
    dispatch(choiceBloggersActions.deleteOfferStart());
    const advertisement = advertisementSelector(getState());

    const selectedOffer = advertisement.offers.find(
      ({ user: { _id } }) => _id === id,
    );
    if (!selectedOffer)
      return dispatch(removeBloggerFromSelectedList(blogger, changedPrice));

    const filteredListOffers = advertisement.offers.filter(
      ({ _id }) => _id !== selectedOffer._id,
    );

    apiOffer
      .deleteOfferById(selectedOffer._id)
      .then(({ budget }) => {
        dispatch(removeBloggerFromSelectedList(blogger, changedPrice));
        dispatch(addNewStepInformation({ ...advertisement, budget }));
        dispatch(getAdvertisementSuccess({ ...advertisement, budget }));
        dispatch(removeOfferFromList(filteredListOffers));

        return apiService.getCurrentUserInformation().then(user => {
          dispatch(
            sessionAdvertiserPersonalDataSuccess({
              ...user,
              notification: isSupportedNotification()
                ? Notification.permission
                : '',
            }),
          );
          localStorage.setItem(
            'accaunt',
            JSON.stringify({
              ...user,
              notification: isSupportedNotification()
                ? Notification.permission
                : '',
            }),
          );
        });
      })
      .catch(({ message }) => {
        dispatch(choiceBloggersActions.deleteOfferError(message));
      });
  };
