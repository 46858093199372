import React from 'react';
import { connect } from 'react-redux';

import { listOfStages, stepsList, stepsClassNames } from './config';

import { changeCreationAdvertisementStep } from '../../../redux/store/advertisement/creationStatus/creationStatusActions';
import { creationStatusSelector } from '../../../redux/selectors/selectors';

import './styles.scss';
import { withTranslation } from 'react-i18next';

const StapsBar = ({ stapsStatusInfo, changeCreationAdvertisementStep, t }) => {
  const handleStepClick = index => {
    const isDone = stapsStatusInfo['doneSteps'].includes(index);
    const isNotActive = stapsStatusInfo['activeStep'] === index;

    if (isDone && !isNotActive) {
      changeCreationAdvertisementStep({
        activeStep: index,
        activePage: stepsList[index],
        typeOperation: 'edit',
      });
    }
  };

  const getStyles = (index, type) => {
    if (stapsStatusInfo['activeStep'] === index) {
      return stepsClassNames[type].active;
    }
    if (stapsStatusInfo['doneSteps'].includes(index)) {
      return stepsClassNames[type].done;
    }

    return '';
  };

  return (
    <div className="staps-bar__container">
      {listOfStages.map(({ stepText, stepNumber }, index) => (
        <div
          onClick={() => handleStepClick(index)}
          key={stepNumber}
          className={`staps-bar__item ${getStyles(index, 'item')}`}
        >
          <div
            className={`staps-bar__item-number 
            
            ${getStyles(index, 'number')} `}
          >
            {stepNumber}
          </div>
          <p
            className={`staps-bar__item-text 
            
            ${getStyles(index, 'text')} 
            `}
          >
            {t(stepText)}
          </p>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  stapsStatusInfo: creationStatusSelector(state),
});
const mapDispatchToProps = {
  changeCreationAdvertisementStep,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(StapsBar));
