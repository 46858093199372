import types from './financeTypes';

const initialState = {
  historyBlogger: { body: [], count: 0 },
  historyAdvertiser: { body: [], count: 0 },
  advertiserBudget: {},
  bloggerBudget: {},
  errorMessage: null,
};

export const financeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_TRANSACTION_HISTORY_BLOGGER:
      return { ...state, historyBlogger: payload };
    case types.GET_TRANSACTION_HISTORY_ADVERTISER:
      return { ...state, historyAdvertiser: payload };
    case types.GET_ADVERTISEMENT_BUDGET:
      return { ...state, advertiserBudget: payload };
    case types.GET_BLOGGER_BUDGET:
      return { ...state, bloggerBudget: payload };
    case types.ERROR_REFILL: {
      return { ...state, errorMessage: payload };
    }
    case types.ERRORS_WITHDRAWAL: {
      return { ...state, errorMessage: payload };
    }
    default:
      return state;
  }
};

export default financeReducer;
