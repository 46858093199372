import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as FilterIcon } from '../../../assets/images/vectors/filter.svg';
import {
  contentTypesSortBloggerOptions,
  typeSort,
} from '../../../configs/selectOptions';
import './styles.scss';
import SelectFilter from './selectFilter/selectFilter';
import { instagramSelector } from '../../../redux/store/instagram/instagramSelector';
import { withTranslation } from 'react-i18next';

const HeaderListBlogger = ({
  onChengSearch,
  onClickSearch,
  onChengSort,
  onChengDirection,
  onClickFilter,
  t,
}) => {
  const { searchValue, sortParams, directionParams } = useSelector(
    instagramSelector,
  );

  const sortValue = contentTypesSortBloggerOptions.find(
    ({ value }) => value === sortParams?.substr(6),
  );

  const directionValue =
    directionParams.split('=')[1] === '-1' ? typeSort[1] : typeSort[0];

  return (
    <div className="header-list-blogger">
      <div className="header-list-blogger-left">
        <button
          onClick={onClickFilter}
          className="header-list-blogger-left-button header-list-blogger-left-button-mobile"
        >
          <FilterIcon className="header-list-blogger-left-button-icon" />
          <p>{t('blogger_list_search_filter')}</p>
        </button>

        <div className="header-list-blogger-right">
          <input
            onChange={onChengSearch}
            placeholder={t('blogger_list_search_search')}
            defaultValue={searchValue ? searchValue : ''}
            className="custom-input-number "
          />
          <button
            className="custom-button custom-button-primary"
            onClick={onClickSearch}
          >
            {t('blogger_list_search_button')}
          </button>
        </div>
      </div>

      <div className="header-list-blogger-sort-container">
        <div className="header-list-blogger-sort-container-mobile">
          <button
            onClick={onClickFilter}
            className="header-list-blogger-left-button"
          >
            <FilterIcon className="header-list-blogger-left-button-icon" />
            <p>Filter</p>
          </button>
        </div>
        <div className="header-list-blogger-sort-group">
          <div className="header-list-blogger-sort">
            <SelectFilter
              options={contentTypesSortBloggerOptions}
              onChange={onChengSort}
              defaultValue={sortValue}
              type={'sort'}
              page="secondary"
            />
          </div>
          <div className="header-list-blogger-sort">
            <SelectFilter
              options={typeSort}
              type={'sortBy'}
              defaultValue={directionValue}
              onChange={onChengDirection}
              page="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(HeaderListBlogger);
