import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import ErrorComponent from '../../../component/ErrorComponent/ErrorComponent';
import { typesErrorComponent } from '../../../../configs/errorComponentTypes';
import { landingsBookList } from '../../../../constants/landingsBookList';
import usePathname from '../../../../hooks/usePathname';
import ScrollToTop from '../../../../hooks/scrollToTop';
import { publicRoutes } from '../../../router/config';
import { Header, Footer } from './components/index';
import book from '../../../router/book';

const Main = ({ internetStatus }) => {
  const isAuth = localStorage.getItem('session');
  const pathname = usePathname();

  return (
    <div style={{ overflow: 'hidden' }} className="landing">
      <ScrollToTop />
      {pathname.includes(book.blog) ? null : <Header />}
      {isAuth &&
        (landingsBookList.slice(0, -1).includes(pathname) ||
          pathname.includes('reset-password') ||
          pathname.includes('registration')) && <Redirect to={book.home} />}
      {internetStatus ? (
        <Switch>
          {publicRoutes.map(({ path, component, isExact }) => (
            <Route
              key={path}
              path={path}
              exact={isExact}
              component={component}
            />
          ))}
        </Switch>
      ) : (
        <ErrorComponent type={typesErrorComponent.NO_INTERNET} />
      )}
      {pathname.includes(book.blog) ? null : <Footer />}
    </div>
  );
};
export default withRouter(Main);
