import { postFormData, get, putFormData, remove } from '../apiService';

// get
export const getCampaignList = (params = '') => {
  return get('/campaign/' + params);
};

export const getCampaignById = (id, params = '') => {
  return get('/campaign/' + id + params);
};

// post
export const postCampaignById = data => {
  return postFormData('/campaign', data);
};

// put
export const putCampaignById = (id, data) => {
  return putFormData(`/campaign/${id}`, data);
};

// delete
export const deleteCampaignById = id => {
  return remove(`/campaign/${id}`);
};
