import types from './newsTypes';

const initialQueryParams = {
  offset: 0,
  limit: 6,
  search: 'LATEST_NEWS',
};

const initialState = {
  allNews: {
    items: [],
    count: 0,
  },
  newsItem: {
    similarNews: [],
  },
  params: initialQueryParams,
};

export const newsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_NEWS_LIST_SUCCESS:
      return { ...state, allNews: payload };
    case types.SET_SEARCH:
      return {
        ...state,
        params: {
          ...state.params,
          search: payload,
          offset: 0,
        },
      };
    case types.SET_OFFSET:
      return {
        ...state,
        params: {
          ...state.params,
          offset: payload,
        },
      };
    case types.CLEAR_ALL_PARAMS:
      return {
        ...state,
        params: initialQueryParams,
      };
    case types.GET_NEWS_SUCCESS:
      return { ...state, newsItem: payload };

    default:
      return state;
  }
};

export default newsReducer;
