import React from 'react';

import bottom from '../../../../../../assets/images/landing/global/wave.svg';
import right from '../../../../../../assets/images/landing/earnings/second.svg';
import left from '../../../../../../assets/images/landing/earnings/first.svg';
import top from '../../../../../../assets/images/landing/case/wave.svg';
import './styles.scss';

const Earnings = ({ t }) => {
  return (
    <div className="earnings">
      <img className="earnings__wave-top" src={top} alt="wave" />
      <div className="earnings__top">
        <div className="earnings__top-title">
          {t('bloggers_landing_earnings_title')}
        </div>
        <div className="earnings__top-subtitle">
          {t('bloggers_landing_earnings_subtitle')}
        </div>
      </div>
      <div className="earnings__center-container">
        <div className="earnings__center">
          <div className="earnings__center-left">
            <div className="earnings__center-left-photo">
              <img
                className="earnings__center-left-photo-img"
                src={left}
                alt="img"
              />
            </div>
            <div className="earnings__center-left-block">
              <div className="earnings__center-left-block-title">
                {t('bloggers_landing_earnings_block_1_title')}
              </div>
              <div className="earnings__center-left-block-subtitle">
                {t('bloggers_landing_earnings_block_1_subtitle')}
              </div>
              <div className="earnings__center-left-block-subtitle">
                {t('bloggers_landing_earnings_block_1_subtitle_2')}
              </div>
            </div>
          </div>
          <div className="earnings__center-right">
            <div className="earnings__center-right-block">
              <div className="earnings__center-right-block-title">
                {t('bloggers_landing_earnings_block_2_title')}
              </div>
              <div className="earnings__center-right-block-subtitle">
                {t('bloggers_landing_earnings_block_2_subtitle')}
              </div>
              <div className="earnings__center-right-block-subtitle">
                {t('bloggers_landing_earnings_block_2_subtitle_2')}
              </div>
            </div>
            <div className="earnings__center-right-photo">
              <img
                className="earnings__center-right-photo-img"
                src={right}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
      <img className="earnings__wave-bottom" src={bottom} alt="wave" />
    </div>
  );
};

export default Earnings;
