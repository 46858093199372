import React from 'react';
import AddIcon from '../../../../assets/images/vectors/add.svg';

import './styles.scss';

import Loader from '../../../component/loaders/ButtonLoader/ButtonLoader';

const Button = ({
  loadingStatus,
  options = {},
  handleButtonClick = () => {},
}) => {
  const {
    text = '',
    isIcon = false,
    renderType = 'primary',
    type = 'button',
    size = 's',
    disabled,
  } = options;

  return (
    <button
      disabled={disabled || loadingStatus}
      type={type}
      onClick={handleButtonClick}
      className={`custom-button custom-button-${renderType} custom-button-${size} ${
        (disabled || loadingStatus) && 'custom-button-disabled'
      }`}
    >
      {loadingStatus ? (
        <Loader size={16} />
      ) : (
        <>
          {text}
          {isIcon && (
            <img
              className={`custom-button__icon custom-button__icon-${size}`}
              src={AddIcon}
              alt="plus"
            />
          )}
        </>
      )}
    </button>
  );
};

export default Button;
