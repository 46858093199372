import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderListBlogger from '../../component/HeaderListBlogger/HeaderListBlogger';
import { modalTypes } from '../../../configs/modalTypes';
import { openModal } from '../../../redux/store/modal/modalActions';
import OfferCard from '../../component/OfferCard/OfferCard';
import { withTranslation } from 'react-i18next';
import {getCpaOffersList} from '../../../api/cpaoffer-service/cpaoffer-service'
import {
  editDirection,
  editSearch,
  editSort
} from '../../../redux/store/instagram/instagramOperations';
import {
  getOptionsSearchSelector
} from '../../../redux/store/instagram/instagramSelector';
import './Cpaoffer.scss';
import { connect } from 'react-redux';
import CpaOfferCard from '../../component/CpaOfferCard/CpaOfferCard';
import HeaderListCpaOffer from '../../component/HeaderListCpaOffer/HeaderListCpaOffer';


const CpaOffer = () => {

  const [userNameValue, setUserNameValue] = useState('');
  const dispatch = useDispatch();
  const prevSearchValue = useSelector(getOptionsSearchSelector);
  const [currentSelected, setCurrentSelected] = useState(0);
  const [mapOffers,setmapOffers] = useState([])

  useEffect(()=>{
    getCpaOffersList().then((res)=>{
      setmapOffers(res.items)
    })
  },[])

  const _onChengSort = ({ value }) => {
    dispatch(editSort(value));
  };

  const _onClickSearch = () => {
    if (prevSearchValue !== userNameValue) {
      dispatch(editSearch(userNameValue));
    }
  };

  const _onChengSearch = ({ target }) => {
    setUserNameValue(target.value);
  };

  const _onChengDirection = ({ value }) => {
    dispatch(editDirection(value));
  };

  const _onClickFilter = (e) => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.showOfferText,
      }),
    );
  };
  const _onClickText = (title, text, id) => {
    setCurrentSelected(id)
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.showOfferText,
        details: {title: title, text: text, onClick: ()=>{}, buttonText: "Close"}
      }),
    );
  };
  return (
    <div className="offers">
      <div className="offers__list">
        <HeaderListCpaOffer
          onChengDirection={_onChengDirection}
          onChengSort={_onChengSort}
        />
          {mapOffers && mapOffers.length && mapOffers.map((offer)=>
            {
                return <CpaOfferCard key={mapOffers._id} offer={offer} _onClickText={_onClickText} />
            })
          }
        </div>
    </div>
  )
};

export default withTranslation()(CpaOffer);
