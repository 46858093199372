import { get, post } from '../apiService';
import { normalizeDateToFilter } from '../../utilites/globalUtilites';

export const getAdvertiserTransactionHistory = (
  limit = 10,
  offset = 0,
  sortBy = 'createDate',
  orderBy = 'desc',
  type = 'ALL',
  dateTo = null,
  dateFrom = null,
) => {
  return get(
    `/advertiser/transaction/history?limit=${limit}&offset=${offset}&type=${type}${
      sortBy ? '&sortBy=' + sortBy : ''
    }${orderBy ? '&orderBy=' + orderBy : ''}${
      dateTo ? '&dateTo=' + normalizeDateToFilter(dateTo, 'dateTo') : ''
    }${
      dateFrom ? '&dateFrom=' + normalizeDateToFilter(dateFrom, 'dateFrom') : ''
    }`,
  );
};
export const getBloggerTransactionHistory = (
  limit = 10,
  offset = 0,
  sortBy = 'createDate',
  orderBy = 'asc',
  type = 'ALL',
  dateTo = null,
  dateFrom = null,
) => {
  return get(
    `/blogger/transaction/history?limit=${limit}&offset=${offset}&type=${type}${
      sortBy ? '&sortBy=' + sortBy : ''
    }${orderBy ? '&orderBy=' + orderBy : ''}${
      dateTo ? '&dateTo=' + normalizeDateToFilter(dateTo, 'dateTo') : ''
    }${
      dateFrom ? '&dateFrom=' + normalizeDateToFilter(dateFrom, 'dateFrom') : ''
    }`,
  );
};
export const getAdvertiserBudget = () => {
  return get('/advertiser/budget');
};
export const getBloggerBudget = () => {
  return get('/blogger/budget');
};

export const postRefillAdvertiser = amount => {
  return post(`/wallet/fill-up`, { amount });
};
export const postWithdrawalBlogger = body => {
  return post(`/wallet/withdraw `, body);
};
