export const dataOfferTypeInfo = {
    offerType: [
      {
        key: 'placeholder',
        text: 'select option',
      },
      {
        key: 'ACTIVE',
        text: 'ACTIVE',
      },
      {
        key: 'REJECTED',
        text: 'REJECTED',
      },
      {
        key: 'PENDING',
        text: 'PENDING',
      },
      {
        key: 'MODERATION',
        text: 'MODERATION',
      },
      
    ],
  };
  