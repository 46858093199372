import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import './styles.scss';
import Input from '../../common/Fields/Input/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../common/Button/Button';
import Select from '../../common/Fields/Select/Select';
import { UpdateBloggerTypeInfo } from '../../../../configs/typeBloger';

import closeIcon from '../../../../assets/images/vectors/closePink.svg';
import {
  instagramAccountAllStatistics,
  instagramAccountUpdateScreen,
  updateStatistic,
} from '../../../../schemas/instagram';
import {
  instagramUpdateScreenshots,
  postVerificationInstagramAccount,
  updateInstagramStatistic,
} from '../../../../api/account-blogger-service/account-blogger-service';
import { useDispatch } from 'react-redux';
import { getAccountInstagramListBlogger } from '../../../../redux/store/accountBlogger/accountBloggerOperations';
import { setErrorHandler } from '../../../../redux/store/errorHandler/errorHandlerOperations';
import { withTranslation } from 'react-i18next';
import { setNotifyStatus } from '../../../../redux/store/notify/notifyActions';

const InstagramStatisticsApprove = ({ closeModal, details, t }) => {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: details.body,
    resolver: yupResolver(
      details.type === 'UPDATE_SCREEN'
        ? instagramAccountUpdateScreen
        : details.type === 'UPDATE_PRISE'
        ? updateStatistic
        : instagramAccountAllStatistics,
    ),
  });
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    reset({ ...details.body });
  }, [details]);
  useEffect(() => {
    if (details.type === 'UPDATE_PRISE') {
      setValue('accountSubject', details.accountSubject);
      setValue('accountGender', details.accountGender);
    }
  }, []);
  useEffect(() => {
    const subscription = watch(v => setFormData(v));
    return () => subscription.unsubscribe();
  }, [watch]);

  const getNameImage = type => {
    if (formData && formData[type] && formData[type][0]) {
      return formData[type][0].name;
    }
  };

  const getContent = ({
    accountSubject,
    accountGender,
    storiesPerDay,
    pricePerPost,
    locationCitiesAndAgeRangeAll,
    locationCountriesAndAgeRangeMen,
    audienceReach,
    genderGeneral,
    ageFemale,
    storiesViews,
  }) => {
    if (details.type === 'UPDATE_SCREEN') {
      const formData = new FormData();
      formData.append(
        'locationCitiesAndAgeRangeAll',
        locationCitiesAndAgeRangeAll[0],
      );
      formData.append(
        'locationCountriesAndAgeRangeMen',
        locationCountriesAndAgeRangeMen[0],
      );
      formData.append('audienceReach', audienceReach[0]);
      formData.append('genderGeneral', genderGeneral[0]);
      formData.append('ageFemale', ageFemale[0]);
      formData.append('storiesViews', storiesViews[0]);
      return formData;
    } else if (details.type === 'UPDATE_PRISE') {
      return { accountSubject, accountGender, storiesPerDay, pricePerPost };
    } else {
      const formData = new FormData();
      formData.append('accountSubject', accountSubject);
      formData.append('accountGender', accountGender);
      formData.append('storiesPerDay', storiesPerDay);
      formData.append('pricePerPost', pricePerPost);
      formData.append(
        'locationCitiesAndAgeRangeAll',
        locationCitiesAndAgeRangeAll[0],
      );
      formData.append(
        'locationCountriesAndAgeRangeMen',
        locationCountriesAndAgeRangeMen[0],
      );
      formData.append('audienceReach', audienceReach[0]);
      formData.append('genderGeneral', genderGeneral[0]);
      formData.append('ageFemale', ageFemale[0]);
      formData.append('storiesViews', storiesViews[0]);
      return formData;
    }
  };

  const dispatch = useDispatch();
  const onSubmit = data => {
    setLoading(true);
    if (details.type === 'UPDATE_SCREEN') {
      instagramUpdateScreenshots(details.id, getContent(data))
        .then(() => {
          closeModal();
          dispatch(getAccountInstagramListBlogger());
          dispatch(
            setNotifyStatus({
              title: 'Your Statistics was successfully update',
              type: 'success',
            }),
          );
        })
        .catch(r => {
          dispatch(getAccountInstagramListBlogger());
          dispatch(setErrorHandler(r));
        })
        .finally(() => setLoading(false));
    } else if (details.type === 'UPDATE_PRISE') {
      updateInstagramStatistic(details.id, getContent(data))
        .then(() => {
          dispatch(
            setNotifyStatus({
              title: 'Your info was successfully update',
              type: 'success',
            }),
          );
          closeModal();
        })
        .catch(r => {
          dispatch(getAccountInstagramListBlogger());
          dispatch(setErrorHandler(r));
        })
        .finally(() => setLoading(false));
    } else if (details.type === 'ALL') {
      postVerificationInstagramAccount(details.id, getContent(data))
        .then(() => {
          dispatch(getAccountInstagramListBlogger());
          closeModal();
        })
        .catch(r => dispatch(setErrorHandler(r)))
        .finally(() => setLoading(false));
    }
  };

  const _onClickClose = ({ target }) => {
    setValue(target.name, null, {
      shouldValidate: true,
    });
  };
  const getInfoImage = name => {
    if (errors[name]) {
      return (
        <div className="instagram-statistics__info">
          <p className="instagram-statistics__info-error">
            {errors[name].type}
          </p>
        </div>
      );
    } else if (getNameImage(name)) {
      return (
        <div className="instagram-statistics__info">
          <p>{getNameImage(name)}</p>
          <img
            className="instagram-statistics__info-close"
            name={name}
            src={closeIcon}
            alt="close"
            onClick={_onClickClose}
          />
        </div>
      );
    }
    return null;
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="instagram-statistics">
      <p className="instagram-statistics-title">
        {details.type === 'UPDATE_SCREEN'
          ? t('account_update_statistics_update')
          : details.type === 'UPDATE_PRISE'
          ? t('account_update_statistics_update')
          : t('account_update_statistics_approve')}
      </p>
      {details.type === 'ALL' || details.type === 'UPDATE_SCREEN' ? (
        <>
          {' '}
          <label
            htmlFor={'instagram-statistics__cities'}
            data-content={t('account_blogger_choose_file')}
            className={`instagram-statistics__label ${
              details.type === 'ALL' && 'instagram-statistics__label-all'
            }`}
          >
            <p> {t('account_blogger_location_cities')} </p>
          </label>
          <input
            className="instagram-statistics__input"
            id={'instagram-statistics__cities'}
            type="file"
            accept="image/png, image/gif, image/jpeg"
            {...register('locationCitiesAndAgeRangeAll')}
          />
          {getInfoImage('locationCitiesAndAgeRangeAll')}
          <label
            data-content={t('account_blogger_choose_file')}
            htmlFor={'instagram-statistics__countries'}
            className={`instagram-statistics__label ${
              details.type === 'ALL' && 'instagram-statistics__label-all'
            }`}
          >
            <p>{t('account_blogger_location_countries')}</p>
          </label>
          <input
            id={'instagram-statistics__countries'}
            className="instagram-statistics__input"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            {...register('locationCountriesAndAgeRangeMen')}
          />
          {getInfoImage('locationCountriesAndAgeRangeMen')}
          <label
            htmlFor={'instagram-statistics__countries-audience'}
            data-content={t('account_blogger_choose_file')}
            className={`instagram-statistics__label ${
              details.type === 'ALL' && 'instagram-statistics__label-all'
            }`}
          >
            <p>{t('account_blogger_audience')}</p>
          </label>
          <input
            id={'instagram-statistics__countries-audience'}
            className="instagram-statistics__input"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            {...register('audienceReach')}
          />
          {getInfoImage('audienceReach')}
          <label
            htmlFor={'instagram-statistics-general'}
            data-content={t('account_blogger_choose_file')}
            className={`instagram-statistics__label ${
              details.type === 'ALL' && 'instagram-statistics__label-all'
            }`}
          >
            <p>{t('account_blogger_general')}</p>
          </label>
          <input
            id="instagram-statistics-general"
            className="instagram-statistics__input"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            {...register('genderGeneral')}
          />
          {getInfoImage('genderGeneral')}
          <label
            htmlFor={'instagram-statistics-age'}
            data-content={t('account_blogger_choose_file')}
            className={`instagram-statistics__label ${
              details.type === 'ALL' && 'instagram-statistics__label-all'
            }`}
          >
            <p>{t('account_blogger_female')}</p>
          </label>
          <input
            id={'instagram-statistics-age'}
            className="instagram-statistics__input"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            {...register('ageFemale')}
          />
          {getInfoImage('ageFemale')}
          <label
            htmlFor={'storiesViews'}
            data-content={t('account_blogger_choose_file')}
            className={`instagram-statistics__label ${
              details.type === 'ALL' && 'instagram-statistics__label-all'
            }`}
          >
            <p>{t('account_blogger_stories')}</p>
          </label>
          <input
            id={'storiesViews'}
            className="instagram-statistics__input"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            {...register('storiesViews')}
          />
          {getInfoImage('storiesViews')}
        </>
      ) : null}

      {details.type === 'ALL' || details.type === 'UPDATE_PRISE' ? (
        <div className="instagram-statistics__input-info">
          <Input
            errors={errors?.pricePerPost?.message}
            inputChangeOptions={register('pricePerPost')}
            options={{
              labelText: t('account_update_price_label'),
              placeholderText: t('account_update_price_placeholder'),
              isStory: true,
              typeText: 'number',
            }}
          />
          <Input
            errors={errors?.storiesPerDay?.message}
            inputChangeOptions={register('storiesPerDay')}
            options={{
              labelText: t('account_update_stories_label'),
              placeholderText: t('account_update_stories_placeholder'),
              typeText: 'number',
              isStory: true,
            }}
          />
          <Select
            inputChangeOptions={register('accountSubject')}
            listOfOptions={UpdateBloggerTypeInfo.accountSubject}
            options={{
              labelText: t('account_update_subject_label'),
            }}
          />
          <Select
            inputChangeOptions={register('accountGender')}
            listOfOptions={UpdateBloggerTypeInfo.accountGender}
            options={{
              labelText: t('account_update_gender'),
            }}
          />
        </div>
      ) : null}
      <Button
        options={{
          isIcon: false,
          renderType: 'primary',
          // size: 'm-s',
          text: t('account_account_blogger_send'),
          type: 'submit',
          disabled: !isValid || loading,
        }}
      />
    </form>
  );
};
export default withTranslation()(InstagramStatisticsApprove);
