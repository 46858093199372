import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { editInstagramApprovedAccountsFavoriteStatus } from '../../../../redux/store/instagram/instagramOperations';
import {
  addBloggerToSelectedList,
  deleteOfferByUserId,
} from '../../../../redux/store/advertisement/choiceBloggers/choiceBloggersOperations';
import { openModal } from '../../../../redux/store/modal/modalActions';
import {
  SelectedBloggersInformationSelector,
  newAdvertisementDataSelector,
} from '../../../../redux/selectors/selectors';

import { compareKeyBySelect } from '../../../../helpers/compareKeyBySelect';
import { accountSubjectOptions } from '../../../../configs/selectOptions';
import { numberWithSpaces } from '../../../../helpers/numberWithSpaces';
import { modalTypes } from '../../../../configs/modalTypes';
import Rating from '../../common/Rating/Rating';
import { FavoriteIcon } from './IconComponents';

import { ReactComponent as AllStatistics } from '../../../../assets/images/vectors/all-statistics.svg';
import paywallAvatar from '../../../../assets/images/bloggers/paywallAvatar.svg';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const BloggerCard = ({
  editInstagramApprovedAccountsFavoriteStatus,
  bloggerInformation,
  isPaywalled,
  advertisement: { offers },
  addBloggerToSelectedList,
  deleteOfferByUserId,
  bloggersSelected,
  type = 'primary',
  t,
}) => {
  const {
    _id: id,
    userName,
    accountSubject,
    subscribersCount,
    accountQuality,
    likes_views,
    mean_views,
    comments_views,
    category,
    avatar,
    priceForOneThousand,
    pricePerPost,
    isFavorite,
    price_per_1000,
    rating,
  } = bloggerInformation;
  const uniqueBloggers = (list, id) =>
    list?.find(({ id: selectedItemId }) => selectedItemId === id);

  const selectedBloger = uniqueBloggers(bloggersSelected?.bloggersList, id);
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [changedPrice, setChangedPrice] = useState(selectedBloger?.price || '');
  const [disabledButton, setDisabledButton] = useState(false);
  const [addPrice, setAddPrice] = useState(false);
  const addRef = useRef();

  const deleteOffer = () => {
    deleteOfferByUserId(id, bloggerInformation, changedPrice);
    setSelectedStatus(!selectedStatus);
    setDisabledButton(false);
    setAddPrice(false);
    setChangedPrice('');
  };

  const addOffer = () => {
    addBloggerToSelectedList(bloggerInformation, changedPrice);
    setSelectedStatus(!selectedStatus);
    setDisabledButton(true);
    setAddPrice(false);
  };

  const toggleVisibleAddPrice = () => {
    setAddPrice(!addPrice);
  };
  const _openStatisticsBlogger = () => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.statisticsBlogger,
        details: { id, isFavorite, type },
      }),
    );
  };

  const regexp = /^[0-9\b]+$/;
  const handleChangedPrice = e => {
    e.preventDefault();
    let changedPrice = e.target.value;
    if (changedPrice === '' || regexp.test(changedPrice)) {
      setChangedPrice(changedPrice);
    }
  };

  const handleChangeFavoriteStatus = () => {
    editInstagramApprovedAccountsFavoriteStatus({
      accountId: id,
      status: !isFavorite,
    });
  };

  const offer = offers?.find(({ user }) => user?._id === id);

  return (
    <div className="blogger-card">
      <div className="blogger-card-head">
        <img
          crossOrigin="anonymous"
          src={avatar ? avatar : paywallAvatar}
          className="blogger-card-head__img"
          alt="blooger"
          /*onError={e => {
            e.target.onerror = null;
            e.target.src = paywallAvatar;
          }}*/
        />
        {/*<p className="blogger-card-head__profile">{userName}</p>*/}
        <p className="blogger-card-head__category">
          {accountSubject &&
            t(compareKeyBySelect(accountSubjectOptions, accountSubject))}
        </p>
        <div className="blogger-card-head__links">
          <div className="blogger-card-head__stars">
            <Rating rating={accountQuality} rate={rating} />
          </div>
        </div>
      </div>
      <div className="blogger-card-main">
        <div className="blogger-card__inner-container">
          <div className="blogger-card-main__characteristic">
            <p className="blogger-card-main__characteristic-desc">
              {t('blogger_list_item_subscribers')}
            </p>
            <p className="blogger-card-main__characteristic-value">
              {numberWithSpaces(subscribersCount)}
            </p>
          </div>
          {likes_views &&  
            <div className="blogger-card-main__characteristic">
              <p className="blogger-card-main__characteristic-desc">
                {t('blogger_list_item_views_likes')}
              </p>
              <p className="blogger-card-main__characteristic-value">
                {numberWithSpaces(likes_views)}
              </p>
            </div>
          }
          {comments_views && 
            <div className="blogger-card-main__characteristic">
              <p className="blogger-card-main__characteristic-desc">
                {t('blogger_list_item_views_comments')}
              </p>
              <p className="blogger-card-main__characteristic-value">
                {numberWithSpaces(comments_views)}
              </p>
            </div>
          }
          {
            mean_views &&
            <div className="blogger-card-main__characteristic">
              <p className="blogger-card-main__characteristic-desc">
                {t('blogger_list_item_views_mean')}
              </p>
              <p className="blogger-card-main__characteristic-value">
                {numberWithSpaces(mean_views)}
              </p>
          </div>
          }
          {
            category && 
            <div className="blogger-card-main__characteristic">
              <p className="blogger-card-main__characteristic-desc">
                {t('blogger_list_item_category')}
              </p>
              <p className="blogger-card-main__characteristic-value">
                {numberWithSpaces(category)}
              </p>
            </div>
          }

          { priceForOneThousand &&
            <div className="blogger-card-main__characteristic">
              <p className="blogger-card-main__characteristic-desc">
                {t('blogger_list_item_price_per_views')}
              </p>
              <p className="blogger-card-main__characteristic-value">
                $ {numberWithSpaces(priceForOneThousand)}
              </p>
            </div>
          }
          {
            priceForOneThousand===undefined && price_per_1000 && 
            <div className="blogger-card-main__characteristic">
              <p className="blogger-card-main__characteristic-desc">
                {t('blogger_list_item_price_per_views')}
              </p>
              <p className="blogger-card-main__characteristic-value">
                $ {numberWithSpaces(price_per_1000)}
              </p>
            </div>
          }
        </div>
        <div className="blogger-card-price_per_post">
          <p className="blogger-card-price_per_post-desc">
            {t('blogger_list_item_price_per_post')}
          </p>
          <p className="blogger-card-price_per_post-value">
            $ {numberWithSpaces(pricePerPost)}
          </p>
        </div>
      </div>
      {type === 'tertiary' && (
        <div className="blogger-card-price__tertiary">
          <p className="blogger-card-price__tertiary-desc">
            {t('blogger_list_item_price')}
          </p>
          <p className="blogger-card-price__tertiary-value">
            $ {numberWithSpaces(pricePerPost)}
          </p>
        </div>
      )}
      {(type === 'featured' || type === 'all' || type === 'primary') && (
        <div className="blogger-card-footer">
          <div className="blogger-card-price">
            <p className="blogger-card-price__desc">
              {t('blogger_list_item_price')}
            </p>
            <p className="blogger-card-price__value">
              $ {numberWithSpaces(pricePerPost)}
            </p>
          </div>
          <div className="bargain">
            <span
              ref={addRef}
              onClick={toggleVisibleAddPrice}
              className={selectedBloger ? 'bargain-disabled' : 'bargain-click'}
            >
              {t('blogger_list_item_propose_own_price')}
            </span>
            {addPrice && !disabledButton && (
              <div className="bargain-input">
                <label className="custom-input__label">
                  {t('blogger_list_item_desired_price')}
                </label>
                <input
                  onInput={setChangedPrice}
                  className="custom-input-money"
                  type="number"
                  value={changedPrice}
                  onChange={handleChangedPrice}
                />
              </div>
            )}
            {selectedBloger && (
              <div className="bargain-input-desired">
                <label className="custom-input__label">
                  {t('blogger_list_item_not_desired_price')}
                </label>
                <span className="bargain-input-price">
                  {changedPrice === pricePerPost || changedPrice === ''
                    ? t('blogger_list_item_not_selected')
                    : `$ ${numberWithSpaces(changedPrice)}`}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {(type === 'featured' || type === 'all' || type === 'primary') &&
        (!uniqueBloggers(bloggersSelected?.bloggersList, id) ? (
          <button onClick={addOffer} className="blogger-card-button">
            {t('Select')}
          </button>
        ) : (
          <button
            disabled={offer?.status === 'ACTIVE'}
            onClick={deleteOffer}
            className="blogger-card-button blogger-card-button-selected"
          />
        ))}
    </div>
  );
};

const mapStateToProps = state => ({
  bloggersSelected: SelectedBloggersInformationSelector(state),
  advertisement: newAdvertisementDataSelector(state),
});

const mapDispatchToProps = {
  addBloggerToSelectedList,
  deleteOfferByUserId,
  editInstagramApprovedAccountsFavoriteStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(BloggerCard));
