import './styles.scss';

const Container = ({ name, children }) => {
  return (
    <div className="auth-form-container">
      <div className="auth-form-container__wrapper">
        <div className="auth-form-container__top-decoration" />
        <div className="auth-form-container__inner">
          <div className="auth-form__title-container">
            <h3 className="auth-form__title">{name}</h3>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default Container;
