import * as api from '../../../api/news-service/news-service';
import * as newsActions from './newsActions';
import { changeGlobalLoaderStatus } from '../globalLoader/globalLoaderActions';

// get
export const fetchNewsList =
  (params = {}) =>
  dispatch => {
    dispatch(changeGlobalLoaderStatus(true));
    dispatch(newsActions.getNewsListStart());

    return api
      .getNewsList(params)
      .then(newsList => {
        dispatch(newsActions.getNewsListSuccess(newsList));
      })
      .catch(({ message }) => {
        dispatch(newsActions.getNewsListError(message));
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };

export const fetchNews = id => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  dispatch(newsActions.getNewsStart());

  return api
    .getNewsById(id)
    .then(news => {
      dispatch(newsActions.getNewsSuccess(news));
    })
    .catch(({ message }) => {
      dispatch(newsActions.getNewsError(message));
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
