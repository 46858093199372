import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  clearAllParams,
  clearDateParams,
} from '../../../redux/store/advertisement/advertisement/advertisementActions';
import { allAdvertisementSelector } from '../../../redux/selectors/selectors';
import {
  fetchAdvertisementStatistic,
  editDateFrom,
  editDateTo,
  editOffset,
  editOrder,
  editSort,
} from '../../../redux/store/advertisement/advertisement/AdvertisementOperations';

import MobileAdvertisementStatisticItem from '../../component/AdvertisementStatisticItem/MobileAdvertisementStatisticItem/MobileAdvertisementStatisticItem';
import AdvertisementStatisticItem from '../../component/AdvertisementStatisticItem/AdvertisementStatisticItem';
import IncompleteFunctional from '../../component/IncompleteFunctional/IncompleteFunctional';
import SelectFilter from '../../component/HeaderListBlogger/selectFilter/selectFilter';
import { sortByList, orderByList } from '../../../constants/advertisementList';
import EmptyComponent from '../../component/EmptyComponent/EmptyComponent';
import DatePicker from '../../component/common/DatePicker/DatePicker';
import Pagination from '../../component/common/Pagination/Pagination';

import discharge from '../../../assets/images/vectors/discharge.svg';
import './styles.scss';
import { useGetUserRole } from '../../../hooks/getUserRole';
import { useTranslation } from 'react-i18next';
import { getZeroDate } from '../../../utilites/globalUtilites';

const Statistics = () => {
  const [dateFrom, setDateFrom] = useState({ state: 'initial', value: '' });
  const [dateTo, setDateTo] = useState({ state: 'initial', value: '' });
  const sizeItemOnPage = 10;
  const [t] = useTranslation();

  const role = useGetUserRole();
  const dispatch = useDispatch();

  const {
    advertisementStatistic,
    params: { offset, dateFromParams, dateToParams },
  } = useSelector(allAdvertisementSelector);

  useEffect(() => {
    if (role === 'ADVERTISER') {
      dispatch(fetchAdvertisementStatistic());
    }
    return () => dispatch(clearAllParams());
  }, [dispatch, role]);

  useEffect(() => {
    if (role === 'ADVERTISER') {
      if (!dateFromParams && !dateToParams) {
        setDateFrom({ state: 'initial', value: '' });
        setDateTo({ state: 'initial', value: '' });
      }
    }
  }, [dateFromParams, dateToParams, role]);

  const onChangeSort = ({ value }) => {
    dispatch(editSort(value));
  };

  const onChangeOrder = ({ value }) => {
    dispatch(editOrder(value));
  };

  const onChangeDateFrom = e => {
    setDateFrom({ state: 'changed', value: e });
    dispatch(editDateFrom(dayjs(e).format('YYYY-MM-DD')));
  };

  const onChangeDateTo = e => {
    setDateTo({ state: 'changed', value: e });
    dispatch(editDateTo(dayjs(e).format('YYYY-MM-DD')));
  };

  const noDisabled = dateFrom.state === 'changed' || dateTo.state === 'changed';

  const handleResetDates = () => {
    if (noDisabled) {
      dispatch(clearDateParams());
      setDateFrom({ state: 'initial', value: '' });
      setDateTo({ state: 'initial', value: '' });
      dispatch(fetchAdvertisementStatistic());
    }
  };

  return role === 'BLOGGER' ? (
    ""
  ) : role === 'ADVERTISER' ? (
    <div className="statistics-all">
      <div className="statistics-all__options">
        <span className="statistics-all__options-start-date">
          {t('statistics_start')}
        </span>
        <div className="statistics-all__options-date">
          <span className="statistics-all__options-date-start">
            <span className="statistics-all__options-date-name">
              {t('statistics_table_end')}
            </span>
            <span className="statistics-all__options-date-from">
              {t('statistics_table_from')}
            </span>
          </span>
          <DatePicker
            selected={dateFrom.value}
            onChange={onChangeDateFrom}
            maxDate={dateTo.value ? dateTo.value : getZeroDate()}
          />
          <span className="statistics-all__options-date-to">
            {t('statistics_table_to')}
          </span>
          <DatePicker
            selected={dateTo.value}
            onChange={onChangeDateTo}
            minDate={dateFrom.value}
            maxDate={getZeroDate()}
          />
          <div
            onClick={handleResetDates}
            className={
              !noDisabled
                ? 'statistics-all__options-date-discharge-disabled'
                : 'statistics-all__options-date-discharge'
            }
          >
            <img src={discharge} alt="discharge" />
          </div>
        </div>
        <div className="statistics-all__options-select">
          <div className="statistics-all__options-select-group">
            <div
              onClick={handleResetDates}
              className={
                !noDisabled
                  ? 'statistics-all__options-select-group-discharge-disabled'
                  : 'statistics-all__options-select-group-discharge'
              }
            >
              <img src={discharge} alt="discharge" />
            </div>
            <div className="statistics-all__options-select-group-sort">
              <SelectFilter
                options={sortByList}
                onChange={onChangeSort}
                defaultValue={sortByList[3]}
                type={'sort'}
              />
            </div>
            <div className="statistics-all__options-select-group-sort">
              <SelectFilter
                options={orderByList}
                onChange={onChangeOrder}
                defaultValue={orderByList[0]}
                type={'sortBy'}
              />
            </div>
          </div>
        </div>
      </div>
      {advertisementStatistic.count ? (
        <>
          <MobileAdvertisementStatisticItem
            items={advertisementStatistic.items}
          />
          <table id="statistics">
            <thead>
              <tr>
                <th> {t('statistics_table_start')} </th>
                <th> {t('statistics_table_end')} </th>
                <th> {t('statistics_table_title')} </th>
                <th> {t('statistics_table_id')} </th>
                <th> {t('statistics_table_content_status')} </th>
                <th> {t('statistics_table_status')} </th>
                <th> {t('statistics_table_bloggers')} </th>
                <th> {t('statistics_table_spent')} </th>
                <th />
              </tr>
            </thead>
            {advertisementStatistic.items.map(statistic => (
              <AdvertisementStatisticItem
                key={statistic._id}
                statistic={statistic}
                t={t}
              />
            ))}
          </table>
        </>
      ) : (
        <EmptyComponent
          title={t('statistics_null_title')}
          text={t('statistics_null_text')}
          button={t('statistics_null_button')}
        />
      )}
      <Pagination
        items={advertisementStatistic}
        elementType="page"
        sizeItemOnPage={sizeItemOnPage}
        getNextPageItems={e => {
          dispatch(editOffset(e));
        }}
        isStartedPage={offset}
      />
    </div>
  ) : null;
};

export default Statistics;
