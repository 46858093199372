import * as yup from 'yup';

export const schema = yup.object().shape({
  accountSubject: yup.string().notRequired(),
  audienceGender: yup.string().notRequired(),
  accountGender: yup.string().notRequired(),
  accountType: yup.string().notRequired(),
  subscribersCountGte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
  subscribersCountLte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
  pricePerPostGte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
  pricePerPostLte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
  storiesViewsGte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
  storiesViewsLte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
  audienceReachGte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
  audienceReachLte: yup
    .number()
    .notRequired()
    .positive()
    .transform(value => (isNaN(value) ? undefined : value)),
});
