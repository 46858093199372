export const balanceFormConfig = {
  PAYTM: [
    {
      type: 'account',
      typeInput: 'number',
      labelText: 'finance_blogger_form_PAYTM_label_account',
      placeholderText: 'finance_blogger_form_PAYTM_placeholder_account',
    },
    {
      type: 'channel',
      typeInput: 'text',
      labelText: 'finance_blogger_form_PAYTM_label_email',
      placeholderText: 'finance_blogger_form_PAYTM_placeholder_email',
    },
  ],
  UPI: [
    {
      type: 'account',
      typeInput: 'number',
      labelText: 'finance_blogger_form_UPI_label_account',
      placeholderText: 'finance_blogger_form_UPI_placeholder_account',
    },
    {
      type: 'accountName',
      typeInput: 'text',
      labelText: 'finance_blogger_form_UPI_label_name',
      placeholderText: 'finance_blogger_form_UPI_placeholder_name',
    },
    {
      type: 'bankCode',
      typeInput: 'text',
      labelText: 'finance_blogger_form_UPI_label_code',
      placeholderText: 'finance_blogger_form_UPI_placeholder_code',
    },
    {
      type: 'channel',
      typeInput: 'text',
      labelText: 'finance_blogger_form_UPI_label_channel',
      placeholderText: 'finance_blogger_form_UPI_placeholder_channel',
    },
  ],
};
