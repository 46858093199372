import React from 'react';

import './styles.scss';

const AdvantagesItem = ({ item: { photo, title, subtitle }, t }) => {
  return (
    <div className="advantages-item">
      <img className="advantages-item-img" src={photo} alt="img" />
      <span className="advantages-item-title">{t(title)}</span>
      <span className="advantages-item-text">{t(subtitle)}</span>
    </div>
  );
};

export default AdvantagesItem;
