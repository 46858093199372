export const sortByList = [
  { label: 'statistics_sort_title', value: 'title' },
  { label: 'statistics_sort_status', value: 'status' },
  { label: 'statistics_sort_date', value: 'startDate' },
  { label: 'statistics_sort_ID', value: 'publicId' },
];

export const orderByList = [
  { label: 'statistics_order_high', value: 'desc' },
  { label: 'statistics_order_low', value: 'asc' },
];

export const sortByItem = [
  { label: 'my_campaigns_listBlogger_filter_status', value: 'status' },
  { label: 'my_campaigns_listBlogger_filter_date', value: 'createdDate' },
  { label: 'my_campaigns_listBlogger_filter_publicId', value: 'publicId' },
  { label: 'my_campaigns_listBlogger_filter_price', value: 'price' },
];

export const orderByItem = [
  { label: 'my_campaigns_listBlogger_filter_High', value: 'desc' },
  { label: 'my_campaigns_listBlogger_filter_Low', value: 'asc' },
];

export const statusByItem = [
  { label: 'my_campaigns_listBlogger_All', value: 'ALL' },
  { label: 'my_campaigns_listBlogger_Pending', value: 'PENDING' },
  { label: 'my_campaigns_listBlogger_Active', value: 'ACTIVE' },
  { label: 'my_campaigns_listBlogger_Rejected', value: 'REJECTED' },
  { label: 'my_campaigns_listBlogger_Moderation', value: 'MODERATION' },
  { label: 'my_campaigns_listBlogger_Completed', value: 'COMPLETED' },
  { label: 'my_campaigns_listBlogger_Unresolved', value: 'UNRESOLVED' },
  { label: 'my_campaigns_listBlogger_Failed', value: 'FAILED' },
];
