import * as api from '../../../../api/advertisement-service/advertisement-service';

import * as globalLoaderActions from '../../globalLoader/globalLoaderActions';
import * as baseInformationActions from './baseInformationActions';

import { changeCreationAdvertisementStep } from '../creationStatus/creationStatusActions';
import { fetchAdvertisement } from '../advertisement/AdvertisementOperations';
import { addNewStepInformation } from '../creationData/creationDataActions';

import { advertisementCreationSteps } from '../../../../constants/advertisementCreationSteps';
import { sendStoryDependingOfType } from '../../../../helpers/sendStoryDependingOfType';
import { correctBaseInformation } from '../../../../helpers/updateBaseInfoData';
import { createFormData } from '../../../../helpers/formDataConvertor';
import book from '../../../../views/router/book';
import { setNotifyStatus } from '../../notify/notifyActions';
import {
  newAdvertisementDataSelector,
  storyFormSelector,
} from '../../../selectors/selectors';
import { setErrorHandler } from '../../errorHandler/errorHandlerOperations';

export const nextStepWithoutSendingData = () => dispatch => {
  dispatch(baseInformationActions.addBaseAdvertisementInfoStart());

  dispatch(
    changeCreationAdvertisementStep({
      activePage: advertisementCreationSteps.choiceBloggers,
      activeStep: 1,
      doneSteps: [0],
    }),
  );
};

export const sendBaseInformationForm = (
  data = {},
  storyItem,
  history,
) => dispatch => {
  dispatch(baseInformationActions.addBaseAdvertisementInfoStart());
  dispatch(globalLoaderActions.changeButtonLoaderStatus(true));

  api
    .postAdvertisementBaseInfo(correctBaseInformation(storyItem, data))
    .then(response => {
      const { _id } = response;

      const story = sendStoryDependingOfType(storyItem, storyItem.contentType);
      const changedStoryItem = createFormData(story);
      console.log(story)
      return api
        .postAdvertisementStories(_id, changedStoryItem)
        .then(response => {
          const {
            title,
            endDate,
            banner,
            campaign,
            startDate,
            story,
          } = response;
          const baseInfoData = {
            title,
            endDate,
            banner,
            campaign,
            startDate,
          };
          dispatch(
            baseInformationActions.addBaseAdvertisementInfoSuccess(
              baseInfoData,
            ),
          );
          dispatch(addNewStepInformation(response));
          dispatch(baseInformationActions.setStoriesList(story));
          dispatch(
            setNotifyStatus({
              title: 'Your advertisement sent for moderation',
              type: 'success',
            }),
          );
          history.push(book.statistics);
          dispatch(baseInformationActions.clearBaseInformation());
        })
        .catch(r => dispatch(setErrorHandler(r)));
    })
    .catch(({ message }) => {
      dispatch(baseInformationActions.addBaseAdvertisementInfoError(message));
    })
    .finally(() =>
      dispatch(globalLoaderActions.changeButtonLoaderStatus(false)),
    );
};

export const changeBaseInformationForm = (id, data = {}) => (
  dispatch,
  getState,
) => {
  const changedBaseInfoDataFormat = createFormData(data);
  const creationData = newAdvertisementDataSelector(getState());
  const budget = creationData.budget && creationData.budget;
  dispatch(baseInformationActions.addBaseAdvertisementInfoStart());
  dispatch(globalLoaderActions.changeButtonLoaderStatus(true));

  return api
    .putAdvertisementById(id, changedBaseInfoDataFormat)
    .then(advertisement => {
      dispatch(addNewStepInformation({ ...advertisement, budget }));
      dispatch(
        changeCreationAdvertisementStep({
          activePage: advertisementCreationSteps.choiceBloggers,
          doneSteps: [0],
          activeStep: 1,
        }),
      );
      dispatch(
        setNotifyStatus({
          title: 'Your advertisement was successfully edited',
          type: 'success',
        }),
      );
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally(() =>
      dispatch(globalLoaderActions.changeButtonLoaderStatus(false)),
    );
};

export const changeAllInformationForm = (
  id,
  data = {},
  storyItem,
  actualStory,
  history,
) => (dispatch, getState) => {
  dispatch(baseInformationActions.addBaseAdvertisementInfoStart());
  dispatch(globalLoaderActions.changeButtonLoaderStatus(true));
  const { _id: storyId } = storyFormSelector(getState());

  api
    .putAdvertisementById(id, correctBaseInformation(storyItem, data))
    .then(() => {
      const story = sendStoryDependingOfType(
        storyItem,
        actualStory.contentType,
      );
      const changedStoryItem = createFormData(story);

      return api
        .putAdvertisementStories(storyId, changedStoryItem)
        .then(advertisement => {
          dispatch(addNewStepInformation(advertisement));
          history.push(book.statistics);
          dispatch(
            setNotifyStatus({
              title: 'Your advertisement sent for moderation again',
              type: 'success',
            }),
          );
          dispatch(fetchAdvertisement(id));
          dispatch(baseInformationActions.clearBaseInformation());
        })
        .catch(r => dispatch(setErrorHandler(r)));
    })
    .catch(r => dispatch(setErrorHandler(r)))
    .finally(() =>
      dispatch(globalLoaderActions.changeButtonLoaderStatus(false)),
    );
};
