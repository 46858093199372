import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { clearInformationAdboutAdvertisement } from '../../../redux/store/advertisement/advertisement/AdvertisementOperations';
import book from '../../router/book';

import emptyResult from '../../../assets/images/error/emptyResult.svg';
import './styles.scss';

const EmptyComponent = ({ title, text, button }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const createAdvertisement = () => {
    dispatch(clearInformationAdboutAdvertisement());
    history.push(book.basicInformation);
  };

  return (
    <div className="empty-result">
      <img src={emptyResult} className="empty-result-img" alt="empty result" />
      <span className="empty-result-title">{title}</span>
      <span className="empty-result-text">{text}</span>
      {button && (
        <button onClick={createAdvertisement} className="empty-result-btn">
          {button}
        </button>
      )}
    </div>
  );
};

export default EmptyComponent;
