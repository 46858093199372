import types from './campaignTypes';

// get
export const getCampaignListStart = () => ({
  type: types.GET_CAMPAIGN_LIST_START,
});

export const getCampaignListSuccess = data => ({
  type: types.GET_CAMPAIGN_LIST_SUCCESS,
  payload: data,
});

export const getCampaignListError = (error = '') => ({
  type: types.GET_CAMPAIGN_LIST_ERROR,
  payload: error,
});

export const getCampaignStart = () => ({
  type: types.GET_CAMPAIGN_START,
});

export const getCampaignSuccess = data => ({
  type: types.GET_CAMPAIGN_SUCCESS,
  payload: data,
});

export const getCampaignError = (error = '') => ({
  type: types.GET_CAMPAIGN_ERROR,
  payload: error,
});

// post
export const postCampaignStart = () => ({
  type: types.POST_CAMPAIGN_START,
});

export const postCampaignSuccess = data => ({
  type: types.POST_CAMPAIGN_SUCCESS,
  payload: data,
});

export const postCampaignError = (error = '') => ({
  type: types.POST_CAMPAIGN_ERROR,
  payload: error,
});

// edit
export const editCampaignStart = () => ({
  type: types.EDIT_CAMPAIGN_START,
});

export const editCampaignSuccess = data => ({
  type: types.EDIT_CAMPAIGN_SUCCESS,
  payload: data,
});

export const editCampaignError = (error = '') => ({
  type: types.EDIT_CAMPAIGN_ERROR,
  payload: error,
});

// delete
export const deleteCampaignStart = () => ({
  type: types.DELETE_CAMPAIGN_START,
});

export const deleteCampaignSuccess = id => ({
  type: types.DELETE_CAMPAIGN_SUCCESS,
  payload: id,
});

export const deleteCampaignError = (error = '') => ({
  type: types.DELETE_CAMPAIGN_ERROR,
  payload: error,
});

// selected campaign
export const selectedCampaign = data => ({
  type: types.SELECTED_CAMPAIGN,
  payload: data,
});

export const deleteSelectedCampaign = () => ({
  type: types.DELETE_SELECTED_CAMPAIGN,
});

// set
export const setOffset = data => ({
  type: types.SET_OFFSET,
  payload: data,
});

export const setSortType = data => ({
  type: types.SET_SORT_TYPE,
  payload: data,
});

export const setSortItemType = data => ({
  type: types.SET_SORT_ITEM_TYPE,
  payload: data,
});

export const setOrderType = data => ({
  type: types.SET_ORDER_TYPE,
  payload: data,
});

export const setStatus = data => ({
  type: types.SET_STATUS,
  payload: data,
});

export const clearAllParams = () => ({
  type: types.CLEAR_ALL_PARAMS,
});
