const types = {
  ADD_DATA_TO_ADVERTISEMENT: 'advertisement/ADD_DATA_TO_ADVERTISEMENT',

  SET_STORIES_LIST: 'advertisement/SET_STORIES_LIST',

  ADD_BASE_ADVERTISEMENT_INFO_START:
    'advertisement/ADD_BASE_ADVERTISEMENT_INFO_START',
  ADD_BASE_ADVERTISEMENT_INFO_SUCCESS:
    'advertisement/ADD_BASE_ADVERTISEMENT_INFO_SUCCESS',
  ADD_BASE_ADVERTISEMENT_INFO_ERROR:
    'advertisement/ADD_BASE_ADVERTISEMENT_INFO_ERROR',
  ADD_BASE_STORIES_INFO_START: 'advertisement/ADD_BASE_STORIES_INFO_START',
  ADD_BASE_STORIES_INFO_SUCCESS: 'advertisement/ADD_BASE_STORIES_INFO_SUCCESS',
  ADD_BASE_STORIES_INFO_ERROR: 'advertisement/ADD_BASE_STORIES_INFO_ERROR',

  // clear
  CLEAR_BASE_INFORMATION: 'advertisement/CLEAR_BASE_INFORMATION',
  CLEAR_INFORMATION_STORIES: 'advertisement/CLEAR_INFORMATION_STORIES',

  // set
  SET_BANNER: 'advertisement/SET_BANNER',
};

export default types;
