import React from 'react';

import { config } from './config';

import './styles.scss';

const WorkMobile = ({ t }) => {
  return (
    <div className="work-mobile">
      <div className="work-mobile__left">
        <div className="work-mobile__left-first">01</div>
        <div className="work-mobile__left-line"></div>
        <div className="work-mobile__left-first">02</div>
        <div className="work-mobile__left-line"></div>
        <div className="work-mobile__left-first">03</div>
      </div>
      <div className="work-mobile__right">
        {config.map(({ title, subtitle, img }, index) => (
          <div key={index} className="work-mobile__right-item">
            <h1 className="work-mobile__right-item-title">{t(title)}</h1>
            <h2 className="work-mobile__right-item-subtitle">{t(subtitle)}</h2>
            <img src={img} alt="img" className="work-mobile__right-item-img" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkMobile;
