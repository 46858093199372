import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderListBlogger from '../../component/HeaderListBlogger/HeaderListBlogger';
import { modalTypes } from '../../../configs/modalTypes';
import { openModal } from '../../../redux/store/modal/modalActions';
import OfferCardNew from '../../component/OfferCardNew/OfferCardNew';
import { withTranslation } from 'react-i18next';
import {getAllOffers, changeOfferStatusById} from '../../../api/offer-service/offer-service'
import {
  editDirection,
  editSearch,
  editSort
} from '../../../redux/store/instagram/instagramOperations';
import {
  getOptionsSearchSelector
} from '../../../redux/store/instagram/instagramSelector';
import './Offers.scss';
import { connect } from 'react-redux';

// import IncompleteFunctional from '../../component/IncompleteFunctional/IncompleteFunctional';
{/* <IncompleteFunctional page="offers" />; */}

const Offers = () => {

  const [userNameValue, setUserNameValue] = useState('');
  const dispatch = useDispatch();
  const prevSearchValue = useSelector(getOptionsSearchSelector);
  const [currentSelected, setCurrentSelected] = useState(0);
  const [mapOffers,setmapOffers] = useState([])
  const [refresh, refreshState] = useState(1)
  useEffect(()=>{
    getAllOffers().then((res)=>{
      setmapOffers(res.items)
    })
  },[refresh])

  const _onChengSort = ({ value }) => {
    dispatch(editSort(value));
  };

  const _onClickSearch = () => {
    if (prevSearchValue !== userNameValue) {
      dispatch(editSearch(userNameValue));
    }
  };

  const _onChengSearch = ({ target }) => {
    setUserNameValue(target.value);
  };

  const _onChengDirection = ({ value }) => {
    dispatch(editDirection(value));
  };

  const _onClickFilter = (e) => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.manualSearchOffersFilter,
      }),
    );
  };

  const _onClickText = (title, content, id) => {
    setCurrentSelected(id)
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.showOfferText,
        details: {title: title, text: content, onClick: ()=>{}, buttonText: "Close"}
      }),
    );
  };
  const _onClickDone = (e) => {
    setCurrentSelected(e)

    let offer = mapOffers.filter((val)=>{
      if (val._id === e) return true;
      return false;
    })[0]
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.showAddCompletedLink,
        details: {
          title: "Send link of result"
          , onLoad:() => {
                getAllOffers().then((res)=>{
                setmapOffers(res.items)
              })
            }
          , refreshState: refreshState
          , refresh:refresh
          , text:mapOffers.filter((val)=>{
          return val._id == e
        })[0].numText, _id: offer._id, onClick: ()=>{console.log(e)
        }, buttonText: "Close"}
      }),
    );
  };
  const _onClickUpload = (e) => {
    setCurrentSelected(e)

    let offer = mapOffers.filter((val)=>{
      if (val._id === e) return true;
      return false;
    })[0]
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.showOfferDemoDownload,
        details: {
          title: "Загрузка демо-ролика"
          , onLoad:() => {
                getAllOffers().then((res)=>{
                setmapOffers(res.items)
              })
            }
          , refreshState: refreshState
          , refresh:refresh
          , text:mapOffers.filter((val)=>{
          return val._id == e
        })[0].numText, _id: offer._id, onClick: ()=>{console.log(e)
        }, buttonText: "Закрыть"}
      }),
    );
  };
  const _onClickComplete = (e) => {
    setCurrentSelected(e)
    mapOffers.map((val)=>{
      let t = val 
      if (val._id === e) changeOfferStatusById(val._id, {status: "MODERATION", comment: "in moderation"});
      return t;
    })
    getAllOffers().then((res)=>{
      setmapOffers(res.items)
    })
  };

  const _onClickAccept = (e) => {
    setCurrentSelected(e)
    mapOffers.map((val)=>{
      let t = val 
      if (val._id === e) changeOfferStatusById(val._id, {status: "ACTIVE", comment: "in progress"});
      return t;
    })
    getAllOffers().then((res)=>{
      setmapOffers(res.items)
    })
  };

  const _onClickDecline = (e) => {
    setCurrentSelected(e)
    mapOffers.map((val)=>{
      let t = val 
      if (val._id === e) changeOfferStatusById(val._id, {status: "REJECTED", comment: "rejected"});
      return t;
    })
    getAllOffers().then((res)=>{
      setmapOffers(res.items)
    })
  };
  return (
    <div className="offers">
      <HeaderListBlogger
        onClickSearch={_onClickSearch}
        onChengSearch={_onChengSearch}
        onChengDirection={_onChengDirection}
        onChengSort={_onChengSort}
        onClickFilter={_onClickFilter}
      />
      <div className="offers__list">
          {mapOffers && mapOffers.length && mapOffers.map((offer)=>{
              return <OfferCardNew refreshState={refreshState} refresh={refresh} key={mapOffers._id} offer={offer} _id={offer._id} _onClickUpload={_onClickUpload} _onClickText={_onClickText} _onClickComplete={_onClickDone} _onClickAccept={_onClickAccept} _onClickDecline={_onClickDecline}/>
            })
          }
      </div>
    </div>
  )
};

export default withTranslation()(Offers);
