import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Select from '../../common/Fields/Select/Select';
import Button from '../../common/Button/Button';
import FormContainer from '../../common/FormContainer/FormContainer';


import '../general.scss';
import './styles.scss';
import { filterInfo } from '../../../../redux/store/instagram/instagramOperations';
import { useDispatch, useSelector } from 'react-redux';
import { getOptionsFilterSelector } from '../../../../redux/store/instagram/instagramSelector';
import { closeModal } from '../../../../redux/store/modal/modalActions';
import { dataBloggerTypeInfo } from '../../../../configs/typeBloger';
import { withTranslation } from 'react-i18next';
import { dataOfferTypeInfo } from '../../../../configs/typeOffer';

const OffersFilteringForm = ({ type, t }) => {
  const formData = useSelector(getOptionsFilterSelector);
  const {
    register,
    handleSubmit,
    reset,
    formState: {},
  } = useForm({
    defaultValues: formData,
  });
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    offerType: "ACTIVE"
  });
  const onSubmit = data => {
    dispatch(filterInfo({ ...data, ...value }, type));
    dispatch(closeModal());
  };

  const handleFormClear = () => reset({});
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="base-info-form__container"
    >
      <section className="form-section">
        <FormContainer button={''}>
          <section className="form-section">
            <div className="form-block-container">
              <div className="form-block form-block-part">
                <Select
                  listOfOptions={dataOfferTypeInfo.offerType}
                  inputChangeOptions={register('offerType')}
                  options={{
                    labelText: t('blogger_list_sort_account_type'),
                  }}
                />
              </div>
            </div>
            <div className="form-block-container">
              <div className="bloggers-filtering-form__buttons">
                <div className="bloggers-filtering-form__buttons-first">
                  <Button
                    handleButtonClick={handleFormClear}
                    options={{
                      text: t('blogger_list_sort_clear'),
                      renderType: 'secondary',
                      size: 's',
                    }}
                  />
                </div>
                <Button
                  options={{
                    text: t('blogger_list_sort_proceed'),
                    renderType: 'primary',
                    size: 's',
                    type: 'submit',
                  }}
                />
              </div>
            </div>
          </section>
        </FormContainer>
      </section>
    </form>
  );
};

export default withTranslation()(OffersFilteringForm);
