import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setFcmToken } from '../../../../redux/store/session/sessionActions';

import { getToken } from '../../../../helpers/firebase/initPushNotifications';

const Notifications = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let fcmToken = '';
    async function tokenFunc() {
      fcmToken = await getToken(dispatch);
      if (fcmToken) {
        dispatch(setFcmToken(fcmToken));
      }
      return fcmToken;
    }
    tokenFunc();
  }, [dispatch]);

  return <></>;
};

export default Notifications;
