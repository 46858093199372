import React from 'react';

import './styles.scss';

const ContentFields = ({ options = {}, handleClick = {} }) => {
  const { title = '', subtitle = '', activeSubtitle, file = '' } = options;

  const video = file?.includes('mp4') || file?.includes('mov');
  const image =
    file?.includes('png') ||
    file?.includes('jpg') ||
    file?.includes('gif') ||
    file?.includes('jpeg');

  return (
    <>
      <div className="campaign__left-top-container-item">
        <div className="campaign__left-top-container-item-name">{title}</div>
        <div
          className={
            activeSubtitle
              ? 'campaign__left-top-container-item-info-active'
              : 'campaign__left-top-container-item-info'
          }
        >
          {subtitle}
          {activeSubtitle}
          {video && (
            <video
              onClick={handleClick}
              className="campaign__left-top-container-item-video"
              alt="file"
              controls
            >
              <source src={file} />
            </video>
          )}
          {image && (
            <img
              onClick={handleClick}
              className="campaign__left-top-container-item-image"
              src={file}
              alt="file"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContentFields;
