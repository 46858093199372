import React, { useState } from 'react';
import FormContainer from '../../component/common/FormContainer/FormContainer';
import walletSVG from '../../../assets/images/vectors/wallet.svg';
import clockSVG from '../../../assets/images/vectors/clock.svg';
import dolsrs from '../../../assets/images/vectors/dolsrs.svg';
import './styles.scss';
import { useSelector } from 'react-redux';

import WithdrawForm from './balanceForm/withdrawForm/WithdrawForm';
import RefillForm from './balanceForm/refillForm/refillForm';
import TransactionHistoryTable from './transactionHistoryTable/transactionHistoryTable';
import { useTranslation } from 'react-i18next';

const Finance = () => {
  const [userRole] = useState(() => {
    const accaunt = localStorage.getItem('accaunt');
    return accaunt && JSON.parse(accaunt).role;
  });
  const { advertiserBudget, bloggerBudget } = useSelector(
    state => state.finance,
  );
  const [t] = useTranslation();

  return (
    <div className="finances-page">
      <section className="finances-page__balance-container">
        <FormContainer
          options={{ head: true, title: t('finance_advertiser_form_balance') }}
          button={null}
        >
          {userRole === 'BLOGGER' ? <WithdrawForm /> : <RefillForm />}
        </FormContainer>
        <div className="finances-page__balance-current-container">
          <FormContainer>
            <div className="user-balance">
              <div className="user-balance__content-container">
                <p className="user-balance__content-title">
                  {t(
                    userRole === 'BLOGGER'
                      ? 'finance_blogger_balance'
                      : 'finance_advertiser_balance',
                  )}
                </p>
                <p className="user-balance__content-value">
                  $
                  {userRole === 'BLOGGER'
                    ? bloggerBudget.availableToWithdrawal
                    : advertiserBudget.available}
                </p>
              </div>
              <img src={walletSVG} className="user-balance__icon" alt={''} />
            </div>
          </FormContainer>
          <FormContainer>
            <div className="user-balance">
              <div className="user-balance__content-container">
                <p className="user-balance__content-title">
                  {userRole === 'BLOGGER'
                    ? t('finance_blogger_active_offers')
                    : t('finance_advertiser_hold')}
                </p>
                <p className="user-balance__content-value">
                  $
                  {userRole === 'BLOGGER'
                    ? bloggerBudget.hold
                    : advertiserBudget.hold}
                </p>
              </div>
              <img src={clockSVG} className="user-balance__icon" alt={''} />
            </div>
          </FormContainer>
          {userRole === 'BLOGGER' ? (
            <FormContainer>
              <div className="user-balance">
                <div className="user-balance__content-container">
                  <p className="user-balance__content-title">
                    {t('finance_blogger_expected_earnings')}
                  </p>
                  <p className="user-balance__content-value">
                    ${bloggerBudget.requestedToWithdrawal}
                  </p>
                </div>
                <img src={dolsrs} className="user-balance__icon" alt={''} />
              </div>
            </FormContainer>
          ) : null}
        </div>
      </section>
      <TransactionHistoryTable userRole={userRole} t={t} />
    </div>
  );
};

export default Finance;
