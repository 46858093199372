import React from 'react';
import './styles.scss';

import Company from '../../../assets/images/vectors/company.svg';

const StepDescription = ({ options = {} }) => {
  const { title = '', description = '' } = options;

  return (
    <div className="step-description__container">
      <p className="step-description__title">{title}</p>
      <p className="step-description__description">{description}</p>
      <img
        className="step-description__img"
        src={Company}
        alt="house and trees"
      />
    </div>
  );
};

export default StepDescription;
