import React from 'react';

import StepsDescription from '../../../../component/StepsDescription/StepDescription';
import AdsSearchForm from '../../../../component/Forms/Company/AdsSearchForm/AdsSearchForm';

const AdsSearch = () => {
  return (
    <div>
      <div className="new-company__create">
        <div className="new-company__left">
          <AdsSearchForm />
        </div>
        <div className="new-company__right">
          <StepsDescription
            options={{
              title: 'Bloggers pick',
              description:
                'Add ready examples of creatives to help bloggers in advertisement creation',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdsSearch;
