export const contentTypesSelectOptions = [
  { text: 'advertisement_BaseInfo_Improvisation', key: 'IMPROVISATION' },
  { text: 'advertisement_BaseInfo_Custom', key: 'CUSTOM' },
];

export const contentTypesSelectDescription = {
  IMPROVISATION: 'advertisement_BaseInfo_type_IMPROVISATION',
  CUSTOM: 'advertisement_BaseInfo_type_CUSTOM',
};

export const improvisavionTypeDescriptions = {
  PHOTO_SELECT: 'advertisement_BaseInfo_PHOTO_SELECT',
  POST_REPOST: 'advertisement_BaseInfo_POST_REPOST',
  DISCRETION: 'advertisement_BaseInfo_DISCRETION',
  RECORD_A_LIVE_VIDEO: 'advertisement_BaseInfo_RECORD_A_LIVE_VIDEO',
};

export const improvisavionTypeSelectOptions = [
  {
    text: 'advertisement_BaseInfo_improvisation_item_1',
    key: 'PHOTO_SELECT',
  },
  {
    text: 'advertisement_BaseInfo_improvisation_item_2',
    key: 'POST_REPOST',
  },
  {
    text: 'advertisement_BaseInfo_improvisation_item_3',
    key: 'DISCRETION',
  },
  {
    text: 'advertisement_BaseInfo_improvisation_item_4',
    key: 'RECORD_A_LIVE_VIDEO',
  },
];

export const contentTypesSortBloggerOptions = [
  { label: 'blogger_list_sort_subscribers', value: 'subscribersCount' },
  { label: 'blogger_list_sort_price_per_post', value: 'pricePerPost' },
  { label: 'blogger_list_sort_stories_per_day', value: 'storiesPerDay' },
  { label: 'blogger_list_sort_quality', value: 'accountQuality' },
  { label: 'blogger_list_sort_audience_reach', value: 'audienceReach' },
  {
    label: 'blogger_list_sort_price_for_thousand',
    value: 'priceForOneThousand',
  },
  { label: 'blogger_list_sort_recommended_price', value: 'recommendedPrice' },
  { label: 'blogger_list_sort_stories_views', value: 'storiesViews' },
];
export const contentTypesSortCpaOfferOptions = [
  { label: 'blogger_list_sort_price', value: 'price' },
];
export const contentTypesSortFinance = [
  { label: 'Date', value: 'createDate' },
  { label: 'Amount', value: 'amount' },
  { label: 'Type', value: 'type' },
];
export const typeSort = [
  { label: 'cpa_offers_list_type_sort_high', value: '1' },
  { label: 'cpa_offers_list_type_sort_Low', value: '-1' },
];

export const typeSortFinance = [
  { label: 'High first', value: 'desc' },
  { label: 'Low first', value: 'asc' },
];
export const contentTypesChoiceOfBloggers = [
  { text: 'Autosearch', key: 'AUTO_SEARCH' },
  { text: 'Manual search', key: 'CUSTOM_SEARCH' },
  { text: 'Favorite bloggers', key: 'FAVORITE_BLOGGERS' },
];

export const accountSubjectOptions = [
  { text: 'blogger_list_type_personal_pages', key: 'PERSONAL_PAGES' },
  { text: 'blogger_list_type_family', key: 'FAMILY' },
  { text: 'blogger_list_type_animal', key: 'ANIMAL' },
  { text: 'blogger_list_type_sport_health', key: 'SPORT_HEALTH' },
  { text: 'blogger_list_type_travel', key: 'TRAVEL' },
  { text: 'blogger_list_type_photographer', key: 'PHOTOGRAPHER' },
  { text: 'blogger_list_type_auto_moto', key: 'AUTO_MOTO' },
  { text: 'blogger_list_type_fashion_beauty', key: 'FASHION_BEAUTY' },
  { text: 'blogger_list_type_entertainment', key: 'ENTERTAINMENT' },
  { text: 'blogger_list_type_blog', key: 'BLOG' },
  { text: 'blogger_list_type_mass_media', key: 'MASS_MEDIA' },
  { text: 'blogger_list_type_other', key: 'OTHER' },
];
