import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import WhiteLogo from '../../../../../../../assets/images/landing/header/bloggerly-white.svg';
import DarkLogo from '../../../../../../../assets/images/landing/header/bloggerly-black.svg';
import './styles.scss';

const Burger = ({ open, setOpen, referrals, book }) => {
  const [height, setHeight] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);

    return () => window.removeEventListener('scroll', checkScroll);
  });

  const checkScroll = () => {
    if (!height && window.pageYOffset > 200) {
      setHeight(true);
    } else if (height && window.pageYOffset <= 200) {
      setHeight(false);
    }
  };

  const handleShowBurger = () => {
    return `burger__item ${
      open
        ? `${referrals ? 'burger__item-open-black' : 'burger__item-open-white'}`
        : `${
            referrals ? 'burger__item-close-black' : 'burger__item-close-white'
          }`
    }`;
  };

  return (
    <>
      <div
        className={
          open
            ? 'burger-container-logo-hide'
            : `${
                referrals
                  ? `${
                      height
                        ? 'burger-container-logo-dark-show'
                        : 'burger-container-logo-dark'
                    }`
                  : `${
                      height
                        ? 'burger-container-logo-show'
                        : 'burger-container-logo'
                    }`
              }`
        }
      >
        <Link to={book.advertisers}>
          <img src={referrals ? DarkLogo : WhiteLogo} alt="bloggerly" />
        </Link>
      </div>
      <div className="burger" open={open} onClick={() => setOpen(!open)}>
        <div className={handleShowBurger()} />
        <div className={handleShowBurger()} />
        <div className={handleShowBurger()} />
      </div>
    </>
  );
};

export default Burger;
