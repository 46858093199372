import React from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../../../../redux/store/modal/modalActions';

import { modalTypes } from '../../../../../configs/modalTypes';

import paywall from '../../../../../assets/images/vectors/paywall.svg';
import './styles.scss';

const Paywall = ({ title, type = 'all', t }) => {
  const dispatch = useDispatch();

  return (
    <div className="paywall-content">
      <div className="paywall-content__img">
        <img src={paywall} alt="paywall" />
      </div>
      <p className="paywall-content__title">{t('blogger_list_limited')}</p>
      <p className="paywall-content__text">{t(title)}</p>
      <button
        className="paywall-content__btn custom-btn"
        onClick={() =>
          dispatch(
            openModal({
              isOpen: true,
              renderType: modalTypes.quickRefillPopUp,
              details: type,
            }),
          )
        }
      >
        {t('blogger_list_quick_refill')}
      </button>
    </div>
  );
};

export default Paywall;
