import types from './creationDataTypes';

export const addNewStepInformation = payload => ({
  type: types.ADD_NEW_STEPS_INFORMATION,
  payload,
});

export const editAdvertisementStart = () => ({
  type: types.EDIT_ADVERTISEMENT_START,
});

export const editAdvertisementSuccess = (data = {}) => ({
  type: types.EDIT_ADVERTISEMENT_SUCCESS,
  payload: data,
});

export const editAdvertisementError = (error = '') => ({
  type: types.EDIT_ADVERTISEMENT_ERROR,
  payload: error,
});

export const postAdvertisementStart = () => ({
  type: types.POST_ADVERTISEMENT_START,
});

export const postAdvertisementSuccess = (data = {}) => ({
  type: types.POST_ADVERTISEMENT_SUCCESS,
  payload: data,
});

export const postAdvertisementError = (error = '') => ({
  type: types.POST_ADVERTISEMENT_ERROR,
  payload: error,
});

export const clearAdvertisementInfoAfterPost = () => ({
  type: types.CLEAR_ADVERTISEMENT_INGO_AFTER_POST,
});
