import React from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../../redux/store/modal/modalActions';

import FormContainer from '../../component/common/FormContainer/FormContainer';
import { modalTypes } from '../../../configs/modalTypes';
import Button from '../common/Button/Button';

import './styles.scss';

const InsufficientFundsModal = () => {
  const dispatch = useDispatch();

  return (
    <FormContainer>
      <div className="insufficient-funds">
        <h3 className="insufficient-funds__title">
          Insufficient funds. Please top up your balance to pick the targeting.
          <br /> You can use quick refill button.
        </h3>
        <Button
          handleButtonClick={() =>
            dispatch(
              openModal({
                isOpen: true,
                renderType: modalTypes.quickRefillPopUp,
              }),
            )
          }
          options={{ text: 'Quick refill' }}
        />
      </div>
    </FormContainer>
  );
};

export default InsufficientFundsModal;
