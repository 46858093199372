import React, { useState } from 'react';
import Button from '../../../component/common/Button/Button';
import './styles.scss';
import Input from '../../../component/common/Fields/Input/Input';
import Select from '../../../component/common/Fields/Select/Select';
import { useDispatch } from 'react-redux';
import { instagramAccountCreate } from '../../../../redux/store/accountBlogger/accountBloggerOperations';
import instagramImg from '../../../../assets/images/instagram/instagram.png';
import { withTranslation } from 'react-i18next';

const AddedNewInstagramAccount = ({ t }) => {
  const [nikName, setNikName] = useState();
  const [price, setPrice] = useState();
  const [accountType, setAccountType] = useState("");
  const _onChange = ({ target }) => {
    setNikName(target.value);
  };
  const _onChangePrice = ({ target }) => {
    setPrice(target.value);
  };
  const dispatch = useDispatch();
  const _handleButtonClick = () => {
    dispatch(instagramAccountCreate(nikName, price, accountType));
  };
  const _openInstagramLogin = () => {
    const developmentLink =
      'https://backend-dev.bloggerly.lampawork.com/api/v1';
    const productionLink = 'https://backend.bloggerly.io/api/v1';
    const environment = process.env.REACT_APP_ENV;

    const baseLink =
      environment === 'production' ? productionLink : developmentLink;
    let appId = '266260932002906';
    let redUri = baseLink + '/instagram/new-create-account';
    let url = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redUri}&scope=user_profile,user_media&response_type=code`;
    document.cookie = 'path = test;';
    window.open(url, '_parent').focus();
  };
  const getIconInstagram = () => (
    <>
      <p className="add_instagram_account__or">
        {t('account_added_account_or')}
      </p>
      <div className="add_instagram_account__button-insta">
        <img src={instagramImg} onClick={_openInstagramLogin} alt={''} />
      </div>
    </>
  );
  return (
    <div className="add_instagram_account">
      <Select
        handlerOnChange={({ target: { value } }) => setAccountType(value)}
        listOfOptions={[{ text: 'Select service', key: 'Select service' },  { text: 'Youtube', key: 'Youtube' }, { text: 'Instagram', key: 'Instagram' }]}
      />
      {accountType === "Youtube" && 
        <Input 
          inputChangeOptions={{
            onChange: _onChange,
          }}
          options={{
            labelText: t('account_added_account_label'),
            placeholderText: t('account_added_account_placeholder'),
            isStory: true,
            width: '500px'
          }}
        />
      }

      {accountType === "Youtube" && 
        <Input 
          inputChangeOptions={{
            onChange: _onChangePrice,
          }}
          options={{
            labelText: t('account_added_account_price_label'),
            placeholderText: t('account_added_account_price_placeholder'),
            isStory: true,
            width: '500px'
          }}
        />
      }
      {accountType === "Instagram" && 
        <Input 
          inputChangeOptions={{
            onChange: _onChange,
          }}
          options={{
            labelText: t('account_added_account_instagram_label'),
            placeholderText: t('account_added_account_instagram_placeholder'),
            isStory: true,
            width: '500px'
          }}
        />
      }

      {accountType === "Instagram" && 
        <Input 
          inputChangeOptions={{
            onChange: _onChangePrice,
          }}
          options={{
            labelText: t('account_added_account_price_label'),
            placeholderText: t('account_added_account_price_placeholder'),
            isStory: true,
            width: '500px'
          }}
        />
      }
      <Button
        options={{
          isIcon: false,
          renderType: 'primary',
          text: t('account_added_account_button'),
          type: 'submit',
        }}
        handleButtonClick={_handleButtonClick}
      />
    </div>
  );
};

export default withTranslation()(AddedNewInstagramAccount);
