import Calendar from '../../../../../../assets/images/landing/advantages/calendar.svg';
import Exchange from '../../../../../../assets/images/landing/advantages/exchange.svg';
import Convert from '../../../../../../assets/images/landing/advantages/convert.svg';
import Search from '../../../../../../assets/images/landing/advantages/search.svg';
import Time from '../../../../../../assets/images/landing/advantages/time.svg';
import Like from '../../../../../../assets/images/landing/advantages/like.svg';

export const advertisers = [
  {
    photo: Like,
    title: 'advertisers_landing_item_1_title',
    subtitle: 'advertisers_landing_item_1_subtitle',
  },
  {
    photo: Convert,
    title: 'advertisers_landing_item_2_title',
    subtitle: 'advertisers_landing_item_2_subtitle',
  },
  {
    photo: Calendar,
    title: 'advertisers_landing_item_3_title',
    subtitle: 'advertisers_landing_item_3_subtitle',
  },
  {
    photo: Time,
    title: 'advertisers_landing_item_4_title',
    subtitle: 'advertisers_landing_item_4_subtitle',
  },
  {
    photo: Search,
    title: 'advertisers_landing_item_5_title',
    subtitle: 'advertisers_landing_item_5_subtitle',
  },
  {
    photo: Exchange,
    title: 'advertisers_landing_item_6_title',
    subtitle: 'advertisers_landing_item_6_subtitle',
  },
];
