import React, { useEffect, useState } from 'react';
import Input from '../../../../component/common/Fields/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../../../../component/common/Fields/CustomSelect/CustomSelect';
import { options } from '../../config';
import { yupResolver } from '@hookform/resolvers/yup';
import { amountWithdrawal } from '../../../../../schemas/finanse';
import { connect } from 'react-redux';
import Button from '../../../../component/common/Button/Button';
import { useTranslation } from 'react-i18next';

const FirstStepFinance = ({ onSubmit, dataInfo, bloggerBudget }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: dataInfo,
    resolver: yupResolver(amountWithdrawal),
  });
  const [t] = useTranslation();

  const [showAmountInfo, setShowAmountInfo] = useState(null);
  const [valueConvert, setValueConvert] = useState('');
  useEffect(() => {
    const subscription = watch(({ amount = 0 }, { name, type }) => {
      updateValueOnCusre(
        amount,
        bloggerBudget?.rateInfo?.rate,
        bloggerBudget?.rateInfo?.currency,
      );

      if (name === 'amount' && amount.length > 7) {
        setValue('amount', amount.slice(0, 7));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, bloggerBudget]);
  const updateValueOnCusre = (amount, rate, currency) => {
    const sum = rate ? (rate * amount).toFixed(2) : null;
    setValueConvert(e =>
      sum && !isNaN(sum) ? sum + ` (${currency})` : 0 + ` (${currency})`,
    );
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="purchase__container">
        <div className="purchase__input">
          <p className="purchase__input-amount-info">
            {showAmountInfo
              ? t('finance_blogger_available_info') +
                bloggerBudget.availableToWithdrawal
              : ''}
          </p>
          <Input
            options={{
              labelText: t('finance_blogger_form_label_amount'),
              placeholderText: t('finance_blogger_form_placeholder_amount'),
              maxLength: 7,
              dynamicText: valueConvert,
              typeText: 'number',
              onKeyDown: evt =>
                evt.key === 'e' ||
                evt.key === '+' ||
                evt.key === '' ||
                evt.key === '-'
                  ? evt.preventDefault()
                  : '',
            }}
            onFocus={() => {
              setShowAmountInfo(true);
            }}
            onBlur={() => {
              setShowAmountInfo(false);
            }}
            inputChangeOptions={register('amount')}
          />
          <p className="finances-page__error-text">
            {errors?.amount?.message && t(errors.amount.message)}
          </p>
        </div>

        <div className="purchase__input">
          <Controller
            control={control}
            name="provider"
            render={field => (
              <CustomSelect
                name="method"
                fieldOptions={field}
                options={options}
                t={t}
              />
            )}
          />
        </div>
      </div>
      <div className="purchase__buy-button">
        <Button
          options={{
            text: t('finance_blogger_form_button_next_step'),
            renderType: 'primary',
            type: 'submit',
            disabled: !isValid,
          }}
        />
      </div>
    </form>
  );
};
const mapStateToProps = state => ({
  bloggerBudget: state.finance.bloggerBudget,
});
export default connect(mapStateToProps, null)(FirstStepFinance);
