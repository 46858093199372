import React from 'react';
import Button from '../common/Button/Button';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const GetInfoTextInPopup = ({
  details: { text, onClick, buttonText, title },
  closeModal,
  t,
}) => {
  return (
    <div className="info-text">
      {title && <p className="info-text__title" style={{}}>{t(title)}</p>}
      <p className="info-text__text">{t(text)}</p>

      <Button
        handleButtonClick={() => {
          onClick();
          closeModal();
        }}
        options={{
          text: buttonText ? t(buttonText) : 'OK',
          renderType: 'primary',
          size: 'l',
          type: 'submit',
        }}
      />
    </div>
  );
};

export default withTranslation()(GetInfoTextInPopup);
