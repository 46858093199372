import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BlogerCard from '../../../../component/renderCards/BlogerCard/BlogerCard';
import {
  instagramAprrovedAccountsSelector,
  newAdvertisementDataSelector,
  sessionPersonalInformationSelector,
} from '../../../../../redux/selectors/selectors';
import {
  editDirection,
  editOffset,
  editSearch,
  editSort,
  fetchInstagramApprovedAccounts,
  fetchInstagramApprovedAccounts2,
} from '../../../../../redux/store/instagram/instagramOperations';
import { openModal } from '../../../../../redux/store/modal/modalActions';
import { fetchAdvertisement } from '../../../../../redux/store/advertisement/advertisement/AdvertisementOperations';
import StepsDescription from '../../../../component/StepsDescription/StepDescription';
import SelectedBloggers from '../../../../component/SelectedBloggers/SelectedBloggers';
import { modalTypes } from '../../../../../configs/modalTypes';
import './styles.scss';
import '../../general.scss';
import HeaderListBlogger from '../../../../component/HeaderListBlogger/HeaderListBlogger';
import BudgetForm from '../../../../component/Forms/Company/BudgetForm/BudgetForm'
import {
  getOffsetInfo,
  getOptionsSearchSelector,
} from '../../../../../redux/store/instagram/instagramSelector';
import Pagination from '../../../../component/common/Pagination/Pagination';
import { typesErrorComponent } from '../../../../../configs/errorComponentTypes';
import ErrorComponent from '../../../../component/ErrorComponent/ErrorComponent';
import Paywall from '../../../../component/common/Fields/Paywall/Paywall';
import { withTranslation } from 'react-i18next';

import {getApprovedAccounts2 } from '../../../../../api/instagram-service/instagram-service'
const ManualSearch = ({ type, t}) => {
  const { _id } = useSelector(state => newAdvertisementDataSelector(state));
  const BloggersList = useSelector(state =>
    {
      let campaignType = localStorage.getItem('campaignType')
      if (campaignType == "youtube") return instagramAprrovedAccountsSelector(state)
      else {
        return instagramAprrovedAccountsSelector(state)
      }
    }
 
  )
  const sizeItemOnPage = 6;
  const offsetInfo = useSelector(state => getOffsetInfo(state));

  const [searchValue, setSearchValue] = useState('');
  const prevSearchValue = useSelector(getOptionsSearchSelector);
  const { isPaywalled } = false//useSelector(sessionPersonalInformationSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    let campaignType = localStorage.getItem('campaignType')
    if (campaignType == "youtube") dispatch(fetchInstagramApprovedAccounts('', type));
    else {
      dispatch(fetchInstagramApprovedAccounts2('', type));
    }
    dispatch(fetchAdvertisement(_id));
   
  }, [_id, type, dispatch]);
  const _onChengSort = e => {
    dispatch(editSort(e.value, type));
  };
  const _onClickSearch = () => {
    if (prevSearchValue !== searchValue) {
      dispatch(editSearch(searchValue, type));
    }
  };
  const _onChengSearch = ({ target }) => {
    setSearchValue(target.value);
  };
  const _onChengDirection = e => {
    dispatch(editDirection(e.value, type));
  };
  const _onClickFilter = () => {
    dispatch(
      openModal({
        isOpen: true,
        renderType: modalTypes.manualSearchBloggersFilter,
        type,
      }),
    );
  };
  const getNotFound = () => {
    return BloggersList?.items.length === 0 ? (
      <ErrorComponent type={typesErrorComponent.NOT_FOUND_SEARCH} />
    ) : null;
  };
  return (
    <div>
      <div className="new-company__create">
        <div className="new-company__left">
          <HeaderListBlogger
            onClickSearch={_onClickSearch}
            onChengSearch={_onChengSearch}
            onChengDirection={_onChengDirection}
            onChengSort={_onChengSort}
            onClickFilter={_onClickFilter}
          />
          {isPaywalled && (
            <Paywall title={t('blogger_list_paywalled')} type={type} />
          )}
          <div className="new-company__cards-list">
            {BloggersList.items.map(blogger =>{
                  return <BlogerCard
                    isPaywalled={isPaywalled}
                    key={blogger._id}
                    bloggerInformation={blogger}
                    type={type}
                  />
              }
            )}
          </div>
          {getNotFound()}
          <div className="auto-search__show-more">
            <Pagination
              items={BloggersList}
              elementType="page"
              sizeItemOnPage={sizeItemOnPage}
              getNextPageItems={e => {
                dispatch(editOffset(e, type));
              }}
              isStartedPage={offsetInfo}
            />
          </div>
        </div>
        <div className="new-company__right">
          <div className="auto-search__selected-bloggers">
            <SelectedBloggers />
          </div>
          <StepsDescription
            options={{
              title: t('advertisement_Step2_ManualSearch_info'),
              description: t('advertisement_Step2_ManualSearch_description'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ManualSearch);
