import Calendar from '../../../../../../assets/images/landing/advantages/calendar.svg';
import Exchange from '../../../../../../assets/images/landing/advantages/exchange.svg';
import Convert from '../../../../../../assets/images/landing/advantages/convert.svg';
import Search from '../../../../../../assets/images/landing/advantages/search.svg';
import Time from '../../../../../../assets/images/landing/advantages/time.svg';
import Like from '../../../../../../assets/images/landing/advantages/like.svg';

export const bloggers = [
  {
    photo: Like,
    title: 'bloggers_landing_benefits_item_1_title',
    text: 'bloggers_landing_benefits_item_1_text',
  },
  {
    photo: Convert,
    title: 'bloggers_landing_benefits_item_2_title',
    text: 'bloggers_landing_benefits_item_2_text',
  },
  {
    photo: Calendar,
    title: 'bloggers_landing_benefits_item_3_title',
    text: 'bloggers_landing_benefits_item_3_text',
  },
  {
    photo: Time,
    title: 'bloggers_landing_benefits_item_4_title',
    text: 'bloggers_landing_benefits_item_4_text',
  },
  {
    photo: Search,
    title: 'bloggers_landing_benefits_item_5_title',
    text: 'bloggers_landing_benefits_item_5_text',
  },
  {
    photo: Exchange,
    title: 'bloggers_landing_benefits_item_6_title',
    text: 'bloggers_landing_benefits_item_6_text',
  },
];
